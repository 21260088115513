import ReactDOM from 'react-dom';

import { useFileManagementContext } from 'components/templates/FileManagement/components/FileManagementContext';

import ErrorModalOverlay from './ErrorModalOverlay';
import ModalBackdrop from './Modal/ModalBackdrop';

export default function ErrorModal({ title, message, messages, onClose }) {
    const {
        state: { getRoot },
    } = useFileManagementContext();
    return (
        <>
            <ModalBackdrop rootElement={getRoot()} onClose={onClose} />
            {ReactDOM.createPortal(
                <ErrorModalOverlay
                    title={title}
                    messages={messages}
                    message={message}
                    onClose={onClose}
                />,
                getRoot().getElementById('modal-container')
            )}
        </>
    );
}
