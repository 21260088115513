import { useState } from 'react';
import TagManager from 'react-gtm-module';

import { GTM_AUTH, GTM_ID, GTM_PREVIEW, env } from 'constants/appConfig';
import { User } from 'providers/currentUser/UserDataProvider';

export type AddToUserDataLayer = (
    name: string,
    value: string | boolean | object | undefined | null
) => void;

export type InitGtm = (userData: User, isInternal: boolean) => void;

export const useGoogleTagManager = () => {
    const [initialized, setInitialized] = useState<boolean>(false);
    const initGtm: InitGtm = (userData, isInternal) => {
        TagManager.initialize({
            gtmId: GTM_ID as string,
            auth: GTM_AUTH,
            preview: GTM_PREVIEW,
            dataLayer: {
                ecid: userData.customerId,
                uid: userData.userId,
                auid: userData.userId,
                tname: userData.tenantName,
                environment: env === 'production' ? 'prod' : env,
                internal: isInternal,
            },
            dataLayerName: 'userData',
        });

        setInitialized(true);
    };

    const addToUserDataLayer: AddToUserDataLayer = (name, value) => {
        if (initialized) {
            TagManager.dataLayer({
                dataLayer: {
                    [name]: value,
                },
                dataLayerName: 'userData',
            });
        }
    };

    return { initGtm, addToUserDataLayer, isInitialized: initialized };
};
