import { ErrorMessage } from '@hookform/error-message';
import { useForm } from 'react-hook-form';

import Box from 'components/atoms/Box';
import Label from 'components/atoms/Label';

import { InputField, TextArea } from './Form.styles';

export interface CommonInputInterface {
    id: string;
    inputMode: typeof InputField | typeof TextArea;
    defaultValue?: string;
    value?: string;
    label: string;
    isRequired: boolean;
    validationConditions: object;
}

export interface FormInterface {
    inputs: Array<CommonInputInterface>;
    id: string;
    submit: any;
}

export default function Form({ inputs, id, submit }: FormInterface) {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        reValidateMode: 'onChange',
        mode: 'onSubmit',
        shouldUseNativeValidation: false,
        criteriaMode: 'all',
    });

    return (
        <form onSubmit={handleSubmit(submit)} id={id}>
            {inputs.map((input) => {
                const inputRegister = register(input.id, {
                    ...input.validationConditions,
                });
                return (
                    <Box css={{ margin: '8px 0' }} key={input.id}>
                        <Label
                            required={input.isRequired}
                            style={{
                                ...(errors[input.id] && {
                                    color: 'red',
                                    '> span': { color: 'red' },
                                }),
                            }}
                        >
                            {input.label}
                        </Label>
                        <ErrorMessage
                            errors={errors}
                            name={input.id}
                            render={({ messages }) => {
                                if (!messages) return;
                                return Object.keys(messages).map((x) => (
                                    <Box
                                        key={x}
                                        css={{
                                            fontWeight: 600,
                                            ...(errors[input.id] && {
                                                color: 'red',
                                            }),
                                        }}
                                    >
                                        {messages[x]}
                                    </Box>
                                ));
                            }}
                        />
                        <InputField
                            as={input.inputMode}
                            autoComplete={'off'}
                            defaultValue={input.defaultValue}
                            aria-autocomplete={'none'}
                            {...inputRegister}
                            error={!!errors[input.id]}
                        />
                    </Box>
                );
            })}
        </form>
    );
}
