import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useState } from 'react';

import { useToast } from 'components/_legacy/ccd-toast';
import Box from 'components/atoms/Box';
import { useProgressBar } from 'components/atoms/ProgressBar';
import ConfirmationModal from 'components/organisms/ProjectVersion/components/ConfirmationModal';
import {
    restoreRevisionFailed,
    restoreRevisionSuccess,
} from 'components/organisms/ProjectVersion/hooks/revisionOperationsMessages';
import { restoreRevision } from 'services/api/project.service';

interface RestoreRevision {
    collaborationSpaceId: string;
    projectId: string;
    setCurrent: (revision: number) => void;
    afterAction: () => void;
    beforeAction: () => void;
}

export const useRestoreRevision = ({
    setCurrent,
    afterAction,
    beforeAction,
    collaborationSpaceId,
    projectId,
}: RestoreRevision) => {
    const { showToast } = useToast();
    const { setProgressBar } = useProgressBar();
    const queryClient = useQueryClient();
    const [showDialog, setShowDialog] = useState(false);
    const [revisionId, setRevisionId] = useState('');

    const { mutate: mutateRestoreRevision } = useMutation(restoreRevision, {
        onSuccess: async (data) => {
            try {
                await queryClient.invalidateQueries({ queryKey: ['getProjectRevisions'] });
                showToast({
                    type: 'info',
                    title: restoreRevisionSuccess.title,
                    messages: restoreRevisionSuccess.messages,
                });
                const newRevision = Number.parseInt(data.revisionId);
                setCurrent(newRevision);
                const event = new CustomEvent('setCurrentVersion', {
                    detail: {
                        eventArgs: {
                            current: newRevision,
                            comment: data.commitDescription,
                        },
                    },
                });
                document.dispatchEvent(event);
                document.dispatchEvent(new CustomEvent('revisionChanged'));
            } catch (e) {
                console.error(e);
            } finally {
                setProgressBar(false);
                afterAction();
            }
        },
        onError: (error: AxiosError) => {
            try {
                console.error(error);
                const errorMessage = restoreRevisionFailed(error.response?.status);
                showToast({
                    type: 'error',
                    title: errorMessage.title,
                    messages: errorMessage.messages,
                });
            } catch (e) {
                console.log(e);
            } finally {
                setProgressBar(false);
                afterAction();
            }
        },
    });

    const DialogComponent = () => (
        <>
            {showDialog ? (
                <ConfirmationModal
                    title={'Restore version to latest'}
                    confirmLabel='Restore'
                    onClose={() => setShowDialog(false)}
                    onConfirm={async () => {
                        beforeAction();
                        setProgressBar(true);
                        setShowDialog(false);
                        mutateRestoreRevision({
                            collaborationSpaceId,
                            projectId,
                            revisionId,
                        });
                    }}
                >
                    <Box as={'span'} css={({ colors }) => ({ color: colors.text })}>
                        Do you want to restore <b>version {revisionId}</b> to latest?
                    </Box>
                </ConfirmationModal>
            ) : null}
        </>
    );

    return {
        openRestoreRevisionDialog: (revisionId: string) => {
            setRevisionId(revisionId);
            setShowDialog(true);
        },
        restoreRevisionDialog: <DialogComponent />,
    };
};
