export const CLOSE_SIDE_NAV = 'CLOSE_SIDE_NAV';
export const CLOSE_PROGRAMMABLE_MODALS = 'CLOSE_PROGRAMMABLE_MODALS';
export const OPEN_RENAME_FILE_SIDE_NAV = 'OPEN_RENAME_FILE_SIDE_NAV';
export const FILE_UPLOADED = 'FILE_UPLOADED';
export const FILE_DOWNLOADED = 'FILE_DOWNLOADED';
export const FILE_MOVED = 'FILE_MOVED';
export const FILE_DELETED = 'FILE_DELETED';
export const FILE_RENAMED = 'FILE_RENAMED';
export const FOLDER_CREATED = 'FOLDER_CREATED';
export const OPEN_IMAGE_VIEWER = 'OPEN_IMAGE_VIEWER';
export const OPEN_PDF_VIEWER = 'OPEN_PDF_VIEWER';
export const OPEN_HTML_ARCHIVE_VIEWER = 'OPEN_HTML_ARCHIVE_VIEWER';
