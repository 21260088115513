import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { useToast } from 'components/_legacy/ccd-toast';
import { useProgressBar } from 'components/atoms/ProgressBar';
import { useAuthContext } from 'providers/authorization/AuthProvider';
import { getAuroraOptOut, updateAuroraOptOut } from 'services/api/analyticsSettings.service';
import useFeatureFlagsQuery from 'services/queries/featuresFlags.query';
import { AuroraOptOut } from 'types/optOut';

export const useGetAuroraOptOutQuery = (enabled = true) => {
    const { isAuthenticated } = useAuthContext();
    const { data: featureFlags } = useFeatureFlagsQuery();
    return useQuery<AuroraOptOut>(['getOptOut'], getAuroraOptOut, {
        enabled:
            !!featureFlags?.Analytics_SiemensAuroraAnalyticsEnabled && isAuthenticated && enabled,
    });
};

export const useUpdateAuroraOptOut = ({ onSuccess }: { onSuccess?: () => void }) => {
    const queryClient = useQueryClient();
    const { showToast } = useToast();
    const { setProgressBar } = useProgressBar();

    return useMutation({
        mutationFn: updateAuroraOptOut,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['getOptOut'] });
            onSuccess && onSuccess();
        },
        onError: () => {
            showToast({
                type: 'error',
                messages: ['Cannot update opt-out settings'],
            });
        },
        onSettled: () => {
            setProgressBar(false);
        },
    });
};
