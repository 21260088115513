import { useCallback, useState } from 'react';

import { useToast } from 'components/_legacy/ccd-toast';
import useHttp from 'components/_legacy/hooks/useHttp';
import appConfig from 'constants/appConfig';

export default function useExportBom() {
    const { sendRequest } = useHttp();
    const { showToast } = useToast();

    const [exportInProgress, setExportInProgress] = useState(false);
    const exportBom = useCallback(
        (param: {
            collaborationspaceid: string;
            projectid: string;
            projectversion: string;
            dataCount: number;
            sortDirection: { direction: string; column: { id: string } } | undefined;
            columnsOrder: string[];
            variant?: string;
        }) => {
            const url = new URL(
                `/frontend/project/bom/export/${param.collaborationspaceid}/${param.projectid}`,
                appConfig.baseURL
            );
            for (const column of param.columnsOrder) {
                url.searchParams.append('columns', column);
            }
            url.searchParams.set('projectVersion', param.projectversion);
            url.searchParams.set('fileType', 'Xlsx');
            if (param.sortDirection?.column?.id) {
                url.searchParams.set('sortColumn', param.sortDirection.column.id);
            }
            if (param.sortDirection?.direction) {
                let direction;
                switch (param.sortDirection.direction) {
                    case 'ascending':
                        direction = 'ASC';
                        break;
                    case 'descending':
                        direction = 'DESC';
                        break;
                    default:
                        direction = 'NOSORT';
                }
                url.searchParams.set('sortOrder', direction);
            }
            param.variant && url.searchParams.set('Variant', param.variant);

            const processData = (data: { blob: () => any }) => data.blob();

            const onError = (error: any) => {
                console.error(error);
                showToast({
                    type: 'error',
                    title: 'Export failed...',
                    messages: ['Try again later.'],
                    autoClose: true,
                });
                setExportInProgress(false);
            };

            const onSuccess = (blob: Blob | MediaSource) => {
                const urlAddress = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = urlAddress;
                a.download = 'bom_export.xlsx';
                document.body.appendChild(a);
                a.click();
                URL.revokeObjectURL(urlAddress);
                a.remove();
                setExportInProgress(false);
            };

            const requestConfig = { url, method: 'GET' };
            const retryOptions = {};
            setExportInProgress(true);
            sendRequest({ requestConfig, retryOptions, processData, onSuccess, onError });
        },
        []
    );
    return {
        exportBom,
        exportInProgress,
    };
}
