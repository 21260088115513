import {
    NAME_INPUT_ID,
    NAME_IS_REQUIRED,
} from 'components/templates/FileManagement/components/parts/FileForm/consts';
import {
    reservedNamesValidator,
    specialCharactersValidator,
    trailingDot,
    whitespacesValidator,
} from 'shared/validation';
import useNameAlreadyExistsValidator from 'shared/validation/useNameAlreadyExistsValidator';

export function useGetFolderInputs() {
    const { nameAlreadyExists } = useNameAlreadyExistsValidator();
    return {
        getFolderInputs: (defaultValue = '') => [
            {
                label: 'Folder name',
                id: NAME_INPUT_ID,
                inputMode: 'text',
                defaultValue: defaultValue,
                isRequired: true,
                validationConditions: {
                    required: NAME_IS_REQUIRED,
                    maxLength: {
                        value: 256,
                        message: 'Name can have max 256 characters',
                    },
                    validate: {
                        noSpecialChar: specialCharactersValidator,
                        noReservedChar: reservedNamesValidator,
                        noTrailingDot: trailingDot,
                        noWhitespaces: (value) => {
                            return whitespacesValidator(
                                value,
                                'Folder name cannot contain only whitespaces'
                            );
                        },
                        nameAlreadyExists: nameAlreadyExists,
                    },
                },
            },
        ],
    };
}
