import styled from '@emotion/styled';
import MuiTooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

import { customColors } from 'theme';

export const Tooltip = styled(({ className, enterDelay = 750, ...props }: TooltipProps) => (
    <MuiTooltip {...props} enterDelay={enterDelay} classes={{ popper: className }} />
))(({ theme }) => ({
    zIndex: 3000,
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: customColors['black-0'],
        fontSize: theme.typography.pxToRem(12),
        border: `1px solid ${customColors['pl-black-23']}`,
        boxShadow: 'rgba(0, 0, 0, 0.3) 0 6px 11px',
    },
    [`& .${tooltipClasses.arrow}`]: {
        '&::before': {
            backgroundColor: 'white',
            border: `1px solid ${customColors['pl-black-23']}`,
        },
    },
}));

export default Tooltip;
