import { PropsWithChildren, createContext } from 'react';

import useRevisionsActionStatus from 'hooks/useRevisionsActionStatus';

export interface RevisionsActionContextInterface {
    revisionsActionInProgress: boolean;
}

export const RevisionsActionContext = createContext<RevisionsActionContextInterface>(
    {} as RevisionsActionContextInterface
);

export function RevisionsActionStatusProvider({ children }: PropsWithChildren): JSX.Element {
    const revisionsActionInProgress = useRevisionsActionStatus();
    return (
        <RevisionsActionContext.Provider value={revisionsActionInProgress}>
            {children}
        </RevisionsActionContext.Provider>
    );
}

export default RevisionsActionStatusProvider;
