import jwtDecode from 'jwt-decode';
import { PropsWithChildren, useEffect, useState } from 'react';

import { useAuthContext } from 'providers/authorization/AuthProvider';
import { useGetCollaborationSpaces } from 'services/queries/auth.query';
import { createGenericContext } from 'shared/createGenericContext';

interface IdToken {
    sub: string;
    email: string;
    given_name?: string;
    family_name?: string;
    'https://sws.siemens.com/identity/claims/provider': string;
    'https://sws.siemens.com/identity/claims/username': string;
    'sws.samauth.tier.region'?: string;
    'sws.samauth.ten': string;
    'sws.samauth.ten.name'?: string;
    'sws.samauth.ten.user'?: string;
    'sws.samauth.ten.extId'?: string;
    'sws.samauth.grp.names'?: string[];
    aud?: string;
    exp: number;
    iat?: number;
    iss?: string;
    userid?: string;
}

interface UserData {
    collaborationSpaceId: string;
    user?: User;
}
export interface User {
    name: string;
    email: string;
    userId?: string;
    tenantId: string;
    tenantName: string;
    customerId: string;
    samAuthTier?: string[];
}

const [useUserDataContext, UserDataContextProvider] = createGenericContext<UserData>();

const UserDataProvider = ({ children }: PropsWithChildren) => {
    const [userData, setUserData] = useState<User>();
    const { idToken } = useAuthContext();

    useEffect(() => {
        if (idToken) {
            const decoded = jwtDecode<IdToken>(idToken);
            setUserData({
                name: decoded.given_name + ' ' + decoded.family_name,
                email: decoded.email,
                userId: decoded['sws.samauth.ten.user'] || decoded.userid,
                tenantId: decoded['sws.samauth.ten'] || '',
                tenantName: decoded['sws.samauth.ten.name'] || '',
                customerId: decoded['sws.samauth.ten.extId'] || decoded['sws.samauth.ten'],
            });
        }
    }, [idToken]);

    const { data: collaborationSpaceId } = useGetCollaborationSpaces();

    return (
        <UserDataContextProvider
            value={{ collaborationSpaceId: collaborationSpaceId || '', user: userData }}
        >
            {children}
        </UserDataContextProvider>
    );
};

export { useUserDataContext, UserDataProvider };
