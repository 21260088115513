import { useProjectPermissionQuery } from 'providers/permissions/permission.query';
import { ProjectPermissions } from 'types/permissions';

export default function useProjectPermission(
    collaborationSpaceId: string,
    projectId: string | undefined
) {
    const { data } = useProjectPermissionQuery(collaborationSpaceId, projectId);
    function hasProjectPermission(operationId: ProjectPermissions): boolean {
        return data ? data[operationId] : false;
    }

    return {
        hasProjectPermission,
    };
}
