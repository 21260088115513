import React, { useCallback, useContext, useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';

import Events from '../CcdEvents';
import { FINDCHIPS_TOOLTIP_ID, SUPPLYFRAME_PREVIEW_URL } from '../consts';
import CurrentRowContext from '../contexts/CurrentRowContext';
import {
    ENABLE_TOOLTIP_EVENT,
    MESSAGE_EVENT,
    REBUILD_TOOLTIP_EVENT,
    SHOW_SIDE_NAV_EVENT,
} from '../events';

import './BomViewTooltip.css';

// BomViewTooltip
const tooltipConfiguration = {
    place: 'right',
    type: 'info',
    effect: 'solid',
    border: true,
    clickable: true,
    className: 'customTheme',
    arrowColor: 'white',
    borderColor: '#A3A39B',
    delayHide: 100,
    overridePosition: (pos, currentEvent, _currentTarget, _place, desiredPlace) => {
        const width = currentEvent.view?.innerWidth ?? window.innerWidth;
        const height = currentEvent.view?.innerHeight ?? window.innerHeight;

        const positionOfRight = pos.left + tooltipWidth;
        const positionOfBottom = pos.top + tooltipHeight;

        if (positionOfRight > width) {
            pos.left = pos.left - (positionOfRight - width) - tooltipPadding;
        }

        if (positionOfBottom > height) {
            pos.top = pos.top - (positionOfBottom - height) - tooltipPadding;
        }

        if (pos.top < 0) {
            pos.top = tooltipPadding;
        }

        if (pos.left < 0) {
            pos.left = tooltipPadding;
        }

        if (desiredPlace == 'top') {
            pos.top += 15;
        }

        if (desiredPlace == 'bottom') {
            pos.top -= 15;
        }

        return pos;
    },
};

const tooltipWidth = 500;
const tooltipHeight = 450;
const tooltipPadding = 100;

export default function BomViewTooltip({ isCloudEnvironment }) {
    const [tooltipLoaded, setTooltipLoaded] = useState(true);
    const [showTooltip, setShowTooltip] = useState(true);
    const [moreResultsFound, setMoreResultsFound] = useState(false);
    const [previousRow, setPreviousRow] = useState('0');
    const currentRow = useContext(CurrentRowContext);

    useEffect(() => {
        const enableTooltipEventHandler = (e) => {
            setShowTooltip(e.detail.flag);

            if (!e.detail.flag) {
                clearTooltip();
            }
        };

        const rebuildTooltipEventHandler = () => {
            ReactTooltip.rebuild();
            setTooltipLoaded(false);
            setMoreResultsFound(false);
        };

        const findchipsMessageEventHandler = (event) => {
            if (isCloudEnvironment && event.data?.totalResultsCount) {
                setMoreResultsFound(event.data.totalResultsCount > 1);
            }
        };

        window.addEventListener(ENABLE_TOOLTIP_EVENT, enableTooltipEventHandler);

        window.addEventListener(REBUILD_TOOLTIP_EVENT, rebuildTooltipEventHandler);

        window.addEventListener(MESSAGE_EVENT, findchipsMessageEventHandler);

        return () => {
            window.removeEventListener(ENABLE_TOOLTIP_EVENT, enableTooltipEventHandler);
            window.removeEventListener(REBUILD_TOOLTIP_EVENT, rebuildTooltipEventHandler);

            window.removeEventListener(MESSAGE_EVENT, findchipsMessageEventHandler);
        };
    }, []);

    const tooltipContent = useCallback(
        (datatip) => {
            const address = datatip
                ? SUPPLYFRAME_PREVIEW_URL + encodeURIComponent(datatip)
                : 'about:blank';

            const loaded = () => {
                if (!isCloudEnvironment) {
                    setMoreResultsFound(true);
                }
                setTooltipLoaded(true);
            };

            const showSideNav = (data) => Events.emit(SHOW_SIDE_NAV_EVENT, data);

            const style = tooltipLoaded ? { visibility: 'visible' } : { visibility: 'hidden' };

            return (
                <div className='ccd-bom__tooltip wrapper'>
                    {moreResultsFound === true && tooltipLoaded === true && (
                        <div className='showMore showMoreDiv'>
                            <span onClick={() => showSideNav(datatip)}>Show more</span>
                        </div>
                    )}
                    <div className='firstDiv'>
                        <iframe
                            id='findChipsTooltip'
                            title='tooltip'
                            src={address}
                            onLoad={loaded}
                            style={style}
                            loading='lazy'
                        />
                    </div>
                    <div className='secondDiv'>
                        {tooltipLoaded === false && (
                            <div>
                                <div className='loader' />
                                <span>Finding parts...</span>
                            </div>
                        )}
                    </div>
                </div>
            );
        },
        [tooltipLoaded, moreResultsFound]
    );

    const clearTooltip = useCallback(() => {
        setShowTooltip(false);
        setTooltipLoaded(false);
    }, []);

    const tooltipCallback = () => {
        setTimeout(() => setShowTooltip(false));
        setTimeout(() => setShowTooltip(true), 150);
    };

    useEffect(() => {
        if (currentRow !== -1 && currentRow !== previousRow) {
            setPreviousRow(currentRow);
            tooltipCallback();
        }
    }, [currentRow, previousRow]);

    return (
        <>
            {showTooltip && (
                <div onMouseLeave={tooltipCallback}>
                    <ReactTooltip
                        id={FINDCHIPS_TOOLTIP_ID}
                        {...tooltipConfiguration}
                        afterHide={() => {
                            clearTooltip();
                        }}
                        getContent={(datatip) => {
                            return tooltipContent(datatip);
                        }}
                    />
                </div>
            )}
        </>
    );
}
