import { useCallback } from 'react';

import useSiemensAnalytics from 'components/_legacy/hooks/useSiemensAnalytics';

import {
    EE,
    FILE_DELETED,
    FILE_DOWNLOADED,
    FILE_MOVED,
    FILE_RENAMED,
    FILE_UPLOADED,
    FOLDER_CREATED,
} from '../eventEmitter';
import { splitFileName } from '../utils';

const FILE_MANAGEMENT_CATEGORY = 'Project File Management';
const COMMAND_BAR_SOURCE = 'Command Bar';

function getExtension(fileName, isFolder = false) {
    if (isFolder) {
        return 'No Extension';
    }
    const extension = splitFileName(fileName).extension.replace(/^./, '').toUpperCase();
    return extension || 'No Extension';
}

export default function useAnalytics() {
    const { logCommand } = useSiemensAnalytics();

    function safeLogCommand(commandFunc) {
        try {
            commandFunc();
        } catch (error) {
            console.error('Error while handling analytics event', error);
        }
    }
    const fileUploadedListener = useCallback(
        ({ fileName, typeName, isDragAndDropUpload }) => {
            safeLogCommand(() => {
                logCommand({
                    commandName: 'File Upload',
                    commandProperties: {
                        event_action: 'File Upload',
                        event_category: FILE_MANAGEMENT_CATEGORY,
                        event_source: isDragAndDropUpload ? 'Drag-n-Drop' : 'Upload',
                        file_extension: getExtension(fileName),
                        file_type: typeName,
                    },
                });
            });
        },
        [logCommand]
    );
    const fileDownloadedListener = useCallback(
        ({ isFolder, typeName, fileName }) => {
            safeLogCommand(() => {
                logCommand({
                    commandName: 'File Download',
                    commandProperties: {
                        event_action: 'File Download',
                        event_category: FILE_MANAGEMENT_CATEGORY,
                        event_source: 'Download',
                        file_extension: getExtension(fileName, isFolder),
                        file_type: typeName,
                    },
                });
            });
        },
        [logCommand]
    );

    const fileMovedListener = useCallback(() => {
        safeLogCommand(() => {
            logCommand({
                commandName: 'File Move',
                commandProperties: {
                    event_action: 'File Move',
                    event_category: FILE_MANAGEMENT_CATEGORY,
                    event_source: COMMAND_BAR_SOURCE,
                },
            });
        });
    }, [logCommand]);

    const fileDeletedListener = useCallback(() => {
        safeLogCommand(() => {
            logCommand({
                commandName: 'File Delete',
                commandProperties: {
                    event_action: 'File Delete',
                    event_category: FILE_MANAGEMENT_CATEGORY,
                    event_source: COMMAND_BAR_SOURCE,
                },
            });
        });
    }, [logCommand]);

    const fileRenamedListener = useCallback(() => {
        safeLogCommand(() => {
            logCommand({
                commandName: 'File Rename',
                commandProperties: {
                    event_action: 'File Rename',
                    event_category: FILE_MANAGEMENT_CATEGORY,
                    event_source: COMMAND_BAR_SOURCE,
                },
            });
        });
    }, [logCommand]);

    const folderCreatedListener = useCallback(
        ({ folderLevel }) => {
            safeLogCommand(() => {
                logCommand({
                    commandName: 'Folder Create',
                    commandProperties: {
                        event_action: 'Folder Create',
                        event_category: FILE_MANAGEMENT_CATEGORY,
                        event_source: COMMAND_BAR_SOURCE,
                        folder_level: folderLevel,
                    },
                });
            });
        },
        [logCommand]
    );

    const initialize = () => {
        EE.addListener(FILE_UPLOADED, fileUploadedListener);
        EE.addListener(FILE_DOWNLOADED, fileDownloadedListener);
        EE.addListener(FILE_MOVED, fileMovedListener);
        EE.addListener(FILE_DELETED, fileDeletedListener);
        EE.addListener(FILE_RENAMED, fileRenamedListener);
        EE.addListener(FOLDER_CREATED, folderCreatedListener);
    };

    const dispose = () => {
        EE.removeListener(FILE_UPLOADED, fileUploadedListener);
        EE.removeListener(FILE_DOWNLOADED, fileDownloadedListener);
        EE.removeListener(FILE_MOVED, fileMovedListener);
        EE.removeListener(FILE_DELETED, fileDeletedListener);
        EE.removeListener(FILE_RENAMED, fileRenamedListener);
        EE.removeListener(FOLDER_CREATED, folderCreatedListener);
    };
    return { initialize, dispose };
}
