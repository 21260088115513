import useWebpageViewer from 'FileManagement/components/parts/CommandBar/hooks/useWebpageViewer';

import VerticalMenu, { TButtons } from 'components/molecules/VerticalMenu';
import useCurrentFolderContext from 'components/templates/FileManagement/components/CurrentFolderContextProvider/useCurrentFolderContext';
import useSortTiles from 'hooks/useSortTiles';

import {
    useAddFolder,
    useCommandBar,
    useDeleteFiles,
    useDisplayPdf,
    useDownloadCurrentFolder,
    useImageViewer,
    useMoveFile,
    useRenameFile,
    useRenameFolder,
    useSideNavFileUpload,
    useSwitchDisplay,
} from './hooks';
import useDownloadFile from './hooks/useDownloadFile';
import useMarkAsClean from './hooks/useMarkAsClean';

export default function CommandBar() {
    const { gridSortingHandler, onViewChange, sortType } = useCurrentFolderContext();
    const { isSeparatorVisible, isTileGroupVisible } = useCommandBar();

    const { sortTiles } = useSortTiles(gridSortingHandler, isTileGroupVisible, sortType);
    const { switchDisplay } = useSwitchDisplay({ onClick: onViewChange });
    const { addFolder, addFolderForm } = useAddFolder();
    const { sideNavFileUpload, uploadForm } = useSideNavFileUpload();
    const { downloadCurrentFolder, downloadCurrentFolderAnchor } = useDownloadCurrentFolder();
    const { renameFile, renameFileForm } = useRenameFile();
    const { renameFolder, renameFolderForm } = useRenameFolder();
    const { displayPdf, pdfViewer } = useDisplayPdf();
    const { imageViewer, imageViewerModal } = useImageViewer();
    const { deleteFiles, deleteFilesModal } = useDeleteFiles();
    const { moveFile, moveFileModal, moveFileWarning } = useMoveFile();
    const { downloadFile, downloadFileAnchor } = useDownloadFile();
    const { htmlViewer, displayHTML } = useWebpageViewer();
    const { markAsClean, markAsCleanAnchor } = useMarkAsClean();

    const buttons: TButtons = [
        switchDisplay,
        sortTiles,
        addFolder,
        sideNavFileUpload,
        downloadCurrentFolder,
        {
            id: 'selectionGroupSeparator',
            elementType: 'divider',
            visible: isSeparatorVisible,
        },
        renameFile,
        renameFolder,
        displayPdf,
        displayHTML,
        imageViewer,
        deleteFiles,
        moveFile,
        downloadFile,
        markAsClean,
    ] as TButtons;

    const buttonHelpers = [
        uploadForm,
        addFolderForm,
        downloadCurrentFolderAnchor,
        renameFileForm,
        renameFolderForm,
        pdfViewer,
        imageViewerModal,
        deleteFilesModal,
        moveFileModal,
        moveFileWarning,
        downloadFileAnchor,
        htmlViewer,
        markAsCleanAnchor,
    ];

    return (
        <div>
            <VerticalMenu key='command-bar' buttons={buttons} />
            {buttonHelpers}
        </div>
    );
}
