import { useTheme } from '@emotion/react';
import { useEffect, useMemo, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useParams } from 'react-router';
import ReactTooltip from 'react-tooltip';

import Box from 'components/atoms/Box';
import { useProgressBar } from 'components/atoms/ProgressBar';
import ProgressSpinner from 'components/atoms/ProgressSpiner';
import { ScanErrorTooltipContainer } from 'components/atoms/ScanErrorTooltipContainer/ScanErrorTooltipContainer';
import { UnlockSuspiciousVersionDialog } from 'components/organisms/ProjectVersion/UnlockSuspiciousVersionDialog';
import { LoadingRevisions } from 'components/organisms/ProjectVersion/components/Footer/LoadingRevisions';
import { RevisionsLoaded } from 'components/organisms/ProjectVersion/components/Footer/RevisionsLoaded';
import { useUserDataContext } from 'providers/currentUser/UserDataProvider';
import { useGetProjectRevisions } from 'services/queries/project.query';
import { LoadingStateLabel } from 'types/loadingStateLabel';
import { ProjectRevision } from 'types/project';

import convertDate from '../ProjectHistoryPanel/utils/RevisionDateToString';
import ProjectHistoryTitle from '../ProjectHistoryTitle';
import ProjectHistoryViewAccordion from '../ProjectHistoryViewAccordion';
import emptyStates from '../ProjectHistoryViewEmptyState';
import ProjectInfoContext from '../ProjectInfoContext';

const { ProjectHistoryViewEmptyState } = emptyStates;

interface HistoryViewRevisions {
    [k: string]: ProcessedRevision[];
}

export interface ProcessedRevision extends ProjectRevision {
    eventName: 'Design started ' | 'Design Check In update!';
    eventDescription: 'Created' | 'Modified';
}

export default function ProjectHistoryView({
    projectName,
    revision,
    thumbnail,
}: {
    projectName: string;
    revision: string;
    thumbnail: string;
}) {
    const [successVisibility, setSuccessVisibility] = useState(true);
    const [revisions, setRevisions] = useState<HistoryViewRevisions>();
    const { setProgressBar } = useProgressBar();
    const { collaborationSpaceId } = useUserDataContext();
    const { projectId } = useParams();
    const { ref, inView } = useInView();
    const theme = useTheme();

    const {
        isLoading: isLoadingRevisions,
        isFetchingNextPage,
        data: dataInfiniteRevisions,
        fetchNextPage,
        hasNextPage,
    } = useGetProjectRevisions({
        collaborationSpaceId,
        projectId: projectId,
    });

    const data: ProjectRevision[] = useMemo(
        () =>
            dataInfiniteRevisions?.pages
                .flat()
                .map((x) => x.data)
                .flat() ?? [],
        [dataInfiniteRevisions]
    );

    useEffect(() => {
        if (inView && !isFetchingNextPage) {
            fetchNextPage();
        }
    }, [inView]);

    // disable progressBar on unmount
    useEffect(() => () => setProgressBar(false), []);

    const initialDirection = 1;

    const processRevisions = (revisions: ProjectRevision[]): HistoryViewRevisions => {
        const processRevision = (revision: ProjectRevision): ProcessedRevision => {
            return {
                ...revision,
                eventName:
                    revision.versionNumber === '1' ? 'Design started ' : 'Design Check In update!',
                eventDescription: revision.versionNumber === '1' ? 'Created' : 'Modified',
            };
        };

        const processed = revisions.map((x) => processRevision(x));

        return processed.reduce((group: HistoryViewRevisions, revision) => {
            const date = convertDate(revision.lastModified.date);
            group[date] = group[date] ?? [];
            group[date].push(revision);
            return group;
        }, {});
    };

    const getRevisionsCallback = (data?: ProjectRevision[]) => {
        if (data === undefined) {
            return;
        }
        setProgressBar(false);
        setRevisions(processRevisions(data));
    };

    useEffect(() => {
        getRevisionsCallback(data);

        // @ts-ignore
        document.addEventListener('revisionChanged', getRevisionsCallback);
        return () => {
            // @ts-ignore
            document.removeEventListener('revisionChanged', getRevisionsCallback);
        };
    }, [data]);

    const renderAccordions = (revisions: HistoryViewRevisions) => {
        const accordions = [];
        const accordionType = (index: number, amount: number) => {
            if (amount === 1) return 'single';
            if (index === 0) return 'last';
            if (index === amount - 1) return 'first';
        };

        let count = 0;
        let directionCounter = 0;
        const revisionsCx = Object.keys(revisions).length;
        for (const index of Object.keys(revisions)) {
            const accordion = (
                <ProjectHistoryViewAccordion
                    key={'ProjectHistoryViewAccordion' + index}
                    accordionType={accordionType(count, revisionsCx)}
                    title={index}
                    revisions={revisions[index]}
                    initialDirection={(directionCounter + initialDirection) % 2}
                />
            );
            directionCounter += revisions[index].length;
            count++;
            accordions.push(accordion);
        }
        return accordions;
    };

    return (
        <ProjectInfoContext.Provider
            value={{
                projectName: projectName,
                thumbnail: thumbnail,
                currentRevision: +revision,
            }}
        >
            {isLoadingRevisions && <ProgressSpinner label={LoadingStateLabel.HISTORY} />}
            {data && data.length === 0 && !isLoadingRevisions && <ProjectHistoryViewEmptyState />}
            {revisions && data && data.length > 0 && (
                <>
                    <Box
                        css={{
                            display: 'flex',
                            flexDirection: 'column',
                            flexGrow: 1,
                            maxWidth: 900,
                            margin: '12px auto 0',
                            height: '100%',
                            overflow: 'hidden',
                            backgroundColor: theme.colors.accordion.background,
                        }}
                    >
                        <ProjectHistoryTitle />
                        <Box
                            css={{
                                overflowY: 'auto',
                                flex: '2 1 auto',
                            }}
                        >
                            {renderAccordions(revisions)}
                            <Box css={{ height: '1px' }} ref={ref} />
                        </Box>
                        <Box>
                            {(dataInfiniteRevisions === undefined || isFetchingNextPage) && (
                                <LoadingRevisions searchEnabled={false} />
                            )}
                            {dataInfiniteRevisions && !hasNextPage && successVisibility && (
                                <RevisionsLoaded
                                    onClick={() => {
                                        setSuccessVisibility(false);
                                    }}
                                />
                            )}
                        </Box>
                    </Box>
                    <UnlockSuspiciousVersionDialog />
                    <ScanErrorTooltipContainer place={'top'} />
                    <ReactTooltip
                        id='tooltipScanWarn'
                        place={'top'}
                        effect='solid'
                        type='light'
                        border
                        arrowColor='white'
                        borderColor='#A3A39B'
                        delayHide={500}
                        clickable
                        className='ccd-project-version-tooltip'
                    />
                </>
            )}
        </ProjectInfoContext.Provider>
    );
}
