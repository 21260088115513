import { ErrorMessage } from '@hookform/error-message';
import isEmpty from 'lodash/isEmpty';
import { arrayOf, bool, func, object, string } from 'prop-types';
import React, { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import Box from 'components/atoms/Box';
import { Input } from 'components/atoms/Input/Input';

import './Form.scss';

/**
 *  Form component provide ability to create form with multiple inputs.
 *  Input element has ability to validate input value. For invalid inputs error message will be displayed.
 *
 *  Error handling start after first submit. Each error message will be displayed on single line.
 *
 * @param {Input[]} inputs - required. Array contains definition of each input field. For definition look at @input param.
 * @param {string} id - required. String used for connecting submit button to Form instance.
 * @callback submit - required. Callback call on submit action.
 * @param focus - optional. Hook state. When parent sets this value to existing {@link Input#id} inputs id focus on this filed will be triggered.
 */

/**
 * @typedef Input - single object of inputs array.
 * @type Object
 * @property {string} label - required. String displayed as input's label.
 * @property {string} id - required and unique. String used by react hook form.
 * @property {string} defaultValue - optional. String displayed as input value on initial render.4
 * @property {boolean} isRequired - optional. If set to true label will be marked as required.
 * @property {Object} validationConditions - Object contains validation conditions with error messages.
 * @property {bool} isLoading - Determios if form is during submit state and blocks it's inputs.
 * Check react hook form documentation {@link https://react-hook-form.com/api/useform/register}
 */
export default function Form({ inputs, id, submit, focus, isLoading }) {
    const {
        register,
        handleSubmit,
        setFocus,
        formState: { errors },
    } = useForm({
        reValidateMode: 'onChange',
        mode: 'onSubmit',
        shouldUseNativeValidation: false,
        criteriaMode: 'all',
    });

    useEffect(() => {
        !isEmpty(focus) && setFocus(focus, { shouldSelect: true });
    }, [focus, setFocus]);

    function errorExists(inputId) {
        const err = errors[inputId]?.types;
        return err ? err.length !== 0 : false;
    }

    const getErrorRender = useCallback(({ messages }) => {
        const tempArray = [];
        const func = (message) => {
            return (
                <Box
                    key={message}
                    className='ccd-add-folder-label ccd-add-folder-label-error-message'
                    css={({ colors }) => ({
                        color: colors.error.text,
                    })}
                >
                    {message}
                </Box>
            );
        };
        for (const message in messages) {
            // Check here is unnecessary because message property is non-enumerable https://stackoverflow.com/a/25724382
            // noinspection JSUnfilteredForInLoop
            tempArray.push(func(messages[message]));
        }
        return tempArray;
    }, []);

    useEffect(() => {
        // focus very first element after receiving inputs' list
        !isEmpty(inputs) && setFocus(inputs[0].id, { shouldSelect: true });
    }, [inputs, setFocus]);

    function fieldsRenderer(inputsDefinition) {
        return inputsDefinition.map((input) => {
            const inputRegister = register(input.id, {
                ...input.validationConditions,
            });
            return (
                <div key={input.id}>
                    <div>
                        {input.isRequired && (
                            <Box
                                as={'label'}
                                className={'ccd-add-folder-required'}
                                css={({ colors }) => ({
                                    color: colors.header,
                                    ...(errorExists(input.id) && { color: colors.error.text }),
                                })}
                            >
                                *
                            </Box>
                        )}
                        <Box
                            as={'label'}
                            className={'ccd-add-folder-label'}
                            css={({ colors }) => ({
                                color: colors.text,
                                ...(errorExists(input.id) && { color: colors.error.text }),
                            })}
                        >
                            {input.label}:
                        </Box>
                    </div>
                    <ErrorMessage errors={errors} name={input.id} render={getErrorRender} />

                    <Input
                        id={`${id}-${input.id}`}
                        className={'ccd-add-folder-input'}
                        css={({ colors }) => ({
                            color: colors.text,
                            border: `1px solid ${colors.textArea.border}`,
                            '&:hover': {
                                outline: `1px solid ${colors.textArea.hover.border}`,
                                outlineOffset: '-2px',
                            },
                            '&:focus-within': {
                                outline: `solid 2px ${colors.textArea.focus.border}`,
                                outlineOffset: '-2px',
                            },
                            ...(errorExists(input.id) && {
                                background: 'rgba(220, 0, 0, 0.1)',
                                borderColor: colors.textArea.error.border,
                                '&:focus-within': {
                                    outline: `solid 1px ${colors.textArea.error.border}`,
                                    outlineOffset: '-2px',
                                },
                            }),
                            '&:disabled': {
                                color: colors.disabledText,
                            },
                        })}
                        defaultValue={input.defaultValue}
                        autoComplete='off'
                        aria-label={input.id}
                        {...inputRegister}
                        disabled={isLoading}
                    />
                </div>
            );
        });
    }

    return (
        <form onSubmit={handleSubmit(submit)} id={id} className='ccd-add-folder-container'>
            {fieldsRenderer(inputs)}
        </form>
    );
}

Form.propTypes = {
    inputs: arrayOf(object),
    id: string,
    submit: func,
    focus: string,
    isLoading: bool,
};
