import React, { PropsWithChildren, createContext } from 'react';

import useSubscriptionManager from 'hooks/useSubscriptionManager';

export const SubscriptionManagerContext = createContext<any>({});

export function SubscriptionManagerProvider({ children }: PropsWithChildren): JSX.Element {
    const subscriptionManager = useSubscriptionManager();

    return (
        <SubscriptionManagerContext.Provider value={subscriptionManager}>
            {children}
        </SubscriptionManagerContext.Provider>
    );
}

export default SubscriptionManagerProvider;
