import { useCallback, useEffect, useState } from 'react';

import { ccdErrorToast } from 'components/_legacy/ccd-toast';
import useHttp from 'components/_legacy/hooks/useHttp';
import { useFileManagementContext } from 'components/templates/FileManagement/components/FileManagementContext';

import { unexpectedError } from '../../../consts';
import { useDownload } from '../../../hooks';

export default function useImageViewerModal(closeModal) {
    const {
        state: { getRoot },
    } = useFileManagementContext();

    const { getDownloadFileUrl } = useDownload();
    const { sendRequest } = useHttp();

    const imageViewerContainerId = 'image-viewer-container';
    const imageViewerElement = () => getRoot().getElementById(imageViewerContainerId);
    const imageViewerToolbarId = 'image-viewer-toolbar';
    function imageViewerToolbarElement() {
        return getRoot().getElementById(imageViewerToolbarId);
    }

    const [image, setImage] = useState('');
    const [size, setSize] = useState({ width: 0, height: 0 });
    const [originalSize, setOriginalSize] = useState({ width: 0, height: 0 });
    const [isLoaded, setLoaded] = useState(false);
    const [zoomCounter, setZoomCounter] = useState(0);
    const [isReset, setReset] = useState(false);

    const zoomFactor = 1.5;

    useEffect(() => {
        async function action() {
            const downloadFileUrl = await getDownloadFileUrl();

            if (!downloadFileUrl) {
                closeModal();
                return;
            }

            const requestConfig = {
                url: downloadFileUrl,
            };

            const processData = async (response) => response.blob();

            const onError = () => {
                closeModal();
                ccdErrorToast(unexpectedError);
            };

            const onSuccess = (responseBlob) => {
                setImage(URL.createObjectURL(responseBlob));
            };

            await sendRequest({
                requestConfig,
                processData,
                onError,
                onSuccess,
            });
        }
        action();
    }, []);

    useEffect(() => {
        if (isLoaded) {
            const scrollContainerHeight =
                imageViewerElement().offsetHeight - imageViewerToolbarElement().offsetHeight;
            const relativeHeight = originalSize.height / scrollContainerHeight;
            const relativeWidth = originalSize.width / imageViewerToolbarElement().offsetWidth;
            if (relativeHeight > relativeWidth) {
                setSize({ width: undefined, height: scrollContainerHeight });
            } else {
                setSize({
                    width: imageViewerElement().offsetWidth,
                    height: undefined,
                });
            }
        }
    }, [isLoaded, originalSize]);

    const onLoad = (event) => {
        if (!isLoaded) {
            setOriginalSize({
                width: event.currentTarget.naturalWidth,
                height: event.currentTarget.naturalHeight,
            });
            setLoaded(true);
        }
    };

    const zoomIn = useCallback(() => {
        if (isReset) {
            fitToWindow();
            return;
        }
        if (zoomCounter >= 4) {
            return;
        }
        setZoomCounter((prevState) => prevState + 1);
        if (size.width) {
            setSize((prev) => {
                return { ...prev, width: prev.width * zoomFactor };
            });
        } else {
            setSize((prev) => {
                return { ...prev, height: prev.height * zoomFactor };
            });
        }
    }, [zoomCounter, isReset, size]);

    const zoomOut = useCallback(() => {
        if (isReset) {
            fitToWindow();
            return;
        }
        if (zoomCounter <= 0) {
            return;
        }
        setZoomCounter((prevState) => prevState - 1);
        if (size.width && imageViewerElement().offsetWidth < size.width) {
            setSize((prev) => {
                return { ...prev, width: prev.width / zoomFactor };
            });
        } else if (size.height && imageViewerElement().offsetHeight < size.height) {
            setSize((prev) => {
                return { ...prev, height: prev.height / zoomFactor };
            });
        }
    }, [zoomCounter, isReset, size]);

    const resetToOriginalSize = useCallback(() => {
        setReset(true);
        setZoomCounter(0);
        setSize((prevState) => {
            if (prevState.width) {
                return { ...prevState, width: originalSize.width };
            } else {
                return { ...prevState, height: originalSize.height };
            }
        });
    }, [originalSize]);

    const stopPropagation = (event) => event.stopPropagation();

    const fitToWindow = () => {
        setZoomCounter(0);
        setReset(false);
        setSize((prevState) => {
            if (prevState.width) {
                return {
                    ...prevState,
                    width: imageViewerElement().offsetWidth,
                };
            } else {
                return {
                    ...prevState,
                    height:
                        imageViewerElement().offsetHeight -
                        imageViewerToolbarElement().offsetHeight,
                };
            }
        });
    };

    useEffect(() => {
        if (image) {
            window.addEventListener('resize', fitToWindow);
        } else {
            window.removeEventListener('resize', fitToWindow);
        }
        return () => window.removeEventListener('resize', fitToWindow);
    }, [image]);

    return {
        size,
        image,
        isLoaded,
        imageViewerToolbarId,
        imageViewerContainerId,
        onLoad,
        zoomIn,
        zoomOut,
        stopPropagation,
        resetToOriginalSize,
    };
}
