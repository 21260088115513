import { useCallback } from 'react';

import { useCcdFetch } from 'components/_legacy/hooks/useCcdFetch';
import { useFileManagementContext } from 'components/templates/FileManagement/components/FileManagementContext';

export default function useMoreFlexibleReplace() {
    const {
        state: { backendUrl, collaborationSpaceId, projectId },
    } = useFileManagementContext();
    const { ccdFetch } = useCcdFetch();

    const onReplace = useCallback(
        async (file, conflictFileId) => {
            const replaceResponse = await replaceFile(file, conflictFileId);
            let response;
            if (replaceResponse.ok) {
                response = await replaceResponse.json();
                return {
                    fileName: file.name,
                    typeName: response.typeName,
                };
            } else {
                console.error(replaceResponse);
                throw replaceResponse;
            }
        },
        [replaceFile]
    );

    async function replaceFile(fileObj, fileId) {
        const url = new URL(
            `/frontend/file-management/${collaborationSpaceId}/${projectId}/document/${fileId}`,
            backendUrl
        );
        const data = new FormData();

        data.append('file', fileObj);
        return ccdFetch(url.toString(), { method: 'PATCH', body: data });
    }

    return { onReplace };
}
