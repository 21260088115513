import { useQuery } from '@tanstack/react-query';

import allUsersApi from 'components/templates/ProjectUsers/services/api/allUsers.api';
import { useUserDataContext } from 'providers/currentUser/UserDataProvider';

const allUsers = () => {
    const { collaborationSpaceId } = useUserDataContext();
    return useQuery(
        ['getAllUsers', collaborationSpaceId],
        async () =>
            allUsersApi({
                collaborationSpaceId,
            }),
        {
            enabled: !!collaborationSpaceId,
        }
    );
};

export default allUsers;
