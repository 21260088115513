import { useFileManagementContext } from 'FileManagement/components/FileManagementContext';

export function DesCollabWebpageViewer({ accId, revisionUrn }) {
    const {
        state: { collaborationSpaceId, projectId },
    } = useFileManagementContext();
    return (
        <des-collab-webpage-viewer
            acc-session-id={accId}
            collaborationspace-id={collaborationSpaceId}
            container-type='project'
            container-id={projectId}
            revision-urn={revisionUrn}
        />
    );
}
