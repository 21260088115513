import { arrayOf, func, shape, string } from 'prop-types';
import { useCallback } from 'react';

import { DialogButton } from 'components/atoms/Button';
import Dialog from 'components/molecules/Dialog';

import useMultipleFilesAlreadyExist from './hooks/useMultipleFilesAlreadyExist';

const FileManagementFilesAlredyExistPrefix = 'file-management-files-already-exist';

const MultipleFilesAlreadyExist = ({ fileNames, onClose, isDragAndDropUpload }) => {
    const { replaceHandler, renameHandler } = useMultipleFilesAlreadyExist({
        isDragAndDropUpload,
    });

    const wrappedReplaceHandler = useCallback(async () => {
        await replaceHandler(fileNames, onClose);
    }, [replaceHandler, fileNames, onClose]);

    const wrappedRenameHandler = useCallback(async () => {
        await renameHandler(fileNames, onClose);
    }, [renameHandler, fileNames, onClose]);

    return (
        <Dialog
            title={'Multiple files already exist'}
            buttons={
                <>
                    <DialogButton
                        color='primary'
                        variant={'outlined'}
                        onClick={onClose}
                        id={'CancelButton'}
                    >
                        Cancel
                    </DialogButton>
                    <DialogButton
                        color='secondary'
                        variant={'contained'}
                        onClick={wrappedReplaceHandler}
                        id={'ReplaceButton'}
                    >
                        Replace
                    </DialogButton>
                    <DialogButton
                        color='primary'
                        variant={'contained'}
                        onClick={wrappedRenameHandler}
                        id={'RenameButton'}
                    >
                        Rename
                    </DialogButton>
                </>
            }
            id={FileManagementFilesAlredyExistPrefix}
            open
            setOpen={onClose}
        >
            <div className='ccd-fm__modal-text-bolded'>
                {fileNames.length}&nbsp;files exist in this folder.
            </div>
            <div>Replace the existing one or quickly rename?</div>
        </Dialog>
    );
};

MultipleFilesAlreadyExist.propTypes = {
    fileNames: arrayOf(shape({ name: string })),
    onClose: func,
};

export default MultipleFilesAlreadyExist;
