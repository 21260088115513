import { useState } from 'react';

import { DialogButton } from 'components/atoms/Button';
import { ISelectList } from 'components/atoms/ComboBox';
import Dialog from 'components/molecules/Dialog';
import MpnComboBox, { BomColumn } from 'components/molecules/MpnComboBox/MpnComboBox';
import { Header, Paragraph } from 'components/organisms/DataPrivacy/DataPrivacy.styles';

interface IColumnMappingDialog {
    defaultMpn?: string | undefined;
    bomColumns: ISelectList[];
    open: boolean;
    setOpen: (open: boolean) => void;
    onConfirm: (selectedMpnColumn: string) => void;
    onClose?: () => void;
}

export default function ColumnMappingDialog({
    defaultMpn,
    bomColumns,
    open,
    setOpen,
    onConfirm,
    onClose,
}: IColumnMappingDialog) {
    const [selectedMpn, setSelectedMpn] = useState<string | undefined>(defaultMpn);

    return (
        <Dialog
            title='Configure BOM Intelligence'
            open={open}
            setOpen={setOpen}
            onCloseButton={onClose}
            onInteractOutside={onClose}
            buttons={
                <DialogButton
                    id={'buttonOK'}
                    color={'primary'}
                    variant={'contained'}
                    onClick={() => {
                        onConfirm(selectedMpn as string);
                    }}
                    data-testid={'confirmationDialogConfirmButton'}
                    css={{ width: '100%', margin: 0 }}
                >
                    {selectedMpn ? 'OK' : 'Done'}
                </DialogButton>
            }
            contentCssOverride={{
                maxWidth: 460,
            }}
        >
            <Header>Data fetching</Header>
            <Paragraph>
                For BOM Intelligence to work properly, you need to define the column with your part
                list.
            </Paragraph>
            {selectedMpn && (
                <Paragraph>
                    We have detected the following column with MPN. Is it correct?
                </Paragraph>
            )}
            <MpnComboBox
                defaultMpn={selectedMpn}
                bomColumns={bomColumns}
                onChange={(value) => {
                    setSelectedMpn((value as BomColumn).value);
                }}
            ></MpnComboBox>
        </Dialog>
    );
}
