import Box from 'components/atoms/Box';
import useDescriptionResultVisualize from 'components/organisms/ProjectVersion/hooks/descriptionResultVisualizeHook';
import useVisualizeSearchHook from 'components/organisms/ProjectVersion/hooks/visualizeSearchHook';

export const SearchDescription = ({
    versionDescription,
    criteria,
}: {
    criteria: string[];
    versionDescription: string;
}) => {
    const { prepareDescriptionText } = useDescriptionResultVisualize();
    const { visualizeSearch } = useVisualizeSearchHook();

    const descriptionText = prepareDescriptionText(versionDescription, criteria);

    const visualized = visualizeSearch(descriptionText, criteria);

    return (
        <>
            {descriptionText && (
                <Box>
                    <Box>
                        <div>
                            <span id='description'>in description:</span>
                            {visualized}
                        </div>
                    </Box>
                </Box>
            )}
        </>
    );
};
