import styled from '@emotion/styled';
import React, { MouseEvent, useCallback } from 'react';
import { Cell } from 'react-table';

import { CellRendererProps } from 'components/_legacy/ccd-table';
import Box from 'components/atoms/Box';
import Tooltip from 'components/atoms/Tooltip';
import { ISelectedFile } from 'components/templates/FileManagement/contexts/FileManagementContext';
import { InfectedIcon, Paragraph } from 'components/templates/ProjectTiles/ProjectTiles.styles';
import { isScanStatusInfected } from 'shared/utility';

import './CcdFileNameCellRenderer.scss';

function getIconClass(cell: Cell<ISelectedFile>) {
    if (cell.row.original.isFolder) {
        return 'ccd-folder-name';
    }
    if (cell.row.original.typeName === 'WebPageZipRevision') {
        return 'ccd-html-viewer-file';
    }
    return cell.value.split('.').pop();
}

export default function FileNameCellRenderer({
    cell,
    onCellNameClick,
    isItemOpenable,
}: {
    cell: CellRendererProps<ISelectedFile>;
    isItemOpenable: (item: ISelectedFile) => boolean;
    onCellNameClick: (item: ISelectedFile) => void;
}) {
    const handleOnClick = useCallback(
        (event: MouseEvent) => {
            event.stopPropagation();
            isItemOpenable(cell.row.original) && onCellNameClick(cell.row.original);
        },
        [cell]
    );

    const StyledSpan = styled('span')(({ theme }) => ({
        margin: theme.spacing(0, 1, 0, 0),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        float: 'right',
        cursor: 'pointer',
    }));

    return (
        <>
            <span
                className={`ccd-file-name ${getIconClass(cell)}`}
                onClick={handleOnClick}
                data-testid={'FileIcon'}
            />
            <Box
                as={'span'}
                css={({ colors }) => ({
                    ...(isItemOpenable(cell.row.original) && {
                        ':hover': { color: colors.header, cursor: 'pointer' },
                    }),
                })}
                onClick={handleOnClick}
                data-testid={'FileName'}
            >
                {cell.value}
            </Box>
            {isScanStatusInfected(cell.row.original.contentScanInfo) ? (
                <Tooltip
                    title={'Security scan detected a threat in this file'}
                    arrow
                    enterDelay={500}
                    placement='top'
                >
                    <StyledSpan id='infectedFile'>
                        <Paragraph>Threats detected</Paragraph>
                        <InfectedIcon />
                    </StyledSpan>
                </Tooltip>
            ) : null}
        </>
    );
}
