import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';

import SessionTimeoutDialog from 'components/organisms/SessionTimeoutDialog';
import { useAuthContext } from 'providers/authorization/AuthProvider';

const REFRESH_TOKEN_EXPIRATION_TIMESTAMP = 'REFRESH_TOKEN_EXPIRATION_TIMESTAMP';
const useSessionTimeout = () => {
    const [openDialog, setOpenDialog] = useState(false);
    const { idToken } = useAuthContext();

    useEffect(() => {
        if (idToken) {
            const timer60 = setSessionEndTimeout(60);
            const timer15 = setSessionEndTimeout(15);
            const timer2 = setSessionEndTimeout(2);
            return () => {
                timer60 && clearTimeout(timer60);
                timer15 && clearTimeout(timer15);
                timer2 && clearTimeout(timer2);
            };
        }
    }, [idToken]);

    const refreshTokenExpirationTimestamp = Cookies.get(REFRESH_TOKEN_EXPIRATION_TIMESTAMP) || '0';
    const cookieTime = parseInt(refreshTokenExpirationTimestamp);
    const nowTime = new Date().getTime();
    const timeDiff = cookieTime - nowTime;

    function setSessionEndTimeout(minutesBefore: number) {
        if (isNaN(timeDiff)) {
            console.error('REFRESH_TOKEN_EXPIRATION_TIMESTAMP returned NaN');
            return;
        }
        const msBefore = minutesBefore * 60 * 1000;
        const delay = timeDiff - msBefore;

        return delay > 0 && setTimeout(() => setOpenDialog(true), delay);
    }

    return {
        sessionTimeoutModal:
            openDialog && refreshTokenExpirationTimestamp ? (
                <SessionTimeoutDialog
                    open={openDialog}
                    setOpen={setOpenDialog}
                    timeout={Math.round(timeDiff / 1000)}
                />
            ) : null,
    };
};

export default useSessionTimeout;
