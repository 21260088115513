import { useQueryClient } from '@tanstack/react-query';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import trim from 'lodash/trim';
import { useCallback, useMemo, useState } from 'react';

import {
    ccdLoadingCompletedWithError,
    ccdLoadingCompletedWithSuccess,
    ccdLoadingToast,
} from 'components/_legacy/ccd-toast';
import useCurrentFolderContext from 'components/templates/FileManagement/components/CurrentFolderContextProvider/useCurrentFolderContext';
import { useFileManagementContext } from 'components/templates/FileManagement/components/FileManagementContext';
import { useGetFileInputs } from 'components/templates/FileManagement/components/parts/FileForm';
import { FILES_QUERY } from 'components/templates/FileManagement/components/parts/Table/service/files.query';

import { EE, FILE_UPLOADED, OPEN_RENAME_FILE_SIDE_NAV } from '../../../../../eventEmitter';
import { getFileNameWithNoExtension } from '../../../../../helpers';
import { useGlobalUiBlockersCounterContext } from '../../../../../hooks';
import { useCreateDocument } from '../../../../../services/useCreateDocument';
import { splitFileName } from '../../../../../utils';
import { useProgrammableVisibility } from '../../../../UI';
import { getMessageStrategy } from '../../../../parts/FileUpload';

export const FILE_NAME_ALREADY_EXISTS = 'File name already exists';

const prepareFileSuffix = (fileExtension) => (isEmpty(fileExtension) ? '' : fileExtension);

const useRenameFileSideNavForm = () => {
    const { isOpen, eventData, onClose } = useProgrammableVisibility({
        eventName: OPEN_RENAME_FILE_SIDE_NAV,
    });
    const { createDocument } = useCreateDocument();
    const file = eventData?.file;
    const isDragAndDropUpload = eventData?.isDragAndDropUpload;
    const queryClient = useQueryClient();

    const [isLoading, setIsLoading] = useState(false);

    const { increaseBlockerCounter, decreaseBlockerCounter } = useGlobalUiBlockersCounterContext();
    const { data: filesInCurrentFolder } = useCurrentFolderContext();
    const {
        state: { backendUrl, collaborationSpaceId, projectId, currentFolder },
    } = useFileManagementContext();

    const { getFileInputs } = useGetFileInputs();

    const [, fileExtension] = useMemo(() => getFileNameWithNoExtension(file?.name), [file]);

    const handleClose = () => {
        onClose();
    };

    const onSubmit = useCallback(
        async (data) => {
            let createResponse;

            const desiredFileName = `${trim(data.fileFormLabel)}${prepareFileSuffix(
                fileExtension
            )}`;

            const fileObj = new File([file.originalFile], desiredFileName, {
                type: file.originalFile.type,
            });
            const notificationId = ccdLoadingToast(getMessageStrategy([fileObj]).loadingMessage());

            setIsLoading(true);
            increaseBlockerCounter({ isLight: true });

            try {
                createResponse = await createDocument({
                    fileObj,
                    collaborationSpaceId,
                    projectId,
                    backendUrl,
                    currentFolder,
                });

                if (createResponse.ok) {
                    ccdLoadingCompletedWithSuccess(
                        notificationId,
                        getMessageStrategy([fileObj]).successMessage([fileObj])
                    );
                    const response = await createResponse.json();
                    EE.emit(FILE_UPLOADED, {
                        fileName: desiredFileName,
                        typeName: response.typeName,
                        isDragAndDropUpload: isDragAndDropUpload,
                    });
                    await queryClient.invalidateQueries({
                        queryKey: [FILES_QUERY, collaborationSpaceId, projectId],
                    });
                    handleClose();
                } else {
                    ccdLoadingCompletedWithError(
                        notificationId,
                        getMessageStrategy([fileObj]).failedMessage()
                    );
                }
            } catch (ex) {
                console.error(ex);

                ccdLoadingCompletedWithError(
                    notificationId,
                    getMessageStrategy([fileObj]).failedMessage()
                );
            } finally {
                decreaseBlockerCounter({ isLight: true });
                setIsLoading(false);
            }
        },
        [
            file,
            isDragAndDropUpload,
            fileExtension,
            collaborationSpaceId,
            projectId,
            backendUrl,
            currentFolder,
        ]
    );

    const validateFileName = {
        fileAlreadyExists: (v) =>
            filesInCurrentFolder?.every(
                (f) => `${v.trim()}${prepareFileSuffix(fileExtension)}` !== f.name
            ) || FILE_NAME_ALREADY_EXISTS,
    };

    function getFullFileName(nameWithoutExtension) {
        const { extension } = splitFileName(file.name);
        return `${nameWithoutExtension}${extension}`;
    }

    const inputs = useMemo(() => {
        let result;

        if (isNil(file)) {
            return [];
        } else {
            result = getFileInputs(splitFileName(file?.name).name, getFullFileName);
            Object.assign(result[0].validationConditions?.validate, validateFileName);

            return result;
        }
    }, [file?.name]);

    return {
        isOpen,
        onSubmit,
        file,
        handleClose,
        inputs,
        isLoading,
    };
};

export default useRenameFileSideNavForm;
