import PropTypes from 'prop-types';
import { useMemo } from 'react';

import useMultiSelection from 'components/_legacy/selection/useMultiSelection';
import Box from 'components/atoms/Box';

export default function CcdTileGrid({
    componentAfter,
    data,
    tileFactory,
    staticTiles,
    onSelected,
    onDoubleClick,
    onHover,
    settings,
    forcedSelection,
    additionalProps,
}) {
    const tiles = useMemo(() => data.map((d, idx) => ({ id: idx, original: d })), [data]);

    const { handleSelection, selectedRecords, onKeyDown } = useMultiSelection(
        settings,
        tiles,
        onSelected,
        forcedSelection
    );
    const isTileSelected = (record, currentSelection) => {
        return currentSelection.some((selectedRow) => {
            if (selectedRow.original.id && record.original.id) {
                return selectedRow.original.id === record.original.id;
            } else {
                return selectedRow.id === record.id;
            }
        });
    };

    const renderedTiles = useMemo(
        () =>
            tiles.map((tile) => {
                const onClickHandler = (event) => {
                    handleSelection(tile, event);
                };

                const onDoubleClickHandler = () => {
                    onDoubleClick?.(tile);
                };

                let key = tile.original.id;
                if (!key) {
                    console.warn(
                        'Found data item without id property. Using default data as a key property instead'
                    );
                }

                return (
                    <Box
                        key={`row${tile.id}`}
                        id={`row${tile.id}`}
                        onClick={onClickHandler}
                        onDoubleClick={onDoubleClickHandler}
                        onMouseOver={() => {
                            onHover?.(tile);
                        }}
                        css={(props) => ({
                            borderRadius: '5px',
                            border: '1px solid transparent',
                            ':hover': {
                                backgroundColor: props.colors.tileHover,
                            },
                            ...(isTileSelected(tile, selectedRecords) && {
                                backgroundColor: props.colors.tileHover,
                                border: `1px solid ${props.colors.textArea.focus.border}`,
                            }),
                        })}
                    >
                        {tileFactory(tile.original)}
                    </Box>
                );
            }),
        [selectedRecords, handleSelection, tileFactory, onHover, tiles]
    );

    return (
        <Box
            css={{
                display: 'grid',
                columnGap: '8px',
                rowGap: '8px',
                gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
                justifyContent: 'space-between',
                paddingBottom: '4px',
            }}
            tabIndex={-1}
            onKeyDown={onKeyDown}
            data-testid={'TileGridContainer'}
            {...additionalProps}
        >
            {staticTiles}
            {renderedTiles}
            {componentAfter}
        </Box>
    );
}

CcdTileGrid.propTypes = {
    data: PropTypes.array.isRequired,
    tileFactory: PropTypes.func.isRequired,
    staticTiles: PropTypes.arrayOf(PropTypes.node),
    onSelected: PropTypes.func,
    onDoubleClick: PropTypes.func,
    onHover: PropTypes.func,
    settings: PropTypes.shape({
        multiselection: PropTypes.bool,
    }),
    forcedSelection: PropTypes.shape({
        enabled: PropTypes.bool,
        elements: PropTypes.array,
        comparisonFunc: PropTypes.func,
    }),
    additionalProps: PropTypes.object,
};
