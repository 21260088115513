import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router';

import { TableSortDirection } from 'components/templates/ProjectsTable/ProjectsTable';
import { useUserDataContext } from 'providers/currentUser/UserDataProvider';
import {
    getProjectRevisions as getProjectRevisionsV3,
    getProject as getProjectV3,
    getProjects as getProjectsV3,
    getRecentProjects as getRecentProjectsV3,
} from 'services/api/synchronization/project.service';
import useFeatureFlagsQuery from 'services/queries/featuresFlags.query';
import { Path } from 'types/paths.enum';
import {
    GetProjectRequest,
    GetProjectRevisionsRequest,
    GetProjectsRequest,
    GetProjectsResponse,
    GetRecentProjectsRequest,
    GetRecentProjectsResponse,
    GetSimpleProjectResponse,
    ProjectRevisionPagination,
} from 'types/project';

import { SortOrder, getProjects, getRecentProjects } from '../api/project.service';

export const PROJECT_QUERY = 'getProject';

export const useGetProjectQuery = ({ collaborationSpaceId, projectId }: GetProjectRequest) => {
    const navigate = useNavigate();
    return useQuery<GetSimpleProjectResponse>({
        queryKey: [PROJECT_QUERY, collaborationSpaceId, projectId],
        queryFn: () => getProjectV3({ collaborationSpaceId, projectId }),
        enabled: !!projectId && !!collaborationSpaceId,
        onError: () => {
            navigate(Path.ERROR_403);
        },
    });
};

const mapper = {
    ascending: 'ASC',
    descending: 'DESC',
    'no-sort': undefined,
};

export const PROJECTS_QUERY = 'getProjects';
export const RECENT_PROJECTS_QUERY = 'getRecentProject';

export const useGetProjectsQuery = ({
    collaborationSpaceId,
    ownerShipFilter,
    sortColumn,
    sortOrder,
}: Omit<GetProjectsRequest, 'startFrom' | 'pageSize'> & {
    sortOrder?: TableSortDirection;
}) => {
    const { data } = useFeatureFlagsQuery();
    const { user: currentUser } = useUserDataContext();
    if (!data?.XpeditionSupportFlag) {
        const pageSize = 40;
        return useInfiniteQuery<GetProjectsResponse>(
            [PROJECTS_QUERY, collaborationSpaceId, { ownerShipFilter, sortColumn, sortOrder }],
            ({ pageParam = 0 }) =>
                getProjects({
                    collaborationSpaceId,
                    ownerShipFilter,
                    pageSize,
                    startFrom: pageParam,
                    sortColumn,
                    sortOrder: (sortOrder && mapper[sortOrder]) as SortOrder,
                }),
            {
                enabled: !!collaborationSpaceId,
                getNextPageParam: (lastPage, pages) => {
                    if (pages.length * pageSize >= lastPage.totalSize!) return;
                    return pages.length * pageSize;
                },
            }
        );
    } else {
        return useInfiniteQuery<GetProjectsResponse>(
            [PROJECTS_QUERY, { ownerShipFilter, sortColumn, sortOrder }],
            ({ pageParam = undefined }) =>
                getProjectsV3({
                    pageToken: pageParam,
                    sortColumn,
                    sortDirection: sortOrder,
                    ownership: ownerShipFilter,
                    currentUser: currentUser,
                }),
            {
                getNextPageParam: (lastPage) => {
                    if (!lastPage.pagination?.next) return;
                    return lastPage.pagination.next;
                },
            }
        );
    }
};

export const useGetRecentProjectsQuery = ({ collaborationSpaceId }: GetRecentProjectsRequest) => {
    const { data } = useFeatureFlagsQuery();
    const { user: currentUser } = useUserDataContext();
    if (!data?.XpeditionSupportFlag) {
        return useQuery<GetRecentProjectsResponse>(
            [RECENT_PROJECTS_QUERY, collaborationSpaceId],
            () => getRecentProjects({ collaborationSpaceId }),
            { enabled: !!collaborationSpaceId }
        );
    } else {
        return useQuery<GetRecentProjectsResponse>([RECENT_PROJECTS_QUERY], () =>
            getRecentProjectsV3({ currentUser })
        );
    }
};

export const GET_PROJECT_REVISIONS = 'getProjectRevisions';

export const useGetProjectRevisions = ({
    collaborationSpaceId,
    projectId,
}: GetProjectRevisionsRequest) => {
    const navigate = useNavigate();
    return useInfiniteQuery<ProjectRevisionPagination>(
        [GET_PROJECT_REVISIONS, collaborationSpaceId, projectId],
        async ({ pageParam }: { pageParam?: string }) => {
            return await getProjectRevisionsV3({
                collaborationSpaceId,
                projectId,
                pageToken: pageParam,
            });
        },
        {
            enabled: !!collaborationSpaceId && !!projectId,
            getNextPageParam: (lastPage): string | undefined => {
                return lastPage.pagination.next ?? undefined;
            },
            onError: () => {
                navigate(Path.ERROR_403);
            },
        }
    );
};
