import { useRef } from 'react';

import { ccdInfoToast } from 'components/_legacy/ccd-toast';
import { useCcdFetch } from 'components/_legacy/hooks/useCcdFetch';
import { useFileManagementContext } from 'components/templates/FileManagement/components/FileManagementContext';

import { unexpectedError } from '../../../../consts';
import { EE, FILE_RENAMED } from '../../../../eventEmitter';
import { useGlobalUiBlockersCounterContext, useTableRefresh } from '../../../../hooks';
import { useWaitForFolderInTheLoop } from '../../../../services/useWaitForFolderInTheLoop';
import { NAME_INPUT_ID, useGetFolderInputs } from '../../FileForm';
import { alreadyExistsError, successfulNotification } from '../consts';

export default function useRenameFolderForm(formHandlers, onClose) {
    const {
        increaseBlockerCounter,
        decreaseBlockerCounter,
        isBlocked: isLoading,
    } = useGlobalUiBlockersCounterContext();

    const {
        state: { backendUrl, collaborationSpaceId, projectId, selectedFiles },
    } = useFileManagementContext();
    const { dispatchDataLoaded } = useTableRefresh();
    const formRef = useRef();
    const { waitForFolderInTheLoop } = useWaitForFolderInTheLoop();
    const { ccdFetch } = useCcdFetch();
    const { getFolderInputs } = useGetFolderInputs();

    function dataHandler() {
        if (selectedFiles.length === 1) {
            const selectedFile = selectedFiles[0];
            return {
                inputs: getFolderInputs(selectedFile.name),
                onSubmit: async (params, event) => {
                    const inputValue = params[NAME_INPUT_ID].trim();
                    event.preventDefault();
                    formHandlers.preSubmitCleanup();
                    if (inputValue === selectedFile.name) {
                        formHandlers.activateForm();
                        onClose();
                    } else {
                        await renameFolder(inputValue, selectedFile.id);
                    }
                },
            };
        }
        return {
            onSubmit: () => {
                // This is intentional
            },
            inputs: [],
        };
    }

    async function renameFolder(inputValue, selectedFileId) {
        increaseBlockerCounter();
        const url = new URL(
            `/frontend/file-management/${collaborationSpaceId}/${projectId}/folder/${selectedFileId}/name/`,
            backendUrl
        );

        try {
            const response = await ccdFetch(url.toString(), {
                method: 'PUT',
                body: JSON.stringify({
                    name: inputValue,
                }),
                cache: 'no-cache',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            if (response.ok) {
                await waitForFolder(selectedFileId, inputValue);
                await dispatchDataLoaded([]);
                ccdInfoToast(successfulNotification(inputValue));
                onClose();
            } else {
                handleServerError(response, inputValue);
            }
        } catch (err) {
            console.error(err);
            formHandlers.setError(unexpectedError);
        }

        decreaseBlockerCounter();
        EE.emit(FILE_RENAMED);
    }

    async function waitForFolder(selectedFileId, name) {
        const folderCheckingResponse = await waitForFolderInTheLoop({
            collaborationSpaceId,
            projectId,
            backendUrl,
            folderId: selectedFileId,
        });

        if (!folderCheckingResponse.ok) {
            handleServerError(folderCheckingResponse, name);
        }
    }

    function handleServerError(response, name) {
        console.error(response);
        if (response.status === 409) {
            formHandlers.setError(alreadyExistsError(name));
            return;
        }
        formHandlers.setError(unexpectedError);
    }

    return {
        ...dataHandler(),
        isLoading,
        formRef,
    };
}
