import { useState } from 'react';

import { DialogButton } from 'components/atoms/Button';
import Checkbox from 'components/atoms/Checkbox';
import Dialog from 'components/molecules/Dialog';
import { Paragraph } from 'components/organisms/DataPrivacy/DataPrivacy.styles';
import appConfig from 'constants/appConfig';

import {
    CheckBoxLabel,
    CheckboxRow,
} from '../DownloadSuspiciousFileDialog/DownloadSuspiciousFileDialog.styles';

interface IUnlockSuspiciousContentDialog {
    title: string;
    onClose: () => void;
    onConfirm: () => void;
}

export default function UnlockSuspiciousContentDialog({
    title,
    onClose,
    onConfirm,
}: IUnlockSuspiciousContentDialog) {
    const [isOpen, setIsOpen] = useState<boolean>(true);
    const [isChecked, setIsChecked] = useState<boolean>(false);

    return (
        <Dialog
            title={title}
            open={isOpen}
            setOpen={(open) => {
                setIsOpen(open);
                onClose();
            }}
            buttons={
                <>
                    <DialogButton
                        id={'buttonCancel'}
                        color={'primary'}
                        variant={'outlined'}
                        onClick={() => {
                            setIsOpen(false);
                            onClose();
                        }}
                        data-testid={'confirmationDialogCancelButton'}
                    >
                        Cancel
                    </DialogButton>
                    <DialogButton
                        id={'buttonOK'}
                        color={'primary'}
                        variant={'contained'}
                        onClick={() => {
                            setIsOpen(false);
                            onConfirm();
                        }}
                        data-testid={'confirmationDialogConfirmButton'}
                        disabled={!isChecked}
                    >
                        Mark as safe
                    </DialogButton>
                </>
            }
        >
            <Paragraph>We have detected a potentially infected file.</Paragraph>
            <Paragraph>
                Our server anti-virus scanner has determined this file or project revision contains
                potentially dangerous code.
            </Paragraph>
            <Paragraph>
                It is recommended to remove this file, make sure it is clean on your device and
                upload again where it will be re-scanned.
            </Paragraph>
            <Paragraph>
                However you can confirm at your risk, it is actually safe and the result is a false
                positive.
            </Paragraph>
            <Paragraph>
                Learn more about our security policy at{' '}
                <a href={appConfig.SECURITY} target={'_blank'}>
                    Siemens Security
                </a>
                .
            </Paragraph>
            <CheckboxRow>
                <Checkbox
                    checked={isChecked}
                    onCheckedChange={() => setIsChecked(!isChecked)}
                    children={
                        <CheckBoxLabel>
                            I take responsibility for unlocking this version, even when it was
                            marked as potentially dangerous
                        </CheckBoxLabel>
                    }
                />
            </CheckboxRow>
        </Dialog>
    );
}
