export type RelativeDate = Date | string;

interface IRelativeDates {
    date: RelativeDate;
    referenceDate?: Date;
    options?: {
        dateFormat?: Intl.DateTimeFormatOptions;
        supportedUnits?: Intl.RelativeTimeFormatUnitSingular[];
        upperCaseFirstLetter?: boolean;
    };
}
export default function useRelativeTime() {
    const defaultOptions: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
    };

    const units: Record<Intl.RelativeTimeFormatUnitSingular, number> = {
        year: 24 * 60 * 60 * 1000 * 365,
        quarter: (24 * 60 * 60 * 1000 * 365) / 3,
        month: (24 * 60 * 60 * 1000 * 365) / 12,
        week: 24 * 60 * 60 * 1000 * 7,
        day: 24 * 60 * 60 * 1000,
        hour: 60 * 60 * 1000,
        minute: 60 * 1000,
        second: 1000,
    };

    const sevenDays = 24 * 60 * 60 * 1000 * 7;

    const rtf = new Intl.RelativeTimeFormat('en', { numeric: 'auto' });
    const getEffectiveUnits = (unitTypes?: Intl.RelativeTimeFormatUnitSingular[]) => {
        if (!unitTypes) {
            return units;
        }
        const effectiveUnits: Record<Intl.RelativeTimeFormatUnitSingular, number> = {} as Record<
            Intl.RelativeTimeFormatUnitSingular,
            number
        >;

        for (const key in units) {
            if (unitTypes.includes(key as Intl.RelativeTimeFormatUnitSingular)) {
                effectiveUnits[key as Intl.RelativeTimeFormatUnitSingular] =
                    units[key as Intl.RelativeTimeFormatUnitSingular];
            }
        }
        return effectiveUnits;
    };
    const relativeTime = ({ date, referenceDate = new Date(), options = {} }: IRelativeDates) => {
        date = new Date(date);
        const elapsed = date.getTime() - referenceDate.getTime();
        const elapsedAbs = Math.abs(elapsed);
        const effectiveUnits = getEffectiveUnits(options.supportedUnits);
        const smallestUnit = Object.keys(effectiveUnits).reduce((acc: string, cur: string) => {
            return acc === '' ||
                effectiveUnits[cur as Intl.RelativeTimeFormatUnitSingular] <=
                    effectiveUnits[acc as Intl.RelativeTimeFormatUnitSingular]
                ? cur
                : acc;
        }, '');
        for (const u in effectiveUnits) {
            const timeUnit = u as Intl.RelativeTimeFormatUnitSingular;
            if (
                (elapsedAbs > units[timeUnit] || timeUnit === smallestUnit) &&
                elapsedAbs <= sevenDays
            ) {
                const formattedDate = rtf.format(Math.round(elapsed / units[timeUnit]), timeUnit);
                return options?.upperCaseFirstLetter
                    ? formattedDate[0].toUpperCase() + formattedDate.slice(1)
                    : formattedDate;
            }
        }

        return Intl.DateTimeFormat('default', options.dateFormat || defaultOptions).format(date);
    };

    return {
        relativeTime,
    };
}
