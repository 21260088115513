import { ThemeProvider as EmotionThemeProvider } from '@emotion/react';
import CssBaseline from '@mui/material/CssBaseline';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

import { THEME_QUERY, useThemeSettingsQuery } from 'services/queries/theme.query';
import { createGenericContext } from 'shared/createGenericContext';
import muiTheme from 'theme';

import useSetThemeSettings from './hooks/useSetThemeSettings';

interface IThemeProviderProps {
    children: React.ReactElement;
}

export enum ThemesName {
    Light = 'light',
    CalidumDarkGrey = 'calidumDarkGrey',
    CalidumLightGrey = 'calidumLightGrey',
}

interface Theme {
    currentTheme: ThemesName;
    selectTheme: (arg: ThemesName) => void;
}

export interface Colors {
    boxShadow: string;
    background: string;
    backgroundInverted: string;
    header: string;
    headerText: string;
    closeButton: string;
    closeButtonBackground: string;
    text: string;
    actionText: string;
    textHover: string;
    disabledText: string;
    actionTextHover: string;
    error: {
        background: string;
        text: string;
        border: string;
    };
    shadow: string;
    selectedTabText: string;
    drawerBackground: string;
    panelButtonText: string;
    filterArrowIcon: string;
    filterGlobalIcon: string;
    tileHover: string;
    unreadTile: string;
    chip: {
        version: {
            background: string;
            border: string;
        };
        toolVersion: {
            text: string;
        };
    };
    progressIndicator: {
        foreground: string;
        background: string;
    };
    historyPanel: {
        text: string;
        time: string;
        current: string;
    };
    accordion: {
        header: string;
        background: string;
        horizontalLine: string;
        text: string;
    };
    tooltip: {
        border: string;
        item: {
            hover: string;
        };
    };
    tile: {
        textLight: string;
    };
    table: {
        header: { background: string };
        row: { select: string; hover: string };
        border: string;
    };
    combobox: {
        list: {
            background: {
                default: string;
                inverted: string;
                hover: { default: string; inverted: string };
            };
        };
        border: {
            focus: string;
            error: string;
            errorBackground: string;
        };
    };
    checkbox: {
        checkedBackground: string;
        uncheckedBackground: string;
        uncheckedBorder: string;
    };
    radioButton: {
        backgroundColor: string;
        boxShadow: string;
        selectedBoxShadow: string;
        hoverBoxShadow: string;
        focusBoxShadow: string;
        indicatorBackground: string;
    };
    switch: {
        backgroundColor: string;
        checkedBackgroundColor: string;
        indicator: {
            backgroundColor: string;
        };
    };
    textArea: {
        text: string;
        background: string;
        border: string;
        focus: {
            border: string;
            transition: string;
            outline: string;
        };
        hover: {
            border: string;
            outline: string;
        };
        error: {
            border: string;
            background: string;
        };
        warning: {
            border: string;
            background: string;
        };
    };
    button: {
        primary: {
            textColor: string;
            outlinedBorder: string;
            outlinedBackground: string;
            outlinedColor: string;
            containedBorder: string;
            containedBackground: string;
            containedColor: string;
            textHover: string;
            outlinedHover: string;
            containedHover: string;
            textActive: string;
            outlinedActive: string;
            containedActive: string;
            disabledTextColor: string;
        };
        gray: {
            textColor: string;
            outlinedBorder: string;
            outlinedBackground: string;
            outlinedColor: string;
            containedBorder: string;
            containedBackground: string;
            containedColor: string;
            textHover: string;
            outlinedHover: string;
            containedHover: string;
            textActive: string;
            outlinedActive: string;
            containedActive: string;
            disabledTextColor: string;
        };
        secondary: {
            textColor: string;
            outlinedBorder: string;
            outlinedBackground: string;
            outlinedColor: string;
            containedBorder: string;
            containedBackground: string;
            containedColor: string;
            textHover: string;
            outlinedHover: string;
            containedHover: string;
            textActive: string;
            outlinedActive: string;
            containedActive: string;
            disabledTextColor: string;
        };
        negative: {
            textColor: string;
            outlinedBorder: string;
            outlinedBackground: string;
            outlinedColor: string;
            containedBorder: string;
            containedBackground: string;
            containedColor: string;
            textHover: string;
            outlinedHover: string;
            containedHover: string;
            textActive: string;
            outlinedActive: string;
            containedActive: string;
            disabledTextColor: string;
        };
        caution: {
            textColor: string;
            outlinedBorder: string;
            outlinedBackground: string;
            outlinedColor: string;
            containedBorder: string;
            containedBackground: string;
            containedColor: string;
            textHover: string;
            outlinedHover: string;
            containedHover: string;
            textActive: string;
            outlinedActive: string;
            containedActive: string;
            disabledTextColor: string;
        };
    };
}

const schemes: {
    colors: {
        [k in ThemesName]: Colors;
    };
} = {
    colors: {
        light: {
            boxShadow: '0 3px 6px 0 rgb(0 0 0 / 30%)',
            background: '#FFFFFF',
            backgroundInverted: '#015580',
            header: '#005f87',
            headerText: '#ffffff',
            closeButton: '#FFFFFF',
            closeButtonBackground: '#003E5C',
            text: '#1E1E1E',
            filterArrowIcon:
                'invert(9%) sepia(36%) saturate(0%) hue-rotate(178deg) brightness(111%) contrast(97%)',
            filterGlobalIcon:
                'invert(9%) sepia(36%) saturate(0%) hue-rotate(178deg) brightness(111%) contrast(97%)',
            actionText: '#005f87',
            textHover: '#005f87',
            disabledText: '#828282',
            actionTextHover: '#e3700f',
            error: {
                text: '#dc0000',
                border: '#e38984',
                background: '#fbeeed',
            },
            shadow: '#0000004D',
            selectedTabText: '#006487',
            drawerBackground: '#003750',
            panelButtonText: '#AAE6F5',
            tileHover: '#daecf0',
            unreadTile: '#e3faff',
            tooltip: { border: '#dcdcdc', item: { hover: '#cde6eb' } },
            historyPanel: {
                text: '#666666',
                time: '#8c8c8c',
                current: '#009999',
            },
            chip: {
                version: {
                    background: '#f0f0f0',
                    border: '#5a5a5a',
                },
                toolVersion: {
                    text: '#ffffff',
                },
            },
            tile: {
                textLight: '#8c8c8c',
            },
            accordion: {
                header: '#f0f0f0',
                background: '#f2f2f2',
                horizontalLine: '#dfdfdf',
                text: '#8c8c8c',
            },
            table: {
                header: { background: '#F0F0F0' },
                row: { select: '#DAECF0', hover: '#cde6eb' },
                border: '#C8C8C8',
            },
            progressIndicator: {
                foreground: '#2387aa',
                background: '#E6E6E6',
            },
            combobox: {
                list: {
                    background: {
                        default: '#ffffff',
                        inverted: '#003750',
                        hover: { default: '#e3faff', inverted: '#005f87' },
                    },
                },
                border: {
                    focus: '#3296b9',
                    error: '#CA2B1D',
                    errorBackground: '#F9EEED',
                },
            },
            checkbox: {
                checkedBackground: '#005f87',
                uncheckedBackground: '#FFFFFF',
                uncheckedBorder: '#C8C8C8',
            },
            radioButton: {
                backgroundColor: '#FFFFFF',
                boxShadow: '#000000',
                selectedBoxShadow: '#005f87',
                hoverBoxShadow: '#005f87',
                focusBoxShadow: '#005f87',
                indicatorBackground: '#005f87',
            },
            switch: {
                backgroundColor: '#8c8c8c',
                checkedBackgroundColor: '#3296b9',
                indicator: {
                    backgroundColor: '#ffffff',
                },
            },
            textArea: {
                text: '#000000',
                background: '#FFFFFF',
                border: '#8c8c8c',
                focus: {
                    border: '#3296b9',
                    transition: '#3296b9',
                    outline: '#3296b9',
                },
                hover: {
                    border: '#8c8c8c',
                    outline: '#8c8c8c',
                },
                error: {
                    background: 'rgba(220, 0, 0, 0.1)',
                    border: '#dc0000',
                },
                warning: {
                    border: '#eb780a',
                    background: '#fff7cd',
                },
            },
            button: {
                primary: {
                    textColor: '#005f87',
                    outlinedBorder: '#005f87',
                    outlinedBackground: '#ffffff',
                    outlinedColor: '#005f87',
                    containedBorder: '#003750',
                    containedBackground: '#005f87',
                    containedColor: '#ffffff',
                    textHover: '#d9e7ed',
                    outlinedHover: '#d9e7ed',
                    containedHover: '#004669',
                    textActive: '#BFD7E1',
                    outlinedActive: '#BFD7E1',
                    containedActive: '#003750',
                    disabledTextColor: '',
                },
                gray: {
                    textColor: '#464646',
                    outlinedBorder: '#464646',
                    outlinedBackground: '#ffffff',
                    outlinedColor: '#464646',
                    containedBorder: '#3c464b',
                    containedBackground: '#464646',
                    containedColor: '#ffffff',
                    textHover: '#daecf0',
                    outlinedHover: '#daecf0',
                    containedHover: '#004669',
                    textActive: '#cde6eb',
                    outlinedActive: '#cde6eb',
                    containedActive: '#3c464b',
                    disabledTextColor: '',
                },
                secondary: {
                    textColor: '#3296b9',
                    outlinedBorder: '#3296b9',
                    outlinedBackground: '#ffffff',
                    outlinedColor: '#3296b9',
                    containedBorder: '#005f87',
                    containedBackground: '#3296b9',
                    containedColor: '#ffffff',
                    textHover: '#BFD7E1',
                    outlinedHover: '#BFD7E1',
                    containedHover: '#004669',
                    textActive: '#B9EDFA',
                    outlinedActive: '#B9EDFA',
                    containedActive: '#005f87',
                    disabledTextColor: '',
                },
                negative: {
                    textColor: '#dc0000',
                    outlinedBorder: '#dc0000',
                    outlinedBackground: '#ffffff',
                    outlinedColor: '#dc0000',
                    containedBorder: '#730900',
                    containedBackground: '#dc0000',
                    containedColor: '#ffffff',
                    textHover: '#fbeeed',
                    outlinedHover: '#fbeeed',
                    containedHover: '#91140A',
                    textActive: '#F3CCCA',
                    outlinedActive: '#F3CCCA',
                    containedActive: '#730900',
                    disabledTextColor: '',
                },
                caution: {
                    textColor: '#C85A1E',
                    outlinedBorder: '#C85A1E',
                    outlinedBackground: '#ffffff',
                    outlinedColor: '#C85A1E',
                    containedBorder: '#8E3420',
                    containedBackground: '#C85A1E',
                    containedColor: '#ffffff',
                    textHover: '#FBF0E7',
                    outlinedHover: '#FBF0E7',
                    containedHover: '#AA4325',
                    textActive: '#F6D9C4',
                    outlinedActive: '#F6D9C4',
                    containedActive: '#8E3420',
                    disabledTextColor: '',
                },
            },
        },
        calidumLightGrey: {
            boxShadow: '0 3px 6px 0 rgb(0 0 0 / 30%)',
            background: '#C5C3BF',
            backgroundInverted: '#1E1E1E',
            header: '#A3A19E',
            headerText: '#1E1E1E',
            closeButton: '#1E1E1E',
            closeButtonBackground: '#5e5e5e',
            text: '#1E1E1E',
            filterArrowIcon:
                'invert(9%) sepia(36%) saturate(0%) hue-rotate(178deg) brightness(111%) contrast(97%)',
            filterGlobalIcon:
                'invert(9%) sepia(36%) saturate(0%) hue-rotate(178deg) brightness(111%) contrast(97%)',
            actionText: '#545350',
            textHover: '',
            disabledText: 'lightGray',
            actionTextHover: '#3C3A37',
            error: {
                text: '#ff3118',
                border: '#e38984',
                background: '#fbeeed',
            },
            shadow: '#0000004D',
            selectedTabText: '#1e1e1e',
            drawerBackground: '#3C3A37',
            panelButtonText: '#ffffff',
            tileHover: '#e6e2dc',
            unreadTile: '#e6e2dc',
            progressIndicator: { foreground: '#E6E6E6', background: '#A3A19E' },
            tooltip: { border: '#dcdcdc', item: { hover: '#7a7a7a' } },
            historyPanel: {
                text: '#666666',
                time: '#8c8c8c',
                current: '#009999',
            },
            tile: {
                textLight: '#8c8c8c',
            },
            chip: {
                version: {
                    background: '#f0f0f0',
                    border: '#5a5a5a',
                },
                toolVersion: {
                    text: '#ffffff',
                },
            },
            accordion: {
                header: '#f0f0f0',
                background: '#f2f2f2',
                horizontalLine: '#dfdfdf',
                text: '#8c8c8c',
            },
            table: {
                header: { background: '#94928F' },
                row: { select: '#FFFFFF', hover: '#E6E6E6' },
                border: '#898784',
            },
            combobox: {
                list: {
                    background: {
                        default: '#ffffff',
                        inverted: '#3C3A37',
                        hover: { default: '#dcdcdc', inverted: '#3C464B' },
                    },
                },
                border: {
                    focus: '#ffffff',
                    error: '',
                    errorBackground: '',
                },
            },
            checkbox: {
                checkedBackground: '#A3A19E',
                uncheckedBackground: '#C5C3BF',
                uncheckedBorder: '#464646',
            },
            radioButton: {
                backgroundColor: '#A3A19E',
                boxShadow: '#3C3A37',
                selectedBoxShadow: '#3C3A37',
                hoverBoxShadow: '#3C3A37',
                focusBoxShadow: '#3C3A37',
                indicatorBackground: '#3C3A37',
            },
            switch: {
                backgroundColor: '#8c8c8c',
                checkedBackgroundColor: '#A3A19E',
                indicator: {
                    backgroundColor: '#ffffff',
                },
            },
            textArea: {
                text: '#000000',
                background: '#C5C3BF',
                border: '#ffffff',
                focus: {
                    border: '#ffffff',
                    transition: '#ffffff',
                    outline: '#ffffff',
                },
                hover: {
                    border: '#ffffff',
                    outline: '#ffffff',
                },
                error: {
                    background: `rgba(220, 0, 0, 0.1)`,
                    border: '#ff3118',
                },
                warning: {
                    border: '#eb780a',
                    background: '#fff7cd',
                },
            },
            button: {
                primary: {
                    textColor: '#333333',
                    outlinedBorder: '#333333',
                    outlinedBackground: '#C5C3BF',
                    outlinedColor: '#333333',
                    containedBorder: '#333333',
                    containedBackground: '#94928F',
                    containedColor: '#333333',
                    textHover: '#7a7a7a',
                    outlinedHover: '#e6e2dc',
                    containedHover: '#5e5e5e',
                    textActive: '#BFD7E1',
                    outlinedActive: '#BFD7E1',
                    containedActive: '#333333',
                    disabledTextColor: '',
                },
                gray: {
                    textColor: '#464646',
                    outlinedBorder: '#464646',
                    outlinedBackground: '#ffffff',
                    outlinedColor: '#464646',
                    containedBorder: '#3c464b',
                    containedBackground: '#464646',
                    containedColor: '#ffffff',
                    textHover: '#7a7a7a',
                    outlinedHover: '#daecf0',
                    containedHover: '#004669',
                    textActive: '#ADADAD',
                    outlinedActive: '#ADADAD',
                    containedActive: '#ADADAD',
                    disabledTextColor: '',
                },
                secondary: {
                    textColor: '#333333',
                    outlinedBorder: '#333333',
                    outlinedBackground: '#C5C3BF',
                    outlinedColor: '#333333',
                    containedBorder: '#333333',
                    containedBackground: '#A5A5A5',
                    containedColor: '#333333',
                    textHover: '#7a7a7a',
                    outlinedHover: '#e6e2dc',
                    containedHover: '#5e5e5e',
                    textActive: '#BFD7E1',
                    outlinedActive: '#BFD7E1',
                    containedActive: '#333333',
                    disabledTextColor: '',
                },
                negative: {
                    textColor: '#dc0000',
                    outlinedBorder: '#dc0000',
                    outlinedBackground: '#ffffff',
                    outlinedColor: '#dc0000',
                    containedBorder: '#730900',
                    containedBackground: '#dc0000',
                    containedColor: '#ffffff',
                    textHover: '#fbeeed',
                    outlinedHover: '#fbeeed',
                    containedHover: '#91140A',
                    textActive: '#F3CCCA',
                    outlinedActive: '#F3CCCA',
                    containedActive: '#730900',
                    disabledTextColor: '',
                },
                caution: {
                    textColor: '#C85A1E',
                    outlinedBorder: '#C85A1E',
                    outlinedBackground: '#ffffff',
                    outlinedColor: '#C85A1E',
                    containedBorder: '#8E3420',
                    containedBackground: '#C85A1E',
                    containedColor: '#ffffff',
                    textHover: '#FBF0E7',
                    outlinedHover: '#FBF0E7',
                    containedHover: '#AA4325',
                    textActive: '#F6D9C4',
                    outlinedActive: '#F6D9C4',
                    containedActive: '#8E3420',
                    disabledTextColor: '',
                },
            },
        },
        calidumDarkGrey: {
            boxShadow: '0 3px 6px 0 rgb(0 0 0 / 30%)',
            background: '#545350',
            backgroundInverted: '#FFFFFF',
            header: '#3C3A37',
            headerText: '#FFFDFA',
            closeButton: '#F0F0F0',
            closeButtonBackground: '#1a1918',
            text: '#FFFDFA',
            filterArrowIcon:
                'invert(100%) sepia(43%) saturate(374%) hue-rotate(13deg) brightness(106%) contrast(103%)',
            filterGlobalIcon:
                'invert(100%) sepia(0%) saturate(374%) hue-rotate(13deg) brightness(106%) contrast(103%)',
            actionText: '#FFFFFF',
            textHover: '',
            disabledText: 'lightGray',
            actionTextHover: '#1a1918',
            error: {
                text: '#ff3118',
                border: '#e38984',
                background: '#fbeeed',
            },
            shadow: '#0000004D',
            selectedTabText: '#ffffff',
            drawerBackground: '#1a1918',
            panelButtonText: '#ffffff',
            tileHover: '#A3A19E',
            unreadTile: '#A3A19E',
            progressIndicator: {
                foreground: '#E6E6E6',
                background: '#3C3A37',
            },
            historyPanel: {
                text: '#666666',
                time: '#8c8c8c',
                current: '#009999',
            },
            chip: {
                version: {
                    background: '#f0f0f0',
                    border: '#5a5a5a',
                },
                toolVersion: {
                    text: '#ffffff',
                },
            },
            tooltip: { border: '#dcdcdc', item: { hover: '#3C3A37' } },
            tile: {
                textLight: '#8c8c8c',
            },
            accordion: {
                header: '#3C3A37',
                background: '#6e6d6a',
                horizontalLine: '#ffffff',
                text: '#ffffff',
            },
            table: {
                header: { background: '#494744' },
                border: '#A3A19E',
                row: { select: '#7C7A74', hover: '#676663' },
            },
            combobox: {
                list: {
                    background: {
                        default: '#545350',
                        inverted: '#1a1918',
                        hover: { default: '#dcdcdc', inverted: '#3C464B' },
                    },
                },
                border: {
                    focus: '#ffffff',
                    error: '#F4B9B4',
                    errorBackground: '#F9EEED',
                },
            },
            checkbox: {
                checkedBackground: '#3C3A37',
                uncheckedBackground: '#545350',
                uncheckedBorder: '#A3A19E',
            },
            radioButton: {
                backgroundColor: '#3C3A37',
                boxShadow: '#C5C3BF',
                selectedBoxShadow: '#C5C3BF',
                hoverBoxShadow: '#C5C3BF',
                focusBoxShadow: '#C5C3BF',
                indicatorBackground: '#C5C3BF',
            },
            switch: {
                backgroundColor: '#8c8c8c',
                checkedBackgroundColor: '#363533',
                indicator: {
                    backgroundColor: '#ffffff',
                },
            },
            textArea: {
                text: '#ffffff',
                background: '#545350',
                border: '#8C8C8C',
                focus: {
                    border: '#ffffff',
                    transition: '#ffffff',
                    outline: '#ffffff',
                },
                hover: {
                    border: '#8C8C8C',
                    outline: '#8C8C8C',
                },
                error: {
                    background: `rgba(220, 0, 0, 0.1)`,
                    border: '#ffb6b2',
                },
                warning: {
                    border: '#eb780a',
                    background: '#fff7cd',
                },
            },
            button: {
                primary: {
                    textColor: '#ffffff',
                    outlinedBorder: '#ffffff',
                    outlinedBackground: '#6E6D6A',
                    outlinedColor: '#ffffff',
                    containedBorder: '#ffffff',
                    containedBackground: '#3C3A37',
                    containedColor: '#ffffff',
                    textHover: '#A3A19E',
                    outlinedHover: '#A3A19E',
                    containedHover: '#1a1918',
                    textActive: '#545350',
                    outlinedActive: '#545350',
                    containedActive: '#363533',
                    disabledTextColor: '',
                },
                gray: {
                    textColor: '#ffffff',
                    outlinedBorder: '#464646',
                    outlinedBackground: '#ffffff',
                    outlinedColor: '#464646',
                    containedBorder: '#3c464b',
                    containedBackground: '#464646',
                    containedColor: '#ffffff',
                    textHover: '#A3A19E',
                    outlinedHover: '#daecf0',
                    containedHover: '#1a1918',
                    textActive: '#545350',
                    outlinedActive: '#545350',
                    containedActive: '#363533',
                    disabledTextColor: '#9e9e99',
                },
                secondary: {
                    textColor: '#ffffff',
                    outlinedBorder: '#ffffff',
                    outlinedBackground: '#6E6D6A',
                    outlinedColor: '#ffffff',
                    containedBorder: '#ffffff',
                    containedBackground: '#545350',
                    containedColor: '#ffffff',
                    textHover: '#A3A19E',
                    outlinedHover: '#A3A19E',
                    containedHover: '#1a1918',
                    textActive: '#545350',
                    outlinedActive: '#545350',
                    containedActive: '#363533',
                    disabledTextColor: '',
                },
                negative: {
                    textColor: '#dc0000',
                    outlinedBorder: '#dc0000',
                    outlinedBackground: '#ffffff',
                    outlinedColor: '#dc0000',
                    containedBorder: '#730900',
                    containedBackground: '#dc0000',
                    containedColor: '#ffffff',
                    textHover: '#fbeeed',
                    outlinedHover: '#fbeeed',
                    containedHover: '#91140A',
                    textActive: '#F3CCCA',
                    outlinedActive: '#F3CCCA',
                    containedActive: '#730900',
                    disabledTextColor: '',
                },
                caution: {
                    textColor: '#C85A1E',
                    outlinedBorder: '#C85A1E',
                    outlinedBackground: '#ffffff',
                    outlinedColor: '#C85A1E',
                    containedBorder: '#8E3420',
                    containedBackground: '#C85A1E',
                    containedColor: '#ffffff',
                    textHover: '#FBF0E7',
                    outlinedHover: '#FBF0E7',
                    containedHover: '#AA4325',
                    textActive: '#F6D9C4',
                    outlinedActive: '#F6D9C4',
                    containedActive: '#8E3420',
                    disabledTextColor: '',
                },
            },
        },
    },
};

let [useThemeContext, ThemeContextProvider] = createGenericContext<Theme>();

function ThemeProvider({ children }: IThemeProviderProps) {
    const [theme, setTheme] = useState<ThemesName>(ThemesName.Light);

    const queryClient = useQueryClient();
    const { data } = useThemeSettingsQuery();

    useEffect(() => {
        if (data?.theme) {
            setTheme(ThemesName[data.theme]);
        }
    }, [data]);

    const { mutateAsync: mutateSetTheme } = useSetThemeSettings({
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: [THEME_QUERY] });
        },
        onError: (e) => {
            console.error(e);
        },
    });

    async function onThemeChange(theme: ThemesName) {
        setTheme(theme);
        await mutateSetTheme({ theme: theme });
    }

    return (
        <ThemeContextProvider value={{ selectTheme: onThemeChange, currentTheme: theme }}>
            <EmotionThemeProvider
                theme={{
                    ...muiTheme,
                    colors: { ...schemes.colors[theme] },
                }}
            >
                <CssBaseline />
                {children}
            </EmotionThemeProvider>
        </ThemeContextProvider>
    );
}

export default ThemeProvider;
export { useThemeContext };
