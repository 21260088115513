import { PropsWithChildren } from 'react';

import useXccCompareService, { XccCompareService } from 'hooks/useXccCompareService';
import { createGenericContext } from 'shared/createGenericContext';

const [useXccCompareContext, XccCompareContextProvider] = createGenericContext<XccCompareService>();

const XccCompareProvider = ({ children }: PropsWithChildren) => {
    const value = useXccCompareService();
    return <XccCompareContextProvider value={value}>{children}</XccCompareContextProvider>;
};

export { useXccCompareContext, XccCompareProvider };
