import styled from '@emotion/styled';
import * as RadixSwitch from '@radix-ui/react-switch';

export const SwitchRoot = styled(RadixSwitch.Root)(({ theme }) => ({
    all: 'unset',
    width: 32,
    height: 16,
    backgroundColor: theme.colors.switch.backgroundColor,
    borderRadius: '9999px',
    position: 'relative',
    boxShadow: `0 0 0 0`,
    WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)',
    cursor: 'pointer',
    '&:focus': { boxShadow: `0 0 0 0 black` },
    '&[data-state="checked"]': { backgroundColor: theme.colors.switch.checkedBackgroundColor },
}));

export const SwitchThumb = styled(RadixSwitch.Thumb)(({ theme }) => ({
    display: 'block',
    width: 12,
    height: 12,
    backgroundColor: theme.colors.switch.indicator.backgroundColor,
    borderRadius: '9999px',
    boxShadow: `0 0 0 0`,
    transition: 'transform 100ms',
    transform: 'translateX(2px)',
    willChange: 'transform',
    '&[data-state="checked"]': { transform: 'translateX(18px)' },
}));

export const Switch = (props: RadixSwitch.SwitchProps) => {
    return (
        <SwitchRoot {...props}>
            <SwitchThumb />
        </SwitchRoot>
    );
};

export default Switch;
