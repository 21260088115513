import { useTheme } from '@emotion/react';
import { Box } from '@mui/material';
import React, { MouseEventHandler } from 'react';

import ProjectNameLink from 'components/atoms/ProjectNameLink';
import RevisionChip from 'components/atoms/RevisionChip';
import Tooltip from 'components/atoms/Tooltip';

export type ProjectTitleActions = {
    tip: string;
    handler?: MouseEventHandler<HTMLDivElement>;
};

interface ICcdTileProjectLink {
    title: string;
    onProjectTitle?: ProjectTitleActions;
    revisionNumber?: number;
}

export const ProjectNameLinkId = 'TileProjectLink';

export function TileProjectLink({ title, revisionNumber, onProjectTitle }: ICcdTileProjectLink) {
    const theme = useTheme();
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                cursor: 'pointer',
                '&:hover': {
                    color: theme.colors.textHover,
                },
            }}
        >
            <ProjectNameLink onClick={onProjectTitle?.handler} data-testid={ProjectNameLinkId}>
                <Tooltip title={onProjectTitle?.tip} arrow placement='top'>
                    <span>{title}</span>
                </Tooltip>
            </ProjectNameLink>
            {revisionNumber && <RevisionChip label={`Version ${revisionNumber}`} />}
        </Box>
    );
}
