import React, { CSSProperties } from 'react';
import { SingleValueProps } from 'react-select';

import { IUserItem } from './IUserItem';

const SingleValue = (props: SingleValueProps<IUserItem>) => {
    const { data, getStyles } = props;

    let displayValue: string;
    if (data.fullName.includes('null')) {
        displayValue = data.email;
    } else {
        displayValue = `${data.fullName} (${data.email})`;
    }

    return <div style={getStyles('singleValue', props) as CSSProperties}>{displayValue}</div>;
};

export default SingleValue;
