import fetcher from 'providers/api/fetcher';

interface IAddUserApi {
    projectId: string;
    email: string;
    role: string;
    collaborationSpaceId: string;
    method: 'PUT' | 'POST';
}

export const shareProjectApi = async ({
    projectId,
    email,
    role,
    collaborationSpaceId,
    method,
}: IAddUserApi): Promise<any> =>
    await fetcher({
        url: `share/project/${collaborationSpaceId}/${projectId}`,
        data: {
            email,
            role,
            guest: false,
        },
        method,
    });
