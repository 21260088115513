import React, { PropsWithChildren, useState } from 'react';

import { DialogButton } from 'components/atoms/Button';
import Dialog from 'components/molecules/Dialog';

interface IConfirmationModal extends PropsWithChildren {
    title: string;
    confirmLabel: string;
    onClose: () => void;
    onConfirm: () => void;
}

export default function ConfirmationModal({
    title,
    confirmLabel,
    onClose,
    onConfirm,
    children,
}: IConfirmationModal) {
    const [isOpen, setIsOpen] = useState(true);

    return (
        <Dialog
            title={title}
            open={isOpen}
            setOpen={(open) => {
                setIsOpen(open);
                onClose();
            }}
            buttons={
                <>
                    <DialogButton
                        id={'buttonCancel'}
                        color={'primary'}
                        variant={'outlined'}
                        onClick={() => {
                            setIsOpen(false);
                            onClose();
                        }}
                        data-testid={'confirmationDialogCancelButton'}
                    >
                        Cancel
                    </DialogButton>
                    <DialogButton
                        id={'buttonOK'}
                        color={'primary'}
                        variant={'contained'}
                        onClick={() => {
                            setIsOpen(false);
                            onConfirm();
                        }}
                        data-testid={'confirmationDialogConfirmButton'}
                    >
                        {confirmLabel}
                    </DialogButton>
                </>
            }
        >
            {children}
        </Dialog>
    );
}
