import ReactTooltip, { TooltipProps } from 'react-tooltip';

import SecurityThreatTooltipContent from 'components/organisms/ProjectVersion/components/TooltipContent/SecurityThreatTooltipContent';

export const ScanErrorTooltipContainer = ({ place }: Pick<TooltipProps, 'place'>) => {
    return (
        <ReactTooltip
            id='tooltipScanError'
            place={place}
            effect='solid'
            type='light'
            border
            arrowColor='white'
            borderColor='#A3A39B'
            delayHide={500}
            clickable
            getContent={SecurityThreatTooltipContent}
            css={{ zIndex: '2000 !important', opacity: '1 !important' }}
        />
    );
};
