import { arrayOf, bool, func, object, string } from 'prop-types';
import { useCallback } from 'react';

import { FormButton } from 'components/atoms/Button';
import SideNav, { SideNavComponents } from 'components/molecules/SideNav';

import { ErrorModal, Form } from '../../UI';

import './fileFormComponent.scss';

export default function FileFormComponent({
    isVisible,
    onClose,
    headerTitle,
    onSubmit,
    error,
    buttonDisabled,
    handleCloseError,
    inputs,
    focus,
    isLoading,
}) {
    const onErrorCloseHandler = useCallback(
        (e) => {
            e.stopPropagation();
            handleCloseError();
        },
        [handleCloseError]
    );

    return (
        <div>
            <SideNav
                title={headerTitle}
                open={isVisible}
                setOpen={() => {
                    error || onClose();
                }}
            >
                <SideNavComponents
                    css={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                    }}
                >
                    <Form
                        inputs={inputs}
                        id='ccdFileManagementFileForm'
                        submit={onSubmit}
                        focus={focus}
                        isDisabled={buttonDisabled}
                        isLoading={isLoading}
                    />
                    <FormButton
                        id='ccdFileManagementSubmitButton'
                        type='submit'
                        form='ccdFileManagementFileForm'
                        color='primary'
                        variant='contained'
                        disabled={buttonDisabled}
                    >
                        Done
                    </FormButton>
                </SideNavComponents>
            </SideNav>
            {error && (
                <ErrorModal
                    title={error.title}
                    messages={error.messages}
                    onClose={onErrorCloseHandler}
                />
            )}
        </div>
    );
}

FileFormComponent.propTypes = {
    isVisible: bool,
    onClose: func,
    data: string,
    id: string,
    onSubmit: func,
    error: object,
    buttonDisabled: bool,
    handleCloseError: func,
    inputs: arrayOf(object),
    focus: string,
    isLoading: bool,
};

FileFormComponent.defaultProps = {
    buttonDisabled: false,
    isLoading: false,
};
