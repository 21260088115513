import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useState } from 'react';

import { useToast } from 'components/_legacy/ccd-toast';
import { useProgressBar } from 'components/atoms/ProgressBar';
import ConfirmationModal from 'components/organisms/ProjectVersion/components/ConfirmationModal';
import {
    deleteRevisionFailed,
    deleteRevisionSuccess,
} from 'components/organisms/ProjectVersion/hooks/revisionOperationsMessages';
import { deleteRevision } from 'services/api/project.service';

interface DeleteRevision {
    collaborationSpaceId: string;
    projectId: string;
    afterAction: () => void;
    beforeAction: () => void;
}

export const useDeleteRevision = ({
    afterAction,
    beforeAction,
    collaborationSpaceId,
    projectId,
}: DeleteRevision) => {
    const { showToast } = useToast();
    const { setProgressBar } = useProgressBar();
    const [showDialog, setShowDialog] = useState(false);
    const [revisionId, setRevisionId] = useState('');

    const { mutate: mutateDeleteRevision } = useMutation(deleteRevision, {
        onSuccess: async () => {
            try {
                showToast({
                    type: 'info',
                    title: deleteRevisionSuccess.title,
                    messages: deleteRevisionSuccess.messages,
                });
            } catch (e) {
                console.error(e);
            } finally {
                setProgressBar(false);
                afterAction();
            }
        },
        onError: (error: AxiosError) => {
            try {
                console.error(error);
                const errorMessage = deleteRevisionFailed(error.response?.status);
                showToast({
                    type: 'error',
                    title: errorMessage.title,
                    messages: errorMessage.messages,
                });
            } catch (e) {
                console.log(e);
            } finally {
                setProgressBar(false);
                afterAction();
            }
        },
    });

    const DialogComponent = () => (
        <>
            {showDialog ? (
                <ConfirmationModal
                    title={'Remove version'}
                    confirmLabel='Remove'
                    onClose={() => setShowDialog(false)}
                    onConfirm={async () => {
                        beforeAction();
                        setProgressBar(true);
                        setShowDialog(false);
                        mutateDeleteRevision({ collaborationSpaceId, projectId, revisionId });
                    }}
                >
                    Do you want to remove <b>version {revisionId}</b>?
                </ConfirmationModal>
            ) : null}
        </>
    );

    return {
        openDeleteRevisionDialog: (revisionId: string) => {
            setRevisionId(revisionId);
            setShowDialog(true);
        },
        revisionId: Number.parseInt(revisionId),
        deleteRevisionDialog: <DialogComponent />,
    };
};
