import { useEffect, useState } from 'react';

import { useFileManagementContext } from 'components/templates/FileManagement/components/FileManagementContext';

import { EE, OPEN_HTML_ARCHIVE_VIEWER } from '../../../eventEmitter';

export default function useOpenHTMLArchiveViewer() {
    const { dispatch } = useFileManagementContext();
    const [openHtmlViewer, setOpenHtmlViewer] = useState(false);

    useEffect(() => {
        if (openHtmlViewer) {
            EE.emit(OPEN_HTML_ARCHIVE_VIEWER);
            setOpenHtmlViewer(false);
        }
    }, [openHtmlViewer]);

    function onHTMLArchiveClickHandler(item) {
        setOpenHtmlViewer(true);
        dispatch({
            selectedFiles: [item],
        });
    }

    return { onHTMLArchiveClickHandler };
}
