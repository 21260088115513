import { ReactNode, useEffect, useState } from 'react';

import LeftDrawer from './LeftDrawer';

export const useLeftDrawer = (children: ReactNode) => {
    const [open, setOpen] = useState(false);
    useEffect(() => {
        children && setOpen(true);
    }, [children]);
    return {
        drawer: (
            <LeftDrawer open={open} closeDrawer={() => setOpen(false)}>
                {children}
            </LeftDrawer>
        ),
        isOpen: open,
        openDrawer: () => setOpen(true),
        closeDrawer: () => setOpen(false),
        toggleDrawer: () => setOpen((open) => !open),
    };
};
