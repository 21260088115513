import { useCallback, useState } from 'react';

export function useNoAccessUploadModal() {
    const [isModalVisible, setModalVisibility] = useState(false);

    const hideModal = useCallback(() => {
        setModalVisibility(false);
    }, []);

    const showModal = useCallback(() => {
        setModalVisibility(true);
    }, []);

    return { isModalVisible, hideModal, showModal };
}
