import React, { MouseEventHandler } from 'react';

import CloseSvgIcon from 'components/atoms/CloseSvgIcon';

interface Props {
    onClose: MouseEventHandler<SVGElement>;
}

export const closeButtonTestId = 'ccd-close-button';

export function CloseButton({ onClose }: Props) {
    return <CloseSvgIcon onClick={onClose} data-testid={closeButtonTestId} />;
}
