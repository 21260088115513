import { useMutation } from '@tanstack/react-query';

import useMarkAsReadApi from '../../services/api/markAsRead.api';
import useOptimisticUpdate from './optimisticUpdate';

const useMarkAsRead = () => {
    const { onMutate, onError } = useOptimisticUpdate();
    return useMutation({
        mutationFn: useMarkAsReadApi,
        onMutate: (notificationIdToMark) =>
            onMutate((cache) => {
                return cache.map((item) => {
                    if (item.NotificationId === notificationIdToMark) {
                        return { ...item, IsRead: true };
                    }
                    return item;
                });
            }),
        onError: onError,
    });
};

export default useMarkAsRead;
