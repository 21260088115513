import { useCallback, useContext, useState } from 'react';

import Button from 'components/atoms/Button';
import Tooltip from 'components/atoms/Tooltip';
import ExitFullScreen from 'images/exitFullScreen.svg?react';
import FullScreen from 'images/fullScreen.svg?react';
import { FullScreenContext } from 'providers/fullScreen/FullScreenProvider';

export default function BomViewFullScreenButton({}) {
    const { fullScreen, setFullScreen } = useContext(FullScreenContext);
    const [currentFullScreen, setCurrentFullScreen] = useState(fullScreen);

    const description = !currentFullScreen ? 'Fullscreen' : 'Exit fullscreen';

    const toggleFullScreen = useCallback(() => {
        setCurrentFullScreen((currentFullScreen) => !currentFullScreen);
        setFullScreen(!currentFullScreen);
    }, [fullScreen]);

    return (
        <Tooltip title={description} arrow enterDelay={500} placement='top'>
            <Button
                variant='text'
                css={{ cursor: 'pointer' }}
                color={'gray'}
                id='fullscreen'
                onClick={toggleFullScreen}
                leftIcon={!currentFullScreen ? FullScreen : ExitFullScreen}
            >
                {description}
            </Button>
        </Tooltip>
    );
}
