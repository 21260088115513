import styled from '@emotion/styled';
import {
    AccordionDetailsProps,
    AccordionProps,
    AccordionSummaryProps,
    Accordion as MuiAccordion,
    AccordionDetails as MuiAccordionDetails,
    AccordionSummary as MuiAccordionSummary,
} from '@mui/material';

export const Accordion = styled(MuiAccordion)<AccordionProps>(({ theme }) => ({
    ':before': {
        content: 'none',
    },
    '&.MuiAccordion-root': {
        boxShadow: 'none',
        marginBottom: theme.spacing(4),
        backgroundColor: theme.colors.background,
    },
    '&.Mui-expanded': {
        margin: theme.spacing(0),
    },
}));

Accordion.defaultProps = { defaultExpanded: true };

export const AccordionHeader = styled(MuiAccordionSummary)<AccordionSummaryProps>(({ theme }) => ({
    height: theme.spacing(8),
    borderRadius: '2px',
    flexDirection: 'row-reverse',
    '&.MuiAccordionSummary-root': {
        backgroundColor: theme.colors.accordion.header,
        minHeight: theme.spacing(8),
    },
    '& .MuiAccordionSummary-content': {
        margin: '0',
        display: 'block',
        overflow: 'hidden',
        backgroundColor: theme.colors.accordion.header,
    },
}));

export const AccordionBody = styled(MuiAccordionDetails)<AccordionDetailsProps>(({ theme }) => ({
    '&.MuiAccordionDetails-root': {
        padding: theme.spacing(2, 0),
    },
    '& > div': {
        marginBottom: theme.spacing(2),
    },
    '& > div:last-child': {
        marginBottom: theme.spacing(0),
    },
}));
