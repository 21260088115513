import {
    InputField,
    TextArea,
} from 'components/templates/ProjectEditView/components/Form/Form.styles';
import {
    reservedNamesValidator,
    specialCharactersValidator,
    trailingDot,
    whitespacesValidator,
} from 'shared/validation';

import { CommonInputInterface } from '../Form';

export const NAME_INPUT_ID = 'projectNameLabel';
export const DESCRIPTION_INPUT_ID = 'descriptionLabel';

export function getProjectInputs(
    projectName?: string,
    projectDescription?: string
): CommonInputInterface[] {
    return [
        {
            label: 'Project Name',
            id: NAME_INPUT_ID,
            inputMode: InputField,
            isRequired: true,
            defaultValue: projectName,
            validationConditions: {
                required: 'Name is required',
                maxLength: {
                    value: 256,
                    message: 'Name can have max 256 characters',
                },
                validate: {
                    noSpecialChar: specialCharactersValidator,
                    noReservedChar: reservedNamesValidator,
                    noTrailingDot: trailingDot,
                    noWhitespaces: (value: string) => {
                        return whitespacesValidator(value, 'Name cannot contain only whitespaces');
                    },
                },
            },
        },
        {
            label: 'Description',
            id: DESCRIPTION_INPUT_ID,
            inputMode: TextArea,
            isRequired: false,
            defaultValue: projectDescription,
            validationConditions: {
                maxLength: {
                    value: 1024,
                    message: 'Description can have max 1024 characters',
                },
            },
        },
    ];
}
