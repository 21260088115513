import * as RadioGroup from '@radix-ui/react-radio-group';

import Box from 'components/atoms/Box';

import {
    RadioGroupIndicator,
    RadioGroupItem,
    RadioGroupRoot,
    RadioTextLabel,
} from './RadioButtons.style';

export interface RadioButtonConfiguration {
    value: string;
    label: string;
}

export interface Props extends RadioGroup.RadioGroupProps {
    config: Array<RadioButtonConfiguration>;
    isError?: boolean;
}

export const RadioButtons = ({ config, isError, ...props }: Props) => {
    const renderedRadioButtons = config.map((radioButtonConfig: RadioButtonConfiguration) => (
        <Box key={radioButtonConfig.label} css={{ display: 'flex', alignItems: 'center' }}>
            <RadioGroupItem id={radioButtonConfig.label} value={radioButtonConfig.value}>
                <RadioGroupIndicator />
            </RadioGroupItem>
            <RadioTextLabel isError={isError}>{radioButtonConfig.label}</RadioTextLabel>
        </Box>
    ));

    return <RadioGroupRoot {...props}>{renderedRadioButtons}</RadioGroupRoot>;
};

export default RadioButtons;
