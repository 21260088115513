import head from 'lodash/head';
import isEmpty from 'lodash/isEmpty';

const FILE_EXTENSION_REGEX = /\.\w+$/i;

export const getFileNameWithNoExtension = (fileName) => {
    if (isEmpty(fileName)) {
        return [fileName, ''];
    }

    return [
        head(fileName.split(FILE_EXTENSION_REGEX)),
        head(fileName.match(FILE_EXTENSION_REGEX)) || '',
    ];
};
