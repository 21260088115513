import { useEffect, useState } from 'react';

import { useFileManagementContext } from 'components/templates/FileManagement/components/FileManagementContext';

import { EE, OPEN_PDF_VIEWER } from '../../../eventEmitter';

export default function useOpenPdfViewer() {
    const { dispatch } = useFileManagementContext();
    const [openPdfViewer, setOpenPdfViewer] = useState(false);

    useEffect(() => {
        if (openPdfViewer) {
            EE.emit(OPEN_PDF_VIEWER);
            setOpenPdfViewer(false);
        }
    }, [openPdfViewer]);

    function onPdfClickHandler(item) {
        setOpenPdfViewer(true);
        dispatch({
            selectedFiles: [item],
        });
    }

    return { onPdfClickHandler };
}
