import React from 'react';

import { Center } from 'components/atoms/Flex/Flex';
import { ISelectedFile } from 'components/templates/FileManagement/contexts/FileManagementContext';
import {
    InfectedIcon,
    InfectedThumbnail,
    Paragraph,
    Thumbnail,
    ThumbnailWrapper,
} from 'components/templates/ProjectTiles/ProjectTiles.styles';
import AltiumBoardDesign from 'images/typeAltiumBoardDesign100.svg?react';
import XLS from 'images/typeExcel100.svg?react';
import File from 'images/typeFile100.svg?react';
import Folder from 'images/typeFolder100.svg?react';
import HTMLARCHIVE from 'images/typeHTMLArchive100.svg?react';
import Matlab from 'images/typeMatLab100.svg?react';
import PPT from 'images/typePowerPoint100.svg?react';
import PDF from 'images/typeTechPackPDF100.svg?react';
import WORD from 'images/typeWord100.svg?react';
import XML from 'images/typeXMLSchemaRevision100.svg?react';
import ZIP from 'images/typeZipFile100.svg?react';
import { isScanStatusInfected } from 'shared/utility';

function Icon({ isFolder, name, typeName }: { isFolder: boolean; name: string; typeName: string }) {
    if (isFolder) {
        return <Folder />;
    }
    if (typeName === 'WebPageZipRevision') {
        return <HTMLARCHIVE />;
    }
    switch (name.split('.').pop()) {
        case 'pdf':
            return <PDF />;
        case 'zip':
            return <ZIP />;
        case 'xml':
            return <XML />;
        case 'xls':
        case 'xlsx':
            return <XLS />;
        case 'ppt':
            return <PPT />;
        case 'mat':
            return <Matlab />;
        case 'sch':
        case 'cchdoc':
            return <AltiumBoardDesign />;
        case 'doc':
        case 'docx':
            return <WORD />;
        default:
            return <File />;
    }
}

export default function FileTileThumbnail({ record }: { record: ISelectedFile }) {
    const getThumbnail = (record: ISelectedFile) => {
        if (record.thumbnailUrl) {
            return <Thumbnail draggable={false} alt={record.name} src={record.thumbnailUrl} />;
        }
        if (isScanStatusInfected(record.thumbnailScanInfo)) {
            return (
                <InfectedThumbnail>
                    <InfectedIcon />
                    <Paragraph>Security scan detected a threat in this cover image.</Paragraph>
                    <Paragraph>Make sure you upload a virus free file.</Paragraph>
                </InfectedThumbnail>
            );
        }

        return (
            <Center css={{ height: '100%' }}>
                <Icon isFolder={record.isFolder} name={record.name} typeName={record.typeName} />
            </Center>
        );
    };

    return <ThumbnailWrapper>{getThumbnail(record)}</ThumbnailWrapper>;
}
