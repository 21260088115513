import { useContext } from 'react';

import { RevisionsActionContext } from 'providers/revisionsAction/RevisionsActionStatusProvider';

export const useRevisionsAction = () => {
    const { revisionsActionInProgress } = useContext(RevisionsActionContext);

    return {
        revisionsActionInProgress: revisionsActionInProgress,
    };
};
