"use strict";
window._paq = window._paq || [];
window.San = function() {
    function Pa(a) {
        return 0 === a ? (ia = 0, !0) : a && 0 < a ? (ia = 1E3 * a, !0) : !1;
    }

    function ja() {
        ja = !0;
    }

    function Qa() {
        u[m.UNCLASSIFIED] = !1;
        u[m.STRICTLY_NECESSARY] = !0;
        u[m.NECESSARY] = !0;
        u[m.OPTIONAL] = !0;
    }

    function rb() {
        ka ? _paq.push(["setRequestMethod", "POST"]) : (_paq.push(["setRequestMethod", "GET"]), _paq.push(["disableAlwaysUseSendBeacon"]));
        _paq.push(["setTrackerUrl", r]);
        _paq.push(["setSiteId", N]);
        _paq.push(["deleteCookies"]);
        _paq.push(["disableCookies"]);
        _paq.push(["disablePerformanceTracking"]);
        _paq.push(["setIgnoreClasses",
            "san_ignore"]);
        _paq.push(["setLinkClasses", "san_link"]);
        _paq.push(["setDownloadClasses", "san_download"]);
        _paq.push(["setCustomRequestProcessing", function(a) {
            var c = !1, b = !1, d = !1, q = !1, f = "", e = "";
            if (a.startsWith("action_name=%24b")) q = b = !0; else if (a.startsWith("action_name=%24e")) q = d = !0; else if (a.startsWith("action_name=%24o")) return F(C), "";
            if (!0 === w && !1 === b && !1 === d) return "";
            if (!1 === q && a.startsWith("e_c=") && (c = !0, Ra && (b = a.indexOf("%5B_sanCan%3A"), -1 < b && (q = a.indexOf("_sanCav%3A", b), -1 < q)))) {
                var h = a.indexOf("_%5D");
                -1 < h && (f = a.substring(b + 13, q), e = a.substring(q + 10, h), f && e && (a = "e_c=" + a.substring(h + 4)));
            }
            (c = wa(c, f, e)) && (a = a + "&" + c);
            ka && a && (a = Sa(a));
            d && F(C);
            return a;
        }]);
    }

    function Sa(a) {
        var c = D[0], b = a[D[1]];
        b < W && (W = b);
        for (var d = 0; d < W; d++) c += String[D[3]](a[D[2]](d) ^ 199);
        if (W < b) for (d = W; d < b; d++) c += String[D[3]](a[D[2]](d) ^ 165);
        a = D[4];
        for (b = 0; b < a[D[1]]; b++) c += String[D[3]](a[D[2]](b) ^ 179);
        return c;
    }

    function G() {
        return v && !w && !O;
    }

    function A(a) {
        return a && "string" === typeof a ? a.replace(/^\s+|\s+$/gm, "") : a;
    }

    function H(a) {
        return !a ||
            0 === a.length;
    }

    function Ta(a) {
        var c = "";
        if (!H(a)) {
            if (1 == a.length) return a;
            for (var b = a.length - 1; 0 <= b; b--) c += a[b];
        }
        return c;
    }

    function n(a, c) {
        if (a) if ("string" === typeof a) {
            var b = A(a);
            if (b) {
                if (b.length <= (c ? c : 256)) return k = g.SUCCESS, b;
                k = g.KEY_TOO_LONG;
            } else k = g.INVALID_KEY_NAME;
        } else k = g.INVALID_KEY_TYPE; else k = g.INVALID_KEY_NAME;
        return "";
    }

    function Ua(a) {
        return (a = n(a, 32)) ? /^[A-Za-z0-9. ]+$/.test(a) ? a : "" : "";
    }

    function xa(a) {
        if (a = n(a, 32)) {
            if (!/^[A-Za-z0-9_.+ -]+$/.test(a)) return k = g.INVALID_PROD_VER, "";
            if (ya) {
                a = a +
                    " - " + ya;
                if (32 >= a.length) return a;
                k = g.INVALID_PROD_VER;
            } else return a;
        }
        return "";
    }

    function X(a, c) {
        if (isNaN(a)) {
            var b = A(a);
            if (b) if ("string" === typeof b) {
                if ("{" === b[0]) try {
                    var d = JSON.parse(b), q = 0, f;
                    for (f in d) {
                        var e = d[f];
                        q++;
                        if ("string" === typeof e && "{" === e[0]) throw k = g.VALUE_NESTED_JSON_NOT_SUPPORTED, Error("Property with nested JSON is not supported");
                    }
                    0 == q && (b = "");
                } catch (h) {
                    throw k = g.VALUE_BAD_JSON_FORMAT, Error("Property has bad JSON format.");
                }
            } else if ("number" !== typeof b) throw k = g.VALUE_DATA_TYPE_NOT_SUPPORTED,
                Error("Property data type is not supported.");
            b || (b = c ? "Null" : "");
            return b;
        }
        return a;
    }

    function sb(a) {
        function c(a, d) {
            return a << d | a >>> 32 - d;
        }

        function b(a) {
            var d = "", c, b;
            for (c = 7; 0 <= c; c--) b = a >>> 4 * c & 15, d += b.toString(16);
            return d;
        }

        var d, q, f = Array(80), e = 1732584193, h = 4023233417, g = 2562383102, l = 271733878,
            k = 3285377520, t, y, m, n, p;
        a = function(a) {
            a = a.replace(/\r\n/g, "\n");
            for (var d = "", c = 0; c < a.length; c++) {
                var b = a.charCodeAt(c);
                128 > b ? d += String.fromCharCode(b) : (127 < b && 2048 > b ? d += String.fromCharCode(b >> 6 | 192) : (d += String.fromCharCode(b >>
                    12 | 224), d += String.fromCharCode(b >> 6 & 63 | 128)), d += String.fromCharCode(b & 63 | 128));
            }
            return d;
        }(a);
        t = a.length;
        var z = [];
        for (d = 0; d < t - 3; d += 4) q = a.charCodeAt(d) << 24 | a.charCodeAt(d + 1) << 16 | a.charCodeAt(d + 2) << 8 | a.charCodeAt(d + 3), z.push(q);
        switch (t % 4) {
            case 0:
                d = 2147483648;
                break;
            case 1:
                d = a.charCodeAt(t - 1) << 24 | 8388608;
                break;
            case 2:
                d = a.charCodeAt(t - 2) << 24 | a.charCodeAt(t - 1) << 16 | 32768;
                break;
            case 3:
                d = a.charCodeAt(t - 3) << 24 | a.charCodeAt(t - 2) << 16 | a.charCodeAt(t - 1) << 8 | 128;
        }
        for (z.push(d); 14 != z.length % 16;) z.push(0);
        z.push(t >>>
            29);
        z.push(t << 3 & 4294967295);
        for (a = 0; a < z.length; a += 16) {
            for (d = 0; 16 > d; d++) f[d] = z[a + d];
            for (d = 16; 79 >= d; d++) f[d] = c(f[d - 3] ^ f[d - 8] ^ f[d - 14] ^ f[d - 16], 1);
            q = e;
            t = h;
            y = g;
            m = l;
            n = k;
            for (d = 0; 19 >= d; d++) p = c(q, 5) + (t & y | ~t & m) + n + f[d] + 1518500249 & 4294967295, n = m, m = y, y = c(t, 30), t = q, q = p;
            for (d = 20; 39 >= d; d++) p = c(q, 5) + (t ^ y ^ m) + n + f[d] + 1859775393 & 4294967295, n = m, m = y, y = c(t, 30), t = q, q = p;
            for (d = 40; 59 >= d; d++) p = c(q, 5) + (t & y | t & m | y & m) + n + f[d] + 2400959708 & 4294967295, n = m, m = y, y = c(t, 30), t = q, q = p;
            for (d = 60; 79 >= d; d++) p = c(q, 5) + (t ^ y ^ m) + n + f[d] + 3395469782 & 4294967295,
                n = m, m = y, y = c(t, 30), t = q, q = p;
            e = e + q & 4294967295;
            h = h + t & 4294967295;
            g = g + y & 4294967295;
            l = l + m & 4294967295;
            k = k + n & 4294967295;
        }
        p = b(e) + b(h) + b(g) + b(l) + b(k);
        return p.toLowerCase();
    }

    function Va(a) {
        function c(a, c) {
            var b = (a & 65535) + (c & 65535);
            return (a >> 16) + (c >> 16) + (b >> 16) << 16 | b & 65535;
        }

        function b(a, c) {
            return a >>> c | a << 32 - c;
        }

        a = function(a) {
            a = a.replace(/\r\n/g, "\n");
            for (var c = "", b = 0; b < a.length; b++) {
                var f = a.charCodeAt(b);
                128 > f ? c += String.fromCharCode(f) : (127 < f && 2048 > f ? c += String.fromCharCode(f >> 6 | 192) : (c += String.fromCharCode(f >> 12 |
                    224), c += String.fromCharCode(f >> 6 & 63 | 128)), c += String.fromCharCode(f & 63 | 128));
            }
            return c;
        }(a);
        return function(a) {
            for (var c = "", b = 0; b < 4 * a.length; b++) c += "0123456789abcdef".charAt(a[b >> 2] >> 8 * (3 - b % 4) + 4 & 15) + "0123456789abcdef".charAt(a[b >> 2] >> 8 * (3 - b % 4) & 15);
            return c;
        }(function(a, q) {
            var f = [1116352408, 1899447441, 3049323471, 3921009573, 961987163, 1508970993, 2453635748, 2870763221, 3624381080, 310598401, 607225278, 1426881987, 1925078388, 2162078206, 2614888103, 3248222580, 3835390401, 4022224774, 264347078, 604807628, 770255983,
                    1249150122, 1555081692, 1996064986, 2554220882, 2821834349, 2952996808, 3210313671, 3336571891, 3584528711, 113926993, 338241895, 666307205, 773529912, 1294757372, 1396182291, 1695183700, 1986661051, 2177026350, 2456956037, 2730485921, 2820302411, 3259730800, 3345764771, 3516065817, 3600352804, 4094571909, 275423344, 430227734, 506948616, 659060556, 883997877, 958139571, 1322822218, 1537002063, 1747873779, 1955562222, 2024104815, 2227730452, 2361852424, 2428436474, 2756734187, 3204031479, 3329325298],
                e = [1779033703, 3144134277, 1013904242, 2773480762,
                    1359893119, 2600822924, 528734635, 1541459225], h = Array(64), g, l, k, t, m, n,
                p, u, z, r, v, w;
            a[q >> 5] |= 128 << 24 - q % 32;
            a[(q + 64 >> 9 << 4) + 15] = q;
            for (z = 0; z < a.length; z += 16) {
                g = e[0];
                l = e[1];
                k = e[2];
                t = e[3];
                m = e[4];
                n = e[5];
                p = e[6];
                u = e[7];
                for (r = 0; 64 > r; r++) {
                    if (16 > r) h[r] = a[r + z]; else {
                        v = r;
                        w = h[r - 2];
                        w = b(w, 17) ^ b(w, 19) ^ w >>> 10;
                        w = c(w, h[r - 7]);
                        var x;
                        x = h[r - 15];
                        x = b(x, 7) ^ b(x, 18) ^ x >>> 3;
                        h[v] = c(c(w, x), h[r - 16]);
                    }
                    v = m;
                    v = b(v, 6) ^ b(v, 11) ^ b(v, 25);
                    v = c(c(c(c(u, v), m & n ^ ~m & p), f[r]), h[r]);
                    u = g;
                    u = b(u, 2) ^ b(u, 13) ^ b(u, 22);
                    w = c(u, g & l ^ g & k ^ l & k);
                    u = p;
                    p = n;
                    n = m;
                    m = c(t, v);
                    t = k;
                    k = l;
                    l = g;
                    g = c(v, w);
                }
                e[0] = c(g, e[0]);
                e[1] = c(l, e[1]);
                e[2] = c(k, e[2]);
                e[3] = c(t, e[3]);
                e[4] = c(m, e[4]);
                e[5] = c(n, e[5]);
                e[6] = c(p, e[6]);
                e[7] = c(u, e[7]);
            }
            return e;
        }(function(a) {
            for (var c = [], b = 0; b < 8 * a.length; b += 8) c[b >> 5] |= (a.charCodeAt(b / 8) & 255) << 24 - b % 32;
            return c;
        }(a), 8 * a.length));
    }

    function tb(a) {
        a = window.atob(a);
        for (var c = new Uint8Array(a.length), b = 0; b < a.length; b++) c[b] = a.charCodeAt(b);
        return c;
    }

    function ub(a) {
        for (var c = new ArrayBuffer(a.length), b = new Uint8Array(c), d = 0, e = a.length; d < e; d++) b[d] = a.charCodeAt(d);
        return c;
    }

    function vb(a) {
        return (new Uint8Array(a)).reduce(function(a, b) {
            return a + String.fromCharCode(b);
        }, "");
    }

    function wb(a, c, b) {
        c = ub(c);
        window.crypto.subtle.encrypt({ name: "RSA-OAEP" }, a, c).then(function(a) {
            a = vb(a);
            b === ma.EMAIL ? za = window.btoa(a) : b === ma.LOCATION && (na = window.btoa(a));
            if (za && (!oa || na) && P && !(0 >= P.length || !S || 0 >= S.length)) {
                a = {};
                a.em = za;
                na && (a.loc = na);
                var c = JSON.stringify(a);
                E(C) || Aa();
                a = "e_c=" + encodeURIComponent("std-SanPII");
                a += "&e_a=" + encodeURIComponent(c);
                a += "&idsite=" + encodeURIComponent(N);
                a +=
                    "&url=" + encodeURIComponent(window.location.href);
                (c = wa()) && (a = a + "&" + c);
                p && console.log("San: PII req = " + a);
                Wa(a, xb, !0);
            }
        }).catch(function(a) {
            p && console.log("San: Failed to encrypt data. Err: " + a);
        });
    }

    function Xa(a, c) {
        window.crypto.subtle.importKey("spki", tb(Y), {
            name: "RSA-OAEP",
            hash: { name: "SHA-256" },
        }, !0, ["encrypt"]).then(function(b) {
            wb(b, a, c);
        }).catch(function(a) {
            p && console.log("San: Failed to import key. Err: " + a);
        });
    }

    function Ya() {
        var a = N, a = a + Z, a = a + r, a = a + aa, a = a + (window.location.href || ""),
            a = a + (new Date).getTime();
        window.performance && "function" === typeof window.performance.now && (a += performance.now());
        a += Math.random();
        a += navigator.userAgent || "";
        a += navigator.languages || "";
        if (navigator.plugins) for (var c = navigator.plugins.length, b = 0; b < c; b++) a += navigator.plugins[b].name;
        if (navigator.mimeTypes && 0 < navigator.mimeTypes.length) {
            for (var c = "", b = navigator.mimeTypes, d = 0; d < b.length; d++) c += b[d].type + ":" + b[d].description;
            a += c;
        }
        window.screen && (a += screen.width || "", a += screen.height || "", a += screen.availWidth || "", a += screen.availHeight ||
            "", a += screen.colorDepth || "", a += screen.pixelDepth || "");
        a += window.innerWidth || "";
        a += window.innerHeight || "";
        a += window.outerWidth || "";
        a += window.outerHeight || "";
        c = "0";
        b = typeof navigator.javaEnabled;
        "unknown" !== b && "undefined" !== b && navigator.javaEnabled() && (c = "1");
        return a = "02X" + sb(a + c).substring(3);
    }

    function yb() {
        var a = (new Date).getTime();
        window.performance && "function" === typeof window.performance.now && (a += performance.now());
        return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
            var b =
                (a + 16 * Math.random()) % 16 | 0;
            a = Math.floor(a / 16);
            return ("x" === c ? b : b & 3 | 8).toString(16);
        });
    }

    function E(a) {
        a += "=";
        var c = document.cookie.split(";"), b, d;
        for (b = 0; b < c.length; b += 1) {
            for (d = c[b]; " " === d.charAt(0);) d = d.substring(1);
            if (0 === d.indexOf(a)) return d.substring(a.length, d.length);
        }
        return "";
    }

    function ba(a, c, b) {
        var d = "; expires=" + b.toUTCString();
        document.cookie = a + "=" + c + d + ca;
        document.cookie = a + "-expire=" + b.toUTCString() + d + ca;
    }

    function F(a) {
        document.cookie = a + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC" + ca;
        document.cookie =
            a + "-expire=; expires=Thu, 01 Jan 1970 00:00:00 UTC" + ca;
    }

    function zb(a) {
        var c = "";
        if ("undefined" !== typeof window.Storage) try {
            c = window.sessionStorage.getItem(a), null === c && (c = "");
        } catch (b) {
            c = "";
        }
        return c;
    }

    function Za(a) {
        var c = E(Ba);
        !c && a && (c = Ya(), $a(c));
        c.match(/^\d+X[a-fA-F0-9]+$/) || (p && console.log("Improper endpoint detected: " + c), c = Ya(), p && console.log("Using new endpoint: " + c), $a(c));
        return c;
    }

    function $a(a) {
        if (a) {
            var c = new Date;
            c.setTime(c.getTime() + 63072E6);
            ba(Ba, a, c);
        }
    }

    function ab(a, c) {
        var b = yb(), d = new Date;
        d.setTime(d.getTime() + a);
        ba(C, b, d);
        for (var e in T) T.hasOwnProperty(e) && delete T[e];
        T = {};
        pa = "";
        c && (qa("$b"), da = !0);
        return b;
    }

    function Ab(a) {
        p && (console.log("CB: req:'" + a.request), console.log("CB: url:'" + a.trackerUrl), console.log("CB: res:'" + a.success));
    }

    function x(a) {
    }

    function bb() {
        ja ? cb && (_paq.push(["enableLinkTracking"]), cb = !1) : _paq.push(["disableLinkTracking"]);
    }

    function qa(a) {
        a ? _paq.push(["trackPageView", a, void 0, x]) : _paq.push(["trackPageView", void 0, void 0, x]);
        bb();
    }

    function db(a) {
        K.then(function() {
            ra("std-Place") ?
                a ? _paq.push(["trackPageView", a, void 0, x]) : _paq.push(["trackPageView", void 0, void 0, x]) : (k = g.EVENT_DISABLED, p && console.log("Place: std-Place is disabled"));
        }, function(a) {
            p && (k = g.CONFIG_SERVER_ERROR, console.log("Configuration server returned error: " + a));
        }).catch(function(a) {
            console.log(a);
        });
    }

    function sa(a, c, b, d) {
        _paq.push(["trackEvent", a, c, b, d, void 0, x]);
        bb();
    }

    function Q(a, c, b, d, e) {
        var f = c, la = b ? b : B.EVENT, h = {}, m = 0, l = [], r = d ? d : "", t = e ? e : "";
        this.addProperty = function(a, c) {
            if (f) {
                var b = n(a);
                if (b) if (200 >= m) {
                    m++;
                    try {
                        var d = X(c, !1);
                        h[b] = d;
                    } catch (e) {
                        m--;
                    }
                } else p && console.log("San: error: event property limit threshold exceeded"), k = g.TOO_MANY_PROPERTIES;
            }
        };
        this.log = function() {
            K.then(function() {
                if (f && G()) {
                    var c = a;
                    r && (c = r + "_" + a);
                    if (ra(c)) p && console.log("Event: " + c + " is enabled"); else {
                        k = g.EVENT_DISABLED;
                        p && console.log("Event: " + c + " is disabled");
                        return;
                    }
                    r && t && (f = "[_sanCan:" + r + "_sanCav:" + t + "_]" + r + "_" + f);
                    la === B.ERROR && (0 < l.length && (h["std-Trace"] = l), 0 < L.length && (h["std-Cmd"] = L), 0 < ea.length && (h["std-Place"] = ea));
                    0 < Object.keys(h).length ?
                        (c = JSON.stringify(h), sa(f, c, r, t)) : sa(f, "Null", r, t);
                }
                t = r = f = "";
                la === B.ERROR && (l.length = 0);
            }, function(a) {
                p && (k = g.CONFIG_SERVER_ERROR, console.log("Configuration server returned error: " + a));
            }).catch(function(a) {
                console.log(a);
            });
        };
        this.addStackTraceItem = function(a, c, b) {
            if (la == B.ERROR) {
                var d = {}, e = A(a);
                (a = !H(e)) && (d["std-Loc"] = e);
                e = A(c);
                (c = !H(e)) && (d["std-Lib"] = e);
                b = A(b);
                (e = !H(b)) && (d["std-Meta"] = b);
                50 > l.length && (a || c || e) && l.push(d);
            }
        };
    }

    function Ca(a) {
        var c = {};
        this.addValue = function(a, d) {
            k = g.SUCCESS;
            var e = n(a);
            if (e) if (d) try {
                var f = X(d, !1);
                c[e] = f;
            } catch (la) {
            } else c[e] = "";
        };
        this.isMap = function() {
            return a;
        };
        this.getData = function() {
            return c;
        };
    }

    function wa(a, c, b) {
        var d = eb, d = d + "&sanSDKVer=4.2.0.3018809" + ("&sanEID=" + Za(!0));
        P && (d += "&sanCID=" + P);
        S && P && (d += "&sanUID=" + S);
        Da && (d += "&sanPX=" + Da);
        Ea && (d += "&sanPC=" + Ea);
        if (0 < Object.getOwnPropertyNames(Fa).length) {
            var e = JSON.stringify(Fa);
            0 < encodeURIComponent(e).length && (d += "&sanPT=" + encodeURIComponent(e));
        }
        c && b && (d += "&sanCan=" + c, d += "&sanCav=" + b);
        c = E(C);
        c || (c = ab(6048E5, !1), w ||
        (d += "&sanNewSess=1", da = !0));
        d += "&sanSID=" + c;
        a && (fb = !0, pa && (d += "&sanSE=" + encodeURIComponent(pa)));
        "" !== R && void 0 !== R && (d += "&SanPRODKey=" + encodeURIComponent(R));
        return d;
    }

    function gb(a) {
        for (var c = []; 2 <= a.length;) c.push(parseInt(a.substring(0, 2), 16)), a = a.substring(2, a.length);
        return c;
    }

    function hb(a) {
        var c = Va(a), b = c.length;
        a = c.substring(0, b / 2);
        c = c.substring(b / 2);
        a = gb(a);
        for (var c = gb(c), d = "", b = 0; b < a.length; b++) var e = ((a[b] ^ c[b]) & 255).toString(16), e = 1 === e.length ? "0" + e : e, d = d + e;
        return d;
    }

    function ib(a) {
        if (!E(U)) {
            var c =
                void 0, b = void 0;
            a ? (c = "sansOO", b = "sansOOO") : (c = "sanOO", b = "sanOOO");
            F(c);
            F(b);
        }
    }

    function jb(a) {
        var c = N + Z, b = {}, d = void 0, q = void 0;
        if (!1 === a) if (q = d = !1, a = E(U)) {
            for (var g = "", k = a.length, h = 0; h < k; h++) if (127 > a.charCodeAt(h)) {
                var m = a.charAt(h), l = f[0];
                switch (m) {
                    case f[2]:
                        l = f[1];
                        break;
                    case f[4]:
                        l = f[3];
                        break;
                    case f[6]:
                        l = f[5];
                        break;
                    case f[1]:
                        l = f[7];
                        break;
                    case f[3]:
                        l = f[8];
                        break;
                    case f[5]:
                        l = f[9];
                        break;
                    case f[7]:
                        l = f[10];
                        break;
                    case f[8]:
                        l = f[2];
                        break;
                    case f[9]:
                        l = f[4];
                        break;
                    case f[10]:
                        l = f[6];
                        break;
                    case f[12]:
                        l = f[11];
                        break;
                    case f[14]:
                        l = f[13];
                        break;
                    case f[16]:
                        l = f[15];
                        break;
                    case f[18]:
                        l = f[17];
                        break;
                    case f[20]:
                        l = f[19];
                        break;
                    case f[22]:
                        l = f[21];
                        break;
                    case f[24]:
                        l = f[23];
                        break;
                    case f[26]:
                        l = f[25];
                        break;
                    case f[28]:
                        l = f[27];
                        break;
                    case f[30]:
                        l = f[29];
                        break;
                    case f[32]:
                        l = f[31];
                        break;
                    case f[34]:
                        l = f[33];
                        break;
                    case f[36]:
                        l = f[35];
                        break;
                    case f[38]:
                        l = f[37];
                        break;
                    case f[40]:
                        l = f[39];
                        break;
                    case f[42]:
                        l = f[41];
                        break;
                    case f[44]:
                        l = f[43];
                        break;
                    case f[46]:
                        l = f[45];
                        break;
                    case f[48]:
                        l = f[47];
                        break;
                    case f[50]:
                        l = f[49];
                        break;
                    case f[52]:
                        l = f[51];
                        break;
                    case f[54]:
                        l =
                            f[53];
                        break;
                    case f[56]:
                        l = f[55];
                        break;
                    case f[58]:
                        l = f[57];
                        break;
                    case f[60]:
                        l = f[59];
                        break;
                    case f[62]:
                        l = f[61];
                        break;
                    case f[35]:
                        l = f[28];
                        break;
                    case f[37]:
                        l = f[30];
                        break;
                    case f[39]:
                        l = f[32];
                        break;
                    case f[41]:
                        l = f[34];
                        break;
                    case f[43]:
                        l = f[36];
                        break;
                    case f[45]:
                        l = f[38];
                        break;
                    case f[47]:
                        l = f[40];
                        break;
                    case f[49]:
                        l = f[42];
                        break;
                    case f[51]:
                        l = f[44];
                        break;
                    case f[53]:
                        l = f[46];
                        break;
                    case f[55]:
                        l = f[48];
                        break;
                    case f[57]:
                        l = f[50];
                        break;
                    case f[59]:
                        l = f[52];
                        break;
                    case f[61]:
                        l = f[54];
                        break;
                    case f[11]:
                        l = f[56];
                        break;
                    case f[13]:
                        l = f[58];
                        break;
                    case f[15]:
                        l = f[60];
                        break;
                    case f[17]:
                        l = f[62];
                        break;
                    case f[19]:
                        l = f[12];
                        break;
                    case f[21]:
                        l = f[14];
                        break;
                    case f[23]:
                        l = f[16];
                        break;
                    case f[25]:
                        l = f[18];
                        break;
                    case f[27]:
                        l = f[20];
                        break;
                    case f[29]:
                        l = f[22];
                        break;
                    case f[31]:
                        l = f[24];
                        break;
                    case f[33]:
                        l = f[26];
                        break;
                    default:
                        l = m;
                }
                g += l;
            } else g += a.charAt(h);
            h = g.split("SanCkD");
            g = h.length;
            k = a = l = m = "";
            2 <= g ? h[0] === c ? (m = h[1], 5 == g && (l = h[2], a = h[3], k = h[4]), "0" === m && !0 === w ? d = !0 : "1" === m && !1 === w ? q = d = !0 : "0" === m && !1 === w && (h = l, !H(h) && ("https://sangwc.industrysoftware.automation.siemens.com/" ===
                r && -1 !== h.indexOf("E") || "https://sangw.industrysoftware.automation.siemens.com/sangateway/" === r && -1 !== h.indexOf("I") || r === h) ? (Ta(a) !== fa && (d = !0, b["std-FullVersion"] = fa), k !== ga && (d = !0, b["std-AppLanguage"] = ga)) : q = d = !0)) : q = d = !0 : q = d = !0;
        } else q = d = !0; else q = d = !0;
        if (!0 === d) {
            d = void 0;
            !1 === w ? (q && (b["std-OptOut"] = "false", b["std-FullVersion"] = fa, b["std-AppLanguage"] = ga), d = c + "SanCkD", d += "0", d += "SanCkD", d = "https://sangwc.industrysoftware.automation.siemens.com/" === r ? d + "E" : "https://sangw.industrysoftware.automation.siemens.com/sangateway/" ===
            r ? d + "I" : d + r, d += "SanCkD", d += Ta(fa), d += "SanCkD", d += ga) : (b["std-OptOut"] = "true", d = c + "SanCkD", d += "1");
            c = d;
            a = "";
            q = c.length;
            for (d = 0; d < q; d++) if (127 > c.charCodeAt(d)) {
                k = c.charAt(d);
                h = e[0];
                switch (k) {
                    case e[2]:
                        h = e[1];
                        break;
                    case e[4]:
                        h = e[3];
                        break;
                    case e[6]:
                        h = e[5];
                        break;
                    case e[7]:
                        h = e[2];
                        break;
                    case e[8]:
                        h = e[4];
                        break;
                    case e[9]:
                        h = e[6];
                        break;
                    case e[10]:
                        h = e[7];
                        break;
                    case e[1]:
                        h = e[8];
                        break;
                    case e[3]:
                        h = e[9];
                        break;
                    case e[5]:
                        h = e[10];
                        break;
                    case e[12]:
                        h = e[11];
                        break;
                    case e[14]:
                        h = e[13];
                        break;
                    case e[16]:
                        h = e[15];
                        break;
                    case e[18]:
                        h =
                            e[17];
                        break;
                    case e[20]:
                        h = e[19];
                        break;
                    case e[22]:
                        h = e[21];
                        break;
                    case e[24]:
                        h = e[23];
                        break;
                    case e[26]:
                        h = e[25];
                        break;
                    case e[28]:
                        h = e[27];
                        break;
                    case e[30]:
                        h = e[29];
                        break;
                    case e[32]:
                        h = e[31];
                        break;
                    case e[34]:
                        h = e[33];
                        break;
                    case e[36]:
                        h = e[35];
                        break;
                    case e[38]:
                        h = e[37];
                        break;
                    case e[40]:
                        h = e[39];
                        break;
                    case e[42]:
                        h = e[41];
                        break;
                    case e[44]:
                        h = e[43];
                        break;
                    case e[46]:
                        h = e[45];
                        break;
                    case e[48]:
                        h = e[47];
                        break;
                    case e[50]:
                        h = e[49];
                        break;
                    case e[52]:
                        h = e[51];
                        break;
                    case e[54]:
                        h = e[53];
                        break;
                    case e[56]:
                        h = e[55];
                        break;
                    case e[58]:
                        h = e[57];
                        break;
                    case e[60]:
                        h = e[59];
                        break;
                    case e[62]:
                        h = e[61];
                        break;
                    case e[27]:
                        h = e[36];
                        break;
                    case e[29]:
                        h = e[38];
                        break;
                    case e[31]:
                        h = e[40];
                        break;
                    case e[33]:
                        h = e[42];
                        break;
                    case e[35]:
                        h = e[44];
                        break;
                    case e[37]:
                        h = e[46];
                        break;
                    case e[39]:
                        h = e[48];
                        break;
                    case e[41]:
                        h = e[50];
                        break;
                    case e[43]:
                        h = e[52];
                        break;
                    case e[45]:
                        h = e[54];
                        break;
                    case e[47]:
                        h = e[56];
                        break;
                    case e[49]:
                        h = e[58];
                        break;
                    case e[51]:
                        h = e[60];
                        break;
                    case e[53]:
                        h = e[62];
                        break;
                    case e[55]:
                        h = e[12];
                        break;
                    case e[57]:
                        h = e[14];
                        break;
                    case e[59]:
                        h = e[16];
                        break;
                    case e[61]:
                        h = e[18];
                        break;
                    case e[11]:
                        h = e[20];
                        break;
                    case e[13]:
                        h = e[22];
                        break;
                    case e[15]:
                        h = e[24];
                        break;
                    case e[17]:
                        h = e[26];
                        break;
                    case e[19]:
                        h = e[28];
                        break;
                    case e[21]:
                        h = e[30];
                        break;
                    case e[23]:
                        h = e[32];
                        break;
                    case e[25]:
                        h = e[34];
                        break;
                    default:
                        h = k;
                }
                a += h;
            } else a += c.charAt(d);
            kb = a;
            b["std-Incremental"] = "true";
            if (c = JSON.stringify(b)) !1 === v && Aa(), b = "e_c=" + encodeURIComponent("sanPE"), b += "&e_a=" + encodeURIComponent(c), b += "&idsite=" + encodeURIComponent(N), b += "&url=" + encodeURIComponent(window.location.href), (c = wa()) && (b = b + "&" + c), ka && (b = Sa(b)), !1 ===
            Wa(b, Bb, !1) && F(U);
        }
    }

    function Bb(a) {
        p && console.log("San: PE cb sts: " + a);
        200 === a ? (a = new Date, a.setTime(a.getTime() + 63072E6), ba(U, kb, a)) : F(U);
    }

    function xb(a) {
        p && console.log("San: PII cb status = " + a);
    }

    function Wa(a, c, b) {
        var d = !0;
        try {
            var e = XMLHttpRequest ? new XMLHttpRequest : ActiveXObject ? new ActiveXObject("Microsoft.XMLHTTP") : null;
            null !== e ? (e.open("POST", r, !0), e.onreadystatechange = function() {
                4 === e.readyState && (null !== c && c(e.status), "function" === typeof x && x({
                    request: a,
                    trackerUrl: r,
                    success: e.status,
                }));
            }, e.onerror =
                function() {
                    null !== c && c(e.status);
                    "function" === typeof x && x({ request: a, trackerUrl: r, success: e.status });
                }, e.setRequestHeader("Content-Type", "application/x-www-form-urlencoded; charset=UTF-8"), !0 === b && e.setRequestHeader("X-sanPII", "true"), e.send(a)) : d = !1;
        } catch (f) {
            d = !1, p && console.log("San: Failed to send SAN record to gateway '" + r + "', Exception: " + f);
        }
        return d;
    }

    function Cb() {
        if (!1 === w && Ga && P && S) {
            var a = M + "?N=" + N + "&V=" + Z;
            try {
                var c = new XMLHttpRequest;
                c.open("GET", a, !0);
                c.onreadystatechange = function() {
                    if (4 ===
                        c.readyState && 200 === c.status) {
                        var a = c.responseText;
                        a && (Y = JSON.parse(a).sank, void 0 !== Y && 0 < Y.length && (Xa(Ga, ma.EMAIL), oa && Xa(oa, ma.LOCATION)));
                    }
                };
                c.onerror = function() {
                };
                c.send(null);
            } catch (b) {
            }
        }
    }

    function lb(a, c, b) {
        a ? (this.sum = a.sum, this.count = a.count, this.max = a.max, this.min = a.min, this.incCounterOnly = a.incCounterOnly) : (this.sum = c, this.count = 1, this.min = this.max = c, this.incCounterOnly = b);
        this.addValue = function(a) {
            this.sum += a;
            this.incCounterOnly || (this.count++, a > this.max && (this.max = a), a < this.min && (this.min =
                a));
        };
    }

    function Db(a, c, b, d, e, f) {
        this.evName = a;
        this.compName = e ? e : "";
        this.compVersion = f ? f : "";
        this.propMap = {};
        this.propMap[c] = new lb(null, b, d);
        this.aggregateValue = function(a, c, b) {
            var d = this.propMap[a];
            d ? d.addValue(c) : this.propMap[a] = new lb(null, c, b);
        };
    }

    function ta(a, c, b, d, e, f, g) {
        var h = e[a];
        h ? h.aggregateValue(c, b, d) : (h = new Db(a, c, b, d, f, g), e[a] = h);
    }

    function Eb(a, c, b, d, e, f) {
        if (e = !v) a:{
            if (navigator.cookieEnabled) if (a = Ua(a)) if (c = xa(c)) if (N = a, Z = c, eb = "sanProdVer=" + Z, b && "string" === typeof b) {
                if (d && d.lang) {
                    fa = b;
                    ga =
                        d.lang;
                    e = !0;
                    break a;
                }
                k = g.INVALID_APP_LANGUAGE;
            } else k = g.INVALID_FULL_PROD_VER; else k = g.INVALID_PROD_VER; else k = g.INVALID_PROD_NAME; else k = g.COOKIE_DISABLED;
            e = !1;
        }
        if (e) {
            rb();
            b = document.createElement("script");
            d = document.getElementsByTagName("script")[0];
            b.type = "text/javascript";
            b.defer = !0;
            b.async = !0;
            b.src = aa;
            p && console.log("Piwik URL: " + aa);
            d.parentNode.insertBefore(b, d);
            if ("https:" === document.location.protocol) {
                ca = "; SameSite=Lax; secure; path=/";
                Ba = "sansEID";
                C = "sansSID";
                U = "sansOOC";
                if (!E("sansEID")) {
                    if (b =
                        E("sanEID")) d = new Date, d.setTime(d.getTime() + 63072E6), ba("sansEID", b, d), F("sanEID");
                    F("sanSID");
                }
                ib(!0);
            } else ib(!1);
            if (0 < Object.keys(V).length) {
                b = [];
                for (var m in V) V.hasOwnProperty(m) && b.push(m);
                b.sort();
                for (m = 0; m < b.length; m++) Fa[b[m]] = V[b[m]];
            }
            F(C);
            jb(!1);
            Cb();
            k = g.SUCCESS;
            v = !0;
        }
        return k;
    }

    function Ha(a) {
        if (!O) {
            var c = void 0, c = void 0 === a || !0 === a ? !0 : !1;
            !1 === v ? (w = c, !0 === c && (Ia = !0)) : c !== w && (w = c, !1 === c && Ia && (qa("$b"), Ia = !1, da = !0), jb(!0));
        }
        k = g.SUCCESS;
    }

    function mb(a, c, b) {
        var d = u[b];
        b === m.UNCLASSIFIED && p && console.log(a +
            c + " is UNCLASSIFIED, enabled: " + d);
        b === m.STRICTLY_NECESSARY && p && console.log(a + c + " is STRICTLY NECESSARY, enabled: " + d);
        b === m.NECESSARY && p && console.log(a + c + " is NECESSARY, enabled: " + d);
        b === m.OPTIONAL && p && console.log(a + c + " is OPTIONAL, enabled: " + d);
    }

    function ra(a) {
        var c = m.UNCLASSIFIED;
        if (a in I) {
            var b = I[a];
            b in u && (c = b);
        } else I[a] = m.UNCLASSIFIED;
        p && mb("EVENT: ", a, c);
        return u[c];
    }

    function Ja(a) {
        var c = m.UNCLASSIFIED, b = a.split(".")[0].split(":")[0];
        if (b in J) {
            var d = J[b];
            d in u && (c = d);
        } else J[b] = m.UNCLASSIFIED;
        p && mb("PROPERTY: ", a, b, c);
        return u[c];
    }

    function nb(a) {
        k = g.SUCCESS;
        if (G()) if (a) {
            if (a = n(a)) 10 <= ea.length && ea.shift(), ea.push(a), db(a);
        } else db();
    }

    function Aa(a) {
        var c = 0, c = a && 0 < a ? 1E3 * a : ia;
        ab(c, !w);
    }

    function ob(a) {
        if (G()) {
            var c = E(C);
            if (c) {
                if (a && 0 < a) {
                    a *= 1E3;
                    var b = new Date;
                    b.setTime(b.getTime() + a);
                    ba(C, c, b);
                }
                return;
            }
            Aa(a);
        }
        k = g.SUCCESS;
    }

    function pb() {
        E(C) && (da ? (qa("$e"), da = !1) : qa("$o"), v = !1);
        Qa();
        k = g.SUCCESS;
    }

    function Fb() {
        var a = new XMLHttpRequest;
        "withCredentials" in a ? a.open("POST", r, !0) : "undefined" !== typeof XDomainRequest ?
            (a = new XDomainRequest, a.open("POST", r)) : a = null;
        return a;
    }

    function Gb(a) {
        var c = n(a);
        if (c && !a.startsWith("std-")) return k = g.SUCCESS, c;
        k = g.INVALID_KEY_NAME;
        return "";
    }

    function qb(a) {
        for (var c in a) {
            var b = a[c], d = b.propMap, b = new Q(c, c, B.EVENT, b.compName, b.compVersion), e;
            for (e in d) {
                var f = d[e];
                if (f.incCounterOnly) b.addProperty(e, f.sum); else {
                    var g = b, h = e;
                    g.addProperty(h + " sum", f.sum);
                    g.addProperty(h + " count", f.count);
                    g.addProperty(h + " max", f.max);
                    g.addProperty(h + " min", f.min);
                    var k = 0;
                    0 < f.count && (k = Math.floor(f.sum /
                        f.count * 1E3 + .5) / 1E3);
                    g.addProperty(h + " average", k);
                }
            }
            b.log();
        }
        for (var l in a) {
            c = a[l].propMap;
            for (var m in c) delete c[m];
            delete a[l];
        }
    }

    function Hb() {
        var a = "", c = "", b = {};
        this.setComponent = function(b, e) {
            Ua(b) ? xa(e) ? (a = b, c = e, Ra = !0, k = g.SUCCESS) : k = g.INVALID_PROD_VER : k = g.INVALID_PROD_NAME;
        };
        this.askVersion = function() {
            return San.askVersion();
        };
        this.newEvent = function(b) {
            k = g.SUCCESS;
            if (b = n(b)) return new Q(b, b, B.EVENT, a, c);
        };
        this.newCommand = function(b) {
            k = g.SUCCESS;
            if (b = n(b)) {
                10 <= L.length && L.shift();
                L.push(b);
                var e =
                    new Q("std-Cmd", "std-Cmd", B.COMMAND, a, c);
                e.addProperty("std-Name", b);
                return e;
            }
        };
        this.newError = function(b, e) {
            k = g.SUCCESS;
            var f = A(b);
            if (!H(f)) {
                var m = new Q("std-Error", "std-Error", B.ERROR, a, c);
                m.addProperty("std-ErrCode", f);
                f = A(e);
                H(f) || m.addProperty("std-ErrMsg", f);
                return m;
            }
        };
        this.incrementCounter = function(d, e) {
            var f = n(d);
            if (f) {
                var g = n(e);
                g && ta(f, g, 1, !0, b, a, c);
            }
        };
        this.aggregateValue = function(d, e, f) {
            if (d = n(d)) if (e = n(e)) isNaN(f) ? k = g.INVALID_AGGREGATE_VALUE : ta(d, e, f, !1, b, a, c);
        };
        this.getAggregatedMap = function() {
            return b;
        };
    }

    var K = void 0, g = {
            SUCCESS: 0,
            INVALID_PROD_NAME: -1,
            INVALID_PROD_VER: -2,
            COOKIE_DISABLED: -3,
            CORS_NOT_SUPPORTED: -4,
            INVALID_CUSTOMER_ID: -5,
            INVALID_USER_ID: -6,
            INVALID_KEY_NAME: -7,
            INVALID_KEY_TYPE: -8,
            KEY_TOO_LONG: -9,
            INVALID_AGGREGATE_VALUE: -10,
            VALUE_NESTED_JSON_NOT_SUPPORTED: -11,
            VALUE_BAD_JSON_FORMAT: -12,
            VALUE_DATA_TYPE_NOT_SUPPORTED: -13,
            INVALID_TAG_CATEGORY: -14,
            ALREADY_INITIALIZED: -15,
            INVALID_FULL_PROD_VER: -16,
            INVALID_APP_LANGUAGE: -17,
            ANALYTICS_COMPONENT_FAILED: -18,
            PII_NOT_SUPPORTED_IN_INSECURE_CONTEXTS: -19,
            INVALID_USER_EMAIL: -20,
            INVALID_USER_LOCATION: -21,
            CONFIG_SERVER_ERROR: -22,
            TOO_MANY_PROPERTIES: -23,
            TOO_MANY_EVENTS: -24,
            EVENT_DISABLED: -25,
            PROPERTY_DISABLED: -26,
            PROD_KEY_NOT_SET: -27,
        }, B = { EVENT: 0, COMMAND: 1, ERROR: 2 }, ma = { EMAIL: 0, LOCATION: 1 },
        m = { UNCLASSIFIED: "0", STRICTLY_NECESSARY: "1", NECESSARY: "2", OPTIONAL: "3" }, P = "",
        S = "", Ga = "", oa = "", za = "", na = "", Y = "", kb = "", ya = "", Da = "", Ea = "",
        R = "", N = "", Z = "", fa = "", ga = "", r = "", M = "", Ka = void 0, aa = "", w = !1,
        O = !1, v = !1, Ra = !1, eb = "", cb = !0, Ia = !1, da = !1, ka = !1,
        D = ["", "length", "charCodeAt", "fromCharCode",
            "&SanEObf=1"], W = 345,
        e = " 7 0 8 1 9 2 3 4 5 6 s A t B u C v D w E x F y G z H a I b J c K d L e M f N g O h P i Q j R k S l T m U n V o W p X q Y r Z".split(" "),
        f = " 0 7 1 8 2 9 3 4 5 6 A s B t C u D v E w F x G y H z I a J b K c L d M e N f O g P h Q i R j S k T l U m V n W o X p Y q Z r".split(" "),
        T = {}, pa = "", k = void 0, p = !1, fb = !1, La = {}, Ma = [], L = [], ea = [],
        ia = 6048E5, ja = !0, Na = [], ua = [], va = [], ha = [], Oa = [], I = {}, J = {}, u = {};
    Qa();
    var V = {}, Fa = {}, ca = "; SameSite=Lax; path=/", Ba = "sanEID", C = "sanSID", U = "sanOOC";
    "function" !==
    typeof String.prototype.startsWith && (String.prototype.startsWith = function(a) {
        return 0 === this.indexOf(a);
    });
    String.prototype.endsWith || (String.prototype.endsWith = function(a, c) {
        if (void 0 === c || c > this.length) c = this.length;
        return this.substring(c - a.length, c) === a;
    });
    return {
        typeLink: 1,
        typeDownload: 2,
        tagCategoryEdition: { key: "Edition" },
        tagCategoryComponent: { key: "Component" },
        tagCategoryPackage: { key: "Package" },
        tagCategoryLicensingModel: { key: "LicensingModel" },
        tagCategoryDeploymentModel: { key: "DeploymentModel" },
        tagCategoryUser: { key: "User" },
        tagCategoryTest: { key: "Test" },
        tagCategoryOther: { key: "Other" },
        tagCategoryAccessPoint: { key: "AccessPoint" },
        tagCategoryEntitlementType: { key: "EntitlementType" },
        tagCategoryProductSKU: { key: "ProductSKU" },
        tagCategoryClientProduct: { key: "ClientProduct" },
        tagCategoryDeploymentEnvironment: { key: "DeploymentEnvironment" },
        applicationLanguageNone: { lang: "NONE" },
        applicationLanguage_CS_CZ: { lang: "cs_CZ" },
        applicationLanguage_DE_DE: { lang: "de_DE" },
        applicationLanguage_EN_US: { lang: "en_US" },
        applicationLanguage_ES_ES: { lang: "es_ES" },
        applicationLanguage_FR_FR: { lang: "fr_FR" },
        applicationLanguage_HU_HU: { lang: "hu_HU" },
        applicationLanguage_IT_IT: { lang: "it_IT" },
        applicationLanguage_JA_JP: { lang: "ja_JP" },
        applicationLanguage_KO_KR: { lang: "ko_KR" },
        applicationLanguage_PL_PL: { lang: "pl_PL" },
        applicationLanguage_PT_BR: { lang: "pt_BR" },
        applicationLanguage_RU_RU: { lang: "ru_RU" },
        applicationLanguage_ZH_CN: { lang: "zh_CN" },
        applicationLanguage_ZH_TW: { lang: "zh_TW" },
        askVersion: function() {
            return "4.2.0.3018809";
        },
        setCustomerIdentifier: function(a) {
            (a = n(a)) && (P =
                a);
        },
        setUserIdentifier: function(a) {
            (a = n(a)) && (S = hb(a));
        },
        setApplicationContext: function(a) {
            v || (a = n(a, 32)) && (ya = a);
        },
        setProductContext: function(a) {
            v || (a = n(a)) && (Da = a);
        },
        setProductComponentName: function(a) {
            v || (a = n(a)) && (Ea = a);
        },
        setProductKey: function(a) {
            v || (R = a);
        },
        addTag: function(a, c) {
            if (v) k = g.ALREADY_INITIALIZED; else {
                var b = n(c);
                if (b) if (a) {
                    var d = a.key;
                    if (d) {
                        var e = V[d];
                        if (e) {
                            for (var d = !1, f = 0; f < e.length; f++) if (e[f] === b) {
                                d = !0;
                                break;
                            }
                            d || e.push(b);
                        } else e = [], e.push(b), V[d] = e;
                    } else k = g.INVALID_TAG_CATEGORY;
                } else k =
                    g.INVALID_TAG_CATEGORY;
            }
        },
        initialize: function(a, c, b, d, e, f) {
            if (O) return K = new Promise(function(a, c) {
            }), v = !0, g.SUCCESS;
            if ("" === R || void 0 === R) return v = !1, k = g.PROD_KEY_NOT_SET;
            aa = f || "https://analytics.components.industrysoftware.automation.siemens.com/matomo/4.12.3/piwik.js";
            r = "https://sangwc.industrysoftware.automation.siemens.com/";
            M = "https://sancsc.industrysoftware.automation.siemens.com/configserver/";
            if (e) if (M = "https://sancs.industrysoftware.automation.siemens.com/configserver/", !0 === e) r = "https://sangw.industrysoftware.automation.siemens.com/sangateway/";
            else {
                r = e;
                var n = zb("santestcs");
                "" !== n && (M = n);
            }
            void 0 !== Ka && (M = Ka);
            var h = M + "?N=" + a + "&V=" + c;
            p && console.log("San: gateway: " + r + ", cfgsvr: " + M);
            K = new Promise(function(a, c) {
                var b = new XMLHttpRequest;
                b.timeout = 4E3;
                b.open("GET", h, !0);
                b.setRequestHeader("X-API-KEY", R);
                b.onload = function() {
                    if (200 == b.status) {
                        var d = b.responseText;
                        if (d) {
                            d = JSON.parse(d);
                            Y = d.sank;
                            Na = d.LegalClassifier;
                            void 0 === Na && (Na = [{
                                id: m.UNCLASSIFIED,
                                description: "Unclassified",
                                enforced: "false",
                                display: "false",
                                initial: "false",
                                active: "false",
                                translation: "0",
                            },
                                {
                                    id: m.STRICTLY_NECESSARY,
                                    description: "Strictly Necessary",
                                    enforced: "true",
                                    display: "false",
                                    initial: "true",
                                    active: "false",
                                    translation: "0",
                                }, {
                                    id: m.NECESSARY,
                                    description: "Necessary",
                                    enforced: "false",
                                    display: "true",
                                    initial: "true",
                                    active: "false",
                                    translation: "0",
                                }, {
                                    id: m.OPTIONAL,
                                    description: "Optional",
                                    enforced: "false",
                                    display: "true",
                                    initial: "true",
                                    active: "true",
                                    translation: "0",
                                }]);
                            ha = d.EventPermissions;
                            if (void 0 !== ha) for (var e = 0; e < ha.length; e++) {
                                var f = ha[e];
                                I[f.event] = f.classifier;
                            }
                            Oa = d.EnvironmentPermissions;
                            if (void 0 !== ha) for (d = 0; d < Oa.length; d++) e = Oa[d], J[e.env] = e.classifier;
                        }
                        a(b.responseText);
                    } else c(b.responseText);
                };
                b.onerror = function(a) {
                    c(a.target.status);
                };
                b.send();
            });
            K.then(function(a) {
            }, function(a) {
                p && (k = g.CONFIG_SERVER_ERROR, console.log("Configuration server returned error: " + a));
            }).catch(function(a) {
                console.log(a);
            });
            return Eb(a, c, b, d, e, f);
        },
        disable: function(a) {
            !1 === v ? (!1 === O && (O = !0 === a ? !0 : !1), k = g.SUCCESS) : k = g.ALREADY_INITIALIZED;
        },
        optOut: Ha,
        isOptedOut: function() {
            return !0 === w || !0 === O;
        },
        isDisabled: function() {
            return !0 ===
                O;
        },
        getEndpointIdentifier: function() {
            return Za(!1);
        },
        getLastErrorMessage: function() {
            var a = "";
            switch (k) {
                case g.SUCCESS:
                    a = "Successful";
                    break;
                case g.INVALID_PROD_NAME:
                    a = "Invalid product name";
                    break;
                case g.INVALID_PROD_VER:
                    a = "Invalid product version";
                    break;
                case g.COOKIE_DISABLED:
                    a = "Browser cookie is disabled";
                    break;
                case g.CORS_NOT_SUPPORTED:
                    a = "Browser does not support CORS";
                    break;
                case g.INVALID_CUSTOMER_ID:
                    a = "Invalid customer identifier";
                    break;
                case g.INVALID_USER_ID:
                    a = "Invalid user identifier";
                    break;
                case g.INVALID_KEY_NAME:
                    a =
                        "Invalid key name";
                    break;
                case g.INVALID_KEY_TYPE:
                    a = "Invalid type: key is not a string";
                    break;
                case g.KEY_TOO_LONG:
                    a = "Key is too long";
                    break;
                case g.INVALID_AGGREGATE_VALUE:
                    a = "The aggregate value is not a number";
                    break;
                case g.VALUE_NESTED_JSON_NOT_SUPPORTED:
                    a = "Value with nested JSON is not supported";
                    break;
                case g.VALUE_BAD_JSON_FORMAT:
                    a = "Value has bad JSON format";
                    break;
                case g.VALUE_DATA_TYPE_NOT_SUPPORTED:
                    a = "Data type of value is not supported";
                    break;
                case g.INVALID_TAG_CATEGORY:
                    a = "Invalid tag category";
                    break;
                case g.ALREADY_INITIALIZED:
                    a = "Analytics is already initialized";
                    break;
                case g.INVALID_FULL_PROD_VER:
                    a = "Invalid full product version";
                    break;
                case g.INVALID_APP_LANGUAGE:
                    a = "Invalid application language";
                    break;
                case g.ANALYTICS_COMPONENT_FAILED:
                    a = "Failed to create Component Analytics as Analytics has been disabled";
                    break;
                case g.PII_NOT_SUPPORTED_IN_INSECURE_CONTEXTS:
                    a = "Setting PII data in insecure contexts is not supported";
                    break;
                case g.INVALID_USER_EMAIL:
                    a = "Invalid user's email";
                    break;
                case g.INVALID_USER_LOCATION:
                    a =
                        "Invalid user's location";
                    break;
                case g.CONFIG_SERVER_ERROR:
                    a = "Config Server error";
                    break;
                case g.TOO_MANY_PROPERTIES:
                    a = "Total Property count exceeds threshold";
                    break;
                case g.TOO_MANY_EVENTS:
                    a = "Total Event count exceeds threshold";
                    break;
                case g.EVENT_DISABLED:
                    a = "Event disabled due to user permission setting";
                    break;
                case g.PROPERTY_DISABLED:
                    a = "Property disabled due to user permission setting";
                    break;
                case g.PROD_KEY_NOT_SET:
                    a = "Product key is not set";
                    break;
                default:
                    a = "Unknown Error: " + k;
            }
            return a;
        },
        enableDebug: function(a) {
            p =
                !0 === a ? !0 : !1;
        },
        disableNecessaryData: function() {
            u[m.NECESSARY] = !1;
            !1 === u[m.OPTIONAL] && Ha(!0);
        },
        disableOptionalData: function() {
            u[m.OPTIONAL] = !1;
            !1 === u[m.NECESSARY] && Ha(!0);
        },
        enableNecessaryData: function() {
            u[m.NECESSARY] = !0;
        },
        enableOptionalData: function() {
            u[m.OPTIONAL] = !0;
        },
        isAllowedStrictlyNecessaryData: function() {
            return u[m.STRICTLY_NECESSARY];
        },
        isAllowedNecessaryData: function() {
            return u[m.NECESSARY];
        },
        isAllowedOptionalData: function() {
            return u[m.OPTIONAL];
        },
        logPageView: function(a) {
            nb(a);
        },
        logPlace: nb,
        logEvent: function(a,
                           c) {
            k = g.SUCCESS;
            if (G()) {
                var b = n(a);
                if (b) try {
                    var d = X(c, !0);
                    d && ("sanPE" === b ? (sa(b, d), p && console.log("Property: " + b + " is enabled")) : K.then(function() {
                        ra(b) ? (sa(b, d), p && console.log("Event: " + b + " is enabled")) : (k = g.EVENT_DISABLED, p && console.log("Event: " + b + " is disabled"));
                    }).catch(function(a) {
                        console.log(a);
                    }));
                } catch (e) {
                }
            }
        },
        newEvent: function(a) {
            k = g.SUCCESS;
            if (a = n(a)) return new Q(a, a, B.EVENT);
        },
        newCommand: function(a) {
            k = g.SUCCESS;
            if (a = n(a)) {
                10 <= L.length && L.shift();
                L.push(a);
                var c = new Q("std-Cmd", "std-Cmd",
                    B.COMMAND);
                c.addProperty("std-Name", a);
                return c;
            }
        },
        newError: function(a, c) {
            k = g.SUCCESS;
            var b = A(a);
            if (!H(b)) {
                var d = new Q("std-Error", "std-Error", B.ERROR);
                d.addProperty("std-ErrCode", b);
                b = A(c);
                H(b) || d.addProperty("std-ErrMsg", b);
                return d;
            }
        },
        pushData: function(a, c) {
            k = g.SUCCESS;
            if (a) {
                var b = Fb();
                b ? (b.setRequestHeader("Content-Type", "text/plain; charset=UTF-8"), b.onreadystatechange = function() {
                    4 === b.readyState && c && c(b.status);
                }, b.onerror = function() {
                    c && c(b.status);
                }, b.send(a)) : k = g.CORS_NOT_SUPPORTED;
            }
            return status;
        },
        logSiteSearch: function(a, c, b) {
            k = g.SUCCESS;
            G() && (a = A(a)) && _paq.push(["trackSiteSearch", a, c, b]);
        },
        logLink: function(a, c) {
            k = g.SUCCESS;
            G() && (a = A(a)) && (1 === c ? _paq.push(["trackLink", a, "link", void 0, x]) : 2 === c && _paq.push(["trackLink", a, "download", void 0, x]));
        },
        newSession: ob,
        setSessionTimeout: function(a) {
            Pa(a);
            ob(a);
        },
        addSessionEnvInfo: function(a, c) {
            k = g.SUCCESS;
            if (G() && !fb) {
                var b = n(a);
                if (b) try {
                    var d = X(c, !1);
                    T[b] = d;
                    pa = JSON.stringify(T);
                } catch (e) {
                }
            }
        },
        endSession: pb,
        deleteSession: function() {
            pb();
        },
        newMapValue: function() {
            return new Ca(!0);
        },
        newListValue: function() {
            return new Ca(!1);
        },
        addProductInfo: function(a, c) {
            k = g.SUCCESS;
            if (G()) {
                var b = Gb(a);
                if (b) try {
                    K.then(function() {
                        var a = {};
                        if (c instanceof Ca) {
                            var d = c.getData(), e = void 0, e = c.isMap() ? "." : ":", f;
                            for (f in d) Ja(b) ? a[b + e + f] = d[f] : k = g.PROPERTY_DISABLED;
                        } else d = X(c, !1), Ja(b) ? a[b] = d : k = g.PROPERTY_DISABLED;
                        0 < Object.keys(a).length && (a["std-Incremental"] = "true", a = JSON.stringify(a), San.logEvent("sanPE", a));
                    }).catch(function(a) {
                        console.log(a);
                    });
                } catch (d) {
                }
            }
        },
        obfData: function(a) {
            !1 === v ? (ka = !0 === a ? !0 :
                !1, k = g.SUCCESS) : k = g.ALREADY_INITIALIZED;
        },
        incrementCounter: function(a, c) {
            var b = n(a);
            if (b) {
                var d = n(c);
                d && ta(b, d, 1, !0, La);
            }
        },
        aggregateValue: function(a, c, b) {
            if (a = n(a)) if (c = n(c)) isNaN(b) ? k = g.INVALID_AGGREGATE_VALUE : ta(a, c, b, !1, La);
        },
        logAggregatedValues: function() {
            qb(La);
            for (var a = 0; a < Ma.length; a++) qb(Ma[a].getAggregatedMap());
        },
        getAnalyticsInterface: function() {
            if (G()) {
                var a = new Hb;
                Ma.push(a);
                k = g.SUCCESS;
                return a;
            }
            k = g.ANALYTICS_COMPONENT_FAILED;
            return null;
        },
        getHash: function(a) {
            return void 0 === a || null === a ? null :
                Va(a);
        },
        setUserPII: function(a, c) {
            k = g.SUCCESS;
            if ("https:" === document.location.protocol) if (v) k = g.ALREADY_INITIALIZED; else {
                var b;
                a:{
                    var d = n(a, 320);
                    if (d && d.match(/^\S+@\S+$/)) {
                        var e = d.indexOf("@");
                        if (-1 !== e && (b = d.substring(0, e), 64 >= b.length && (d = d.substring(e + 1), 255 >= d.length))) {
                            k = g.SUCCESS;
                            b = encodeURIComponent(b) + "@" + encodeURIComponent(d);
                            break a;
                        }
                    }
                    k = g.INVALID_USER_EMAIL;
                    b = "";
                }
                b && (Ga = b, c && ((b = n(c, 3)) ? k = g.SUCCESS : (b = "", k = g.INVALID_USER_LOCATION), b && (oa = b)));
            } else k = g.PII_NOT_SUPPORTED_IN_INSECURE_CONTEXTS;
            return k;
        },
        _validateKey: n,
        _piwikLogging: function(a) {
            "boolean" === typeof a ? x = a ? Ab : void 0 : "function" === typeof a && (x = a);
        },
        _validateProdVersion: xa,
        _bdAddSpecialEvents: function(a, c, b, d) {
            var e = 0;
            if (Array.isArray(a) && Array.isArray(c) && Array.isArray(b) && Array.isArray(d)) {
                for (var f = 0; f < a.length; ++f) {
                    var g = a[f], h = { name: g, id: e++, classification: m.UNCLASSIFIED };
                    ua.push(h);
                    I[g] = h.classification;
                }
                for (a = 0; a < c.length; ++a) g = c[a], h = {
                    name: g,
                    id: e++,
                    classification: m.STRICTLY_NECESSARY,
                }, ua.push(h), I[g] = h.classification;
                for (c =
                         0; c < b.length; ++c) g = b[c], h = {
                    name: g,
                    id: e++,
                    classification: m.NECESSARY,
                }, ua.push(h), I[g] = h.classification;
                for (b = 0; b < d.length; ++b) g = d[b], h = {
                    name: g,
                    id: e++,
                    classification: m.OPTIONAL,
                }, ua.push(h), I[g] = h.classification;
            }
        },
        _bdAddSpecialPEs: function(a, c, b, d) {
            var e = 0;
            if (Array.isArray(a) && Array.isArray(c) && Array.isArray(b) && Array.isArray(d)) {
                for (var f = 0; f < a.length; ++f) {
                    var g = a[f], h = { name: g, id: e++, classification: m.UNCLASSIFIED };
                    va.push(h);
                    J[g] = h.classification;
                }
                for (a = 0; a < c.length; ++a) g = c[a], h = {
                    name: g,
                    id: e++,
                    classification: m.STRICTLY_NECESSARY,
                },
                    va.push(h), J[g] = h.classification;
                for (c = 0; c < b.length; ++c) g = b[c], h = {
                    name: g,
                    id: e++,
                    classification: m.NECESSARY,
                }, va.push(h), J[g] = h.classification;
                for (b = 0; b < d.length; ++b) g = d[b], h = {
                    name: g,
                    id: e++,
                    classification: m.OPTIONAL,
                }, va.push(h), J[g] = h.classification;
            }
        },
        _checkPE: Ja,
        _checkEvent: ra,
        _setConfigServer: function(a) {
            Ka = a;
        },
        _sleep: function(a) {
            return new Promise(function(c) {
                return setTimeout(c, 1E3 * a);
            });
        },
        _enableUnclassified: function() {
            u[m.UNCLASSIFIED] = !0;
        },
        getHashUserIdentifier: function(a) {
            return (a = n(a)) ? hb(a) : null;
        },
        _getSanGatewayURL: function() {
            return r;
        },
        _getSanConfigServerURL: function() {
            return M;
        },
        _getSanPiwikURL: function() {
            return aa;
        },
        _getConfigPromise: function(a) {
            return K;
        },
        _getEnableData: function() {
            return u;
        },
        _getProperties: function() {
            return J;
        },
        _getEvents: function() {
            return I;
        },
        getDefaultSessionDuration: function() {
            return ia / 1E3;
        },
        setDefaultSessionDuration: Pa,
        getSanCookieExpiration: function(a) {
            a = E(a + "-expire");
            if ("" !== a) {
                var c = new Date;
                return ((new Date(a)).getTime() - c) / 1E3;
            }
            return -1;
        },
        allowLinkTracking: ja,
        getSanError: function() {
            return k;
        },
    };
}();
