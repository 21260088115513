import { useEffect, useState } from 'react';

import Box from 'components/atoms/Box';
import { DialogButton } from 'components/atoms/Button';
import {
    DigitalExperienceProgram,
    ProductExcellenceProgram,
} from 'components/atoms/ExcellenceProgam';
import { useProgressBar } from 'components/atoms/ProgressBar';
import ProgressSpinner from 'components/atoms/ProgressSpiner';
import RadioButtons from 'components/atoms/RadioButtons';
import Dialog from 'components/molecules/Dialog';
import { DataPrivacyProps, optOutConfig } from 'components/organisms/DataPrivacy/types';
import appConfig from 'constants/appConfig';
import { useGoogleTagManagerContext } from 'providers/googleTagManager/GoogleTagManager';
import {
    useGetAuroraOptOutQuery,
    useUpdateAuroraOptOut,
} from 'services/queries/analyticsSettings.query';
import useFeatureFlagsQuery from 'services/queries/featuresFlags.query';
import { LoadingStateLabel } from 'types/loadingStateLabel';

import { ErrorArea, Header, Paragraph, RadioButtonsRow } from './DataPrivacy.styles';

interface Props extends DataPrivacyProps {
    pepOnly?: boolean;
}

const AuroraDataPrivacy = ({ open, setOpen, pepOnly = false }: Props) => {
    const { data, isLoading } = useGetAuroraOptOutQuery();

    const { setProgressBar } = useProgressBar();
    const { data: featureFlags } = useFeatureFlagsQuery();
    const { addToUserDataLayer, isInitialized } = useGoogleTagManagerContext();

    const [dpe, setDpe] = useState<boolean>(false);
    const [pep, setPep] = useState<boolean | undefined>(undefined);
    const [isPepError, setPepError] = useState<boolean>(false);

    const { mutate, isLoading: isMutationLoading } = useUpdateAuroraOptOut({
        onSuccess: () => {
            addToUserDataLayer('dpeOptOut', dpe);
            addToUserDataLayer('pepOptOut', pep);
            setOpen(false);
        },
    });

    useEffect(() => {
        if (data && !isLoading) {
            data.dpeOptOut && setDpe(data.dpeOptOut);
            pepOnly ||
                (data.pepOptOut !== null && data.pepOptOut !== undefined && setPep(data.pepOptOut));
        }
    }, [data, isLoading]);

    useEffect(() => () => setProgressBar(false), []);

    const onSubmit = () => {
        setProgressBar(true);
        if (dpe !== undefined && pep !== undefined) {
            mutate({ pepOptOut: pep, dpeOptOut: dpe });
            setPepError(false);
        } else {
            setPepError(true);
        }
    };

    const onCancel = () => {
        setOpen(false);
        if (
            data &&
            data.dpeOptOut !== null &&
            data.dpeOptOut !== undefined &&
            data.pepOptOut !== null &&
            data.pepOptOut !== undefined
        ) {
            setDpe(data.dpeOptOut);
            setPep(data.pepOptOut);
        }
    };

    const dialogProps = pepOnly
        ? { closeButton: false }
        : { onCloseButton: onCancel, onInteractOutside: onCancel, setOpen: setOpen };

    const pepEnabled = featureFlags?.Analytics_SiemensAuroraAnalyticsEnabled;

    if (!isInitialized) {
        return null;
    }

    return (
        <Dialog
            title={'Data Privacy'}
            open={open}
            {...dialogProps}
            buttons={
                <>
                    {isPepError && <ErrorArea>Please select one option</ErrorArea>}
                    {pepOnly || (
                        <DialogButton
                            color='primary'
                            variant={'outlined'}
                            onClick={onCancel}
                            data-testid='dataPrivacyCancelButton'
                            disabled={isMutationLoading}
                        >
                            Cancel
                        </DialogButton>
                    )}

                    <DialogButton
                        color='primary'
                        variant='contained'
                        onClick={onSubmit}
                        disabled={isLoading || isMutationLoading}
                        data-testid='dataPrivacyDoneButton'
                    >
                        Done
                    </DialogButton>
                </>
            }
            contentCssOverride={{
                maxHeight: '100vh',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Box
                css={{
                    maxWidth: 600,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    minHeight: 330,
                }}
            >
                {isLoading || isMutationLoading ? (
                    <ProgressSpinner
                        label={
                            isLoading
                                ? LoadingStateLabel.LOADING
                                : LoadingStateLabel.PROCESSING_DATA
                        }
                    />
                ) : (
                    <>
                        {pepEnabled && (
                            <>
                                <ProductExcellenceProgram firstTimeDisplay={pepOnly} />
                                <RadioButtonsRow>
                                    <RadioButtons
                                        config={optOutConfig}
                                        value={pep?.toString()}
                                        isError={isPepError}
                                        onValueChange={(value: 'true' | 'false') =>
                                            setPep(value === 'true')
                                        }
                                    />
                                </RadioButtonsRow>
                            </>
                        )}
                        {pepOnly || (
                            <>
                                <DigitalExperienceProgram />
                                <RadioButtonsRow>
                                    <RadioButtons
                                        config={optOutConfig}
                                        value={dpe?.toString()}
                                        onValueChange={(value: 'true' | 'false') =>
                                            setDpe(value === 'true')
                                        }
                                    />
                                </RadioButtonsRow>
                            </>
                        )}
                        <>
                            <Header>The DISW Data Privacy Notice</Header>
                            <Paragraph>
                                For more details on Siemens Digital Industries Software personal
                                data processing practices, please see the{' '}
                                <a href={appConfig.PRIVACY_POLICY} target={'_blank'}>
                                    Siemens Digital Industries Software Data Privacy Notice
                                </a>
                                .
                            </Paragraph>
                        </>
                    </>
                )}
            </Box>
        </Dialog>
    );
};

export default AuroraDataPrivacy;
