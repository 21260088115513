import { SyntheticEvent, useEffect, useRef, useState } from 'react';

export default function useScrollContainer() {
    const imgRef = useRef<HTMLImageElement>(null);
    const scrollContainerRef = useRef<HTMLElement>(null);
    const [onDrag, setDrag] = useState(false);

    const onClickWhileDrag = (event: SyntheticEvent | MouseEvent) => {
        if (onDrag) {
            event.stopPropagation();
            setDrag(false);
        }
    };

    useEffect(() => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.onclick = (event) => {
                onClickWhileDrag(event);
            };
            scrollContainerRef.current.onmousedown = (event) => {
                if (event.target === imgRef.current) {
                    setDrag(true);
                }
            };
        }
    }, [scrollContainerRef.current, onDrag]);

    return { imgRef, scrollContainerRef, onClickWhileDrag };
}
