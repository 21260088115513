import styled from '@emotion/styled';
import { useContext } from 'react';
import { useNavigate } from 'react-router';

import ProgressSpinner from 'components/atoms/ProgressSpiner';
import SubscriptionManagerSvg from 'images/License24.svg?react';
import SignOutSvg from 'images/LogOut16.svg?react';
import AvatarSvg from 'images/user-avatar-100px.svg?react';
import { redirectToLogout } from 'providers/authorization/AuthProvider';
import { useUserDataContext } from 'providers/currentUser/UserDataProvider';
import { SubscriptionManagerContext } from 'providers/subscriptionManager/SubscriptionManagerProvider';
import { customColors } from 'theme';
import { LoadingStateLabel } from 'types/loadingStateLabel';
import { Path } from 'types/paths.enum';

const UserPanel = () => {
    const { user: currentUser } = useUserDataContext();
    const navigate = useNavigate();
    const subscriptionManagerContext = useContext(SubscriptionManagerContext);
    const InfoLabel = styled('div')({
        fontSize: 16,
        fontWeight: 500,
        marginTop: 5,
    });

    const InfoValue = styled('div')({
        fontSize: 12,
        marginBottom: 5,
        overflowWrap: 'anywhere',
    });

    const UserName = styled('div')({
        fontSize: 18,
        textAlign: 'center',
    });

    const UserEmail = styled('div')(({ theme }) => ({
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        textAlign: 'center',
        fontSize: 12,
    }));

    const UserInfoWrapper = styled('div')(({ theme }) => ({
        width: '100%',
        alignItems: 'center',
        margin: theme.spacing(5, 0),
    }));

    const AvatarIcon = styled(AvatarSvg)(({ theme }) => ({
        display: 'block',
        margin: 'auto',
        marginBottom: theme.spacing(5),
    }));

    const SubscriptionManagerIcon = styled(SubscriptionManagerSvg)(({ theme }) => ({
        width: '16px',
        height: '16px',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),

        '& : path': {
            stroke: 'red',
        },
    }));

    const SignOutIcon = styled(SignOutSvg)(({ theme }) => ({
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
    }));

    const ButtonArea = styled('div')({
        fontSize: 12,
        width: '100%',
        height: 32,
        display: 'flex',
        color: customColors['siemens-blue-21'],

        '& : hover': {
            backgroundColor: '#004c6c',
        },
    });

    const ButtonPanel = styled('div')({
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
    });

    const AvatarArea = styled('div')(({ theme }) => ({
        padding: theme.spacing(0, 4),
    }));

    const redirectToSubscriptionManager = () => {
        navigate(Path.SM);
    };

    return currentUser ? (
        <>
            <AvatarArea>
                <AvatarIcon />
                <UserName id='avatarPanelUserName'>{currentUser?.name ?? ''}</UserName>
                <UserEmail id='avatarPanelUserEmail'>{currentUser?.email ?? ''}</UserEmail>
                <UserInfoWrapper id='userInfoTenantInfo'>
                    <InfoLabel>Tenant ID</InfoLabel>
                    <InfoValue id='userInfoTenantIdValue'>{currentUser?.tenantId ?? ''}</InfoValue>
                    <InfoLabel>Tenant Name</InfoLabel>
                    <InfoValue id='userInfoTenantNameValue'>
                        {currentUser?.tenantName ?? ''}
                    </InfoValue>
                </UserInfoWrapper>
            </AvatarArea>
            {subscriptionManagerContext.smEnabled ? (
                <ButtonArea onClick={redirectToSubscriptionManager}>
                    <ButtonPanel>
                        <SubscriptionManagerIcon />
                        <span>Subscription Manager</span>
                    </ButtonPanel>
                </ButtonArea>
            ) : null}
            <ButtonArea
                onClick={() => {
                    // the user logs out deliberately, so we remove lastLocation to avoid an error, e.g. when logging in with a different tenant
                    window.localStorage.removeItem('lastLocation');
                    redirectToLogout();
                }}
            >
                <ButtonPanel>
                    <SignOutIcon />
                    <span>Sign Out</span>
                </ButtonPanel>
            </ButtonArea>
        </>
    ) : (
        <ProgressSpinner label={LoadingStateLabel.USER} />
    );
};

export default UserPanel;
