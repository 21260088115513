export const DROP_ENABLE = 0;
export const DROP_IN_PROGRESS = 1;
export const DROP_NOT_POSSIBLE = -1;

export const isDropEnabled = (mode: number, counter: number) =>
    mode === DROP_ENABLE && counter === 0;

export const isDropInProgress = (mode: number, counter: number) =>
    mode === DROP_IN_PROGRESS && counter > 0;

export const isDropNotPossible = (mode: number) => mode === DROP_NOT_POSSIBLE;

export const isDropNotEnabled = (mode: number) => mode !== DROP_ENABLE;
