import styled from '@emotion/styled';

import Button from 'components/atoms/Button';

export const AddEditSideNavBody = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    height: '100%',
    overflowY: 'auto',
    padding: theme.spacing(0, 4, 4, 4),
}));

export const ContentPanel = styled('div')({
    display: 'flex',
    flexDirection: 'column',
});

export const UploadLabel = styled('div')({
    fontSize: 12,
    fontWeight: 600,
});
