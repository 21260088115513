import { Box, List } from '@mui/material';
import { ReactNode, useState } from 'react';
import { useNavigate } from 'react-router';

import { useLeftDrawer } from 'components/organisms/LeftDrawer';
import HelpPanel from 'components/templates/HelpPanel';
import useEventNotifications from 'components/templates/NotificationPanel/useEventNotifications';
import SettingsPanel from 'components/templates/SettingsPanel';
import UserPanel from 'components/templates/UserPanel';
import HomeIcon from 'images/cmdHome16.svg?react';
import HelpIcon from 'images/helpIcon16.svg?react';
import RecentIcon from 'images/homeProjects16.svg?react';
import SettingsIcon from 'images/settingsIcon16.svg?react';
import UserIcon from 'images/userIcon16.svg?react';
import { triggerAnalyticsClickEvent } from 'services/SiemensAnalyticsService/SiemensAnalyticsModule';
import analyticsConsts from 'services/SiemensAnalyticsService/analyticsConsts';
import useFeatureFlagsQuery from 'services/queries/featuresFlags.query';
import { Nullable } from 'types';
import { Path } from 'types/paths.enum';

import { MenuAvatarButton, MenuButton, MenuListItem } from './MainLayout.styles';

export interface MenuElement {
    label: string;
    action?: () => void;
    disabled?: boolean;
    visible?: boolean;
    selected?: boolean;
    icon: JSX.Element;
}

interface drawersList {
    [key: string]: ReactNode;
}

const drawers: drawersList = {
    settings: <SettingsPanel />,
    user: <UserPanel />,
    help: <HelpPanel />,
    empty: null,
};

const MainMenu = (): JSX.Element => {
    const navigate = useNavigate();
    const [currentDrawer, setCurrentDrawer] = useState('');
    const { drawer, toggleDrawer, closeDrawer } = useLeftDrawer(drawers[currentDrawer]);
    const {
        notificationsIcon,
        notificationsComponent,
        switchVisibility: switchNotificationsPanel,
    } = useEventNotifications();

    const { data } = useFeatureFlagsQuery();

    const toggleUserDrawer = () => {
        setCurrentDrawer('user');
        analyticsUserClick(analyticsConsts.Actions.userAvatar);
        toggleDrawer();
    };

    const toggleSettingsDrawer = () => {
        setCurrentDrawer('settings');
        toggleDrawer();
    };

    const analyticsUserClick = (actionName: string) => {
        triggerAnalyticsClickEvent(
            actionName,
            analyticsConsts.Categories.navigation,
            analyticsConsts.Sources.sideNav
        );
    };

    const topMenu: MenuElement[] = [
        {
            label: 'Home',
            action: () => {
                analyticsUserClick(analyticsConsts.Actions.homepage);
                navigate(Path.RECENT);
            },
            selected: window.location.pathname === Path.RECENT,
            icon: <HomeIcon />,
        },
        {
            label: 'Projects',
            action: () => {
                analyticsUserClick(analyticsConsts.Actions.allProjects);
                navigate(Path.PROJECTS);
            },
            selected: window.location.pathname === Path.PROJECTS,
            icon: <RecentIcon />,
        },
    ];

    const bottomMenu: MenuElement[] = [
        {
            label: 'Help',
            action: () => {
                setCurrentDrawer('help');
                toggleDrawer();
                analyticsUserClick(analyticsConsts.Actions.help);
            },
            icon: <HelpIcon />,
        },
    ];

    if (data?.Frontend_InAppEventNotificationFeature) {
        bottomMenu.push({
            label: 'Alerts',
            action: () => {
                analyticsUserClick(analyticsConsts.Actions.notifications);
                closeDrawer();
                switchNotificationsPanel();
            },
            icon: notificationsIcon,
        });
    }

    bottomMenu.push({
        label: 'Settings',
        action: () => {
            analyticsUserClick(analyticsConsts.Actions.settings);
            toggleSettingsDrawer();
        },
        icon: <SettingsIcon />,
    });

    const renderButton = ({
        label,
        action,
        visible = true,
        icon,
        selected = false,
        disabled = false,
    }: MenuElement): Nullable<JSX.Element> =>
        visible ? (
            <MenuListItem
                key={label}
                selected={selected}
                disablePadding
                sx={disabled ? { color: 'lightgrey' } : {}}
            >
                <MenuButton
                    data-testid={`main-menu-${label.toLowerCase()}-button`}
                    onClick={action}
                >
                    <Box>{icon}</Box>
                    <Box>{label}</Box>
                </MenuButton>
            </MenuListItem>
        ) : null;

    return (
        <Box
            sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
            }}
        >
            <List>{topMenu.map(renderButton)}</List>
            <Box>
                <List sx={{ zIndex: 1600 }}>{bottomMenu.map(renderButton)}</List>
                <MenuAvatarButton onClick={toggleUserDrawer} key={'main-menu-avatar-button'}>
                    <UserIcon />
                </MenuAvatarButton>
                {drawer}
                {data?.Frontend_InAppEventNotificationFeature && notificationsComponent}
            </Box>
        </Box>
    );
};

export default MainMenu;
