import styled from '@emotion/styled';

export const DescriptionContainer = styled('div')(({ theme }) => ({
    backgroundColor: theme.colors.background,
    padding: theme.spacing(2, 2),
    borderRadius: '3px',
    margin: theme.spacing(3, 0, 2),
    fontSize: '12px',
    whiteSpace: 'pre',
}));

export const Description = styled('span')({
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
});

export const EmptyDescription = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 400,
    fontSize: '12px',
    fontStyle: 'italic',
    height: '34px',
    color: theme.colors.text,
}));

export const ToggleButton = styled('div')({ float: 'right', cursor: 'pointer' });
