import { SyntheticEvent, useCallback, useContext, useMemo } from 'react';
import ReactTooltip from 'react-tooltip';

import Box from 'components/atoms/Box';
import { Center } from 'components/atoms/Flex/Flex';
import { SearchDescription } from 'components/organisms/ProjectVersion/components/SearchDescription';
import { VersionErrorIcon } from 'components/organisms/ProjectVersion/components/VersionErrorIcon';
import { VersionWarningIcon } from 'components/organisms/ProjectVersion/components/VersionWarningIcon';
import useVisualizeSearchHook from 'components/organisms/ProjectVersion/hooks/visualizeSearchHook';
import Compare from 'images/Compare16.svg?react';
import Close from 'images/close.svg?react';
import SuccessIcon from 'images/indicatorInformationSuccess16.svg?react';
import Restore from 'images/restore.svg?react';
import { ScanInfo, ScanStatus } from 'types/project';

import currentVersionContext from '../../CurrentVersionContext';

import './VersionItem.scss';

interface Props {
    versionNumber: string;
    versionCreator: string;
    versionDate: string;
    versionDescription: string;
    onRestore: () => void;
    onDelete: () => void;
    onChoose: (revisionId: string, comment: string) => void;
    onCompare: () => void;
    compareButtonVisible: boolean;
    restoreButtonVisible: boolean;
    deleteButtonVisible: boolean;
    disableDescriptionTooltip: () => void;
    disableButtonTooltip: () => void;
    scanInfo?: ScanInfo;
    criteria?: string[];
}
export default function VersionItem({
    versionNumber,
    versionCreator,
    versionDate,
    versionDescription,
    onRestore,
    onDelete,
    onChoose,
    onCompare,
    compareButtonVisible,
    restoreButtonVisible,
    deleteButtonVisible,
    disableDescriptionTooltip,
    disableButtonTooltip,
    scanInfo,
    criteria,
}: Props) {
    const current = useContext(currentVersionContext);

    const onRestoreCallback = useCallback(
        (event: SyntheticEvent) => {
            if (!isScanClean) {
                return;
            }
            event.preventDefault();
            event.stopPropagation();
            onRestore();
        },
        [onRestore, versionNumber]
    );

    const onDeleteCallback = useCallback(
        (event: SyntheticEvent) => {
            event.preventDefault();
            event.stopPropagation();
            onDelete();
        },
        [onDelete, versionNumber]
    );

    const hideTooltip = useCallback(() => {
        ReactTooltip.hide();
    }, []);

    const prepareTooltip = (version: string, description: string) =>
        JSON.stringify({ version, description });

    const isScanClean = useMemo(() => {
        if (!scanInfo) {
            return true;
        }
        return scanInfo.scanStatus === ScanStatus.CLEAN;
    }, [scanInfo]);

    const getMessage = () => {
        if (isScanClean || !scanInfo) {
            return 'Restore version to latest';
        } else if (scanInfo.scanStatus === ScanStatus.INFECTED) {
            return 'Actions suspended during security threat';
        } else {
            return 'Actions suspended during security scanning';
        }
    };

    const { visualizeSearch, visualizeVersionSearch } = useVisualizeSearchHook();

    return (
        <Box
            onMouseEnter={hideTooltip}
            data-for='descriptionTooltip'
            data-tip={prepareTooltip(versionNumber, versionDescription)}
            onClick={() => {
                onChoose(versionNumber, versionDescription);
            }}
            key={versionNumber}
            css={({ colors }) => ({
                height: 'fit-content !important',
                cursor: 'pointer',
                fontSize: '12px',
                lineHeight: '21px',
                display: 'flex',
                flexDirection: 'column',
                padding: '0 8px',
                color: colors.text,
                ...(current.currentRevision === Number.parseInt(versionNumber) && {
                    backgroundColor: colors.table.row.select,
                }),
                '&:hover': {
                    cursor: 'pointer',
                    backgroundColor: colors.table.row.hover,
                },
                '&:hover #successIconVersionItem': {
                    display: 'block',
                },
            })}
        >
            <Box
                css={{
                    display: 'flex',
                    padding: '2px 0',
                    width: '100%',
                }}
            >
                <Box css={{ display: 'flex' }}>
                    <div
                        id={'Version' + versionNumber}
                        className='ccd-project-version-version-item-text-version-number'
                    >
                        <span className='ccd-project-version-version-item-text-version-number-span'>
                            Version
                        </span>
                        {visualizeVersionSearch(versionNumber, criteria)}
                    </div>
                    <div className='ccd-project-version-version-item-separator' />
                </Box>
                <Box css={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
                    <Box
                        css={{
                            display: 'flex',
                            flexGrow: '1',
                            justifyContent: 'space-between',
                            width: '100%',
                        }}
                    >
                        <Box className='ccd-project-version-version-item-text-version-creator'>
                            {'Created '}
                            {visualizeSearch(versionDate, criteria)} by{' '}
                            {visualizeSearch(versionCreator, criteria)}
                        </Box>
                        <Center
                            css={{
                                display: 'flex',
                                '& > svg': { margin: '0 4px' },
                            }}
                        >
                            {scanInfo && (
                                <>
                                    {scanInfo.scanStatus === ScanStatus.CLEAN && (
                                        <SuccessIcon
                                            id={'successIconVersionItem'}
                                            css={{ width: '16px', display: 'none' }}
                                            data-for='tooltip'
                                            data-tip='No threats detected'
                                        />
                                    )}
                                    {scanInfo.scanStatus === ScanStatus.INFECTED && (
                                        <VersionErrorIcon versionNumber={versionNumber} />
                                    )}
                                    {scanInfo.scanStatus === ScanStatus.PENDING_SCAN && (
                                        <VersionWarningIcon />
                                    )}
                                </>
                            )}
                            {compareButtonVisible && (
                                <Compare
                                    id={'compareButton-' + versionNumber}
                                    onClick={(event) => {
                                        onCompare();
                                        event.stopPropagation();
                                    }}
                                    data-for='tooltip'
                                    data-tip={'Version comparison'}
                                    onMouseEnter={disableDescriptionTooltip}
                                    onMouseLeave={disableButtonTooltip}
                                    css={({ colors }) => ({
                                        stroke: colors.text,
                                        '&:focus': { outline: 'none' },
                                    })}
                                />
                            )}
                            {restoreButtonVisible && (
                                <Restore
                                    id={'restoreButton-' + versionNumber}
                                    onClick={onRestoreCallback}
                                    data-for='tooltip'
                                    data-tip={getMessage()}
                                    onMouseEnter={disableDescriptionTooltip}
                                    onMouseLeave={disableButtonTooltip}
                                    css={({ colors }) => ({
                                        fill: colors.text,
                                        ...(!isScanClean && {
                                            fill: colors.disabledText,
                                        }),
                                        ...(current.newestRevision ===
                                            Number.parseInt(versionNumber) && {
                                            display: 'none',
                                        }),
                                    })}
                                />
                            )}
                            {deleteButtonVisible && (
                                <Close
                                    id={'deleteButton-' + versionNumber}
                                    onClick={onDeleteCallback}
                                    data-for='tooltip'
                                    data-tip='Remove version'
                                    onMouseEnter={disableDescriptionTooltip}
                                    onMouseLeave={disableButtonTooltip}
                                    css={({ colors }) => ({
                                        fill: colors.text,
                                        ...(current.newestRevision ===
                                            Number.parseInt(versionNumber) && {
                                            display: 'none',
                                        }),
                                    })}
                                />
                            )}
                        </Center>
                    </Box>
                    {versionDescription && criteria && (
                        <SearchDescription
                            versionDescription={versionDescription}
                            criteria={criteria}
                        />
                    )}
                </Box>
            </Box>
        </Box>
    );
}
