import { CcdSearchInput } from 'components/_legacy/ccd-search-input';
import useCurrentFolderContext from 'components/templates/FileManagement/components/CurrentFolderContextProvider/useCurrentFolderContext';

import './FileSearch.scss';

const FILE_SEARCH_ID = 'file-management-file-search';

export default function FileSearch() {
    const { filteringHandler } = useCurrentFolderContext();

    return (
        <div id={FILE_SEARCH_ID} className='ccd-fm__file-search-container'>
            <CcdSearchInput
                idPrefix={FILE_SEARCH_ID}
                placeholder='Search by file, folder or owner name...'
                onChangeCallback={filteringHandler}
            />
        </div>
    );
}
