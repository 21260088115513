import { bool, func, string } from 'prop-types';

import { CcdOverlay } from 'components/_legacy/ccd-overlay';
import Box from 'components/atoms/Box';
import { FormButton } from 'components/atoms/Button';
import Tooltip from 'components/atoms/Tooltip';
import SideNav, { SideNavComponents } from 'components/molecules/SideNav';
import { useFileManagementContext } from 'components/templates/FileManagement/components/FileManagementContext';

import { Breadcrumb } from '../../parts';
import MoveTable from './MoveTable';
import useMoveFile from './useMoveFile';
import useMoveForm from './useMoveForm';

export default function MoveFileForm({ isVisible, onClose, headerTitle, showModal }) {
    const { buttonDisabled, disableButton, enableButton } = useMoveForm();

    const {
        state: { currentFolder, selectedFiles },
        dispatch: dispatchFileManagement,
        onSubmit,
        moveInProgress,
    } = useMoveFile({ disableButton, enableButton }, onClose, showModal);

    const {
        state: { currentFolder: mainTableCurrentFolder, selectedFiles: mainTableSelection },
    } = useFileManagementContext();

    const sourceAndTargetFoldersAreTheSame =
        (selectedFiles.length === 0 && mainTableCurrentFolder.id === currentFolder.id) ||
        (selectedFiles.length === 1 && mainTableCurrentFolder.id === selectedFiles[0].id);

    function getButtonState() {
        return (
            buttonDisabled ||
            sourceAndTargetFoldersAreTheSame ||
            selectedFiles.find((selectedFile) => !selectedFile.isFolder)
        );
    }

    return (
        <SideNav title={headerTitle} open={isVisible} setOpen={onClose}>
            <SideNavComponents
                css={({ colors }) => ({
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    color: colors.text,
                })}
            >
                <CcdOverlay isBlocked={moveInProgress}>
                    <Box data-testid={'moveFileBreadcrumb'} css={{ marginBottom: 8 }}>
                        <Breadcrumb
                            currentFolder={currentFolder}
                            dispatchFileManagement={dispatchFileManagement}
                        />
                    </Box>
                    <MoveTable
                        currentFolder={currentFolder}
                        selectedFiles={selectedFiles}
                        mainTableSelection={mainTableSelection}
                        dispatchFileManagement={dispatchFileManagement}
                    />
                </CcdOverlay>
                <Tooltip
                    title={
                        sourceAndTargetFoldersAreTheSame
                            ? 'Target location needs to differ from the current file location'
                            : ''
                    }
                    arrow
                    enterDelay={500}
                    placement='top'
                >
                    <FormButton
                        id='ccdFileManagementMoveButton'
                        color='primary'
                        variant={'contained'}
                        disabled={getButtonState()}
                        onClick={onSubmit}
                    >
                        {selectedFiles.length === 0 ? 'Move here' : 'Move'}
                    </FormButton>
                </Tooltip>
            </SideNavComponents>
        </SideNav>
    );
}

MoveFileForm.propTypes = {
    isVisible: bool,
    onClose: func,
    headerTitle: string,
    id: string,
    showModal: func,
};
