import { useCallback } from 'react';

export default function useDescriptionResultVisualize() {
    const commentLength = 40;
    const textPayload = 10;

    function roundStrings(text, _criteria, pos) {
        let properBegin = pos;
        const end = pos + commentLength;
        let properEnd = end;
        const regex = / /gi;
        let result;
        const indices = [];
        while ((result = regex.exec(text))) {
            indices.push(result.index);
        }
        if (!indices.includes(pos)) {
            properBegin = Math.max(...[0, ...indices.filter((x) => x < pos)]);
        }

        if (end < text.length) {
            if (!indices.includes(end)) {
                properEnd = Math.min(...[text.length, ...indices.filter((x) => x > end)]);
            }
        }
        return text.substring(properBegin, properEnd);
    }

    const prepareDescriptionText = useCallback((text, conditions) => {
        if (!text) {
            return '';
        }
        const caseFreeText = text.toUpperCase();
        const caseFreeConditions = conditions.map((x) => x.toUpperCase());

        let condition;
        let orgCondition;
        for (const cx in caseFreeConditions) {
            if (caseFreeText.includes(caseFreeConditions[cx])) {
                condition = caseFreeConditions[cx];
                orgCondition = conditions[cx];
                break;
            }
        }

        if (!condition) {
            return '';
        }
        const pos = caseFreeText.indexOf(condition);

        const beginPos = pos - textPayload < 0 ? 0 : pos - textPayload;
        return roundStrings(text, orgCondition, beginPos);
    }, []);

    return {
        prepareDescriptionText,
    };
}
