import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const TileContent = styled(Box)(({ theme }) => ({
    padding: theme.spacing(2),
}));

export const ProjectFooterUpperArea = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    height: '40px',
});

export const ProjectFooterIconsArea = styled(Box)({
    display: 'flex',
    alignItems: 'center',
});

export const ProjectFooterDetails = styled('div')({
    fontSize: '12px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
});

export const Icon = styled('img')((props: MediaImage) => ({ theme }) => ({
    display: 'inline-block',
    backgroundImage: `url(${props.src})`,
    backgroundRepeat: 'no-repeat',
    marginLeft: theme.spacing(2),
    width: 16,
    height: 16,
    float: 'right',
    filter: theme.colors.filterGlobalIcon,
}));
