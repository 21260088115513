import { useFileManagementContext } from 'components/templates/FileManagement/components/FileManagementContext/FileManagementContextProvider';
import filesQuery from 'components/templates/FileManagement/components/parts/Table/service/files.query';

const useNameAlreadyExistsValidator = () => {
    const {
        state: { collaborationSpaceId, projectId, currentFolder },
    } = useFileManagementContext() as any;

    const { data } = filesQuery({
        projectId: projectId,
        collaborationSpaceId: collaborationSpaceId,
        folderId: currentFolder?.id.length ? currentFolder?.id : undefined,
    });

    return {
        nameAlreadyExists: (value: string) =>
            data?.find((item) => item.name === value)
                ? 'Folder or file with the same name already exists'
                : true,
    };
};

export default useNameAlreadyExistsValidator;
