import fetcher from 'providers/api/fetcher';
import {
    GetThemeSettingsResponse,
    SetThemeSettingsRequest,
    SetThemeSettingsResponse,
} from 'types/themeSettings';

export const setThemeSettings = async (
    body: SetThemeSettingsRequest
): Promise<SetThemeSettingsResponse> => {
    const { data } = await fetcher.put(`/frontend/settings/theme`, body);
    return data;
};

export const getThemeSettings = async (): Promise<GetThemeSettingsResponse> => {
    const { data } = await fetcher.get(`/frontend/settings/theme`);
    return data;
};
