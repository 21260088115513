import { useState } from 'react';

import Box from 'components/atoms/Box';
import InfoGuidanceMessage from 'components/atoms/GuidanceMessages/InfoGuidanceMessage';
import OpenGuidanceMessage from 'components/atoms/GuidanceMessages/OpenGuidanceMessage';

export interface useXccGuidanceMessagesInterface {
    openAction: () => void;
    closeAction: () => void;
    setProcessedGuidance?: string;
    setProcessingGuidance?: string;
}

const useXccGuidanceMessages = ({ openAction, closeAction }: useXccGuidanceMessagesInterface) => {
    const [processedGuidanceMessageVisible, setProcessedGuidanceMessageVisible] = useState('');
    const [processingGuidanceMessageVisible, setProcessingGuidanceMessageVisible] = useState('');

    const isGuidanceMessageVisible = (guidanceMessage: string) => {
        return guidanceMessage !== '';
    };

    const setProcessingInfo = (str: string) => {
        setProcessingGuidanceMessageVisible((x) => (x !== str ? str : x));
    };

    const xccGuidanceMessages = () => {
        return (
            <Box>
                {isGuidanceMessageVisible(processedGuidanceMessageVisible) ? (
                    <OpenGuidanceMessage
                        onClose={closeAction}
                        onAction={openAction}
                        info={processedGuidanceMessageVisible}
                        message='You can'
                        linkMessage='open it now'
                    />
                ) : null}
                {isGuidanceMessageVisible(processingGuidanceMessageVisible) ? (
                    <InfoGuidanceMessage
                        onClose={() => setProcessingGuidanceMessageVisible('')}
                        info={processingGuidanceMessageVisible}
                        message=' - Please wait...'
                    />
                ) : null}
            </Box>
        );
    };

    return {
        xccGuidanceMessages: xccGuidanceMessages(),
        isProcessingGuidanceMessageVisible: isGuidanceMessageVisible(
            processingGuidanceMessageVisible
        ),
        isProcessedGuidanceMessageVisible: isGuidanceMessageVisible(
            processedGuidanceMessageVisible
        ),
        setProcessedGuidance: (x: string) => setProcessedGuidanceMessageVisible(x),
        setProcessingGuidance: (x: string) => setProcessingInfo(x),
    };
};

export default useXccGuidanceMessages;
