import { useCallback, useRef, useState } from 'react';

import useDetectOutsideClick from 'components/_legacy/hooks/useDetectOutsideClick';
import { VerticalMenuViewType } from 'components/molecules/VerticalMenu/VerticalMenu';
import { useFileManagementContext } from 'components/templates/FileManagement/components/FileManagementContext';
import ListIcon from 'images/cmdListView16.svg?react';
import TableIcon from 'images/cmdTableView16.svg?react';

import { SWITCH_TABLE_VIEW, SWITCH_TILE_VIEW } from '../../../../consts';

export default function useSwitchDisplay({ onClick }) {
    const {
        state: { isTableViewDisplayed },
        dispatch,
    } = useFileManagementContext();

    const [isActiveChangeViewMenu, setChangeViewVisibility] = useState(false);
    const changeVisibilityRefObject = useRef(null);

    const outsideClickCallback = useCallback(() => {
        setChangeViewVisibility(false);
    }, [setChangeViewVisibility]);

    useDetectOutsideClick(changeVisibilityRefObject, outsideClickCallback, true);

    const onGridView = useCallback(() => {
        onClick(VerticalMenuViewType.GRID);
        dispatch({
            isTableViewDisplayed: isTableViewDisplayed,
            selectedFiles: [],
        });
    }, []);
    const onTableView = useCallback(() => {
        onClick(VerticalMenuViewType.TABLE);
        dispatch({
            isTableViewDisplayed: !isTableViewDisplayed,
            selectedFiles: [],
        });
    }, []);

    const menuItems = [
        {
            name: 'Tile view',
            icon: ListIcon,
            callback: onGridView,
        },
        {
            name: 'Table view',
            icon: TableIcon,
            callback: onTableView,
        },
    ];

    return {
        switchDisplay: {
            id: isTableViewDisplayed ? SWITCH_TILE_VIEW : SWITCH_TABLE_VIEW,
            children: 'Change View',
            onClick: () => {
                setChangeViewVisibility((prev) => !prev);
            },
            leftIcon: isTableViewDisplayed ? TableIcon : ListIcon,
            popupMenu: {
                menuItems: menuItems,
                isActive: isActiveChangeViewMenu,
                direction: 'right',
            },
            visible: true,
            variant: 'text',
            color: 'gray',
            ref: changeVisibilityRefObject,
        },
    };
}
