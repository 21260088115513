import React, {
    ChangeEvent,
    ChangeEventHandler,
    LegacyRef,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';

import Box from 'components/atoms/Box';
import { DialogButton } from 'components/atoms/Button';

import {
    CannotUploadImage,
    CurrentImage,
    DragContent,
    DragDrop,
    DragDropZone,
    InProgressImage,
    InfectedIcon,
    Message,
    ReplaceRemoveArea,
    StateParams,
    UploadImage,
} from './DragNDropComponent.styles';
import useCheckContent from './hooks/useCheckContent';
import {
    DROP_ENABLE,
    DROP_IN_PROGRESS,
    DROP_NOT_POSSIBLE,
    isDropEnabled,
    isDropInProgress,
    isDropNotEnabled,
    isDropNotPossible,
} from './utils/stateUtils';

interface DragNDropComponentInterface {
    onDrop: any;
    onClear: any;
    hasAccess: boolean;
    id: string;
    currentImage: any;
    imageError: boolean;
    isInfected: boolean;
}

export default function DragNDropComponent({
    onDrop,
    onClear,
    hasAccess,
    id,
    currentImage,
    imageError,
    isInfected,
}: DragNDropComponentInterface) {
    const ALLOWED_FILE_EXTENSIONS = '.png, .jpg, .jpeg, .gif';
    const uploadInputRef = useRef(null);
    const [mode, setMode] = useState(DROP_ENABLE);
    const [hasStaticError, setHasStaticError] = useState(false);
    const { isDragAndDropPossible, isFileValid, validationError } = useCheckContent(hasAccess);
    const [counter, setCounter] = useState(0);
    const [infectedInternal, setInfectedInternal] = useState(isInfected);
    const onDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
        setCounter((prev) => prev + 1);
        setHasStaticError(false);
        if (isDragAndDropPossible(event.dataTransfer.items)) {
            event.dataTransfer.dropEffect = 'copy';
            setMode(DROP_IN_PROGRESS);
        } else {
            event.dataTransfer.dropEffect = 'none';
            setMode(DROP_NOT_POSSIBLE);
        }
        event.preventDefault();
    };

    const onDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
        setCounter((prev) => prev - 1);
        event.preventDefault();
    };

    const onDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
    };

    const uploadFile = useCallback(
        (files: FileList) => {
            if (isFileValid(files)) {
                onDrop(files[0]);
                setMode(DROP_ENABLE);
                setInfectedInternal(false);
            } else {
                setHasStaticError(true);
                setMode(DROP_NOT_POSSIBLE);
            }
        },
        [onDrop]
    );

    useEffect(() => {
        if (imageError) {
            setHasStaticError(true);
            setMode(DROP_NOT_POSSIBLE);
        }
    }, [imageError]);

    const onUploadFile: ChangeEventHandler<HTMLInputElement> = (
        event: ChangeEvent<HTMLInputElement>
    ) => {
        uploadFile((event.target as HTMLInputElement).files!);
    };

    const resetStaticErrorFlag = () => {
        setHasStaticError(false);
        setMode(DROP_ENABLE);
    };

    const onDropHandler = (event: React.DragEvent<HTMLDivElement>) => {
        uploadFile(event.dataTransfer.files);
        setCounter(0);
        event.preventDefault();
    };

    useEffect(() => {
        if (counter === 0 && !hasStaticError) {
            setMode(DROP_ENABLE);
        }
    }, [counter, hasStaticError]);

    const getDragMessage = useMemo(() => {
        if (infectedInternal) {
            return (
                <>
                    <div>Security scan detected a threat in this file.</div>
                    <div>Make sure you upload a virus free file.</div>
                </>
            );
        }

        if (!hasAccess) {
            return `Your Viewer role doesn’t allow to upload files`;
        }
        switch (mode) {
            case DROP_NOT_POSSIBLE:
                return (
                    <>
                        <div>Can’t upload this file.</div>
                        <div>{validationError}</div>
                    </>
                );
            case DROP_ENABLE:
                return 'Drag & Drop project cover image here, or';
            case DROP_IN_PROGRESS:
                return 'Drag & Drop project cover image here.';
            default:
                console.error('Unknown drag&drop state');
        }
    }, [DROP_NOT_POSSIBLE, hasAccess, mode, validationError, infectedInternal]);

    const handleFileSelect = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        if (uploadInputRef.current) {
            (uploadInputRef.current as HTMLInputElement).click();
        }
    }, []);

    const addInputElement = useCallback(() => {
        return (
            <input
                ref={uploadInputRef as LegacyRef<HTMLInputElement>}
                type='File'
                name='FileUpload'
                hidden
                id='fileUploadId'
                accept={ALLOWED_FILE_EXTENSIONS}
                onChange={onUploadFile}
            />
        );
    }, [uploadInputRef]);

    const BackgroundImage = ({ hasAccess, mode, counter, infected }: StateParams) => {
        return (
            <>
                {infected && <InfectedIcon />}
                {!(hasAccess || infected) && <CannotUploadImage />}
                {hasAccess && !infected && isDropNotPossible(mode) && <CannotUploadImage />}
                {hasAccess && !infected && isDropInProgress(mode, counter) && <InProgressImage />}
                {hasAccess &&
                    !infected &&
                    !isDropNotPossible(mode) &&
                    !isDropInProgress(mode, counter) && <UploadImage />}
            </>
        );
    };

    const DragAndDropMode = (props: StateParams) => {
        return (
            <>
                <BackgroundImage {...props} />
                <Message {...props}>{getDragMessage}</Message>
                {mode === DROP_ENABLE && (
                    <>
                        {addInputElement()}
                        <DialogButton
                            color={'primary'}
                            variant={'contained'}
                            css={{
                                marginTop: '16px',
                                width: '160px',
                            }}
                            onClick={handleFileSelect}
                        >
                            Choose File
                        </DialogButton>
                    </>
                )}
                {mode === DROP_NOT_POSSIBLE && hasStaticError && (
                    <DialogButton
                        color={'negative'}
                        variant={'outlined'}
                        css={{
                            marginTop: '16px',
                            width: '160px',
                        }}
                        onClick={resetStaticErrorFlag}
                    >
                        Try again
                    </DialogButton>
                )}
            </>
        );
    };

    const removeImage = useCallback(() => {
        onClear();
        setInfectedInternal(isInfected);
    }, [onClear]);

    const ShowCurrentSelection = () => {
        return (
            <>
                <Box css={{ flexGrow: 1 }}>
                    <CurrentImage id='preview-image' src={currentImage} alt='not set' />
                </Box>
                <ReplaceRemoveArea>
                    <DialogButton
                        color={'primary'}
                        variant={'outlined'}
                        css={{ width: 160 }}
                        onClick={removeImage}
                    >
                        Remove image
                    </DialogButton>
                    {addInputElement()}
                    <DialogButton
                        color={'primary'}
                        variant={'contained'}
                        css={{ width: 160 }}
                        onClick={handleFileSelect}
                    >
                        Replace image
                    </DialogButton>
                </ReplaceRemoveArea>
            </>
        );
    };

    return (
        <DragDrop
            onDragEnter={onDragEnter}
            onDragLeave={onDragLeave}
            onDrop={onDropHandler}
            onDragOver={onDragOver}
            id={id}
        >
            <DragDropZone
                hasAccess={hasAccess}
                mode={mode}
                counter={counter}
                infected={infectedInternal}
            >
                <DragContent>
                    {(isDropNotEnabled(mode) || !currentImage) && (
                        <DragAndDropMode
                            hasAccess={hasAccess}
                            mode={mode}
                            counter={counter}
                            infected={infectedInternal}
                        />
                    )}
                    {isDropEnabled(mode, counter) && currentImage && <ShowCurrentSelection />}
                </DragContent>
            </DragDropZone>
        </DragDrop>
    );
}
