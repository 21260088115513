import React from 'react';

import Box from 'components/atoms/Box';

const TooltipContent = (data) => {
    if (!data) {
        return null;
    }
    const values = JSON.parse(data);
    return (
        <div>
            <div>
                <b>Version {values.version} check-in comment</b>
            </div>
            <Box className='ccd-project-version-description-text'>
                {values.description !== '' ? values.description : 'No description'}
            </Box>
        </div>
    );
};

export default TooltipContent;
