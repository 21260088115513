import { UseMutationOptions, useMutation } from '@tanstack/react-query';

import { updateProject } from 'services/api/project.service';
import { UpdateProjectRequest, UpdateProjectResponse } from 'types/project';

const useEditProject = (
    options: UseMutationOptions<UpdateProjectResponse, undefined, UpdateProjectRequest>
) => useMutation(updateProject, options);

export default useEditProject;
