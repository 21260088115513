import { useCallback, useRef, useState } from 'react';

import { ccdInfoToast } from 'components/_legacy/ccd-toast';
import { useCcdFetch } from 'components/_legacy/hooks/useCcdFetch';
import { useFileManagementContext } from 'components/templates/FileManagement/components/FileManagementContext';

import { unexpectedError } from '../../../../consts';
import { EE, FILE_RENAMED } from '../../../../eventEmitter';
import { useGlobalUiBlockersCounterContext, useTableRefresh } from '../../../../hooks';
import { splitFileName } from '../../../../utils';
import { NAME_INPUT_ID, useGetFileInputs } from '../../FileForm';
import { alreadyExistsError, successfulNotification } from '../consts';

export default function useRenameFileForm(formHandlers, onClose) {
    const {
        increaseBlockerCounter,
        decreaseBlockerCounter,
        isBlocked: isLoading,
    } = useGlobalUiBlockersCounterContext();

    const {
        state: { backendUrl, collaborationSpaceId, projectId, selectedFiles },
    } = useFileManagementContext();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalSelectedFileId, setModalSelectedFileId] = useState(false);
    const [modalInputValue, setModalInputValue] = useState(false);
    const { dispatchDataLoaded } = useTableRefresh();
    const formRef = useRef();
    const { ccdFetch } = useCcdFetch();
    const { getFileInputs } = useGetFileInputs();

    const onModalClose = useCallback(() => {
        setIsModalVisible(false);
        formHandlers.activateForm();
    }, [formHandlers]);

    const onRenameConfirmed = useCallback(async () => {
        setIsModalVisible(false);
        await submitFile(modalInputValue, modalSelectedFileId);
    }, [modalInputValue, modalSelectedFileId]);

    function dataHandler() {
        if (selectedFiles.length === 1) {
            const selectedFile = selectedFiles[0];
            return getRenameFileData(selectedFile);
        }
        return {
            onSubmit: () => {
                // This is intentional
            },
            inputs: [],
        };
    }

    function getRenameFileData(selectedFile) {
        const { name } = splitFileName(selectedFile.name);
        return {
            onSubmit: async (params, event) => {
                event.preventDefault();
                formHandlers.preSubmitCleanup();
                const inputValue = params[NAME_INPUT_ID].trim();
                if (getFullFileName(inputValue) === selectedFile.name) {
                    formHandlers.activateForm();
                    onClose();
                } else if (!inputValue) {
                    setModalSelectedFileId(selectedFile.id);
                    setModalInputValue(inputValue);
                    setIsModalVisible(true);
                } else {
                    await submitFile(inputValue, selectedFile.id);
                }
            },
            inputs: getFileInputs(name, getFullFileName),
        };
    }

    async function submitFile(inputValue, selectedFileId) {
        increaseBlockerCounter();
        const url = new URL(
            `/frontend/file-management/${collaborationSpaceId}/${projectId}/document/${selectedFileId}/name/`,
            backendUrl
        );
        try {
            const requestConfig = {
                method: 'PUT',
                body: JSON.stringify({
                    name: getFullFileName(inputValue),
                }),
                cache: 'no-cache',
                headers: {
                    'Content-Type': 'application/json',
                },
            };
            const response = await ccdFetch(url.toString(), requestConfig);

            if (response.ok) {
                await dispatchDataLoaded([]);
                ccdInfoToast(successfulNotification(getFullFileName(inputValue)));
                onClose();
            } else {
                handleServerError(response, inputValue);
            }
        } catch (err) {
            console.error(err);
            formHandlers.setError(unexpectedError);
        }
        decreaseBlockerCounter();
        EE.emit(FILE_RENAMED);
    }

    function handleServerError(response, name) {
        console.error(response);
        if (response.status === 409) {
            formHandlers.setError(alreadyExistsError(getFullFileName(name)));
            return;
        }
        formHandlers.setError(unexpectedError);
    }

    function getFullFileName(nameWithoutExtension) {
        const { extension } = splitFileName(selectedFiles[0].name);
        return `${nameWithoutExtension}${extension}`;
    }

    return {
        ...dataHandler(),
        isModalVisible,
        onModalClose,
        onRenameConfirmed,
        isLoading,
        formRef,
    };
}
