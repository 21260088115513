import { useParams } from 'react-router';

import { ScanTooltip } from 'components/organisms/ProjectVersion/components/TooltipContent/SecurityThreatTooltipContent';
import ErrorIcon from 'images/indicatorError16.svg?react';
import { useUserDataContext } from 'providers/currentUser/UserDataProvider';
import { useProjectPermission } from 'providers/permissions';
import { ProjectPermissions } from 'types/permissions';

export const VersionErrorIcon = ({ versionNumber }: { versionNumber: string }) => {
    const { collaborationSpaceId } = useUserDataContext();
    const { projectId } = useParams();
    const { hasProjectPermission } = useProjectPermission(collaborationSpaceId, projectId);
    const permission = hasProjectPermission(ProjectPermissions.MarkAsClean);

    const scanTooltip: ScanTooltip = {
        versionNumber,
        projectId: encodeURIComponent(collaborationSpaceId + '/' + projectId),
        hasPermissionToMarkAsSafe: permission,
    };
    return (
        <ErrorIcon
            id={'tooltipScanErrorIcon'}
            data-for='tooltipScanError'
            data-tip={JSON.stringify(scanTooltip)}
        />
    );
};
