import { useCallback, useEffect, useState } from 'react';

import { CLOSE_SIDE_NAV, EE } from '../../../eventEmitter';

export default function useSideNavVisibility() {
    const [visibility, setVisibility] = useState(false);

    const closeSideNav = useCallback(() => {
        setVisibility(false);
    }, []);

    const switchSideNavVisibility = useCallback((e) => {
        e.stopPropagation();
        EE.emit(CLOSE_SIDE_NAV);
        setVisibility((prevState) => !prevState);
    }, []);

    useEffect(() => {
        EE.addListener(CLOSE_SIDE_NAV, closeSideNav);
        return () => {
            EE.removeListener(CLOSE_SIDE_NAV, closeSideNav);
        };
    }, [closeSideNav]);

    return { visibility, closeSideNav, switchSideNavVisibility };
}
