import isNil from 'lodash/isNil';

import { FileFormComponent } from '../../../parts/FileForm';
import useRenameFileSideNavForm from './hooks/useRenameFileSideNavForm';

const RenameFileSideNavForm = () => {
    const { isOpen, onSubmit, file, handleClose, inputs, isLoading } = useRenameFileSideNavForm();

    if (isNil(file)) {
        return null;
    }

    return (
        <FileFormComponent
            isVisible={isOpen}
            onClose={handleClose}
            headerTitle='Rename file'
            onSubmit={onSubmit}
            focus='fileFormLabel'
            inputs={inputs}
            isLoading={isLoading}
            buttonDisabled={isLoading}
        />
    );
};

export default RenameFileSideNavForm;
