import { useProgressBar } from 'components/atoms/ProgressBar';
import fetcher from 'providers/api/fetcher';
import {
    BomRequest,
    BomResponse,
    BomSettingsRequest,
    BomSettingsResponse,
    MpnConfigRequest,
} from 'types/project';

export const getBomData = async ({
    collaborationSpaceId,
    projectId,
    startFrom,
    pageSize,
    projectVersion,
    sortOrder,
    sortColumn,
    variant,
}: BomRequest): Promise<BomResponse> => {
    const { setProgressBar } = useProgressBar();
    setProgressBar(true);
    const { data } = await fetcher.get(
        `/frontend/project/bom/content/${collaborationSpaceId}/${projectId}`,
        {
            params: {
                startFrom,
                pageSize,
                projectVersion,
                ...(sortOrder && { sortOrder: sortOrder }),
                ...(sortColumn && { sortColumn: sortColumn }),
                variant,
            },
        }
    );
    setProgressBar(false);
    return data;
};

export const getBomSettings = async ({
    collaborationSpaceId,
    projectId,
}: BomSettingsRequest): Promise<BomSettingsResponse> => {
    const { data } = await fetcher.get(
        `/frontend/settings/bom-table/${collaborationSpaceId}/${projectId}`
    );
    return data;
};

export const updateMpnColumnName = async ({
    collaborationSpaceId,
    projectId,
    mpnColumnName,
}: MpnConfigRequest) => {
    await fetcher.put(`/frontend/settings/bom-table/${collaborationSpaceId}/${projectId}/mpn`, {
        mpnColumnName,
    });
};
