import { useEffect, useState } from 'react';

import { useFileManagementContext } from 'components/templates/FileManagement/components/FileManagementContext/FileManagementContextProvider';
import filesQuery from 'components/templates/FileManagement/components/parts/Table/service/files.query';
import { FilesTableSortDirection } from 'components/templates/FileManagement/components/parts/Table/useTable';
import { CurrentFolder } from 'components/templates/FileManagement/types';
import { useSettings } from 'hooks/useSettings';

export type OnMoveFilesTableSort = (
    sortColumn: { id: MoveFilesSortColumnName },
    sortOrder: FilesTableSortDirection
) => void;

export type MoveFilesSortColumnName = 'name' | 'lastModifiedDate';
export interface MoveFilesTableSort {
    sortColumn: MoveFilesSortColumnName;
    sortOrder: FilesTableSortDirection;
}

const moveFileManagementSettings = 'moveFileManagementSettings';

const useMoveSorting = (currentFolder: CurrentFolder) => {
    const { getSetting, storeSetting } = useSettings();

    const {
        state: { collaborationSpaceId, projectId },
    } = useFileManagementContext() as any;

    const [settings, setSettings] = useState<MoveFilesTableSort>(() => {
        const settings = getSetting(moveFileManagementSettings);
        return settings
            ? JSON.parse(settings)
            : {
                  sortColumn: 'lastModifiedDate',
                  sortOrder: 'descending',
              };
    });

    const moveSortingHandler: OnMoveFilesTableSort = (column, direction) => {
        if (direction) {
            setSettings({
                sortColumn: column.id,
                sortOrder: direction,
            });
        }
    };

    useEffect(() => {
        storeSetting(moveFileManagementSettings, JSON.stringify(settings));
    }, [settings]);

    const { data, isError, isLoading } = filesQuery({
        projectId: projectId,
        collaborationSpaceId: collaborationSpaceId,
        folderId: currentFolder?.id.length ? currentFolder?.id : '',
        sortDir: settings.sortOrder,
        sortBy: settings.sortColumn,
    });

    return { settings, moveSortingHandler, data, isError, isLoading };
};

export default useMoveSorting;
