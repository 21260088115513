import React from 'react';

import MultipleFilesAlreadyExist from './MultipleFilesAlreadyExist';
import SingleFileAlreadyExists from './SingleFileAlreadyExists';

interface Props {
    onClose: () => void;
    filesNames: { name: string }[];
    isDragAndDropUpload?: boolean;
}

const FilesAlreadyExistsModalForFileUpload = ({
    filesNames,
    onClose,
    isDragAndDropUpload,
}: Props) =>
    filesNames.length === 1 ? (
        <SingleFileAlreadyExists
            file={filesNames[0]}
            onClose={onClose}
            isDragAndDropUpload={isDragAndDropUpload}
        />
    ) : (
        <MultipleFilesAlreadyExist
            fileNames={filesNames}
            onClose={onClose}
            isDragAndDropUpload={isDragAndDropUpload}
        />
    );

export default FilesAlreadyExistsModalForFileUpload;
