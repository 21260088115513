import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { useToast } from 'components/_legacy/ccd-toast';
import { useProgressBar } from 'components/atoms/ProgressBar';
import { getBomData, getBomSettings, updateMpnColumnName } from 'services/api/bom.services';
import { BomRequest, BomResponse, BomSettingsRequest, BomSettingsResponse } from 'types/project';

export const useBomDataQuery = ({
    collaborationSpaceId,
    projectId,
    startFrom,
    pageSize,
    projectVersion,
    sortOrder,
    sortColumn,
    isSettingsReady,
    variant,
    mpnConfigured = true,
}: BomRequest) => {
    const isEnabled =
        !!collaborationSpaceId &&
        !!projectId &&
        !!+projectVersion &&
        isSettingsReady &&
        mpnConfigured;

    return useInfiniteQuery<BomResponse>(
        [
            'getBomData',
            collaborationSpaceId,
            projectId,
            startFrom,
            pageSize,
            projectVersion,
            sortOrder,
            sortColumn,
            variant,
            mpnConfigured,
        ],
        ({ pageParam = 0 }) =>
            getBomData({
                collaborationSpaceId,
                projectId,
                startFrom: pageParam,
                pageSize,
                projectVersion,
                sortOrder,
                sortColumn,
                variant,
            }),
        {
            enabled: isEnabled,
            getNextPageParam: (lastPage, pages) => {
                if (pages.length * parseInt(pageSize) >= lastPage.totalSize) return;
                return pages.length * parseInt(pageSize);
            },
            retry: (_count, error) => {
                // @ts-ignore
                return error?.response?.status < 400;
            },
        }
    );
};
export const useBomSettingsQuery = ({ collaborationSpaceId, projectId }: BomSettingsRequest) => {
    return useQuery<BomSettingsResponse>(
        ['getBomSettings', collaborationSpaceId, projectId],
        () => getBomSettings({ collaborationSpaceId, projectId }),
        {
            enabled: !!collaborationSpaceId && !!projectId,
        }
    );
};

export const useUpdateMpnColumnName = ({ onSuccess }: { onSuccess?: () => void }) => {
    const queryClient = useQueryClient();
    const { showToast } = useToast();
    const { setProgressBar } = useProgressBar();

    return useMutation({
        mutationFn: updateMpnColumnName,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['getBomSettings'] });
            onSuccess && onSuccess();
        },
        onError: () => {
            showToast({
                type: 'error',
                messages: ['Cannot update manufaturer part number settings'],
            });
        },
        onSettled: () => {
            setProgressBar(false);
        },
    });
};
