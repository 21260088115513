import { DialogContent } from '@radix-ui/react-dialog';

import Button from 'components/atoms/Button';
import Dialog from 'components/molecules/Dialog';

export interface XccQuestionDialogInterface {
    open: boolean;
    changeState: (state: boolean) => {};
    onAction: () => void;
    onCancel: () => void;
}

const XccQuestionDialog = ({
    open,
    changeState,
    onAction,
    onCancel,
}: XccQuestionDialogInterface) => {
    return (
        <Dialog
            open={open}
            setOpen={changeState}
            hideOverlay={true}
            contentCssOverride={{
                backgroundColor: 'rgb(255, 247, 205)',
                borderColor: 'red',
                top: '75%',
            }}
            overlayColor={'#FFFFFF00'}
            buttons={
                <>
                    <Button
                        style={{ height: '24px', marginRight: '16px' }}
                        color={'caution'}
                        variant={'outlined'}
                        onClick={onCancel}
                        id={'deleteProjectCancelButton'}
                    >
                        Cancel
                    </Button>
                    <Button
                        style={{ height: '24px' }}
                        color={'caution'}
                        variant={'contained'}
                        onClick={onAction}
                        id={'deleteProjectDeleteButton'}
                    >
                        Let me know
                    </Button>
                </>
            }
        >
            <DialogContent>
                <span style={{ whiteSpace: 'pre-wrap' }}>
                    {
                        "Project view not ready \n\nProject hasn't been processed yet. It usually takes a few minutes. \nConnect can let you know when the project is ready to open"
                    }
                </span>
            </DialogContent>
        </Dialog>
    );
};

export default XccQuestionDialog;
