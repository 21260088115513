import React, { PropsWithChildren, createContext } from 'react';

import useTopMargin from 'hooks/useTopMargin';

export interface Margin {
    topMargin: string;
}

export const TopMarginContext = createContext<Margin>({ topMargin: '0px' });

export function TopMarginProvider({ children }: PropsWithChildren) {
    const margin = useTopMargin();
    return <TopMarginContext.Provider value={margin}>{children}</TopMarginContext.Provider>;
}

export default TopMarginProvider;
