import React, { useContext, useEffect, useMemo, useState } from 'react';

import Box from 'components/atoms/Box';

import { FINDCHIPS_TOOLTIP_ID } from '../consts';
import CurrentRowContext from '../contexts/CurrentRowContext';

import './BomViewBindingCellRenderer.css';

export default function BomViewBindingCellRenderer(textWrap, { cell }) {
    const [showCell, setShowCell] = useState(false);
    const currentRow = useContext(CurrentRowContext);
    useEffect(() => {
        setShowCell(currentRow === cell.row.id);
    }, [currentRow, cell]);

    const findchips = useMemo(() => {
        if (cell.value && cell.value.trim() !== '') {
            return (
                <Box
                    as={'span'}
                    className={'findchips'}
                    css={(props) => ({
                        color: props.colors.actionText,
                        display: 'inline',
                        cursor: 'pointer',
                        ...(showCell || {
                            display: 'none',
                        }),
                    })}
                    data-for={FINDCHIPS_TOOLTIP_ID}
                    data-tip={cell.value}
                    data-event='click'
                    data-event-off='focusout mouseleave'
                >
                    Findchips
                </Box>
            );
        } else {
            return (
                <Box
                    as={'span'}
                    className={'findchips'}
                    css={({ colors }) => ({
                        display: 'none',
                        ...(showCell && {
                            color: colors.disabledText,
                            display: 'inline',
                        }),
                    })}
                >
                    Findchips
                </Box>
            );
        }
    }, [showCell, cell]);

    return (
        <div className='cell-wrapper'>
            <span className={textWrap ? '' : 'cell-value-wrapper'}>{cell.value}</span>
            {findchips}
        </div>
    );
}
