import { useId } from 'react';

import { CcdRelativeDateCellRenderer } from 'components/_legacy/ccd-relative-date-cell-renderer';
import { CcdTableColumn } from 'components/_legacy/ccd-table';
import { useFileManagementContext } from 'components/templates/FileManagement/components/FileManagementContext';
import usePagination from 'components/templates/FileManagement/components/parts/Table/usePagination';
import {
    FilesTableName,
    FilesTableSortDirection,
    OnFilesTableSort,
} from 'components/templates/FileManagement/components/parts/Table/useTable';
import { ISelectedFile } from 'components/templates/FileManagement/contexts/FileManagementContext';

import { isItemOpenable } from '../../../../utils';
import { FileNameCellRenderer, TableComponent, useListItem } from '../../Table';

export default function TableDisplay({
    data,
    sortingHandler,
    initialSortSettings,
}: {
    data: ISelectedFile[] | undefined;
    sortingHandler: OnFilesTableSort;
    initialSortSettings: {
        sortedColumnName: FilesTableName;
        sortDirection: FilesTableSortDirection;
    };
}) {
    const id = useId();
    const {
        state: { selectedFiles, currentFolder },
        dispatch: dispatchFileManagement,
    } = useFileManagementContext();
    const { paginationMemo } = usePagination();

    const { selectionHandler, onFileClickHandler } = useListItem(
        currentFolder,
        dispatchFileManagement
    );

    const columns: CcdTableColumn<ISelectedFile>[] = [
        {
            Header: 'Name',
            accessor: 'name',
            width: 400,
            minWidth: 100,
            ccdSorted: true,
            Cell: (cell) => {
                return (
                    <FileNameCellRenderer
                        onCellNameClick={onFileClickHandler}
                        cell={cell}
                        isItemOpenable={isItemOpenable}
                    />
                );
            },
        },
        {
            Header: 'Creation Date',
            accessor: 'creationDate',
            ccdSorted: true,
            minWidth: 100,
            Cell: CcdRelativeDateCellRenderer,
        },
        {
            Header: 'Last Modified Date',
            accessor: 'lastModifiedDate',
            ccdSorted: true,
            minWidth: 100,
            Cell: CcdRelativeDateCellRenderer,
        },
        {
            Header: 'Last Modified By',
            accessor: 'lastModifiedBy',
            minWidth: 100,
            ccdSorted: true,
        },
        {
            Header: '',
            accessor: 'empty',
            ccdSorted: false,
        },
    ];

    columns.forEach((item) => {
        item.tableId = id;
    });

    return (
        <TableComponent
            data={data}
            columns={columns}
            rowHandler={selectionHandler}
            doubleClickHandler={onFileClickHandler}
            sortingHandler={sortingHandler}
            tableSettings={{
                wrapText: false,
                supportsDragNDrop: false,
                suppressOutsideClick: true,
                multiselection: true,
                initialSortSettings,
            }}
            paginationMemo={paginationMemo}
            selectedFiles={selectedFiles}
            rowRenderer={null}
        />
    );
}
