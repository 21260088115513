import { RadioButtonConfiguration } from 'components/atoms/RadioButtons/RadioButtons';

export interface DataPrivacyProps {
    open: boolean;
    setOpen: (open: boolean) => void;
}

export const optOutConfig: Array<RadioButtonConfiguration> = [
    { value: 'false', label: 'Agree' },
    { value: 'true', label: 'Decline' },
];
