import styled from '@emotion/styled';
import { default as IndianaScrollContainer } from 'react-indiana-drag-scroll';

export const ScrollContainer = styled(IndianaScrollContainer)({
    textAlign: 'center',
    overflow: 'hidden',
    color: 'white',
});

export const Toolbar = styled('div')({
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100%',
    backgroundColor: '#000000C7',
    padding: '0 16px',
    height: '50px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
});

export const Container = styled('div')<{ topMargin: string }>`
    position: fixed;
    top: calc(50% + ${(props) => props.topMargin});
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9989;

    height: 100vh;
    width: 100vw;
    padding-bottom: 32px;

    display: flex;
    justify-content: space-between;
    flex-direction: column;
`;
