import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { PropsWithChildren, useState } from 'react';
import { useLocation } from 'react-router';

import appConfig from 'constants/appConfig';
import fetcher from 'providers/api/fetcher';
import { useCheckSubscription } from 'services/queries/checkSubscription.query';
import { createGenericContext } from 'shared/createGenericContext';
import { Path } from 'types/paths.enum';

//token is valid for 2 hours so refresh will be triggered 5 minutes before invalidation
export const REFRESH_ACCESS_TOKEN_INTERVAL = 115 * 60 * 1000;

export const redirectToLogin = () => {
    const lastLocation = window.localStorage.getItem('lastLocation');
    if (lastLocation) {
        const lastLocationValue = JSON.parse(lastLocation);
        const state = window.btoa(JSON.stringify({ redirectTo: lastLocationValue }));
        window.location.replace(
            `${appConfig.baseURL}/auth/sam/login?` + new URLSearchParams({ state: state })
        );
    } else {
        window.location.replace(`${appConfig.baseURL}/auth/sam/login`);
    }
};
export const redirectToLogout = () => {
    window.location.replace(`${appConfig.baseURL}/auth/sam/logout`);
};

export const useRefreshToken = ({
    onSuccess,
    onError,
}: {
    onSuccess?: () => void;
    onError?: () => void;
}) => {
    const location = useLocation();
    return useQuery({
        queryKey: ['refreshToken'],
        queryFn: async (): Promise<string> => {
            const { data } = await fetcher.post<string>('/auth/sam/refresh-token', '', {
                headers: { 'Content-Type': 'application/json' },
            });
            return data;
        },
        retry: (_failureCount, error: AxiosError) => {
            if (
                location.pathname === Path.ERROR_UNSUBSCRIBED_USER ||
                location.pathname === Path.ERROR_UNSUBSCRIBED_USER_HTML ||
                location.pathname === Path.ERROR_HTML
            ) {
                return false;
            }
            if (error.response?.status === 401) {
                redirectToLogout();
                return false;
            }
            redirectToLogin();
            return true;
        },
        onSuccess,
        onError,
        refetchInterval: REFRESH_ACCESS_TOKEN_INTERVAL,
        refetchIntervalInBackground: true,
    });
};

interface Auth {
    isAuthenticated: boolean;
    idToken?: string;
}
const [useAuthContext, AuthContextProvider] = createGenericContext<Auth>();

const AuthProvider = ({ children }: PropsWithChildren) => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

    const { data: idToken } = useRefreshToken({
        onSuccess: () => {
            setIsAuthenticated(true);
        },
        onError: () => {
            setIsAuthenticated(false);
        },
    });

    useCheckSubscription();

    return (
        <AuthContextProvider value={{ isAuthenticated, idToken }}>{children}</AuthContextProvider>
    );
};

export { useAuthContext, AuthProvider };
