import React, { useState } from 'react';

import {
    ActionTileHeaderRoot,
    ActionTileHeaderText,
    ActionTileHeaderThumbnail,
} from './ActionTileHeader.styles';

type ThumbnailProperties = {
    height?: number | `${number}%` | `${number}px`;
    width?: number | `${number}%` | `${number}px`;
    borderRadius?: `${number}% ${number}%`;
};

interface CcdActionTileHeaderProps {
    header: string;
    thumbnailProperties: ThumbnailProperties;
    thumbnailSrc?: string;
    DefaultThumbnailSrc: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

export function ActionTileHeader({
    header,
    thumbnailProperties,
    thumbnailSrc,
    DefaultThumbnailSrc,
}: CcdActionTileHeaderProps) {
    const [isError, setError] = useState(false);

    return (
        <ActionTileHeaderRoot>
            <ActionTileHeaderThumbnail>
                {isError ? (
                    <DefaultThumbnailSrc
                        style={{
                            height: thumbnailProperties.height,
                            width: thumbnailProperties.width,
                        }}
                    />
                ) : (
                    <img
                        style={{
                            height: thumbnailProperties.height,
                            width: thumbnailProperties.width,
                            borderRadius: thumbnailProperties.borderRadius,
                        }}
                        alt='thumbnail'
                        src={thumbnailSrc}
                        onError={() => {
                            setError(true);
                        }}
                    />
                )}
            </ActionTileHeaderThumbnail>
            <ActionTileHeaderText>{header}</ActionTileHeaderText>
        </ActionTileHeaderRoot>
    );
}
