import { useEffect, useState } from 'react';

export default function useTopMargin() {
    const [topMargin, setTopMargin] = useState('0px');

    const callback = (mutationList: any) => {
        for (const mutation of mutationList) {
            if (mutation.type === 'attributes') {
                const margin = document?.body?.style?.marginTop ?? '0px';
                setTopMargin(margin === '' ? '0px' : margin);
            }
        }
    };

    useEffect(() => {
        const targetNode = document.body;

        const observer = new MutationObserver(callback);
        observer.observe(targetNode, {
            attributes: true,
            attributeFilter: ['style'],
        });

        return () => {
            observer.disconnect();
        };
    }, []);

    return {
        topMargin,
    };
}
