import ComboBox, { ComboBoxGroupRoot } from 'components/atoms/ComboBox';
import Label from 'components/atoms/Label';

import { IUserItem } from './IUserItem';
import Option from './Option';
import SingleValue from './SingleValue';

interface IUserComboBox {
    value: IUserItem;
}

export const DisabledUserComboBox = ({ value }: IUserComboBox) => (
    <ComboBoxGroupRoot>
        <Label>Name</Label>
        <ComboBox
            placeholder={'Add user from the Xcelerator Admin Console list'}
            components={{ Option, SingleValue }}
            isDisabled={true}
            value={value}
            id={'DisabledUserComboBox'}
        />
    </ComboBoxGroupRoot>
);
