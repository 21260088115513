import styled from '@emotion/styled';

export const Container = styled('div')(({ theme }) => ({
    backgroundColor: theme.colors.header,
    borderRadius: 'inherit',
    color: '#fff',
    textAlign: 'left',
    alignItems: 'center',
    lineHeight: `{$container-height}`,
    padding: theme.spacing(0, 1, 0, 4),
    fontSize: 17,
    display: 'flex',
    height: 48,

    fontWeight: 700,
    fontFamily: `'Segoe UI', 'Open Sans', 'Helvetica Neue', 'Verdana', 'Arial', 'YU Gothic UI',
    'MS PGothic', 'Hiragino Sans', 'Microsoft YaHei', '微软雅黑', STXihei, '华文细黑', 'sans-serif'`,
}));

export const Header = styled('span')({
    marginRight: 'auto',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
});
