import * as React from "react";
const SvgErrorStateIcon = (props) => /* @__PURE__ */ React.createElement("svg", { id: "Layer_1", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", x: "0px", y: "0px", viewBox: "0 0 90.1 91.7", style: {
  enableBackground: "new 0 0 90.1 91.7"
}, xmlSpace: "preserve", ...props }, /* @__PURE__ */ React.createElement("style", { type: "text/css" }, "\n	.st0{fill:#9B9B9B;}\n"), /* @__PURE__ */ React.createElement("path", { className: "st0", d: "M44,0H0v88h72V28L44,0z M44,6l21.9,22H44V6z M4,84V4h36v28h28v52H4z" }), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("path", { id: "SVGID_1_", d: "M84.6,91.7H55.3c-1.8,0-3.5-1-4.5-2.6c-1.1-1.7-1.2-3.9-0.2-5.8l0,0l14.2-26.4c1-2,3-3.2,5.2-3.2 c2.2,0,4.2,1.2,5.2,3.2l14.2,26.4c0.9,1.8,0.9,4-0.2,5.8C88.1,90.7,86.4,91.7,84.6,91.7z" })), /* @__PURE__ */ React.createElement("use", { xlinkHref: "#SVGID_1_", style: {
  overflow: "visible",
  fill: "#9B9B9B"
} }), /* @__PURE__ */ React.createElement("clipPath", { id: "SVGID_2_" }, /* @__PURE__ */ React.createElement("use", { xlinkHref: "#SVGID_1_", style: {
  overflow: "visible"
} })))), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("path", { id: "SVGID_3_", d: "M53.3,84.3c-0.5,1-0.4,2.1,0.1,3.1c0.4,0.8,1.3,1.3,2.2,1.3h28.7c0.9,0,1.7-0.5,2.2-1.3 c0.6-0.9,0.6-2.1,0.1-3.1l-13.9-26c-0.6-1-1.6-1.6-2.8-1.6c-1.1,0-2.2,0.6-2.8,1.6L53.3,84.3z" })), /* @__PURE__ */ React.createElement("use", { xlinkHref: "#SVGID_3_", style: {
  overflow: "visible",
  fill: "#FA142D"
} }), /* @__PURE__ */ React.createElement("clipPath", { id: "SVGID_4_" }, /* @__PURE__ */ React.createElement("use", { xlinkHref: "#SVGID_3_", style: {
  overflow: "visible"
} })))), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("path", { id: "SVGID_5_", d: "M71.3,76.7l4.3-4.6c0.3-0.4,0.5-0.9,0.3-1.4c-0.1-0.5-0.5-0.9-0.9-1c-0.4-0.1-0.9,0-1.3,0.4l-4.3,4.6 l-4.3-4.6c-0.5-0.5-1.3-0.5-1.8,0c-0.5,0.5-0.5,1.4,0,2l4.3,4.6l-4.3,4.6c-0.3,0.4-0.5,0.9-0.3,1.4s0.5,0.9,0.9,1 c0.4,0.1,0.9,0,1.3-0.4l4.3-4.6l4.3,4.6c0.5,0.5,1.3,0.5,1.8,0c0.5-0.5,0.5-1.4,0-2L71.3,76.7z" })), /* @__PURE__ */ React.createElement("use", { xlinkHref: "#SVGID_5_", style: {
  overflow: "visible",
  fill: "#FFFFFF"
} }), /* @__PURE__ */ React.createElement("clipPath", { id: "SVGID_6_" }, /* @__PURE__ */ React.createElement("use", { xlinkHref: "#SVGID_5_", style: {
  overflow: "visible"
} })))));
export default SvgErrorStateIcon;
