import React from 'react';

import useRelativeTime from 'hooks/useRelativeTime';

export default function CcdRelativeDateCellRenderer(cell) {
    const { relativeTime } = useRelativeTime();
    if (!cell.value) {
        return <></>;
    }
    return <span>{relativeTime({ date: new Date(cell.value) })}</span>;
}
