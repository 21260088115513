import { useContext } from 'react';

import CommandVisibilityContext from '../contexts/CommandVisibilityContext';

export default function useCommandBarVisibilityContext() {
    const context = useContext(CommandVisibilityContext);

    if (context === undefined) {
        throw new Error(
            'useCommandBarVisibilityContext must be used within a CommandBarVisibilityContextProvider'
        );
    }
    return context;
}
