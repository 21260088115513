export const useSettings = () => {
    const storeSetting = (key: string, value: string) => window.localStorage.setItem(key, value);

    const getSetting = (key: string) => window.localStorage.getItem(key);

    return {
        storeSetting,
        getSetting,
    };
};
