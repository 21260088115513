import fetcher from 'providers/api/fetcher';
import { NotificationSettingsResponse } from 'types/notificationSettings';
import { UpdateNotificationSettings } from 'types/notificationSettings';

export const getNotificationsSettings = async (): Promise<NotificationSettingsResponse> => {
    const { data } = await fetcher.get(`/frontend/settings/notification`);
    return data;
};

export const updateNotificationsSettings = async ({
    EmailCheckIn: emailCheckIn,
    EmailCheckOut: emailCheckOut,
}: UpdateNotificationSettings): Promise<NotificationSettingsResponse> => {
    const { data } = await fetcher.put(`/frontend/settings/notification`, {
        emailCheckIn,
        emailCheckOut,
    });
    return data;
};
