import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Fragment, useState } from 'react';

import { useToast } from 'components/_legacy/ccd-toast';
import { useProgressBar } from 'components/atoms/ProgressBar';
import UnlockSuspiciousContentDialog from 'components/organisms/UnlockSuspiciousContentDialog/UnlockSuspiciousContentDialog';
import { useFileManagementContext } from 'components/templates/FileManagement/components/FileManagementContext';
import { FILES_QUERY } from 'components/templates/FileManagement/components/parts/Table/service/files.query';
import CmdMarkAsClean16 from 'images/SetPrIvilegedUser16.svg?react';
import { markAsClean } from 'services/api/markAsClean.service';

import { useCommandBarVisibilityContext } from '../../../../hooks';

export default function useMarkAsClean() {
    const { isMarkAsCleanCommandAccessible } = useCommandBarVisibilityContext();
    const {
        state: { collaborationSpaceId, projectId, selectedFiles },
        dispatch,
    } = useFileManagementContext();
    const [showDialog, setShowDialog] = useState(false);
    const { setProgressBar } = useProgressBar();
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const { mutate } = useMutation(markAsClean, {
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [FILES_QUERY, collaborationSpaceId, projectId],
            });
            dispatch({
                selectedFiles: [],
            });
            setProgressBar(false);
        },
        onError: (e) => {
            console.error(e);
            showToast({
                type: 'error',
                messages: ['Unable to mark file as safe'],
            });
            setProgressBar(false);
        },
    });

    const SuspiciousFileDialog = () => (
        <>
            {showDialog ? (
                <UnlockSuspiciousContentDialog
                    title='Unlock suspicious file?'
                    onClose={() => setShowDialog(false)}
                    onConfirm={() => {
                        mutate({ collaborationSpaceId, projectId, fileId: selectedFiles[0].id });
                        setProgressBar(true);
                        setShowDialog(false);
                    }}
                />
            ) : null}
        </>
    );

    return {
        markAsCleanAnchor: (
            <Fragment key='markAsCleanFragment'>
                <a hidden key='markAsClean' id='markAsClean-input' />
                <SuspiciousFileDialog key='suspiciousFileDialog' />
            </Fragment>
        ),
        markAsClean: {
            id: 'markAsClean',
            children: 'Mark as safe',
            onClick: () => setShowDialog(true),
            leftIcon: CmdMarkAsClean16,
            variant: 'text',
            color: 'gray',
            visible: isMarkAsCleanCommandAccessible,
        },
    };
}
