import { useTheme } from '@emotion/react';
import cx from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

import Box from 'components/atoms/Box/Box';
import Img from 'components/atoms/Img/Img';

import './ccdPopupMenuList.scss';

/**
 * Callback called ond popupMenuList closing
 * the function takes no parameters
 *
 * @callback onCloseCallbackType
 */
type onCloseCallbackType = () => void;

/**
 * Callback called on populating menu list
 *
 * @callback idPopForMenuCallbackType
 * @param name {string} - name displayed in a row
 */
type idPopForMenuCallbackType = (name: string) => any;

export type MenuItems = {
    name: string;
    callback: () => void;
    icon?: string;
};

export type Align = 'center' | 'left' | 'right';

export interface Props extends React.PropsWithChildren {
    menuItems: Array<MenuItems>;
    isActive?: boolean; // Parameter controls visibility of this component. If false component will not render.
    onClose?: onCloseCallbackType; // callback called on popup close.
    direction?: Align; // select direction of drawing component. Default or no value: center. Other options: right, left.
    useTableOffset?: boolean; // add different offset to table tooltip (11px instead of 8px)
    idPopForMenu?: idPopForMenuCallbackType; // Function which should return JSON object with id field
    enableSelection?: boolean; // if true last selection will be highlighted.
    defaultSelection?: string; // if passed value equals to item name on menuItems list this item will be used on initial render
}

export const CcdPopupMenuList = ({
    menuItems,
    isActive,
    onClose,
    direction,
    useTableOffset,
    idPopForMenu,
    defaultSelection,
    enableSelection = false,
}: Props) => {
    const containerRef = useRef(null);
    const [selection, setSelection] = useState(defaultSelection);

    const theme = useTheme();

    useEffect(() => {
        const pageClickEvent = (e: MouseEvent) => {
            if (
                containerRef.current !== null &&
                // @ts-expect-error useRef
                !containerRef.current.contains(e.target)
            ) {
                onClose?.();
            }
        };

        window.addEventListener('click', pageClickEvent);

        return () => {
            window.removeEventListener('click', pageClickEvent);
        };
    }, []);

    if (!isActive) {
        return null;
    }

    return (
        <Box
            as={'nav'}
            css={(props) => ({
                position: 'fixed',
                marginTop: 10,
                backgroundColor: props.colors.background,
                border: `1px solid ${props.colors.tooltip.border}`,
                '&:after': { borderBottomColor: props.colors.background },
                '&:before': { borderBottomColor: props.colors.tooltip.border },
            })}
            ref={containerRef}
            className={cx(
                'ccd-fm-pml',
                direction,
                useTableOffset ? `table-offset-${direction}` : null
            )}
        >
            <ul>
                {menuItems.map((x) => {
                    return (
                        <Box
                            as={'li'}
                            className='ccd-fm-pml__list_item'
                            css={({ colors }) => ({
                                fontWeight: 'normal',
                                padding: '0 4px',
                                fontSize: '0.75rem',
                                alignItems: 'center',
                                height: '24px',
                                whiteSpace: 'nowrap',
                                'span:last-child': {
                                    padding: '0 4px',
                                },
                                ':hover': {
                                    backgroundColor: colors.tooltip.item.hover,
                                },
                            })}
                            {...idPopForMenu?.(x.name)}
                            key={x.name}
                            onClick={() => {
                                x.callback();
                                setSelection(x.name);
                                onClose?.();
                            }}
                            data-testid={'item' + x.name}
                        >
                            {x.icon && (
                                <Box
                                    className={
                                        'd-flex align-items-center justify-content-center ccd-fm-pml__icon'
                                    }
                                    css={{
                                        ...(enableSelection &&
                                            selection === x.name && {
                                                backgroundClip: 'border-box',
                                                backgroundColor: theme.colors.tooltip.item.hover,
                                            }),
                                    }}
                                >
                                    <Img
                                        alt={x.name}
                                        src={x.icon}
                                        css={(props) => ({
                                            filter: props.colors.filterArrowIcon,
                                            height: '16px',
                                            width: 'auto',
                                        })}
                                    />
                                </Box>
                            )}
                            <Box as={'span'}>{x.name}</Box>
                        </Box>
                    );
                })}
            </ul>
        </Box>
    );
};

export default CcdPopupMenuList;
