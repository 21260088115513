import styled from '@emotion/styled';

export const VerticalMenuDivider = styled('div')(({ theme }) => ({
    display: 'inline-flex',
    margin: theme.spacing(1, 1, 0, 1),
    height: 24,
    width: 1,
    backgroundColor: theme.palette.primary.light,
}));

export default VerticalMenuDivider;
