import { Header, Paragraph } from 'components/organisms/DataPrivacy/DataPrivacy.styles';
import appConfig from 'constants/appConfig';

export const DigitalExperienceProgram = () => (
    <>
        <Header>Digital Product Experience</Header>
        <Paragraph>
            To enable users to benefit from our personalized Digital Product Experience the
            collection of personalized data is necessary. The Digital Product Experience is designed
            to enable users to use Siemens Digital Industries Software offerings more effectively by
            providing a personalized user experience, such as getting started training, in-app
            notifications, email reminders, and more. With the digital experience the users will
            realize the value with the solution more efficiently. The Digital Product Experience
            does not contain any marketing outreach.
        </Paragraph>
        <Paragraph>
            You can learn more about our data collection and privacy policies in the{' '}
            <a href={appConfig.TRUST_CENTER} target={'_blank'}>
                Siemens Trust Center
            </a>
            .
        </Paragraph>
        <Paragraph>
            The Digital Product Experience is optional. If you do not want Siemens to collect this
            data and do not want a personalized experience, select Decline below.
        </Paragraph>
    </>
);
