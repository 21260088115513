import { useCallback, useEffect, useMemo, useState } from 'react';

import Box from 'components/atoms/Box';
import { FormButton } from 'components/atoms/Button';

import { AddEditSideNavBody, ContentPanel, UploadLabel } from './ProjectView.styles';
import DragNDropComponent from './components/DragNDropComponent/DragNDropComponent';
import Form from './components/Form';
import { getProjectInputs } from './components/parts/getProjectInputs';
import { EDIT_MODE, NEW_MODE } from './consts';
import useShowFile from './hooks/useShowFile';
import useSubmit from './hooks/useSubmit';

interface Data {
    projectNameLabel: string;
    descriptionLabel: string;
}

export interface ProjectFormViewInterface {
    collaborationSpaceId: string;
    projectId?: string;
    projectName?: string;
    projectDescription?: string;
    thumbnail?: string;
    isInfected?: boolean;
    onSubmitHandler: () => void;
}

export default function ProjectFormView({
    collaborationSpaceId,
    projectId,
    projectName,
    projectDescription,
    thumbnail,
    isInfected,
    onSubmitHandler,
}: ProjectFormViewInterface) {
    const [removed, setRemoved] = useState(false);
    const { getImage, image, loadingError } = useShowFile();
    const { submit } = useSubmit({ collaborationSpaceId, projectId });

    const [buttonDisabled, setButtonDisabled] = useState(false);
    const currentMode = projectName ? EDIT_MODE : NEW_MODE;

    useEffect(() => {
        if (!thumbnail) {
            return;
        }
        try {
            getImage(thumbnail);
        } catch {
            getImage(null);
        }
    }, [thumbnail]);

    const onSubmit = useCallback(
        async (data: Data) => {
            setButtonDisabled(true);

            await submit({
                projectName: data.projectNameLabel,
                projectDescription: data.descriptionLabel,
                thumbnail: { image, removed },
                currentMode,
            });
            setButtonDisabled(false);
            onSubmitHandler();
        },
        [currentMode, submit, image]
    );

    const onDrop = useCallback(
        (e: any) => {
            try {
                getImage(e);
                setRemoved(false);
            } catch {
                getImage(null);
                setRemoved(false);
            }
        },
        [getImage]
    );

    const onClear = useCallback(() => {
        getImage(null);
        setRemoved(true);
    }, [getImage]);

    const currentImageMemo = useMemo(() => {
        return image;
    }, [image]);

    const submitActionName = useMemo(() => {
        if (currentMode === NEW_MODE) {
            return 'Create';
        } else {
            return 'Done';
        }
    }, [currentMode]);

    const inputs = useMemo(() => getProjectInputs(projectName, projectDescription), []);

    return (
        <AddEditSideNavBody>
            <ContentPanel>
                <Form inputs={inputs} id='ccdEditProjectForm' submit={onSubmit} />
                <Box>
                    <UploadLabel>Cover image</UploadLabel>
                    <DragNDropComponent
                        onDrop={onDrop}
                        onClear={onClear}
                        hasAccess={true}
                        id='upload-file-drag-n-drop'
                        currentImage={currentImageMemo}
                        imageError={loadingError}
                        isInfected={!!isInfected}
                    />
                </Box>
            </ContentPanel>

            <FormButton
                id='EditProjectSubmitButton'
                type='submit'
                color={'primary'}
                variant={'contained'}
                form='ccdEditProjectForm'
                disabled={buttonDisabled}
            >
                {submitActionName}
            </FormButton>
        </AddEditSideNavBody>
    );
}
