import styled from '@emotion/styled';
import {
    ComponentPropsWithoutRef,
    FunctionComponent,
    PropsWithChildren,
    SVGProps,
    forwardRef,
} from 'react';

import CcdPopupMenuList, {
    Props as CcdPopupMenuListProps,
} from 'components/_legacy/ccd-popup-menu/components/CcdPopupMenuList';

export interface ButtonProps {
    color?: 'primary' | 'secondary' | 'gray' | 'negative' | 'caution';
    variant?: 'text' | 'outlined' | 'contained';
}

export interface IButton
    extends ButtonProps,
        PropsWithChildren,
        Omit<ComponentPropsWithoutRef<'button'>, 'color'> {
    id?: string;
    leftIcon?: FunctionComponent<SVGProps<SVGSVGElement>>;
    rightIcon?: FunctionComponent<SVGProps<SVGSVGElement>>;
    popupMenu?: CcdPopupMenuListProps;
    'data-for'?: string;
    'data-tip'?: string;
}

const Icon = styled('span')<{
    disabled?: boolean;
    color: 'primary' | 'secondary' | 'gray' | 'negative' | 'caution';
}>(({ disabled, color, theme }) => ({
    display: 'inherit',
    width: '16px',
    color: theme.colors.button[color].textColor,
    ...(disabled && {
        'svg > *': {
            fill: '#1010104D',
        },
    }),
}));

export const StyledButton = styled('button')(
    ({ color = 'primary', variant = 'text' }: ButtonProps) =>
        ({ theme }) => ({
            verticalAlign: 'top',
            minHeight: '26px', //bellow that value button contents won't position correctly
            height: '34px',
            fontSize: '12px',
            fontWeight: 600,
            padding: theme.spacing(1, 2),
            border: 'solid 1px',
            borderRadius: '2px',
            ':not([disabled])': {
                ...(variant === 'text' && {
                    border: 'solid 0',
                    backgroundColor: 'transparent',
                    color: theme.colors.button[color].textColor,
                }),
                ...(variant === 'outlined' && {
                    borderColor: theme.colors.button[color].outlinedBorder,
                    backgroundColor: theme.colors.button[color].outlinedBackground,
                    color: theme.colors.button[color].outlinedColor,
                }),
                ...(variant === 'contained' && {
                    borderColor: theme.colors.button[color].containedBorder,
                    backgroundColor: theme.colors.button[color].containedBackground,
                    color: theme.colors.button[color].containedColor,
                }),
            },
            ':disabled': {
                ...(variant === 'text' && {
                    border: 'none',
                    backgroundColor: 'transparent',
                    cursor: 'not-allowed',
                    color: theme.colors.button[color].disabledTextColor,
                }),
            },
            ':hover:not([disabled])': {
                ...(variant === 'text' && {
                    backgroundColor: theme.colors.button[color].textHover,
                }),
                ...(variant === 'outlined' && {
                    backgroundColor: theme.colors.button[color].outlinedHover,
                }),
                ...(variant === 'contained' && {
                    backgroundColor: theme.colors.button[color].containedHover,
                }),
            },
            ':active:not([disabled])': {
                ...(variant === 'text' && {
                    backgroundColor: theme.colors.button[color].textActive,
                }),
                ...(variant === 'outlined' && {
                    backgroundColor: theme.colors.button[color].outlinedActive,
                }),
                ...(variant === 'contained' && {
                    backgroundColor: theme.colors.button[color].containedActive,
                }),
            },
        })
);

export const DialogButton = styled(StyledButton)(({ theme }) => ({
    height: '32px',
    fontSize: '14px',
    lineHeight: '19px',
    padding: theme.spacing(1, 3),
    margin: theme.spacing(0, 1),
    cursor: 'pointer',
    ':disabled': { cursor: 'not-allowed' },
}));

export const FormButton = styled(StyledButton)(() => ({
    width: '100%',
    height: '32px',
    fontSize: '14px',
    lineHeight: '19px',
    cursor: 'pointer',
    ':disabled': { cursor: 'not-allowed' },
}));

const ChildrenWrapper = styled('span')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '> span': { marginLeft: theme.spacing(2) },
    '> span:first-of-type': { marginLeft: theme.spacing(0) },
}));

const Button = forwardRef<HTMLButtonElement, IButton>((props, ref) => {
    const {
        id,
        rightIcon: EndIconProp,
        leftIcon: StartIconProp,
        children,
        variant = 'outlined',
        color = 'primary',
        disabled,
        popupMenu,
        ...other
    } = props;

    return (
        <StyledButton
            data-testid={id}
            variant={variant}
            color={color}
            disabled={disabled}
            ref={ref}
            {...other}
        >
            <ChildrenWrapper>
                {StartIconProp && (
                    <Icon disabled={disabled} color={color}>
                        <StartIconProp />
                    </Icon>
                )}
                {children && <span>{children}</span>}
                {EndIconProp && (
                    <Icon disabled={disabled} color={color}>
                        <EndIconProp />
                    </Icon>
                )}
            </ChildrenWrapper>
            {popupMenu && <CcdPopupMenuList {...popupMenu} />}
        </StyledButton>
    );
});

export default Button;
