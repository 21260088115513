import styled from '@emotion/styled';

import Button from 'components/atoms/Button';

export const ProjectVersionButton = styled(Button)({
    padding: '4px',
    height: '20px',
    minHeight: '20px',
    paddingTop: 0,
    paddingBottom: 0,
    fontSize: '10px',
});
