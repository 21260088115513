import styled from '@emotion/styled';

import Box from 'components/atoms/Box';

const ProjectNameLink = styled(Box)({
    fontSize: 12,
    fontWeight: 600,
    width: '100%',
    overflow: 'hidden',
    wordBreak: 'break-word',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
});

export default ProjectNameLink;
