import { useCallback } from 'react';

import { CcdTileGrid } from 'components/_legacy/ccd-tile-grid';
import { FileTile } from 'components/atoms/FileTile';
import { useFileManagementContext } from 'components/templates/FileManagement/components/FileManagementContext';
import { isScanStatusInfected } from 'shared/utility';

import { isItemOpenable } from '../../../../utils';
import { useListItem } from '../../Table';
import FileTileThumbnail from './FileTileThumbnail';
import CcdFileUploadStaticFile from './StaticTiles/FileUploadStaticTile/CcdFileUploadStaticFile';

export default function TileDisplay({ data }) {
    const {
        state: { selectedFiles, currentFolder },
        dispatch,
    } = useFileManagementContext();

    const { selectionHandler, onFileClickHandler } = useListItem(currentFolder, dispatch);
    const forcedSelection = {
        enabled: true,
        elements: selectedFiles,
        comparisonFunc: (selectedElement, record) => selectedElement.id === record.original.id,
    };

    const settings = {
        multiselection: true,
    };

    const handleOpen = useCallback(
        (event, record) => {
            event.stopPropagation();
            onFileClickHandler(record);
        },
        [currentFolder]
    );

    const tileFactory = (record) => {
        return (
            <FileTile
                name={record.name}
                action={record.isFolder ? 'Created' : 'Uploaded'}
                creationDate={new Date(record.creationDate)}
                createdBy={record.createdBy}
                onOpen={(event) => {
                    handleOpen(event, record);
                }}
                openEnable={isItemOpenable(record)}
                isInfected={isScanStatusInfected(record.contentScanInfo)}
            >
                <FileTileThumbnail record={record} />
            </FileTile>
        );
    };

    return (
        <CcdTileGrid
            data={data}
            tileFactory={tileFactory}
            staticTiles={[<CcdFileUploadStaticFile key='s1' />]}
            onSelected={selectionHandler}
            settings={settings}
            forcedSelection={forcedSelection}
        />
    );
}
