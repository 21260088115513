import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { redirectToLogout } from 'providers/authorization/AuthProvider';
import { PermissionType, getPermissions } from 'providers/permissions/permission.sevice';

const commonPermissionQueryConfiguration = {
    staleTime: 6 * 60 * 1000,
    cacheTime: 6 * 60 * 1000,
    retry: (_: number, err: AxiosError) => {
        return ![401, 403, 400].includes(err.response?.status ?? 0);
    },
};
export function useProjectPermissionQuery(
    collaborationSpaceId: string,
    projectId: string | undefined
) {
    return useQuery({
        ...commonPermissionQueryConfiguration,
        queryKey: ['projectPermissions', collaborationSpaceId, projectId],
        queryFn: () =>
            getPermissions(PermissionType.Project, `${collaborationSpaceId}/${projectId}`),
        enabled: !!(collaborationSpaceId && projectId),
        onError: (err: AxiosError) => {
            if (err.response?.status === 401) {
                redirectToLogout();
            }
        },
    });
}

export function useCollaborationSpacePermissionQuery(collaborationSpaceId: string) {
    return useQuery({
        ...commonPermissionQueryConfiguration,
        queryKey: ['collaborationSpacePermissions', collaborationSpaceId],
        queryFn: () => getPermissions(PermissionType.CollaborationSpace, collaborationSpaceId),
        enabled: !!collaborationSpaceId,
        onError: (err: AxiosError) => {
            if (err.response?.status === 401) {
                redirectToLogout();
            }
        },
    });
}
