import React from 'react';

import './ccdToastContent.scss';

export default function CcdToastContent({ type, title, messages }) {
    const renderMultipleMessages = (messageArray) => {
        return messageArray.map((msg, index) => {
            return (
                <p className='notification-message-line' key={index}>
                    {msg}
                </p>
            );
        });
    };
    return (
        <div className='notification-content'>
            {title && (
                <div className={messages ? 'notification-header-container' : ''}>
                    <h2 className={`notification-header notification-header-${type}`}>{title}</h2>
                </div>
            )}
            <div>{messages && renderMultipleMessages(messages)}</div>
        </div>
    );
}
