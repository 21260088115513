import styled from '@emotion/styled';
import * as Dialog from '@radix-ui/react-dialog';

export const DialogOverlay = styled(Dialog.Overlay)({
    backgroundColor: '#000000BF',
    position: 'fixed',
    zIndex: 2000,
    inset: 0,
});

export const DialogContent = styled(Dialog.Content)(({ theme }) => ({
    background: theme.colors.background,
    boxShadow: '0px 2px 4px 1px rgba(0, 0, 0, 0.3)',
    borderRadius: 3,
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '90vw',
    minWidth: '420px',
    lineHeight: 1.5,
    fontSize: 12,
    zIndex: 2000,
}));

export const DialogTitle = styled(Dialog.Title)(({ theme }) => ({
    margin: 0,
    fontWeight: 700,
    color: theme.colors.headerText,

    fontSize: 17,
    height: 48,

    padding: theme.spacing(3, 4, 3, 4),
}));

export const DialogHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    borderRadius: '3px 3px 0 0',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.colors.header,
}));

export const DialogChildren = styled('div')(({ theme }) => ({
    margin: theme.spacing(4),
}));

export const DialogClose = styled(Dialog.Close)(({ theme }) => ({
    margin: theme.spacing(1, 2, 0, 0),
}));

export const DialogButtons = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    margin: theme.spacing(4),
    lineHeight: 1.5,
}));
