import { useMutation } from '@tanstack/react-query';

import useMarkAllAsReadApi from 'components/templates/NotificationPanel/services/api/markAllAsRead.api';

import useOptimisticUpdate from './optimisticUpdate';

const useMarkAllAsRead = () => {
    const { onMutate, onError } = useOptimisticUpdate();
    return useMutation({
        mutationFn: useMarkAllAsReadApi,
        onMutate: () =>
            onMutate((cache) => {
                return cache.map((item) => ({ ...item, IsRead: true }));
            }),
        onError: onError,
    });
};

export default useMarkAllAsRead;
