import Box from 'components/atoms/Box';

import '../VersionItem/VersionItem.scss';

export default function WaitingState({ info }) {
    return (
        <Box
            id='waitingstate'
            css={({ colors }) => ({
                height: 'fit-content !important',
                fontSize: '12px',
                lineHeight: '21px',
                color: colors.text,
                display: 'flex',
                pointerEvents: 'none',
            })}
        >
            <div>{info}</div>
        </Box>
    );
}
