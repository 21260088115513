import React from 'react';

import { CcdEmptyState } from 'components/_legacy/ccd-empty-state';
import EmptyStateImage from 'images/Empty-State-icon.svg?react';

export const EmptyState = () => {
    return (
        <CcdEmptyState
            message='There is no project data here yet'
            subMessage='Upload a design in PADS Pro first'
            icon={EmptyStateImage}
            subMessageRenderer={undefined}
            id='DesignViewEmptyState'
        />
    );
};
