import React from 'react';
import ReactDOM from 'react-dom';

import './ModalBackdrop.css';

export default function ModalBackdrop({
    rootElement,
    onClose,
}: {
    rootElement: Document;
    onClose?: () => void;
}) {
    const backdropContainer = rootElement.getElementById('backdrop-container');

    if (backdropContainer === null) {
        console.error('backdrop-container id does not exists');
        return null;
    }

    return ReactDOM.createPortal(
        <div className='toast-backdrop' onClick={onClose} />,
        backdropContainer
    );
}
