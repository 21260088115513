import { useQuery } from '@tanstack/react-query';

import { getThemeSettings } from 'services/api/theme.service';
import useFeatureFlagsQuery from 'services/queries/featuresFlags.query';
import { GetThemeSettingsResponse } from 'types/themeSettings';

export const THEME_QUERY = 'getThemeSettings';

export const useThemeSettingsQuery = () => {
    const { data } = useFeatureFlagsQuery();
    return useQuery<GetThemeSettingsResponse>([THEME_QUERY], () => getThemeSettings(), {
        enabled: data?.Frontend_Theme ?? false,
    });
};
