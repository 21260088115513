import { useEffect } from 'react';

import { useGoogleTagManagerContext } from 'providers/googleTagManager/GoogleTagManager';
import featuresFlagsQuery from 'services/queries/featuresFlags.query';

export enum CookiePermission {
    REQUIRED = 'reqd',
    MARKETING = 'targ',
    PERFORMANCE = 'perf',
    FUNCTIONAL = 'fnct',
}

enum CookieConfirmation {
    SAVE = 'SAVE',
    ACCEPT_ALL = 'ACCEPT_ALL',
    DENY_ALL = 'DENY_ALL',
}

const useCookieLayer = () => {
    const { addToUserDataLayer, isInitialized } = useGoogleTagManagerContext();
    const { data } = featuresFlagsQuery();
    const UC_UI = window.UC_UI;
    const cookieMonster = window.cookieMonster;

    const showFirstLayer = () => {
        UC_UI?.showFirstLayer();
    };

    const showSecondLayer = () => {
        UC_UI?.showSecondLayer();
    };

    const updateGTM = () => {
        if (!cookieMonster) {
            return;
        }
        const value = {
            required: cookieMonster.permitted(CookiePermission.REQUIRED),
            marketing: cookieMonster.permitted(CookiePermission.MARKETING),
            performance: cookieMonster.permitted(CookiePermission.PERFORMANCE),
            functional: cookieMonster.permitted(CookiePermission.FUNCTIONAL),
        };
        addToUserDataLayer('cookie_consent_layer', value);
    };

    useEffect(() => {
        const callback = (event: Event) => {
            const customEvent = event as CustomEvent<{ type: string }>;
            const cookieConfirmation: CookieConfirmation | undefined =
                CookieConfirmation[customEvent.detail.type as CookieConfirmation];
            if (cookieConfirmation && isInitialized) {
                updateGTM();
            }
        };
        window.addEventListener('UC_UI_CMP_EVENT', callback);
        return () => {
            window.removeEventListener('UC_UI_CMP_EVENT', callback);
        };
    }, [data, isInitialized]);

    const hasInteracted = cookieMonster?.hasInteracted();

    return {
        showFirstLayer,
        showSecondLayer,
        updateGTM,
        hasInteracted,
    };
};

export default useCookieLayer;
