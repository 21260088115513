import styled from '@emotion/styled';

import { customColors } from 'theme';

export const CcdEventDescriptionWrapper = styled('div')(({ theme }) => ({
    fontSize: '12px',
    marginTop: theme.spacing(1),
}));

export const CcdEventDescriptionSeparator = styled('span')(({ theme }) => ({
    margin: theme.spacing(0, 1),
    borderLeft: `1px solid ${customColors['pl-black-16']}`,
}));
