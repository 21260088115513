import styled from '@emotion/styled';

import InfoIcon from 'images/indicatorInformationSuccess16.svg?react';

import { GuidanceMessageBox, Info, Link } from '../GuidanceMessage/GuidanceMessage.styles';

export const OpenGuidanceMessageBox = styled(GuidanceMessageBox)({
    background: '#EDFBF5',
    border: 'solid #84E3B9 1px',
});

export const ReadyIcon = styled(InfoIcon)(({ theme }) => ({
    width: '16px',
    height: '16px',
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(2),
}));

export const OpenGuidanceMessageInfo = styled(Info)({
    color: 'black',
});

export const OpenGuidanceMessageLink = styled(Link)({
    color: '#007BFF',
});
