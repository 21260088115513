import styled from '@emotion/styled';

import InfoIcon from 'images/indicatorInformationGeneral16.svg?react';
import { customColors } from 'theme';

import { GuidanceMessageBox, Info } from '../GuidanceMessage/GuidanceMessage.styles';

export const InfoGuidanceMessageBox = styled(GuidanceMessageBox)({
    background: customColors['blue-24'],
    border: 'solid #71CCE0 1px',
});

export const InformationIcon = styled(InfoIcon)(({ theme }) => ({
    width: '16px',
    height: '16px',
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(2),
}));

export const InfoGuidanceMessageInfo = styled(Info)({
    color: customColors['blue-7'],
});
