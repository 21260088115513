import { useTheme } from '@emotion/react';
import React from 'react';

import Box from 'components/atoms/Box';

export default function ProjectHistoryTitle() {
    const theme = useTheme();

    return (
        <Box
            data-testid='project-history-title'
            css={{
                verticalAlign: 'middle',
                height: '48px',
                fontWeight: '600',
                fontSize: '18px',
                lineHeight: '24px',
                color: theme.colors.text,
                width: '100%',
                paddingLeft: '32px',
                paddingTop: '16px',
            }}
        >
            Project History
        </Box>
    );
}
