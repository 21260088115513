import { useQuery } from '@tanstack/react-query';

import { FilterFunction } from 'components/templates/NotificationPanel/components/DrawerContent/DrawerContent';

import useNotificationApi from '../../services/api/notification.api';

export const LATEST_NOTIFICATION = 'latestNotifications';

const useNotification = (select?: FilterFunction) => {
    const getNotifications = useNotificationApi();

    return useQuery([LATEST_NOTIFICATION], getNotifications, {
        refetchInterval: 60000,
        cacheTime: 1000,
        select,
    });
};

export default useNotification;
