import React, { MouseEventHandler, useCallback, useRef } from 'react';

import Box from 'components/atoms/Box';
import { TileColor, TileContainer } from 'components/atoms/TileContainer';
import { ProjectTitleActions, TileProjectLink } from 'components/atoms/TileProjectLink';
import { TileProjectDescription } from 'components/molecules/TileProjectDescription';
import CloseIcon from 'images/cmdClose16.svg?react';
import DefaultThumbnail from 'images/defaultRevisionThumbnail.svg?react';

import { ActionTileHeader } from '../../atoms/ActionTileHeader';
import { EventDescription } from '../../atoms/EventDescription';

export type OnCLose = MouseEventHandler<SVGSVGElement>;

export interface CcdRevisionTileProps {
    title: string;
    description?: string;
    disableDescription?: boolean;
    projectTitle: string;
    eventDescription: string;
    creator: string;
    thumbnailUrl?: string;
    onProjectTitle?: ProjectTitleActions;
    onClose?: OnCLose;
    onResize?: (height: number) => void;
    color?: TileColor;
    id?: string;
    read?: boolean;
    markAsRead?: () => void;
    revisionNumber?: number;
}

export const CloseRevisionTileId = 'CloseRevisionTile';
export default function CcdRevisionTile({
    color = TileColor.GREEN,
    title,
    description,
    disableDescription = false,
    projectTitle,
    eventDescription,
    creator,
    thumbnailUrl,
    onClose,
    onResize,
    onProjectTitle,
    read = true,
    markAsRead,
    revisionNumber,
}: CcdRevisionTileProps) {
    const elementRef = useRef<HTMLDivElement>(null);

    const onResizeCallback = useCallback(() => {
        if (onResize && elementRef.current) {
            onResize(elementRef.current.clientHeight);
        }
    }, [onResize, elementRef]);

    return (
        <Box ref={elementRef} data-testid='revision-tile' className='revision-tile'>
            <TileContainer tileColor={color} read={read} onClick={markAsRead}>
                <>
                    {onClose && (
                        <CloseIcon
                            onClick={onClose}
                            css={{
                                float: 'right',
                                height: '12px',
                                width: '12px',
                                cursor: 'pointer',
                            }}
                            data-testid={CloseRevisionTileId}
                        />
                    )}
                    <ActionTileHeader
                        header={projectTitle}
                        thumbnailSrc={thumbnailUrl}
                        DefaultThumbnailSrc={DefaultThumbnail}
                        thumbnailProperties={{ width: 75 }}
                    />
                    {disableDescription ? (
                        <Box css={{ height: 16 }} />
                    ) : (
                        <TileProjectDescription
                            description={description}
                            onToggle={onResizeCallback}
                        />
                    )}
                    <TileProjectLink
                        title={title}
                        onProjectTitle={onProjectTitle}
                        revisionNumber={revisionNumber}
                    />
                    <EventDescription eventDescription={eventDescription} creator={creator} />
                </>
            </TileContainer>
        </Box>
    );
}
