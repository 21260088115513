import useCcdFetch from 'components/_legacy/hooks/useCcdFetch';

import { DATA_LOADING_MAX_REQUEST_ATTEMPTS, DATA_LOADING_TIME_DELAY_IN_MILLIS } from '../consts';

export function useWaitForFolderInTheLoop() {
    async function waitForFolderInTheLoop({
        collaborationSpaceId,
        projectId,
        backendUrl,
        folderId,
    }) {
        const { ccdFetch } = useCcdFetch();

        const url = new URL(
            `frontend/file-management/${collaborationSpaceId}/${projectId}/files?folderId=${folderId}`,
            backendUrl
        );

        const requestConfig = {
            method: 'GET',
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json',
            },
            retries: DATA_LOADING_MAX_REQUEST_ATTEMPTS,
            retryDelay: DATA_LOADING_TIME_DELAY_IN_MILLIS,
            retryOn: [404],
        };

        return ccdFetch(url.toString(), requestConfig);
    }

    return { waitForFolderInTheLoop };
}
