import fetcher from 'providers/api/fetcher';
import { AuroraOptOut } from 'types/optOut';

export const getAuroraOptOut = async (): Promise<AuroraOptOut> => {
    const { data } = await fetcher.get(`/frontend/settings/analytics/aurora-opt-out`);
    return data;
};

export const updateAuroraOptOut = async ({ pepOptOut, dpeOptOut }: AuroraOptOut) => {
    await fetcher.put(`/frontend/settings/analytics/aurora-opt-out`, {
        pepOptOut,
        dpeOptOut,
    });
};
