import * as React from "react";
const SvgType3D48 = (props) => /* @__PURE__ */ React.createElement("svg", { id: "Layer_1", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", x: "0px", y: "0px", viewBox: "0 0 48 48", style: {
  enableBackground: "new 0 0 48 48"
}, xmlSpace: "preserve", ...props }, /* @__PURE__ */ React.createElement("style", { type: "text/css" }, "\n	.st0{fill:url(#SVGID_1_);}\n	.st1{fill:#464646;}\n	.st2{fill:url(#SVGID_00000082367216695652668970000008406800376874408579_);}\n	.st3{fill:url(#SVGID_00000098925142732943463410000017286174555129802660_);}\n	.st4{fill:#FFFFFF;}\n"), /* @__PURE__ */ React.createElement("g", { id: "XCC-Viewer-with-Notes-v4" }, /* @__PURE__ */ React.createElement("g", { id: "Group-3", transform: "translate(-330.000000, -344.000000)" }, /* @__PURE__ */ React.createElement("g", { transform: "translate(80.000000, 284.000000)" }, /* @__PURE__ */ React.createElement("g", { id: "type3D48", transform: "translate(251.000000, 61.000000)" }, /* @__PURE__ */ React.createElement("g", { id: "polygon13" }, /* @__PURE__ */ React.createElement("linearGradient", { id: "SVGID_1_", gradientUnits: "userSpaceOnUse", x1: -259.1046, y1: 427.0693, x2: -257.848, y2: 425.4286, gradientTransform: "matrix(18.383 0 0 -24 4777.2231 10256.0674)" }, /* @__PURE__ */ React.createElement("stop", { offset: 0, style: {
  stopColor: "#FFFFFF"
} }), /* @__PURE__ */ React.createElement("stop", { offset: 0.232, style: {
  stopColor: "#FAFAFA"
} }), /* @__PURE__ */ React.createElement("stop", { offset: 0.496, style: {
  stopColor: "#EDEDED"
} }), /* @__PURE__ */ React.createElement("stop", { offset: 0.775, style: {
  stopColor: "#D6D6D6"
} }), /* @__PURE__ */ React.createElement("stop", { offset: 1, style: {
  stopColor: "#BEBEBE"
} })), /* @__PURE__ */ React.createElement("polygon", { className: "st0", points: "10.5,45.4 10.5,0.6 32,0.6 44.4,12.9 44.4,45.4  " }), /* @__PURE__ */ React.createElement("path", { className: "st1", d: "M31.6,1.6l11.8,11.8v31.1H11.5V1.6H31.6 M32.5-0.4H9.5v46.9h35.9V12.5L32.5-0.4L32.5-0.4z" })), /* @__PURE__ */ React.createElement("g", { id: "polygon26" }, /* @__PURE__ */ React.createElement("linearGradient", { id: "SVGID_00000132785110309533994360000007361417633301319614_", gradientUnits: "userSpaceOnUse", x1: -231.2729, y1: 383.3836, x2: -229.7146, y2: 381.8253, gradientTransform: "matrix(6.6383 0 0 -6.6383 1565.0532 2549.5847)" }, /* @__PURE__ */ React.createElement("stop", { offset: 0, style: {
  stopColor: "#FFFFFF"
} }), /* @__PURE__ */ React.createElement("stop", { offset: 0.221, style: {
  stopColor: "#F8F8F8"
} }), /* @__PURE__ */ React.createElement("stop", { offset: 0.541, style: {
  stopColor: "#E5E5E5"
} }), /* @__PURE__ */ React.createElement("stop", { offset: 0.92, style: {
  stopColor: "#C6C6C6"
} }), /* @__PURE__ */ React.createElement("stop", { offset: 1, style: {
  stopColor: "#BEBEBE"
} })), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "url(#SVGID_00000132785110309533994360000007361417633301319614_)"
}, points: "32.4,12.3 32.4,2 42.7,12.3  " }), /* @__PURE__ */ React.createElement("path", { className: "st1", d: "M33.4,4.4l6.9,6.9h-6.9V4.4 M31.4-0.4v13.8h13.8L31.4-0.4L31.4-0.4z" })), /* @__PURE__ */ React.createElement("g", { id: "rect45" }, /* @__PURE__ */ React.createElement("linearGradient", { id: "SVGID_00000160886070294238741520000001541427738494814142_", gradientUnits: "userSpaceOnUse", x1: -255.6434, y1: 392.4703, x2: -253.3779, y2: 391.2203, gradientTransform: "matrix(14.8085 0 0 -8.1702 3791.4363 3231.1003)" }, /* @__PURE__ */ React.createElement("stop", { offset: 0, style: {
  stopColor: "#73B4C8"
} }), /* @__PURE__ */ React.createElement("stop", { offset: 0.175, style: {
  stopColor: "#6AAEC3"
} }), /* @__PURE__ */ React.createElement("stop", { offset: 0.457, style: {
  stopColor: "#529EB5"
} }), /* @__PURE__ */ React.createElement("stop", { offset: 0.809, style: {
  stopColor: "#2C839F"
} }), /* @__PURE__ */ React.createElement("stop", { offset: 1, style: {
  stopColor: "#147391"
} })), /* @__PURE__ */ React.createElement("rect", { x: 0.6, y: 18, style: {
  fill: "url(#SVGID_00000160886070294238741520000001541427738494814142_)"
}, width: 43.8, height: 23.3 }), /* @__PURE__ */ React.createElement("path", { className: "st1", d: "M43.4,19v21.3H1.6V19H43.4 M45.4,17H-0.4v25.3h45.8V17L45.4,17z" })), /* @__PURE__ */ React.createElement("g", { id: "text870-9", transform: "translate(7.768340, 2.822716)" }, /* @__PURE__ */ React.createElement("path", { id: "path849", className: "st4", d: "M27.9,26.8c0,1.3-0.3,2.5-0.8,3.6c-0.5,1-1.2,1.8-2.1,2.5c-0.6,0.4-1.3,0.7-2.1,0.9 c-0.9,0.2-1.8,0.3-2.7,0.3h-4.5V19.6h4.7c0.9,0,1.8,0.1,2.7,0.3c0.7,0.2,1.3,0.4,1.9,0.8c0.9,0.6,1.6,1.5,2.1,2.5 C27.6,24.3,27.9,25.6,27.9,26.8L27.9,26.8z M24.4,26.8c0-0.8-0.1-1.7-0.5-2.4c-0.5-1-1.4-1.8-2.5-2c-0.5-0.1-1.1-0.1-1.6-0.1 h-0.8v9.1h0.8c0.6,0,1.2,0,1.7-0.1c0.4-0.1,0.7-0.2,1.1-0.4c0.6-0.4,1-0.9,1.3-1.6C24.3,28.5,24.5,27.6,24.4,26.8L24.4,26.8z" })), /* @__PURE__ */ React.createElement("g", { id: "text870-2", transform: "translate(2.571064, 2.714208)" }, /* @__PURE__ */ React.createElement("path", { id: "path852", className: "st4", d: "M16.7,27.3c0.3,0.3,0.5,0.6,0.7,0.9c0.2,0.5,0.3,0.9,0.3,1.4c0,0.6-0.1,1.3-0.4,1.9 c-0.2,0.6-0.6,1.1-1.1,1.5c-0.5,0.4-1.1,0.7-1.7,0.9c-0.8,0.2-1.6,0.3-2.4,0.3c-0.9,0-1.8-0.1-2.7-0.3 c-0.6-0.1-1.3-0.4-1.9-0.6v-3.2h0.3C8.4,30.7,9,31,9.6,31.2c0.6,0.2,1.2,0.4,1.9,0.4c0.4,0,0.7,0,1.1-0.1 c0.4-0.1,0.7-0.2,1-0.4c0.2-0.2,0.4-0.4,0.5-0.6c0.1-0.3,0.2-0.7,0.2-1c0-0.3-0.1-0.7-0.3-1c-0.2-0.2-0.4-0.4-0.7-0.5 c-0.3-0.1-0.7-0.2-1-0.2c-0.4,0-0.8,0-1.1,0h-0.7v-2.6h0.7c0.4,0,0.8,0,1.2,0c0.3,0,0.6-0.1,0.9-0.2c0.2-0.1,0.4-0.3,0.6-0.5 c0.1-0.3,0.2-0.6,0.2-0.9c0-0.2-0.1-0.5-0.2-0.7c-0.1-0.2-0.3-0.3-0.5-0.4c-0.2-0.1-0.5-0.2-0.8-0.2c-0.3,0-0.5-0.1-0.8-0.1 c-0.6,0-1.2,0.1-1.8,0.3c-0.7,0.2-1.3,0.5-1.8,0.9H7.8v-3.1c0.6-0.3,1.3-0.5,1.9-0.6c0.8-0.2,1.6-0.3,2.5-0.3 c0.7,0,1.4,0.1,2.1,0.2c0.5,0.1,1,0.3,1.5,0.6c0.4,0.3,0.8,0.7,1.1,1.2c0.2,0.5,0.4,1,0.3,1.6c0,0.8-0.2,1.5-0.7,2.1 c-0.4,0.6-1,1-1.8,1.2v0.1c0.3,0.1,0.6,0.1,0.9,0.3C16.1,26.9,16.4,27.1,16.7,27.3L16.7,27.3z" })))))));
export default SvgType3D48;
