import styled, { CSSObject } from '@emotion/styled';
import { Box } from '@mui/material';
import { PropsWithChildren } from 'react';

import { customColors } from 'theme';

interface ILabel extends PropsWithChildren {
    required?: boolean;
    style?: CSSObject;
}

const Required = styled('span')(({ theme }) => ({
    marginRight: theme.spacing(1),
    color: customColors['blue-7'],
}));

const Label = ({ children, required, style }: ILabel) => {
    return (
        <Box css={style}>
            {required && <Required>*</Required>}
            <span style={{ fontWeight: 600 }}>{children}</span>
        </Box>
    );
};

export default Label;
