import { CloseButton, Message } from '../GuidanceMessage/GuidanceMessage.styles';
import {
    OpenGuidanceMessageBox,
    OpenGuidanceMessageInfo,
    OpenGuidanceMessageLink,
    ReadyIcon,
} from './OpenGuidanceMessage.styles';

export interface OpenGuidanceMessageInterface {
    info?: string;
    message?: string;
    linkMessage?: string;
    onClose?: () => void;
    onAction?: () => void;
}

const OpenGuidanceMessage = ({
    onClose,
    onAction,
    info,
    message,
    linkMessage,
}: OpenGuidanceMessageInterface) => {
    return (
        <OpenGuidanceMessageBox>
            <div style={{ display: 'inline-flex' }}>
                <ReadyIcon />

                <OpenGuidanceMessageInfo>{info}</OpenGuidanceMessageInfo>
                <Message>{message}</Message>
                {linkMessage ? (
                    <OpenGuidanceMessageLink onClick={onAction}>
                        {linkMessage}
                    </OpenGuidanceMessageLink>
                ) : null}
            </div>
            <CloseButton onClick={onClose} />
        </OpenGuidanceMessageBox>
    );
};

export default OpenGuidanceMessage;
