import fetcher from 'providers/api/fetcher';

export type MarkAsCleanRequest = {
    collaborationSpaceId: string;
    projectId: string;
    fileId: string;
};

export type MarkAsCleanResponse = {
    status: number;
};

export const markAsClean = async ({
    collaborationSpaceId,
    projectId,
    fileId,
}: MarkAsCleanRequest): Promise<MarkAsCleanResponse> => {
    const { status } = await fetcher.post(
        `frontend/file-management/${collaborationSpaceId}/${projectId}/document/${fileId}/mark-clean`
    );
    return { status };
};
