import { Query, useMutation, useQueries } from 'react-query-v5';

import { XccCompareProcessing } from 'hooks/useXccCompareService';
import fetcher from 'providers/api/fetcher';

export const XccCompareQuery = 'xccCompareQuery';

export enum DesignType {
    SCHEMATIC = 'Schematic',
    LAYOUT = 'Layout',
}

const getXccAccessKey = () => window.localStorage.getItem('xcc-access-key');

export enum DesignInCacheStatus {
    InCache = 'InCache',
    NotInCache = 'NotInCache',
    NotFound = 'NotFound',
}

interface XccCompareStatusResponse {
    DesignInCacheStatus: 'InCache' | 'NotInCache' | 'NotFound';
    DesignVersionId: string;
}

export interface XccSimple {
    collaborationSpaceId: string;
    version: string;
    designType: DesignType;
    projectId: string;
}

export interface XccCompareStatusResponseMapped extends XccSimple {
    designInCacheStatus: DesignInCacheStatus;
    product: string;
}

export interface XccCompare extends XccSimple {
    projectName?: string;
}

export const mapStringToDesignType = (designType: string): DesignType => {
    switch (designType.toLowerCase()) {
        case 'schematic':
            return DesignType.SCHEMATIC;
        case 'layout':
            return DesignType.LAYOUT;
        default:
            throw new Error(designType);
    }
};

function XccCompare(
    collaborationSpaceId: string,
    projectId: string | undefined,
    revisionId: string | undefined,
    designType: DesignType | undefined
) {
    return async (): Promise<XccCompareStatusResponseMapped> => {
        const { data }: { data: XccCompareStatusResponse } = await fetcher(
            `frontend/v1/xcc/cache/status/${collaborationSpaceId}/${projectId}/${revisionId}/${designType}`,
            {
                headers: {
                    'X-Xcc-Temp-Data-Header': getXccAccessKey(),
                },
            }
        );
        const designVersionId = data.DesignVersionId.split('_');
        const designInCacheStatus = DesignInCacheStatus[data.DesignInCacheStatus];
        if (designInCacheStatus === undefined) {
            throw new Error(`Unknown design status ${data.DesignInCacheStatus}`);
        }
        return {
            designInCacheStatus: designInCacheStatus,
            product: designVersionId[0],
            collaborationSpaceId: designVersionId[1],
            projectId: designVersionId[2],
            designType: mapStringToDesignType(designVersionId[3]),
            version: designVersionId[4],
        };
    };
}

export const useXccCompareQueries = (xccCompareArr: XccCompareProcessing[]) => {
    return useQueries({
        queries: xccCompareArr.map((xccCompare) => {
            const { projectId, collaborationSpaceId, version, designType, processing } = xccCompare;
            return {
                queryKey: [XccCompareQuery, projectId, collaborationSpaceId, version, designType],
                queryFn: XccCompare(collaborationSpaceId, projectId, version, designType),
                refetchInterval: (data: Query<XccCompareStatusResponseMapped>) => {
                    return (
                        processing &&
                        data.state.data?.designInCacheStatus === DesignInCacheStatus.NotInCache &&
                        5000
                    );
                },
            };
        }),
        combine: (data) => {
            return {
                data: data,
            };
        },
    });
};

export const useXccPopulateMutation = () => {
    return useMutation({
        mutationFn: async ({
            version,
            designType,
            projectId,
            collaborationSpaceId,
        }: XccCompare) => {
            return fetcher.put(
                `frontend/v1/xcc/cache/${collaborationSpaceId}/${projectId}/${version}/${designType}`,
                null,
                {
                    headers: {
                        'X-Xcc-Temp-Data-Header': getXccAccessKey(),
                    },
                }
            );
        },
    });
};
