import * as React from "react";
const SvgTypePdf48 = (props) => /* @__PURE__ */ React.createElement("svg", { id: "Layer_1", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", x: "0px", y: "0px", viewBox: "0 0 25 26", style: {
  enableBackground: "new 0 0 25 26"
}, xmlSpace: "preserve", ...props }, /* @__PURE__ */ React.createElement("style", { type: "text/css" }, "\n	.st0{fill:url(#Path_6_);stroke:#464646;}\n	.st1{fill:url(#Path_7_);stroke:#464646;stroke-linejoin:round;}\n	.st2{fill:none;stroke:#5A5A5A;}\n	.st3{fill:url(#Rectangle_1_);stroke:#464646;}\n	.st4{fill:#FFFFFF;}\n"), /* @__PURE__ */ React.createElement("g", { id: "_x2D_" }, /* @__PURE__ */ React.createElement("g", { id: "File-Manager---Tile-view-_x26_-Folder-Options-Copy", transform: "translate(-129.000000, -446.000000)" }, /* @__PURE__ */ React.createElement("g", { id: "typePdf48", transform: "translate(130.000000, 447.000000)" }, /* @__PURE__ */ React.createElement("linearGradient", { id: "Path_6_", gradientUnits: "userSpaceOnUse", x1: -270.3877, y1: 415.7623, x2: -269.7113, y2: 414.8794, gradientTransform: "matrix(18.383 0 0 -24 4977.1274 9981.3604)" }, /* @__PURE__ */ React.createElement("stop", { offset: 0, style: {
  stopColor: "#FFFFFF"
} }), /* @__PURE__ */ React.createElement("stop", { offset: 0.2323, style: {
  stopColor: "#FAFAFA"
} }), /* @__PURE__ */ React.createElement("stop", { offset: 0.4964, style: {
  stopColor: "#EDEDED"
} }), /* @__PURE__ */ React.createElement("stop", { offset: 0.775, style: {
  stopColor: "#D6D6D6"
} }), /* @__PURE__ */ React.createElement("stop", { offset: 1, style: {
  stopColor: "#BEBEBE"
} })), /* @__PURE__ */ React.createElement("polygon", { id: "Path", className: "st0", points: "16.3,0 4.6,0 4.6,24 23,24 23,6.6  " }), /* @__PURE__ */ React.createElement("linearGradient", { id: "Path_7_", gradientUnits: "userSpaceOnUse", x1: -243.3173, y1: 371.1785, x2: -242.3173, y2: 370.1785, gradientTransform: "matrix(6.6383 0 0 -6.6383 1629.8937 2465.6528)" }, /* @__PURE__ */ React.createElement("stop", { offset: 0, style: {
  stopColor: "#FFFFFF"
} }), /* @__PURE__ */ React.createElement("stop", { offset: 0.2214, style: {
  stopColor: "#F8F8F8"
} }), /* @__PURE__ */ React.createElement("stop", { offset: 0.5415, style: {
  stopColor: "#E5E5E5"
} }), /* @__PURE__ */ React.createElement("stop", { offset: 0.92, style: {
  stopColor: "#C6C6C6"
} }), /* @__PURE__ */ React.createElement("stop", { offset: 1, style: {
  stopColor: "#BEBEBE"
} })), /* @__PURE__ */ React.createElement("polygon", { id: "Path_1_", className: "st1", points: "16.3,0 16.3,6.6 23,6.6  " }), /* @__PURE__ */ React.createElement("line", { id: "Path_2_", className: "st2", x1: 7.4, y1: 17.4, x2: 20.2, y2: 17.4 }), /* @__PURE__ */ React.createElement("line", { id: "Path_3_", className: "st2", x1: 7.4, y1: 14.3, x2: 20.2, y2: 14.3 }), /* @__PURE__ */ React.createElement("line", { id: "Path_4_", className: "st2", x1: 7.4, y1: 20.4, x2: 20.2, y2: 20.4 }), /* @__PURE__ */ React.createElement("linearGradient", { id: "Rectangle_1_", gradientUnits: "userSpaceOnUse", x1: -266.6422, y1: 382.6665, x2: -265.8664, y2: 382.2385, gradientTransform: "matrix(14.8085 0 0 -8.1702 3950.2341 3130.3352)" }, /* @__PURE__ */ React.createElement("stop", { offset: 0, style: {
  stopColor: "#73B4C8"
} }), /* @__PURE__ */ React.createElement("stop", { offset: 0.1747, style: {
  stopColor: "#6AAEC3"
} }), /* @__PURE__ */ React.createElement("stop", { offset: 0.4567, style: {
  stopColor: "#529EB5"
} }), /* @__PURE__ */ React.createElement("stop", { offset: 0.8089, style: {
  stopColor: "#2C839F"
} }), /* @__PURE__ */ React.createElement("stop", { offset: 1, style: {
  stopColor: "#147391"
} })), /* @__PURE__ */ React.createElement("rect", { id: "Rectangle", y: 1.5, className: "st3", width: 14.8, height: 8.2 }), /* @__PURE__ */ React.createElement("path", { id: "Shape", className: "st4", d: "M3.3,2.8h-2v5.6h1v-2h1c0.9,0,1.5-0.7,1.5-1.5V4.3C4.9,3.5,4.2,2.8,3.3,2.8z M3.8,4.9 c0,0.3-0.2,0.5-0.5,0.5h-1V3.8h1c0.3,0,0.5,0.2,0.5,0.5V4.9z" }), /* @__PURE__ */ React.createElement("polygon", { id: "Path_5_", className: "st4", points: "13.5,3.8 13.5,2.8 10.5,2.8 10.5,8.4 11.5,8.4 11.5,6.4 13,6.4 13,5.4 11.5,5.4  11.5,3.8  " }), /* @__PURE__ */ React.createElement("path", { id: "Shape_1_", className: "st4", d: "M7.4,8.4h-2V2.8h2c1.1,0,2,0.9,2,2v1.5C9.4,7.5,8.5,8.4,7.4,8.4z M6.4,7.4h1c0.6,0,1-0.5,1-1 V4.9c0-0.6-0.5-1-1-1h-1V7.4z" })))));
export default SvgTypePdf48;
