import styled from '@emotion/styled';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import { DialogButton } from 'components/atoms/Button';
import Dialog from 'components/molecules/Dialog';
import { Path } from 'types/paths.enum';

interface Props {
    open: boolean;
    setOpen: (open: boolean) => void;
    timeout: number;
}

const DialogText = styled('h2')({
    fontWeight: 'normal',
    textAlign: 'center',
});

const SessionTimeoutDialog = ({ open, setOpen, timeout }: Props) => {
    const navigate = useNavigate();
    const [timer, setTimer] = useState(timeout);

    useEffect(() => {
        const interval = setInterval(() => setTimer((time) => (time > 0 ? time - 1 : 0)), 1000);
        return () => clearInterval(interval);
    }, []);

    return (
        <Dialog
            title='Your session is about to end'
            open={open}
            setOpen={setOpen}
            buttons={
                <>
                    <DialogButton
                        id='buttonCancel'
                        color='primary'
                        variant='outlined'
                        onClick={() => {
                            setOpen(false);
                            navigate(Path.LOGOUT);
                        }}
                        data-testid='sessionLogoutButton'
                    >
                        Start a new session at Login Page
                    </DialogButton>
                    <DialogButton
                        id='buttonOK'
                        color='primary'
                        variant='contained'
                        onClick={() => setOpen(false)}
                        data-testid='sessionIgnoreButton'
                    >
                        Continue current session
                    </DialogButton>
                </>
            }
        >
            <DialogText>Save your work before time expires or start a new session</DialogText>
            <DialogText>
                {Math.floor(timer / 60)}:{(timer % 60).toString().padStart(2, '0')}
            </DialogText>
        </Dialog>
    );
};

export default SessionTimeoutDialog;
