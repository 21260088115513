import { useTheme } from '@emotion/react';
import { useRef } from 'react';

import { CcdEmptyState } from 'components/_legacy/ccd-empty-state';
import Box from 'components/atoms/Box';
import EmptyStateFile from 'images/Empty-State-File.svg?react';

import { THERE_ARE_NO_FILES_HERE_YET } from '../../../../consts';
import {
    NoAccessUploadModal,
    useNoAccessUploadModal,
} from '../../../parts/FileUpload/DragNDropUpload/NoUploadAccessModal';

export default function FileEmptyState({ hasAccess, onInputUpload = undefined }) {
    const uploadRef = useRef();
    const theme = useTheme();

    const {
        isModalVisible: isNoAccessModalVisible,
        hideModal: hideNoAccessModal,
        showModal: showNoAccessModal,
    } = useNoAccessUploadModal();

    function getAction() {
        if (hasAccess) {
            return (
                <input
                    id='CcdEmptyState__Upload'
                    type='File'
                    hidden
                    multiple
                    ref={uploadRef}
                    onChange={(event) => {
                        onInputUpload(event.target.files);
                        event.target.value = null;
                    }}
                />
            );
        } else {
            return isNoAccessModalVisible && <NoAccessUploadModal onClose={hideNoAccessModal} />;
        }
    }

    return (
        <CcdEmptyState
            message={THERE_ARE_NO_FILES_HERE_YET}
            subMessageRenderer={() => (
                <>
                    <Box css={{ textAlign: 'center' }}>Drag & Drop file here or</Box>
                    <Box
                        css={{
                            color: theme.colors.actionText,
                            '&:hover': { color: theme.colors.actionTextHover, cursor: 'pointer' },
                        }}
                        className='file-empty-state'
                        onClick={() => {
                            if (hasAccess) {
                                uploadRef.current.click();
                            } else {
                                showNoAccessModal();
                            }
                        }}
                    >
                        select a file from your device
                    </Box>
                    {getAction()}
                </>
            )}
            icon={EmptyStateFile}
        />
    );
}
