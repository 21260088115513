import styled from '@emotion/styled';

import Box, { BoxProps } from 'components/atoms/Box';

const Wrapper = styled(Box)({
    width: '100%',
    minWidth: 700,
    height: '100%',
    minHeight: 450,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
});

const Content = styled(Box)(({ theme }) => ({
    backgroundColor: theme.colors.background,
    borderRadius: 12,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: theme.colors.text,
    fontStyle: 'normal',
    '& h1': {
        fontWeight: 400,
        fontSize: 30,
        lineHeight: '39px',
    },
    '& p': {
        fontWeight: 400,
        fontSize: 20,
        lineHeight: '27px',
    },
}));

export interface Props extends BoxProps {
    width?: number;
    height?: number;
}

function ErrorState({ children, width, height }: Props) {
    return (
        <Wrapper data-testid='error-state-wrapper'>
            <Content css={{ width, height }}>
                <Box>{children}</Box>
            </Content>
        </Wrapper>
    );
}

export default ErrorState;
