import { SideNavMode } from 'components/organisms/AddUpdateProject/enums/sideNavMode';
import ProjectFormView from 'components/templates/ProjectEditView';
import { isScanStatusInfected } from 'shared/utility';
import { SimpleProject } from 'types/project';

export interface AllProjectsSideNavInterface {
    collaborationSpaceId: string;
    selected: SimpleProject;
    sideNavState: SideNavMode;
    setSideNavState: (value: SideNavMode) => void;
}

export const ProjectSideNav = ({
    collaborationSpaceId,
    selected,
    sideNavState,
    setSideNavState,
}: AllProjectsSideNavInterface) =>
    sideNavState === SideNavMode.createNewProject ? (
        <ProjectFormView
            collaborationSpaceId={collaborationSpaceId}
            onSubmitHandler={() => {
                setSideNavState(SideNavMode.closed);
            }}
        />
    ) : (
        <ProjectFormView
            collaborationSpaceId={collaborationSpaceId}
            projectId={selected.legacyProjectId}
            projectName={selected.name}
            projectDescription={selected.description}
            thumbnail={selected.thumbnailUri}
            isInfected={isScanStatusInfected(selected.scanInfo)}
            onSubmitHandler={() => {
                setSideNavState(SideNavMode.closed);
            }}
        />
    );
