import styled from '@emotion/styled';
import { Buffer } from 'buffer';
import { useContext, useMemo } from 'react';
import { useNavigate } from 'react-router';

import { CellRendererProps } from 'components/_legacy/ccd-table';
import { useProgressBar } from 'components/atoms/ProgressBar';
import Tooltip from 'components/atoms/Tooltip';
import Button2D from 'images/type2D48.svg?react';
import Button3D from 'images/type3D48.svg?react';
import PDFIcon from 'images/typePdf48.svg?react';
import SchematicIcon from 'images/typeTopicSchemaRevsion48.svg?react';
import { XccInfrastructureProviderContext } from 'providers/xccInfrastructure/XccInfrastructureProvider';
import { triggerAnalyticsClickEvent } from 'services/SiemensAnalyticsService/SiemensAnalyticsModule';
import analyticsConsts from 'services/SiemensAnalyticsService/analyticsConsts';
import { ScanInfo, ScanStatus } from 'types/project';

import { DesignData } from '../Design';

export function CellRenderer({
    cell,
    scanInfo,
}: {
    cell: CellRendererProps<DesignData>;
    scanInfo?: ScanInfo;
}) {
    const xccContext = useContext(XccInfrastructureProviderContext);
    const { setProgressBar } = useProgressBar();
    const navigator = useNavigate();

    const encodeInBase64 = (toEncode: string) => {
        const bufferObj = Buffer.from(toEncode, 'utf8');
        return bufferObj.toString('base64');
    };

    const openJTViewer = () => {
        const encodedRevisionUrn = encodeInBase64(cell.row.original.revisionUrn);
        navigator(
            `/acc/${cell.row.original.projectId}/${cell.row.original.revisionId}/${encodedRevisionUrn}`
        );
    };

    const openPDFViewer = (pdfType: string) => {
        const encodedRevisionUrn = encodeInBase64(cell.row.original.revisionUrn);
        const encodedPdfType = encodeInBase64(pdfType);
        navigator(
            `/pdf/${cell.row.original.projectId}/${cell.row.original.revisionId}/${encodedRevisionUrn}/${encodedPdfType}`
        );
    };

    const openXccViewer = async (type: string) => {
        setProgressBar(true);

        const designToOpen = {
            collaborationSpaceId: cell.row.original.collaborationSpaceId,
            projectId: cell.row.original.projectId,
            revision: cell.row.original.revisionId,
            designType: type,
            name: cell.row.original.fileName,
        };
        await xccContext.openDesignInXcc(designToOpen);
        setProgressBar(false);
    };

    const isScanClean = useMemo(
        () => scanInfo?.scanStatus === ScanStatus.CLEAN,
        [scanInfo?.scanStatus]
    );

    const scanInfoAvailableAndNotClean = scanInfo && !isScanClean;
    const scanInfoNotAvailableOrClean = !scanInfo || isScanClean;

    interface StyledButtonInterface {
        onClick: () => void;
        disabled?: boolean;
    }

    const StyledButton = styled('div')<StyledButtonInterface>(({ disabled }) => ({
        marginRight: 21,
        width: 24,
        height: 30,
        float: 'right',
        cursor: disabled ? 'not-allowed' : 'pointer',
        opacity: disabled ? '0.5' : '1',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }));

    const notCleanMessages = () => {
        if (scanInfo?.scanStatus === ScanStatus.INFECTED) {
            return 'Actions suspended during security threat';
        }
        return 'Actions suspended during security scanning';
    };

    const getTooltipMessage = (available: boolean, viewerName: string) => {
        if (!available) {
            return `View ${viewerName} unavailable`;
        }

        if (scanInfoAvailableAndNotClean) {
            return notCleanMessages();
        }

        return `View ${viewerName}`;
    };

    if (
        cell.row.original.fileType === 'SCHEMATIC' ||
        cell.row.original.fileType === 'SCHEMATIC_EDIF'
    ) {
        const cceAvailable = cell.row.original.relatedFiles?.indexOf('CceSchematic') !== -1;
        const pdfAvailable = cell.row.original.relatedFiles?.indexOf('PdfSchematic') !== -1;

        if (!(cceAvailable || pdfAvailable)) {
            return (
                <Tooltip
                    title={getTooltipMessage(cceAvailable, 'schematic')}
                    arrow
                    enterDelay={500}
                    placement='top'
                >
                    <StyledButton disabled={true} id='buttonSchematic' onClick={() => {}}>
                        <SchematicIcon />
                    </StyledButton>
                </Tooltip>
            );
        }

        return (
            <Tooltip
                title={getTooltipMessage(cceAvailable || pdfAvailable, 'schematic')}
                arrow
                enterDelay={500}
                placement='top'
            >
                {cceAvailable ? (
                    <StyledButton
                        disabled={!cceAvailable || scanInfoAvailableAndNotClean}
                        id='buttonSchematic'
                        onClick={() => {
                            if (cceAvailable && scanInfoNotAvailableOrClean) {
                                triggerAnalyticsClickEvent(
                                    analyticsConsts.Actions.viewSchematic,
                                    analyticsConsts.Categories.versionAction,
                                    analyticsConsts.Sources.designTable,
                                    'XCC Viewer'
                                );
                                openXccViewer('schematic');
                            }
                        }}
                    >
                        <SchematicIcon />
                    </StyledButton>
                ) : (
                    <StyledButton
                        disabled={!pdfAvailable || scanInfoAvailableAndNotClean}
                        id='buttonSchematicPdf'
                        onClick={() => {
                            if (pdfAvailable && scanInfoNotAvailableOrClean) {
                                openPDFViewer('PdfSchematic');
                            }
                        }}
                    >
                        <PDFIcon />
                    </StyledButton>
                )}
            </Tooltip>
        );
    }

    if (cell.row.original.fileType === 'LAYOUT') {
        const available2d = cell.row.original.relatedFiles?.indexOf('CceLayout') !== -1;
        const available3d = cell.row.original.relatedFiles?.indexOf('JtModel') !== -1;
        const pdfAvailable = cell.row.original.relatedFiles?.indexOf('PdfLayout') !== -1;
        return (
            <div>
                <Tooltip
                    title={getTooltipMessage(available3d, '3D PCB')}
                    arrow
                    enterDelay={500}
                    placement='top'
                >
                    <StyledButton
                        disabled={!available3d || scanInfoAvailableAndNotClean}
                        id='button3D'
                        onClick={() => {
                            if (available3d && scanInfoNotAvailableOrClean) {
                                triggerAnalyticsClickEvent(
                                    analyticsConsts.Actions.view3D,
                                    analyticsConsts.Categories.versionAction,
                                    analyticsConsts.Sources.designTable,
                                    'JT Viewer'
                                );
                                openJTViewer();
                            }
                        }}
                    >
                        <Button3D />
                    </StyledButton>
                </Tooltip>
                <Tooltip
                    title={getTooltipMessage(available2d || pdfAvailable, '2D PCB')}
                    arrow
                    enterDelay={500}
                    placement='top'
                >
                    {available2d ? (
                        <StyledButton
                            id='button2D'
                            disabled={!available2d || scanInfoAvailableAndNotClean}
                            onClick={() => {
                                if (available2d && scanInfoNotAvailableOrClean) {
                                    triggerAnalyticsClickEvent(
                                        analyticsConsts.Actions.view2D,
                                        analyticsConsts.Categories.versionAction,
                                        analyticsConsts.Sources.designTable,
                                        'XCC Viewer'
                                    );
                                    openXccViewer('layout');
                                }
                            }}
                        >
                            <Button2D />
                        </StyledButton>
                    ) : pdfAvailable ? (
                        <StyledButton
                            disabled={!pdfAvailable || scanInfoAvailableAndNotClean}
                            id='buttonLayoutPdf'
                            onClick={() => {
                                if (pdfAvailable && scanInfoNotAvailableOrClean) {
                                    openPDFViewer('PdfLayout');
                                }
                            }}
                        >
                            <PDFIcon />
                        </StyledButton>
                    ) : (
                        <StyledButton id='button2D' disabled={true} onClick={() => {}}>
                            <Button2D />
                        </StyledButton>
                    )}
                </Tooltip>
            </div>
        );
    }

    return null;
}

export default CellRenderer;
