import styled from '@emotion/styled';

import Paragraph from 'components/atoms/Paragraph/Paragraph';

import { CONTACT_SUPPORT_MESSAGE } from '../../../../consts';
import { ISelectedFile } from '../../../../contexts/FileManagementContext';

const FileName = styled('span')({ fontWeight: '700', overflowWrap: 'anywhere' });

interface IMessage {
    modalTitle: string;
    modalMessage: (selectedFiles: [ISelectedFile]) => JSX.Element;
    loadingMessage: () => [string];
    successMessage: (selectedFiles: [ISelectedFile]) => [string];
    deleteFailedMessage: () => [string, string];
}

interface IMultiple extends IMessage {
    deletePartiallyFailedMessage(): [string, string];
}

const singleFileDeleteStrategy: IMessage = {
    modalTitle: 'Delete file',
    modalMessage: (selectedFiles) => {
        return (
            <Paragraph className='ccd-fm__modal-text ccd-fm-modal-text-ellipsis'>
                Do you want to delete file:&nbsp;
                <FileName>{selectedFiles[0].name}</FileName>?
            </Paragraph>
        );
    },
    loadingMessage: () => {
        return ['Deleting file. Please wait...'];
    },
    successMessage: (selectedFiles) => {
        return [`${selectedFiles[0].name} successfully deleted.`];
    },
    deleteFailedMessage: () => {
        return [
            'Oops! Something went wrong during file delete. Please try again.',
            CONTACT_SUPPORT_MESSAGE,
        ];
    },
};

const singleFolderDeleteStrategy: IMessage = {
    modalTitle: 'Delete folder',
    modalMessage: (selectedFiles) => {
        return (
            <Paragraph className='ccd-fm__modal-text ccd-fm-modal-text-ellipsis'>
                Do you want to delete folder&nbsp;
                <FileName>{selectedFiles[0].name}&nbsp;</FileName>
                and all of its content?
            </Paragraph>
        );
    },
    loadingMessage: () => {
        return ['Deleting folder. Please wait...'];
    },
    successMessage: (selectedFiles) => {
        return [`Folder ${selectedFiles[0].name} successfully deleted.`];
    },
    deleteFailedMessage: () => {
        return [
            'Oops! Something went wrong during folder delete. Please try again.',
            CONTACT_SUPPORT_MESSAGE,
        ];
    },
};

const multipleFoldersDeleteStrategy: IMultiple = {
    modalTitle: 'Delete folders',
    modalMessage: (selectedFiles) => {
        return (
            <Paragraph className='ccd-fm__modal-text'>
                Do you want to delete&nbsp;
                <FileName>{selectedFiles.length} folders&nbsp;</FileName>
                and all of its content?
            </Paragraph>
        );
    },
    loadingMessage: () => {
        return ['Deleting folders. Please wait...'];
    },
    successMessage: (selectedFiles) => {
        return [`${selectedFiles.length} folders successfully deleted.`];
    },
    deleteFailedMessage: () => {
        return [
            'Oops! Something went wrong during folders delete. Please try again.',
            CONTACT_SUPPORT_MESSAGE,
        ];
    },
    deletePartiallyFailedMessage: () => {
        return ["Couldn't delete all the folders. Please try again.", CONTACT_SUPPORT_MESSAGE];
    },
};

const multipleFilesDeleteStrategy: IMultiple = {
    modalTitle: 'Delete files',
    modalMessage: (selectedFiles) => {
        return (
            <Paragraph className='ccd-fm__modal-text'>
                Do you want to delete&nbsp;
                <FileName>{selectedFiles.length} files</FileName>?
            </Paragraph>
        );
    },
    loadingMessage: () => {
        return ['Deleting files. Please wait...'];
    },
    successMessage: (selectedFiles) => {
        return [`${selectedFiles.length} files successfully deleted.`];
    },
    deleteFailedMessage: () => {
        return [
            'Oops! Something went wrong during files delete. Please try again.',
            CONTACT_SUPPORT_MESSAGE,
        ];
    },
    deletePartiallyFailedMessage: () => {
        return ["Couldn't delete all the files. Please try again.", CONTACT_SUPPORT_MESSAGE];
    },
};

const multipleFilesAndFoldersDeleteStrategy: IMultiple = {
    modalTitle: 'Delete items',
    modalMessage: (selectedFiles) => {
        return (
            <Paragraph className='ccd-fm__modal-text'>
                Do you want to delete&nbsp;
                <FileName>{selectedFiles.length} items</FileName>?
            </Paragraph>
        );
    },
    loadingMessage: () => {
        return ['Deleting items. Please wait...'];
    },
    successMessage: (selectedFiles) => {
        return [`${selectedFiles.length} items successfully deleted.`];
    },
    deleteFailedMessage: () => {
        return [
            'Oops! Something went wrong during items delete. Please try again.',
            CONTACT_SUPPORT_MESSAGE,
        ];
    },
    deletePartiallyFailedMessage: () => {
        return ["Couldn't delete all the items. Please try again.", CONTACT_SUPPORT_MESSAGE];
    },
};

export default function getMessageStrategy(selectedFiles: [ISelectedFile]): IMessage | IMultiple {
    if (selectedFiles.length === 1) {
        if (selectedFiles[0].isFolder) {
            return singleFolderDeleteStrategy;
        } else {
            return singleFileDeleteStrategy;
        }
    } else if (selectedFiles.every((file) => file.isFolder)) {
        return multipleFoldersDeleteStrategy;
    } else if (selectedFiles.every((file) => !file.isFolder)) {
        return multipleFilesDeleteStrategy;
    }
    return multipleFilesAndFoldersDeleteStrategy;
}
