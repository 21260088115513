import { useQuery } from '@tanstack/react-query';

import {
    FilesTableName,
    FilesTableSortDirection,
} from 'components/templates/FileManagement/components/parts/Table/useTable';
import { ISelectedFile } from 'components/templates/FileManagement/contexts/FileManagementContext';
import fetcher from 'providers/api/fetcher';

interface IParams {
    collaborationSpaceId: string;
    projectId: string;
    folderId: string;
    sortBy?: FilesTableName;
    sortDir?: FilesTableSortDirection;
}

export const FILES_QUERY = 'files';

const filesQuery = ({ collaborationSpaceId, projectId, folderId, sortBy, sortDir }: IParams) =>
    useQuery({
        queryKey: [FILES_QUERY, collaborationSpaceId, projectId, folderId, { sortBy, sortDir }],
        queryFn: async () => {
            const { data } = await fetcher.get<{ files: ISelectedFile[]; totalCount: number }>(
                `/frontend/file-management/${collaborationSpaceId}/${projectId}/files`,
                { params: { folderId, sortBy, sortDir } }
            );
            return data.files;
        },
    });

export default filesQuery;
