import React from 'react';

import Box from 'components/atoms/Box';

import {
    CcdEventDescriptionSeparator,
    CcdEventDescriptionWrapper,
} from './EventDescription.styles';

interface CcdEventDescriptionProp {
    eventDescription: string;
    creator: string;
}

export function EventDescription({ eventDescription, creator }: CcdEventDescriptionProp) {
    return (
        <CcdEventDescriptionWrapper>
            {eventDescription}
            <CcdEventDescriptionSeparator />
            <Box as='span' css={(props) => ({ color: props.colors.tile.textLight })}>
                by {creator}
            </Box>
        </CcdEventDescriptionWrapper>
    );
}
