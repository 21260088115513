import styled from '@emotion/styled';

import { customColors } from 'theme';

export const CcdEmptyStateContainer = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    boxSizing: 'border-box',
    height: '100%',
});

export const CcdEmptyStateTitle = styled('div')(({ theme }) => ({
    color: customColors['pl-black-16'],
    fontSize: 16,
    fontWeight: 'bold',
    paddingTop: theme.spacing(4),
}));

export const CcdEmptyStateSubtitle = styled('div')(({ theme }) => ({
    color: customColors['pl-black-16'],
    fontSize: 16,
    fontWeight: 600,
    paddingTop: theme.spacing(1),
}));
