import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import React, { ReactElement, useState } from 'react';

import Box from 'components/atoms/Box';
import Collapse from 'components/atoms/Collapse';

interface CcdAccordionProps {
    title: string;
    open?: boolean;
    children: ReactElement;
}

const AccordionTopHorizontalLine = styled(Box)(({ theme }) => ({
    flexDirection: 'row',
    flexGrow: 1,
    height: '1px',
    borderTop: `1px solid ${theme.colors.accordion.horizontalLine}`,
    marginTop: '0.6rem',
}));

export default function CcdAccordion({ title, open = true, children }: CcdAccordionProps) {
    const [isOpened, setIsOpened] = useState(open);

    const theme = useTheme();

    return (
        <div>
            <div onClick={() => setIsOpened((previousState) => !previousState)}>
                <Box
                    css={{
                        display: 'flex',
                        backgroundColor: theme.colors.accordion.background,
                        cursor: 'pointer',
                        lineHeight: '16px',
                        transition: '0.4s',
                    }}
                >
                    <AccordionTopHorizontalLine css={{ marginRight: '8px', marginLeft: '32px' }} />
                    <Box
                        css={{
                            display: 'flex',
                            marginRight: '1px',
                            marginLeft: '1px',
                            marginTop: '2px',
                            maxWidth: '80%',
                            minWidth: '10%',
                        }}
                    >
                        <Box
                            data-testid={'AccordionTitle'}
                            css={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                flexGrow: 1,
                                marginRight: '4px',
                                color: theme.colors.accordion.text,
                            }}
                        >
                            {title}
                        </Box>
                        <Collapse isOpened={isOpened} />
                    </Box>
                    <AccordionTopHorizontalLine css={{ marginLeft: '8px', marginRight: '32px' }} />
                </Box>
            </div>
            <Box
                data-testid={isOpened ? 'AccordionChildVisible' : 'AccordionChildHidden'}
                css={{
                    background: theme.colors.accordion.background,
                    overflow: 'hidden',
                    height: '100%',
                    ...(!isOpened && { height: '0' }),
                }}
            >
                {children}
            </Box>
        </div>
    );
}
