import { IUser } from 'components/templates/ProjectUsers/services/types';
import fetcher from 'providers/api/fetcher';

interface IAllUsersApi {
    collaborationSpaceId: string;
}

const allUsersApi = async ({ collaborationSpaceId }: IAllUsersApi): Promise<IUser[]> => {
    const { data } = await fetcher({
        url: `frontend/collaboration-space/${collaborationSpaceId}/all-users`,
    });

    return data;
};
export default allUsersApi;
