import { PropsWithChildren, createContext, useState } from 'react';

export type ExportButtonState = {
    isExportButtonVisible: boolean;
    isExportButtonEnabled: boolean;
    onExport: () => void;
    setOnExport: (onExport: () => void) => void;
    setIsExportButtonVisible: (isExportButtonVisible: boolean) => void;
    setIsExportButtonEnabled: (isExportButtonEnabled: boolean) => void;
};

export const ExportButtonContext = createContext<ExportButtonState>({
    isExportButtonVisible: false,
    isExportButtonEnabled: false,
    onExport: () => {},
    setOnExport: () => {},
    setIsExportButtonVisible: () => {},
    setIsExportButtonEnabled: () => {},
});

export const ExportPartlistProvider = ({ children }: PropsWithChildren) => {
    const setOnExport = (onExport: () => void) => {
        setExportState((state: ExportButtonState) => ({ ...state, onExport: onExport }));
    };

    const setIsExportButtonVisible = (isExportButtonVisible: boolean) => {
        setExportState((state: ExportButtonState) => ({
            ...state,
            isExportButtonVisible: isExportButtonVisible,
        }));
    };

    const setIsExportButtonEnabled = (isExportButtonEnabled: boolean) => {
        setExportState((state: ExportButtonState) => ({
            ...state,
            isExportButtonEnabled: isExportButtonEnabled,
        }));
    };

    const initState: ExportButtonState = {
        isExportButtonVisible: false,
        isExportButtonEnabled: false,
        onExport: () => {},
        setOnExport: setOnExport,
        setIsExportButtonVisible: setIsExportButtonVisible,
        setIsExportButtonEnabled: setIsExportButtonEnabled,
    };
    const [exportState, setExportState] = useState(initState);

    return (
        <ExportButtonContext.Provider value={exportState}>{children}</ExportButtonContext.Provider>
    );
};
