import isEmpty from 'lodash/isEmpty';

import useCcdFetch from 'components/_legacy/hooks/useCcdFetch';

export function useCreateDocument() {
    const { ccdFetch } = useCcdFetch();

    async function createDocument({
        fileObj,
        collaborationSpaceId,
        projectId,
        backendUrl,
        currentFolder,
    }) {
        const url = new URL(
            `/frontend/file-management/${collaborationSpaceId}/${projectId}/document`,
            backendUrl
        );
        const data = new FormData();

        data.append('file', fileObj);

        if (!isEmpty(currentFolder?.id)) {
            data.append('parentFolderId', currentFolder?.id);
        }

        const options = {
            method: 'POST',
            body: data,
        };

        return ccdFetch(url.toString(), options);
    }

    return { createDocument };
}
