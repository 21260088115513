import { createContext } from 'react';

interface GlobalUiBlockersCounterContext {
    increaseBlockerCounter: () => void;
    decreaseBlockerCounter: () => void;
}

const GlobalUiBlockersCounterContext = createContext<GlobalUiBlockersCounterContext | null>(null);

export default GlobalUiBlockersCounterContext;
