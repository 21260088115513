import React, { useEffect } from 'react';
import { useParams } from 'react-router';

import ComboBox, { ComboBoxGroupRoot } from 'components/atoms/ComboBox';
import Label from 'components/atoms/Label';
import { useProgressBar } from 'components/atoms/ProgressBar';
import allUsers from 'components/templates/ProjectUsers/services/query/allUsers.query';
import { projectUsersQuery } from 'components/templates/ProjectUsers/services/query/projectUsers.query';
import { IUser } from 'components/templates/ProjectUsers/services/types';

import { IUserItem } from './IUserItem';
import Option from './Option';
import SingleValue from './SingleValue';

interface IUserComboBox {
    onChange: (value: any) => void;
    isError: boolean;
    value: any;
}

export const UserComboBox = ({ onChange, isError, value }: IUserComboBox) => {
    const { projectId } = useParams();
    const { data, isLoading } = allUsers();
    const { setProgressBar } = useProgressBar();
    const showError: boolean = isError && !value;

    const { data: projectUserData } = projectUsersQuery({
        projectId,
    });

    useEffect(() => {
        if (isLoading) {
            setProgressBar(true);
        } else {
            setProgressBar(false);
        }
    }, [isLoading]);

    const projectUsers = projectUserData?.pages.map((x) => x.content).flat() ?? [];

    const notSharedUsers =
        data?.filter(({ userId: id1 }) => !projectUsers.some(({ userId: id2 }) => id2 === id1)) ??
        [];

    const user: IUserItem[] =
        notSharedUsers.map((user: IUser) => ({
            email: user.email,
            fullName: `${user.firstName} ${user.lastName}`,
            label: `${user.firstName} ${user.lastName} ${user.email}`,
            id: user.userId,
            value: `${user.firstName} ${user.lastName} ${user.email}`,
        })) ?? [];

    return (
        <ComboBoxGroupRoot>
            <Label
                required
                style={{ ...(showError && { color: 'red', '> span': { color: 'red' } }) }}
            >
                {showError ? 'Name (select name)' : 'Name'}
            </Label>
            <ComboBox
                placeholder={'Add user from the Xcelerator Admin Console list'}
                components={{ Option, SingleValue }}
                selectList={user}
                isDisabled={!data}
                onChange={(value) => {
                    onChange(value);
                }}
                isError={showError}
                value={value}
                id={'UserComboBox'}
            />
        </ComboBoxGroupRoot>
    );
};
