import styled from '@emotion/styled';

export const ComboBoxGroupRoot = styled('div')(({ theme }) => ({
    margin: theme.spacing(2, 0),
    ':first-of-type': {
        marginTop: 0,
    },
    ':last-child': {
        marginBottom: 0,
    },
}));
