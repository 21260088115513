export enum Actions {
    selectVersion = 'Select version',
    userAvatar = 'User Avatar',
    homepage = 'Homepage',
    allProjects = 'All Projects',
    help = 'Help',
    settings = 'Settings',
    notifications = 'Notifications',
    projects = 'Projects',
    openProject = 'Open Project',
    editProject = 'Edit Project',
    createProject = 'Create Project',
    lockProject = 'Lock Project',
    unlockProject = 'Unlock Project',
    deleteProject = 'Delete Project',
    shareProject = 'Add User to Project',
    unShareProject = 'Remove User from Project',
    modifyUserInProject = 'Modify User in Project',
    viewSchematic = 'View Schematic',
    view2D = 'View 2D PCB',
    view3D = 'View 3D PCB',
    viewBOM = 'View BOM',
    viewIPL = 'View IPL',
}

export enum Categories {
    navigation = 'Navigation',
    projectActions = 'Project Actions',
    projectUserManagement = 'Project User Management',
    versionAction = 'Version Actions',
}

export enum Sources {
    projectVersionPicker = 'Project Version Picker',
    sideNav = 'Side Nav',
    projects = 'Projects',
    projectList = 'Project List',
    projectTile = 'Project Tile',
    breadcrumb = 'Breadcrumb',
    projectUsers = 'Project Users Table',
    designTable = 'Project Design Table',
    bomTable = 'Project BOM Table',
}

export default { Actions, Categories, Sources };
