import React from 'react';

import { CcdEmptyState } from 'components/_legacy/ccd-empty-state';
import ErrorStateImage from 'images/Error-State-icon.svg?react';

export const ErrorState = () => {
    return (
        <CcdEmptyState
            message='Failed to load Project data'
            subMessage='Try to refresh the page'
            icon={ErrorStateImage}
        />
    );
};
