import useRelativeTime from 'hooks/useRelativeTime';
import { ProjectRevision } from 'types/project';

export default function useDataMapperHook() {
    const { relativeTime } = useRelativeTime();

    function mapData(data: ProjectRevision[]): ProjectRevision[] {
        return data.map((projectRevision) => ({
            ...projectRevision,
            lastModified: {
                by: projectRevision.lastModified.by,
                date: relativeTime({ date: projectRevision.lastModified.date }),
            },
        }));
    }

    return {
        mapData,
    };
}
