import { useCallback, useRef } from 'react';

import { ccdErrorToast, useToast } from 'components/_legacy/ccd-toast';
import useHttp from 'components/_legacy/hooks/useHttp';
import { useFileManagementContext } from 'components/templates/FileManagement/components/FileManagementContext';

import { EE, FILE_DOWNLOADED } from '../../eventEmitter';
import useGlobalUiBlockersCounterContext from '../useGlobalUiBlockersCounterContext';
import getMessageStrategy from './downloadMessageStrategy';

export default function useDownload() {
    const { sendRequest } = useHttp();
    const {
        state: {
            backendUrl,
            collaborationSpaceId,
            projectId,
            selectedFiles,
            projectName,
            currentFolder,
        },
    } = useFileManagementContext();
    const { increaseBlockerCounter, decreaseBlockerCounter } = useGlobalUiBlockersCounterContext();
    const anchorRef = useRef(null);
    const { showToast } = useToast();

    const showToastAboutIgnoredFiles = () => {
        showToast({
            type: 'info',
            messages: [
                'Files detected as a threat by the security scan will be ignored during download',
            ],
        });
    };

    function onError(error) {
        const messageHandler = getMessageStrategy(selectedFiles);
        decreaseBlockerCounter();

        if (error.code === 404) {
            ccdErrorToast(messageHandler.fileNotFound);
        } else {
            ccdErrorToast(messageHandler.genericError);
        }
    }

    const getDownloadFileUrl = useCallback(async () => {
        let downloadUrl;

        const file = selectedFiles[0];
        const url = new URL(
            `/frontend/file-management/${collaborationSpaceId}/${projectId}/document/${file.id}`,
            backendUrl
        );
        const requestConfig = {
            url: url,
            method: 'GET',
        };

        const onSuccess = (response) => {
            decreaseBlockerCounter();
            downloadUrl = response.downloadUrl;
        };

        increaseBlockerCounter();

        await sendRequest({
            requestConfig,
            onError,
            onSuccess,
        });

        return downloadUrl;
    }, [selectedFiles]);

    const getDownloadFilesUrl = useCallback(async () => {
        let downloadUrl;
        const fileIds = selectedFiles.map((file) => file.id);

        showToastAboutIgnoredFiles();

        const url = new URL(
            `/frontend/file-management/${collaborationSpaceId}/${projectId}/download`,
            backendUrl
        );
        const requestConfig = {
            url: url,
            method: 'POST',
            body: { fileIds: fileIds, fileName: projectName },
        };

        const onSuccess = (response) => {
            decreaseBlockerCounter();
            downloadUrl = response.downloadUrl;
        };

        increaseBlockerCounter();

        await sendRequest({
            requestConfig,
            onError,
            onSuccess,
        });

        return downloadUrl;
    }, [selectedFiles, projectName]);

    const downloadCurrentFolder = useCallback(async () => {
        let downloadUrl;

        showToastAboutIgnoredFiles();

        const url = new URL(
            `/frontend/file-management/${collaborationSpaceId}/${projectId}/download`,
            backendUrl
        );
        const requestConfig = {
            url: url,
            method: 'POST',
            body: { fileIds: [currentFolder.id], fileName: projectName },
        };

        const onSuccess = (response) => {
            decreaseBlockerCounter();
            downloadUrl = response.downloadUrl;
        };

        increaseBlockerCounter();

        await sendRequest({
            requestConfig,
            onError,
            onSuccess,
        });

        anchorRef.current.href = downloadUrl;
        anchorRef.current.click();
        EE.emit(FILE_DOWNLOADED, {
            isFolder: true,
            typeName: currentFolder.typeName,
            fileName: currentFolder.name,
        });
    }, [currentFolder.id, projectName]);

    const downloadFile = useCallback(async () => {
        const url =
            selectedFiles.length === 1 && !selectedFiles[0].isFolder
                ? await getDownloadFileUrl()
                : await getDownloadFilesUrl();
        if (!url) {
            return;
        }
        anchorRef.current.href = url;
        anchorRef.current.click();
        selectedFiles.map((selectedFile) => {
            EE.emit(FILE_DOWNLOADED, {
                isFolder: selectedFile.isFolder,
                typeName: selectedFile.typeName,
                fileName: selectedFile.name,
            });
        });
    }, [selectedFiles]);

    return {
        anchorRef,
        getDownloadFileUrl,
        downloadFile,
        downloadCurrentFolder,
    };
}
