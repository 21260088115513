import React, { useEffect } from 'react';

import FileDisplay from 'components/templates/FileManagement/components/parts/FileDisplay';
import FileSearch from 'components/templates/FileManagement/components/parts/FileSearch';
import { DragNDropUpload } from 'components/templates/FileManagement/components/parts/FileUpload/DragNDropUpload';
import RenameFileSideNavForm from 'components/templates/FileManagement/components/parts/FileUpload/RenameFileSideNavForm';
import appConfig from 'constants/appConfig';

import { useAnalytics } from '../hooks';
import CommandVisibilityContextProvider from './CommandBarVisibilityContextProvider';
import CurrentFolderContextProvider from './CurrentFolderContextProvider';
import { FileManagementProvider } from './FileManagementContext';
import GlobalUiBlockersCounterContextProvider from './GlobalUiBlockersCounterContextProvider';
import MainTableBreadcrumb from './MainTableBreadcrumb';
import { CommandBar } from './parts';

import './FileManagement.scss';

export interface Props {
    collaborationspaceid: string;
    projectid?: string;
    projectname?: string;
    rootid: string;
}

function FileManagement({ collaborationspaceid, projectid, projectname }: Props) {
    const { initialize, dispose } = useAnalytics();

    useEffect(() => {
        initialize();
        return () => {
            dispose();
        };
    }, [initialize, dispose]);

    function showFileManagement() {
        return (
            <FileManagementProvider
                values={{
                    projectName: projectname,
                    backendUrl: appConfig.baseURL,
                    collaborationSpaceId: collaborationspaceid,
                    projectId: projectid,
                    getRoot: () => document,
                }}
            >
                <GlobalUiBlockersCounterContextProvider>
                    <CommandVisibilityContextProvider>
                        <CurrentFolderContextProvider>
                            <div className='ccd-file-management-display'>
                                <FileSearch />
                                <MainTableBreadcrumb />
                                <CommandBar />
                                <DragNDropUpload>
                                    <FileDisplay />
                                </DragNDropUpload>
                                <RenameFileSideNavForm />
                            </div>
                        </CurrentFolderContextProvider>
                    </CommandVisibilityContextProvider>
                </GlobalUiBlockersCounterContextProvider>
            </FileManagementProvider>
        );
    }

    return (
        <>
            {showFileManagement()}
            <div id='backdrop-container' />
            <div id='modal-container' />
        </>
    );
}

export default FileManagement;
