import { bool, func } from 'prop-types';
import { useEffect } from 'react';

import { useForm } from '../../../UI';
import { FileFormComponent, NAME_INPUT_ID } from '../../../parts/FileForm';
import useRenameFolderForm from './useRenameFolderForm';

export default function RenameFolderForm({ isVisible, onClose }) {
    const {
        error,
        focus,
        buttonDisabled,
        setError,
        handleCloseError,
        preSubmitCleanup,
        activateForm,
    } = useForm(NAME_INPUT_ID);

    const { inputs, onSubmit, isLoading, formRef } = useRenameFolderForm(
        {
            preSubmitCleanup,
            setError,
            activateForm,
        },
        onClose
    );

    useEffect(() => {
        isVisible && activateForm();
    }, [isVisible]);

    return (
        <FileFormComponent
            onSubmit={onSubmit}
            onClose={onClose}
            headerTitle='Rename folder'
            isVisible={isVisible}
            error={error}
            buttonDisabled={buttonDisabled}
            handleCloseError={handleCloseError}
            focus={focus}
            inputs={inputs}
            isLoading={isLoading}
            formRef={formRef}
        />
    );
}

RenameFolderForm.propTypes = {
    isVisible: bool,
    onClose: func,
};
