import React from 'react';

import Box from 'components/atoms/Box';
import ProjectNameLink from 'components/atoms/ProjectNameLink';
import Tooltip from 'components/atoms/Tooltip';
import useRelativeTime from 'hooks/useRelativeTime';
import lockIcon from 'images/lock16.svg';
import sharedIcon from 'images/shared.svg';
import { Project } from 'types/project';

import {
    Icon,
    ProjectFooterDetails,
    ProjectFooterIconsArea,
    ProjectFooterUpperArea,
    TileContent,
} from './ProjectTileFooter.styles';

const { relativeTime } = useRelativeTime();

interface StateIconInterface {
    icon: any;
    tooltip: string;
}

const StateIcon = ({ icon, tooltip }: StateIconInterface) => (
    <Tooltip title={tooltip} arrow enterDelay={500} placement='top'>
        <Icon src={icon} />
    </Tooltip>
);

interface IProps
    extends Pick<
        Project,
        'name' | 'status' | 'isSharedWithMe' | 'isSharedByMe' | 'collaborationSpaceId'
    > {
    onNameClick: () => void;
    modificationDate: Date;
    createdBy: string;
}

export function ProjectTileFooter({
    onNameClick,
    modificationDate,
    createdBy,
    isSharedWithMe,
    status,
    name,
    isSharedByMe,
}: IProps) {
    return (
        <TileContent data-testid={'TileContent'}>
            <ProjectFooterUpperArea>
                <ProjectNameLink onClick={onNameClick} data-testid={'ProjectNameLink'}>
                    <Tooltip title={name} arrow placement='top'>
                        <Box
                            as={'span'}
                            css={({ colors }) => ({
                                cursor: 'pointer',
                                '&:hover': { color: colors.header },
                            })}
                        >
                            {name}
                        </Box>
                    </Tooltip>
                </ProjectNameLink>
                <ProjectFooterIconsArea>
                    {status === 'LOCKED' ? <StateIcon icon={lockIcon} tooltip={'Locked'} /> : null}
                    {isSharedWithMe ? (
                        <StateIcon icon={sharedIcon} tooltip={'Shared with me'} />
                    ) : null}
                    {isSharedByMe ? <StateIcon icon={sharedIcon} tooltip={'Shared by me'} /> : null}
                </ProjectFooterIconsArea>
            </ProjectFooterUpperArea>
            <ProjectFooterDetails>
                {relativeTime({ date: modificationDate })}&nbsp;
                <span>| {createdBy}</span>
            </ProjectFooterDetails>
        </TileContent>
    );
}

export default React.memo(ProjectTileFooter);
