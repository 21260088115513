import { CloseButton, Message } from '../GuidanceMessage/GuidanceMessage.styles';
import {
    InfoGuidanceMessageBox,
    InfoGuidanceMessageInfo,
    InformationIcon,
} from './InfoGuidanceMessage.styles';

export interface InfoGuidanceMessageInterface {
    info: string;
    message: string;
    onClose?: () => void;
}

const InfoGuidanceMessage = ({ onClose, info, message }: InfoGuidanceMessageInterface) => {
    return (
        <InfoGuidanceMessageBox>
            <div style={{ display: 'inline-flex' }}>
                <InformationIcon />
                <InfoGuidanceMessageInfo>{info}</InfoGuidanceMessageInfo>
                <Message>{message}</Message>
            </div>
            <CloseButton onClick={onClose} />
        </InfoGuidanceMessageBox>
    );
};

export default InfoGuidanceMessage;
