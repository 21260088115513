import { useContext, useEffect, useState } from 'react';

import { ResetAnalysisContext } from 'providers/resetAnalysisStateProvider';
import { IplResponseBody } from 'types/ipl';

function useIplViewState(isLoading: boolean, isError: boolean, data: IplResponseBody | undefined) {
    const resetAnalysisContext = useContext(ResetAnalysisContext);

    const [resetInProgress, setResetInProgress] = useState(false);
    const [resetButtonEnabled, setResetButtonEnabled] = useState(false);
    const [progressSpinnerVisible, setProgressSpinnerVisible] = useState(false);
    const [iplVisible, setIplVisible] = useState(false);
    useEffect(() => {
        setProgressSpinnerVisible(isLoading || !(isError || data?.data.url));
    }, [isLoading, isError, data]);

    useEffect(() => {
        setIplVisible(!isLoading && data?.data !== undefined && !!data?.data.url);
    }, [isLoading, data]);

    useEffect(() => {
        setResetButtonEnabled(resetAnalysisContext.isResetAnalysisVisible);
    }, [resetAnalysisContext.isResetAnalysisVisible]);

    useEffect(() => {
        setResetInProgress(resetAnalysisContext.isResetAnalysisInProgress);
    }, [resetAnalysisContext.isResetAnalysisInProgress]);

    useEffect(() => {
        resetAnalysisContext.setResetAnalysisVisible(iplVisible);
    }, [iplVisible]);

    return { resetInProgress, resetButtonEnabled, progressSpinnerVisible, iplVisible };
}

export default useIplViewState;
