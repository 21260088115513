import axios from 'axios';

import { baseURL } from 'constants/appConfig';

declare module 'axios' {
    export interface AxiosRequestConfig {
        nullRawData?: Object;
    }
}

const instance = axios.create({
    baseURL,
    withCredentials: true,
});

export default instance;
