import { Buffer } from 'buffer';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';

import { isScanStatusClean } from 'shared/utility';

export function nullIfEmpty(id) {
    if (id === '') {
        return undefined;
    } else {
        return id;
    }
}

export function splitFileName(filename) {
    let extension = '';
    if (isNil(filename)) {
        return {
            name: undefined,
            extension: undefined,
        };
    } else if (filename.endsWith('.tar.gz')) {
        extension = '.tar.gz';
    } else {
        extension = getExtension(filename);
    }
    return {
        name: filename.substring(0, filename.lastIndexOf(extension)),
        extension: extension,
    };
}

export function decodeFileId(fileId) {
    try {
        if (isEmpty(fileId)) {
            return getEmptyFileId();
        }
        return JSON.parse(Buffer.from(fileId, 'base64').toString());
    } catch (_ex) {
        return getEmptyFileId();
    }
}

export function isPdf(fileName) {
    if (isNil(fileName)) {
        return false;
    }
    const fileExtension = getExtension(fileName);
    return fileExtension.toUpperCase() === '.PDF';
}

/**
 * This function check does selected file is an image accordingly to html img tag
 * @param fileName - string with file name
 */
export function isImage(fileName) {
    if (isNil(fileName)) {
        return false;
    }
    const fileExtension = getExtension(fileName).toUpperCase();
    switch (fileExtension) {
        case '.JPG':
        case '.JPEG':
        case '.JFIF':
        case 'PJPEG':
        case '.PJP':
        case '.GIF':
        case '.PNG':
        case '.APNG':
        case '.SVG':
        case '.BMP':
        case '.ICO':
        case '.WEBP':
        case '.AVIF':
            return true;
        default:
            return false;
    }
}

export function isWebPageHTML(typeName) {
    return typeName === 'WebPageZipRevision';
}

function getExtension(filename) {
    const dotPosition = filename.lastIndexOf('.');
    if (filename === '' || dotPosition < 1) {
        return '';
    }
    return filename.slice(dotPosition);
}

function getEmptyFileId() {
    return {
        Id: undefined,
        Urn: undefined,
        ParentFolderId: undefined,
    };
}

export function isItemOpenable(item) {
    return (
        item.isFolder ||
        (isScanStatusClean(item.contentScanInfo) &&
            (isPdf(item.name) || isImage(item.name) || isWebPageHTML(item.typeName)))
    );
}
