import { func, shape, string } from 'prop-types';
import { useCallback } from 'react';

import { ccdLoadingCompletedWithError } from 'components/_legacy/ccd-toast';
import Box from 'components/atoms/Box';
import { DialogButton } from 'components/atoms/Button';
import Dialog from 'components/molecules/Dialog';

import { unexpectedError } from '../../../consts';
import { EE, OPEN_RENAME_FILE_SIDE_NAV } from '../../../eventEmitter';
import { useTableRefresh } from '../../../hooks';
import { useReplace } from '../FileUpload';

const FileManagementFileAlreadyExistsPrefix = 'file-management-single-file-already-exists';

function handleUnexpectedError(notificationId, err) {
    console.error(err);
    ccdLoadingCompletedWithError(notificationId, unexpectedError);
}

const SingleFileAlreadyExists = ({ file, onClose, isDragAndDropUpload }) => {
    const { dispatchDataLoaded } = useTableRefresh();

    const { onReplace } = useReplace(
        file.originalFile,
        file.id,
        dispatchDataLoaded,
        handleUnexpectedError,
        isDragAndDropUpload
    );

    const replaceHandler = useCallback(async () => {
        onClose();
        await onReplace();
    }, [onReplace, onClose]);

    const onRename = (e) => {
        e.stopPropagation();
        onClose();
        EE.emit(OPEN_RENAME_FILE_SIDE_NAV, { file, isDragAndDropUpload });
    };

    return (
        <Dialog
            title={'File already exists'}
            buttons={
                <>
                    <DialogButton
                        color='primary'
                        variant={'outlined'}
                        onClick={onClose}
                        id={FileManagementFileAlreadyExistsPrefix + '__Cancel'}
                    >
                        Cancel
                    </DialogButton>
                    <DialogButton
                        color='secondary'
                        variant={'contained'}
                        onClick={replaceHandler}
                        id={FileManagementFileAlreadyExistsPrefix + '__Replace'}
                    >
                        Replace
                    </DialogButton>
                    <DialogButton
                        color='primary'
                        variant={'contained'}
                        onClick={onRename}
                        id={FileManagementFileAlreadyExistsPrefix + '__Rename'}
                    >
                        Rename
                    </DialogButton>
                </>
            }
            id={FileManagementFileAlreadyExistsPrefix}
            open
            setOpen={onClose}
        >
            <Box css={({ colors }) => ({ color: colors.text })}>
                <Box>
                    <span className='ccd-fm__modal-text-bolded'>{file.name}</span>
                    &nbsp;
                    <span>already exists in this folder.</span>
                </Box>
                <div>Replace the existing one or quickly rename?</div>
            </Box>
        </Dialog>
    );
};

SingleFileAlreadyExists.propTypes = {
    file: shape({ name: string }),
    onClose: func,
};

export default SingleFileAlreadyExists;
