import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';

import { ccdErrorToast, ccdInfoToast } from 'components/_legacy/ccd-toast';
import { DialogButton } from 'components/atoms/Button';
import { useProgressBar } from 'components/atoms/ProgressBar';
import Dialog from 'components/molecules/Dialog';
import { IUserTable } from 'components/templates/ProjectUsers/ProjectUsers';
import { unShareProjectApi } from 'components/templates/ProjectUsers/services/api/unShareProject.api';
import { GET_PROJECT_USERS } from 'components/templates/ProjectUsers/services/query/projectUsers.query';
import { triggerAnalyticsClickEvent } from 'services/SiemensAnalyticsService/SiemensAnalyticsModule';
import analyticsConsts from 'services/SiemensAnalyticsService/analyticsConsts';

export const useUserRemove = (
    collaborationSpaceId: string,
    projectId: string,
    user: IUserTable
) => {
    const [showDialog, setShowDialog] = useState(false);
    const queryClient = useQueryClient();
    const { setProgressBar } = useProgressBar();
    const { mutate } = useMutation(unShareProjectApi, {
        onMutate: () => {
            setShowDialog(false);
            setProgressBar(true);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [GET_PROJECT_USERS, collaborationSpaceId, projectId],
            });
            triggerAnalyticsClickEvent(
                analyticsConsts.Actions.unShareProject,
                analyticsConsts.Categories.projectActions,
                analyticsConsts.Sources.projectUsers
            );
            ccdInfoToast({
                messages: ['User successfully removed from project.'],
            });
        },
        onError: () => {
            ccdErrorToast({
                title: 'Cannot remove user from project',
                messages: ['Try again or contact support'],
            });
        },
        onSettled: () => {
            setProgressBar(false);
        },
    });

    const performUnShare = () => {
        mutate({
            email: user.email,
            projectId: projectId,
            collaborationSpaceId: collaborationSpaceId,
        });
    };
    const RemoveUserDialog = () => (
        <Dialog
            title={'Remove user from project'}
            open={showDialog}
            setOpen={setShowDialog}
            closeButton
            buttons={
                <>
                    <DialogButton
                        color={'primary'}
                        variant={'outlined'}
                        onClick={() => setShowDialog(false)}
                        id={'removeUserCancelButton'}
                    >
                        Cancel
                    </DialogButton>
                    <DialogButton
                        color={'primary'}
                        variant={'contained'}
                        onClick={performUnShare}
                        id={'removeUserConfirmButton'}
                    >
                        Remove
                    </DialogButton>
                </>
            }
        >
            <span>
                Do you want to remove <b>{user.name}</b> from the project?
            </span>
        </Dialog>
    );
    return {
        openRemoveUserDialog: () => setShowDialog(true),
        removeUserDialog: <RemoveUserDialog />,
    };
};
