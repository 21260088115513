import { PropsWithChildren, createContext, useReducer } from 'react';

enum ResetAnalysisActionType {
    SET_RESET_ANALYSIS_IN_PROGRESS = 'SET_RESET_ANALYSIS_IN_PROGRESS',
    SET_RESET_ANALYSIS_VISIBLE = 'SET_RESET_ANALYSIS_VISIBLE',
}

interface SetResetAnalysisInProgressAction {
    type: ResetAnalysisActionType.SET_RESET_ANALYSIS_IN_PROGRESS;
    payload: boolean;
}

interface SetResetAnalysisVisibleAction {
    type: ResetAnalysisActionType.SET_RESET_ANALYSIS_VISIBLE;
    payload: boolean;
}

type ResetAnalysisAction = SetResetAnalysisInProgressAction | SetResetAnalysisVisibleAction;

const resetAnalysisReducer = (
    state: ResetAnalysisState,
    action: ResetAnalysisAction
): ResetAnalysisState => {
    switch (action.type) {
        case ResetAnalysisActionType.SET_RESET_ANALYSIS_IN_PROGRESS:
            return { ...state, isResetAnalysisInProgress: action.payload };
        case ResetAnalysisActionType.SET_RESET_ANALYSIS_VISIBLE:
            return { ...state, isResetAnalysisVisible: action.payload };
        default:
            return state;
    }
};

export type ResetAnalysisState = {
    isResetAnalysisVisible: boolean;
    isResetAnalysisInProgress: boolean;
    setResetAnalysisInProgress: (isResetAnalysisInProgress: boolean) => void;
    setResetAnalysisVisible: (isResetAnalysisVisible: boolean) => void;
};

export const ResetAnalysisContext = createContext<ResetAnalysisState>({
    setResetAnalysisInProgress: () => {},
    isResetAnalysisInProgress: false,
    isResetAnalysisVisible: false,
    setResetAnalysisVisible: () => {},
});

export const ResetAnalysisStateProvider = ({ children }: PropsWithChildren) => {
    const initState: ResetAnalysisState = {
        isResetAnalysisInProgress: false,
        isResetAnalysisVisible: false,
        setResetAnalysisInProgress: (isResetAnalysisInProgress: boolean) =>
            dispatch({
                type: ResetAnalysisActionType.SET_RESET_ANALYSIS_IN_PROGRESS,
                payload: isResetAnalysisInProgress,
            }),
        setResetAnalysisVisible: (isResetAnalysisVisible: boolean) =>
            dispatch({
                type: ResetAnalysisActionType.SET_RESET_ANALYSIS_VISIBLE,
                payload: isResetAnalysisVisible,
            }),
    };

    const [resetAnalysisState, dispatch] = useReducer(resetAnalysisReducer, initState);

    return (
        <ResetAnalysisContext.Provider value={resetAnalysisState}>
            {children}
        </ResetAnalysisContext.Provider>
    );
};
