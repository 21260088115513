import { styled } from '@mui/material';
import * as ToggleGroup from '@radix-ui/react-toggle-group';

export const StyledToggleGroup = styled(ToggleGroup.Root)(({ theme }) => ({
    display: 'flex',
    float: 'right',
    height: 'auto',
    alignItems: 'right',
    verticalAlign: 'center',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
}));

export const StyledToggleGroupItem = styled(ToggleGroup.Item)(({ theme }) => ({
    marginLeft: '0',
    height: '26px',
    float: 'right',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    border: '1px solid rgba(0,0,0,0.15)',

    fontFamily: 'Segoe UI',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    textAlign: 'left',

    '&:first-of-type': {
        borderTopLeftRadius: theme.spacing(1),
        borderBottomLeftRadius: theme.spacing(1),
    },

    '&:last-child': {
        borderTopRightRadius: theme.spacing(1),
        borderBottomRightRadius: theme.spacing(1),
    },

    "&[data-state='on']": {
        borderColor: theme.colors.button['primary'].outlinedBorder,
        backgroundColor: theme.colors.button['primary'].containedBackground,
        color: theme.colors.button['primary'].containedColor,
    },

    "&[data-state='off']": {
        '&:hover': {
            backgroundColor: theme.colors.button['primary'].textHover,
        },
    },
}));
