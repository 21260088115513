import { useQueryClient } from '@tanstack/react-query';
import { func, object } from 'prop-types';
import { useCallback, useState } from 'react';

import {
    ccdLoadingCompletedWithError,
    ccdLoadingCompletedWithSuccess,
    ccdLoadingToast,
} from 'components/_legacy/ccd-toast';
import useHttp from 'components/_legacy/hooks/useHttp';
import { useFileManagementContext } from 'components/templates/FileManagement/components/FileManagementContext';
import { FILES_QUERY } from 'components/templates/FileManagement/components/parts/Table/service/files.query';

import { EE, FILE_MOVED } from '../../../eventEmitter';
import { useFileFromDestinationFolder, useGlobalUiBlockersCounterContext } from '../../../hooks';
import {
    isSuccessfulPromiseFilter,
    isUnsuccessfulPromiseFilter,
    nullIfEmpty,
} from '../../../utils';
import getMessageStrategy from './moveMessageStrategies';

export default function useMoveFile(formHandler, onClose, showModal) {
    const { sendRequest } = useHttp();

    const [state, updateState] = useState({
        currentFolder: {
            id: '',
            name: '',
        },
        selectedFiles: [],
    });
    const dispatch = useCallback(
        (arg1) =>
            updateState((prevState) => ({
                ...prevState,
                ...arg1,
            })),
        [updateState]
    );

    const { increaseBlockerCounter, decreaseBlockerCounter } = useGlobalUiBlockersCounterContext();
    const { getInvalidFiles } = useFileFromDestinationFolder();
    const [moveInProgress, setMoveInProgress] = useState(false);

    const queryClient = useQueryClient();

    const {
        state: {
            backendUrl,
            collaborationSpaceId,
            projectId,
            selectedFiles: mainTableSelection,
            dataLoadToggle,
        },
        dispatch: dispatchFileManagement,
    } = useFileManagementContext();

    function getTargetFolderId() {
        const targetFolder =
            state.selectedFiles.length === 0 ? state.currentFolder.id : state.selectedFiles[0].id;
        return nullIfEmpty(targetFolder);
    }

    const messageStrategy = getMessageStrategy(mainTableSelection);

    function moveFiles(elements) {
        if (!elements) {
            return [];
        }
        const promises = [];
        for (const element of elements) {
            const promise = new Promise((resolve, reject) => {
                const requestConfig = {
                    url: new URL(
                        `/frontend/file-management/${collaborationSpaceId}/${projectId}/transfer`,
                        backendUrl
                    ),
                    method: 'POST',
                    body: {
                        fileId: element.id,
                        targetFolderId: getTargetFolderId(),
                    },
                    cache: 'no-cache',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    credentials: 'include',
                };

                const onError = (error) => {
                    error.file = element;
                    reject(error);
                };

                const onSuccess = () => {
                    resolve(element);
                };

                sendRequest({
                    requestConfig,
                    onError,
                    onSuccess,
                });
            });
            promises.push(promise);
        }
        return promises;
    }

    function enableControls() {
        formHandler.enableButton();
        setMoveInProgress(false);
    }

    async function handleAllPromises() {
        const toastId = ccdLoadingToast({
            messages: messageStrategy.loadingMessage(),
        });
        await Promise.allSettled(moveFiles(mainTableSelection)).then(async (promiseResults) => {
            if (promiseResults.every(isSuccessfulPromiseFilter)) {
                ccdLoadingCompletedWithSuccess(toastId, {
                    title: 'Move successful',
                    messages: messageStrategy.successMessage(mainTableSelection),
                });
                dispatchFileManagement({
                    dataLoadToggle: !dataLoadToggle,
                    selectedFiles: [],
                });
                enableControls();
                onClose();
            } else if (promiseResults.every(isUnsuccessfulPromiseFilter)) {
                ccdLoadingCompletedWithError(toastId, {
                    title: 'Move failed',
                    messages: messageStrategy.moveFailedMessage(),
                    autoCloseTime: 10000,
                });
                enableControls();
            } else {
                const notMovedFiles = promiseResults
                    .filter(isUnsuccessfulPromiseFilter)
                    .map((rejectedResults) => rejectedResults.reason.file);
                ccdLoadingCompletedWithError(toastId, {
                    title: 'Move failed',
                    messages: messageStrategy.movePartiallyFailedMessage(),
                    autoCloseTime: 10000,
                });
                dispatchFileManagement({
                    dataLoadToggle: !dataLoadToggle,
                    selectedFiles: notMovedFiles,
                });
                enableControls();
                onClose();
            }
            const successfulMoves = promiseResults.filter(isSuccessfulPromiseFilter);
            successfulMoves.map(() => EE.emit(FILE_MOVED));
        });
    }

    const onSubmit = useCallback(async () => {
        increaseBlockerCounter();
        formHandler.disableButton();
        setMoveInProgress(true);
        const filesConflicts = await getInvalidFiles(mainTableSelection, getTargetFolderId());
        if (filesConflicts.length === 0) {
            await handleAllPromises();
            await queryClient.invalidateQueries({
                queryKey: [FILES_QUERY, collaborationSpaceId, projectId],
            });
        } else {
            showModal(filesConflicts);
            onClose();
        }
        decreaseBlockerCounter();
    }, [mainTableSelection, formHandler, state.selectedFiles]);

    return { state, dispatch, onSubmit, moveInProgress };
}

useMoveFile.propTypes = {
    formHandler: object,
    onClose: func,
    showModal: func,
};
