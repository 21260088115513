import { useContext } from 'react';

import GlobalUiBlockersCounterContext from '../contexts/GlobalUiBlockersCounterContext';

function useGlobalUiBlockersCounterContext() {
    const context = useContext(GlobalUiBlockersCounterContext);

    if (!context) {
        throw new Error(
            'useGlobalUiBlockersCounterContext must be used within a GlobalUiBlockersCounterContextProvider'
        );
    }

    return context;
}

export default useGlobalUiBlockersCounterContext;
