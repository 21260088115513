import { useEffect, useState } from 'react';

import { VerticalMenuViewType } from 'components/molecules/VerticalMenu/VerticalMenu';
import { ICurrentFolderContext } from 'components/templates/FileManagement/components/CurrentFolderContextProvider/CurrentFolderContextProvider';
import { useFileManagementContext } from 'components/templates/FileManagement/components/FileManagementContext/FileManagementContextProvider';
import filesQuery from 'components/templates/FileManagement/components/parts/Table/service/files.query';
import { useSettings } from 'hooks/useSettings';
import { OnGridSort, SortFields, SortType } from 'hooks/useSortTiles';

export type FilesTableName = 'name' | 'creationDate' | 'lastModifiedDate' | 'lastModifiedBy';
export type FilesTableSortDirection = 'ascending' | 'descending' | undefined;

export type OnFilesTableSort = (
    sortColumn: { id: FilesTableName },
    sortOrder: FilesTableSortDirection
) => void;

export interface FilesTableSort {
    sortColumn: FilesTableName;
    sortOrder: FilesTableSortDirection;
}

export interface IPagination {
    hasMore: boolean;
    update: () => void;
}

export type FileFilterHandler = (text: string) => void;

const fileManagementSettings = 'fileManagementSettings';

export interface ISettings {
    current: VerticalMenuViewType.GRID | VerticalMenuViewType.TABLE;
    grid: SortFields;
    table: FilesTableSort;
}

export default function useTable(
    currentFolder: any,
    dispatchFileManagement: any
): ICurrentFolderContext {
    const [filteringPhrase, setFilteringPhrase] = useState<string>();
    const { getSetting, storeSetting } = useSettings();

    const [settings, setSettings] = useState<ISettings>(() => {
        const settings = getSetting(fileManagementSettings);
        return settings
            ? JSON.parse(settings)
            : {
                  current: VerticalMenuViewType.GRID,
                  grid: {
                      sortColumn: 'lastModifiedDate',
                      sortOrder: 'descending',
                      type: SortType.LATEST,
                  },
              };
    });

    useEffect(() => {
        dispatchFileManagement({
            selectedFiles: [],
        });
        storeSetting(fileManagementSettings, JSON.stringify(settings));
    }, [settings]);

    const {
        state: { collaborationSpaceId, projectId },
    } = useFileManagementContext() as any;

    const { data, isError, isLoading } = filesQuery({
        projectId: projectId,
        collaborationSpaceId: collaborationSpaceId,
        folderId: currentFolder?.id.length ? currentFolder?.id : undefined,
        sortDir: settings[settings.current]?.sortOrder,
        sortBy: settings[settings.current]?.sortColumn,
    });

    const onViewChange = (viewType: VerticalMenuViewType) => {
        setSettings((prevState) => ({
            ...prevState,
            current:
                viewType === VerticalMenuViewType.TABLE
                    ? VerticalMenuViewType.TABLE
                    : VerticalMenuViewType.GRID,
        }));
    };

    const sortingHandler: OnFilesTableSort = (column, direction) => {
        let sorting: FilesTableSort;
        if (direction) {
            sorting = {
                sortColumn: column.id,
                sortOrder: direction,
            };
        }
        setSettings((prevState) => ({ ...prevState, table: sorting }));
    };

    const gridSortingHandler: OnGridSort = (sort) => {
        setSettings((prevState) => ({ ...prevState, grid: sort }));
    };

    const filteringHandler: FileFilterHandler = (text) => {
        if (text) {
            setFilteringPhrase(text.toLowerCase());
        } else {
            setFilteringPhrase(undefined);
        }
        dispatchFileManagement({
            selectedFiles: [],
        });
    };
    const getFilteredData = () => {
        if (filteringPhrase) {
            return data?.filter(
                (file) =>
                    file.name.toLowerCase().includes(filteringPhrase) ||
                    file.createdBy.toLowerCase().includes(filteringPhrase)
            );
        } else {
            return data;
        }
    };

    return {
        sortingHandler,
        gridSortingHandler,
        filteringHandler,
        data,
        filteredData: getFilteredData(),
        hasError: isError,
        isLoading,
        onViewChange,
        sortType: settings.grid.sortType,
        settings,
    };
}
