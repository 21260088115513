import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router';

import fetcher from 'providers/api/fetcher';
import { useUserDataContext } from 'providers/currentUser/UserDataProvider';
import useFeatureFlagsQuery from 'services/queries/featuresFlags.query';

export const getVariants = (revisionId: string): UseQueryResult<string[]> => {
    const { collaborationSpaceId } = useUserDataContext();
    const { projectId } = useParams();
    const { data: featureFlags } = useFeatureFlagsQuery();
    return useQuery(
        ['getVariants', collaborationSpaceId, projectId, revisionId],
        async () => {
            const { data } = await fetcher.get(
                `frontend/project/bom/variants/${collaborationSpaceId}/${projectId}`,
                {
                    params: {
                        projectVersion: revisionId,
                    },
                }
            );
            return data;
        },
        {
            enabled: !!+revisionId && featureFlags?.SynchronizationApi_BomFromCce,
        }
    );
};
