import CmdCreateFolder16 from 'images/cmdCreateFolder16.svg?react';

import { CREATE_FOLDER_BUTTON_ID } from '../../../../consts';
import { useCommandBarVisibilityContext } from '../../../../hooks';
import { AddFolderForm } from '../../../parts';
import useSideNavVisibility from '../../../parts/FileForm/useSideNavVisibility';

export default function useAddFolder() {
    const { isAddFolderCommandAccessible } = useCommandBarVisibilityContext();
    const { visibility, closeSideNav, switchSideNavVisibility } = useSideNavVisibility();

    return {
        addFolderForm: (
            <AddFolderForm
                key={CREATE_FOLDER_BUTTON_ID}
                isVisible={visibility}
                onClose={closeSideNav}
            />
        ),
        addFolder: {
            id: CREATE_FOLDER_BUTTON_ID,
            children: 'Create Folder',
            onClick: switchSideNavVisibility,
            visible: isAddFolderCommandAccessible,
            leftIcon: CmdCreateFolder16,
            variant: 'text',
            color: 'gray',
        },
    };
}
