import { useContext } from 'react';

import { useXccCompareContext } from 'hooks/XccCompareContextProvider';
import { useCurrentTopMargin } from 'hooks/useCurrentTopMargin';
import { XccInfrastructureProviderContext } from 'providers/xccInfrastructure/XccInfrastructureProvider';

const useXccGuidanceMessagesFix = () => {
    const xccContext = useContext(XccInfrastructureProviderContext);
    const xccCompareContext = useXccCompareContext();
    const { topMargin } = useCurrentTopMargin();
    const arr = [
        xccContext.processingGuidanceMessageVisible,
        xccContext.processedGuidanceMessageVisible,
        xccCompareContext.processingGuidanceMessageVisible,
        xccCompareContext.processedGuidanceMessageVisible,
    ];
    const calculateHeight = {
        height: `calc(100vh - ${topMargin} - ${arr.filter((item) => item).length * 32}px)`,
        top: `calc(${arr.filter((item) => item).length * 32}px + ${topMargin})`,
    };

    const calculateTop = {
        top: `calc(${arr.filter((item) => item).length * 32}px + ${topMargin})`,
    };

    return {
        calculateTop: () => calculateTop,
        calculateHeight: () => calculateHeight,
    };
};

export default useXccGuidanceMessagesFix;
