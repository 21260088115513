import { useContext } from 'react';

import CcdRevisionTile from 'components/_legacy/ccd-revision-tile';
import { useProjectHistoryLinePanel } from 'components/templates/ProjectHistory/ProjectHistoryPanel/useProjectHistoryLinePanel';
import { ProcessedRevision } from 'components/templates/ProjectHistory/ProjectHistoryView/ProjectHistoryView';
import { AccordionType } from 'components/templates/ProjectHistory/ProjectHistoryViewAccordion/ProjectHistoryViewAccordion';

import ProjectHistoryItemVersionPanel from '../ProjectHistoryItemVersionPanel';
import ProjectHistoryLine from '../ProjectHistoryLine/ProjectHistoryLine';
import ProjectInfoContext from '../ProjectInfoContext';

import './ProjectHistoryPanel.scss';

export default function useProjectHistoryLineLeftPanel({
    accordionType,
    revision,
}: {
    accordionType?: AccordionType;
    revision: ProcessedRevision;
}) {
    const projectInfoContext = useContext(ProjectInfoContext);
    const { onResize, onProjectTitle, height } = useProjectHistoryLinePanel({ revision });

    return () => {
        return (
            <>
                <div className='project-history-panel-elements'>
                    <CcdRevisionTile
                        key={'id2' + revision.versionNumber}
                        title={revision.eventName}
                        description={revision.description}
                        projectTitle={projectInfoContext.projectName}
                        thumbnailUrl={projectInfoContext.thumbnail}
                        eventDescription={revision.eventDescription}
                        creator={revision.lastModified.by.name}
                        onResize={onResize}
                        onProjectTitle={
                            projectInfoContext.currentRevision !== +revision.versionNumber
                                ? onProjectTitle
                                : undefined
                        }
                    />
                </div>
                <ProjectHistoryLine
                    key={'id1' + revision.versionNumber}
                    direction='left'
                    accordionType={accordionType}
                    containerHeight={height}
                />
                <div className='project-history-panel-elements project-history-panel-revision-element'>
                    <ProjectHistoryItemVersionPanel
                        key={'id0' + revision.versionNumber}
                        revision={revision}
                    />
                </div>
            </>
        );
    };
}
