import { UseMutationOptions, useMutation } from '@tanstack/react-query';

import { createProject } from 'services/api/project.service';
import { CreateProjectRequest, Project } from 'types/project';

const useCreateNewProject = (
    options: UseMutationOptions<Project, undefined, CreateProjectRequest>
) => useMutation(createProject, options);

export default useCreateNewProject;
