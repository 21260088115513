import React from 'react';

import Button, { ButtonProps, IButton } from '../Button/Button';
import { Spinner } from '../ProgressSpiner/ProgressSpinner';

export const SpinnerButton = React.forwardRef<
    HTMLButtonElement,
    IButton &
        ButtonProps & {
            isLoading: boolean;
        }
>(({ children, isLoading, ...props }, ref) => {
    return (
        <Button ref={ref} {...props}>
            <div style={{ display: 'flex' }}>
                {isLoading ? (
                    <Spinner
                        style={{
                            marginRight: '8px',
                            width: '16px',
                            height: '16px',
                        }}
                    />
                ) : null}
                {children}
            </div>
        </Button>
    );
});
SpinnerButton.displayName = 'StatusButton';
