import { UseMutationOptions, useMutation } from '@tanstack/react-query';

import { setThemeSettings } from 'services/api/theme.service';
import { SetThemeSettingsRequest, SetThemeSettingsResponse } from 'types/themeSettings';

const useSetThemeSettings = (
    options: UseMutationOptions<SetThemeSettingsResponse, undefined, SetThemeSettingsRequest>
) => useMutation(setThemeSettings, options);

export default useSetThemeSettings;
