import { debounce } from 'lodash';
import { useEffect, useMemo, useRef } from 'react';

export const useDebounce = (callback: () => void) => {
    const ref = useRef<typeof callback>();
    const switchChangeTimeout = 500;

    useEffect(() => {
        ref.current = callback;
    }, [callback]);

    const debouncedCallback = useMemo(() => {
        const func = () => {
            ref.current?.();
        };

        return debounce(func, switchChangeTimeout);
    }, []);

    return debouncedCallback;
};
