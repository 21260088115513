import React from 'react';

import useProjectHistoryLinePrototypesHook from './useProjectHistoryLinePrototypes';

export default function useProjectHistoryLineElementsHook(direction, width, height) {
    const { leftLine, rightLine, itemAnchor } = useProjectHistoryLinePrototypesHook(width, height);

    const baseItemElement = (
        <React.Fragment key='useProjectHistoryLineElementsHook'>
            {itemAnchor}
            {direction === 'left' && leftLine}
            {direction === 'right' && rightLine}
        </React.Fragment>
    );

    return {
        baseItemElement,
    };
}
