import styled from '@emotion/styled';
import { Tabs, TabsProps } from '@mui/material';

export const StyledTabs = styled((props: TabsProps) => (
    <Tabs {...props} TabIndicatorProps={{ children: <span className='MuiTabs-indicatorSpan' /> }} />
))(({ theme }) => ({
    margin: theme.spacing(2, 0),
    fontSize: 14,
    lineHeight: 14,

    minHeight: 36,
    height: 36,

    '& .MuiButtonBase-root': {
        color: theme.colors.text,
        minWidth: 'fit-content',
        margin: theme.spacing(0, 1, 0, 1),
        padding: theme.spacing(0, 1, 1),
        minHeight: 36,
        height: 36,
        fontWeight: 600,
        '&:first-of-type': {
            marginLeft: theme.spacing(-1),
        },
    },
    '& .Mui-selected': {
        color: `${theme.colors.selectedTabText}  !important`,
    },
    '& .MuiTabs-indicator': {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
        width: '100%',
    },
    '& .MuiTabs-indicatorSpan': {
        '&:first-of-type': {
            minWidth: 'calc(100% - 8px)',
        },

        backgroundColor: theme.colors.backgroundInverted,
    },
}));
