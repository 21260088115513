import PropTypes from 'prop-types';

import { useCommandBarVisibilityContext } from '../../../../hooks';
import {
    FilesAlreadyExistsModalForFileUpload,
    useFilesAlreadyExistsModal,
} from '../../../parts/FilesAlreadyExistsModal';
import { useNoAccessUploadModal } from './NoUploadAccessModal';
import useUpload from './useUpload';

export default function CcdInputUpload({ children }) {
    const { isUploadFileCommandAccessible } = useCommandBarVisibilityContext();
    const {
        isModalVisible: isFilesAlreadyExistsModalVisible,
        namesConflicts,
        hideModal: hideFilesAlreadyExistsModal,
        showModal: showFilesAlreadyExistsModal,
    } = useFilesAlreadyExistsModal();
    const { showModal: showNoAccessModal } = useNoAccessUploadModal();

    const { onInputUpload } = useUpload(showFilesAlreadyExistsModal, showNoAccessModal, false);

    if (!isUploadFileCommandAccessible) {
        return null;
    }

    return (
        <>
            {children(onInputUpload, isUploadFileCommandAccessible)}
            {isFilesAlreadyExistsModalVisible && (
                <FilesAlreadyExistsModalForFileUpload
                    filesNames={namesConflicts}
                    onClose={hideFilesAlreadyExistsModal}
                />
            )}
        </>
    );
}

CcdInputUpload.propTypes = {
    children: PropTypes.func.isRequired,
};
