import fetcher from 'providers/api/fetcher';

export interface IUnShareUserApiRequest {
    collaborationSpaceId: string;
    projectId: string;
    email: string;
}
export const unShareProjectApi = async ({
    collaborationSpaceId,
    projectId,
    email,
}: IUnShareUserApiRequest): Promise<any> => {
    await fetcher.delete(`share/project/${collaborationSpaceId}/${projectId}`, {
        data: `"${email}"`,
        headers: { 'Content-Type': 'application/json' },
    });
};
