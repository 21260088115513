import { useState } from 'react';

export default function useCheckContent(hasAccess: boolean) {
    const [validationError, setValidationError] = useState('');
    const MAX_FILE_SIZE = 50 * 1024 * 1024;

    function isDragAndDropPossible(items: DataTransferItemList) {
        setValidationError('');
        return (
            hasAccess && items && dataTransferContainsOnlyOneFile(items) && isAcceptedFile(items[0])
        );
    }

    function isFileValid(files: FileList) {
        setValidationError('');
        return (
            hasAccess &&
            files &&
            dataTransferContainsOnlyOneFile(files) &&
            isAcceptedFile(files[0]) &&
            validateFileSize(files[0])
        );
    }

    function dataTransferContainsOnlyOneFile(items: DataTransferItemList | FileList) {
        if (items.length === 1) {
            return true;
        }
        setValidationError('Only one file is allowed.');
        return false;
    }

    const isAcceptedFile = (file: DataTransferItem | File) => {
        const fileType = file.type.split('/')[1];
        switch (fileType) {
            case 'png':
            case 'jpg':
            case 'jpeg':
            case 'gif': {
                return true;
            }
            default: {
                setValidationError('File type is not supported.');
                return false;
            }
        }
    };

    function validateFileSize(file: File) {
        if (file.size <= MAX_FILE_SIZE) {
            return true;
        }
        setValidationError('File size should be 50MB or less.');
        return false;
    }

    return {
        isDragAndDropPossible,
        isFileValid,
        validationError,
    };
}
