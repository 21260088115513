import { useCallback, useRef, useState } from 'react';

import useDetectOutsideClick from 'components/_legacy/hooks/useDetectOutsideClick';
import { VerticalMenuViewType } from 'components/molecules/VerticalMenu/VerticalMenu';
import ListIcon from 'images/cmdListView16.svg?react';
import TableIcon from 'images/cmdTableView16.svg?react';
import { OnViewChange } from 'pages/AllProjects/components/AllProjectsMenu';

export default function useChangeView(
    viewTypeChange: OnViewChange,
    viewType: string,
    actionLocked?: boolean
) {
    const [isActiveChangeViewMenu, setChangeViewVisibility] = useState(false);

    const changeVisibilityRefObject = useRef(null);
    const outsideClickCallback = useCallback(() => {
        setChangeViewVisibility(false);
    }, []);

    useDetectOutsideClick(changeVisibilityRefObject, outsideClickCallback, true);
    const onGridView = useCallback(() => {
        if (!actionLocked) {
            viewTypeChange(VerticalMenuViewType.GRID);
        }
    }, []);
    const onTableView = useCallback(() => {
        if (!actionLocked) {
            viewTypeChange(VerticalMenuViewType.TABLE);
        }
    }, []);

    const menuItems = [
        {
            name: 'Tile view',
            icon: ListIcon,
            callback: onGridView,
        },
        {
            name: 'Table view',
            icon: TableIcon,
            callback: onTableView,
        },
    ];
    return {
        changeView: {
            id: '1',
            children: 'Change View',
            onClick: () => {
                setChangeViewVisibility((prev) => !prev);
            },
            leftIcon: viewType === VerticalMenuViewType.GRID ? TableIcon : ListIcon,
            popupMenu: {
                menuItems: menuItems,
                isActive: isActiveChangeViewMenu,
                direction: 'right',
            },
            variant: 'text',
            color: 'gray',
            visible: true,
            ref: changeVisibilityRefObject,
        },
    };
}
