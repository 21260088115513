import styled from '@emotion/styled';

export const NotificationHeaderWrapper = styled('div')(({ theme }) => ({
    height: theme.spacing(8),
    backgroundColor: theme.colors.accordion.header,
    userSelect: 'none',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
}));

export const NotificationHeaderLeftGroup = styled('div')(() => ({
    display: 'inherit',
}));

export const NotificationHeaderTitle = styled('p')(({ theme }) => ({
    marginLeft: theme.spacing(2),
    lineHeight: theme.spacing(4),
    fontSize: '12px',
    color: theme.colors.text,
}));

export const NotificationHeaderMarkAsRead = styled('p')(({ theme }) => ({
    color: theme.colors.actionText,
    fontWeight: '600',
    fontSize: '12px',
    '&:hover': {
        textDecoration: 'underline',
        cursor: 'pointer',
    },
}));
