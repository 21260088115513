import styled from '@emotion/styled';
import React, { MouseEventHandler, ReactElement } from 'react';

import { customColors } from 'theme';

import { TileColor } from './';

interface ITileContainerWrapper {
    tileColor?: TileColor;
    read?: boolean;
}

interface TileContainerProps extends ITileContainerWrapper {
    onClick?: MouseEventHandler<HTMLDivElement>;
    children: ReactElement;
}

const TileContainerWrapper = styled('div')<ITileContainerWrapper>(({ tileColor, read, theme }) => ({
    color: theme.colors.text,
    width: '99.5%',
    borderLeft: `4px solid ${customColors['green-1']}`,
    boxShadow: `0 1px 2px 0 ${theme.colors.shadow}`,
    borderRadius: '3px',
    padding: '12px 16px 12px 16px',
    ':hover': {
        boxShadow: '0 2px 4px 1px rgba(0, 0, 0, 0.3)',
    },
    ...(tileColor === TileColor.RED && { borderColor: customColors.red }),
    ...(tileColor === TileColor.YELLOW && { borderColor: customColors['yellow-15'] }),
    ...(!read && { backgroundColor: theme.colors.unreadTile }),
}));

export function TileContainer({
    children,
    read = true,
    onClick,
    tileColor = TileColor.GREEN,
}: TileContainerProps) {
    return (
        <TileContainerWrapper
            tileColor={tileColor}
            read={read}
            onClick={onClick}
            data-testid={read ? 'revision-tile-container-read' : 'revision-tile-container-unread'}
        >
            {children}
        </TileContainerWrapper>
    );
}
