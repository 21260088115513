import { useQuery } from '@tanstack/react-query';

import { getCollaborationSpaces, getProvisioningStatus } from 'services/api/auth.service';

export const useGetCollaborationSpaces = () =>
    useQuery({
        queryKey: ['getCollaborationSpaces'],
        queryFn: getCollaborationSpaces,
    });

export const useProvisioningStatus = (checkProvisioningStatus: boolean = false) =>
    useQuery({
        queryKey: ['getProvisioningStatus'],
        queryFn: getProvisioningStatus,
        enabled: checkProvisioningStatus,
        refetchInterval: 500,
    });
