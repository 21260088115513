import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const Expandable = styled(Box)({
    width: '100%',
    whiteSpace: 'pre-wrap',
    height: 'fit-content',
});

export const Description = styled(Box)({
    backgroundColor: 'transparent',
    height: 24,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
});

export const WrappedDescription = styled(Box)({
    backgroundColor: 'transparent',
    height: 24,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'pre-wrap',
});

export const ToggleButtonArea = styled(Box)(({ theme }) => ({
    float: 'right',
    cursor: 'pointer',
    marginTop: theme.spacing(3.5),
}));
