import { func, number, object, string } from 'prop-types';

import { ColumnResizerDnd } from 'components/_legacy/ccd-table/components/CcdTableDragAndDropHeaderColumn/HeaderColumn.styles';

import CcdSortingMenu from '../CcdSortingMenu';

const CcdTableDragAndDropHeaderColumn = ({
    column,
    headerProps,
    onDragOver,
    onDrop,
    onDragStart,
    onDrag,
    onDragEnd,
    onDragEnter,
    onSortedMemo,
    addSortingMenu,
    headerGroup,
    index,
    initialSortDirection,
}) => {
    const desiredHeaderColumnProps = column.getHeaderProps(headerProps);
    // because it spoils elements visibility, i.e. popup
    delete desiredHeaderColumnProps.style.position;
    // spoils table header in Apollo
    delete desiredHeaderColumnProps.style.alignItems;

    return (
        <div
            key={column.id}
            className='th'
            {...desiredHeaderColumnProps}
            onDragOver={onDragOver}
            onDrop={onDrop}
            data-index={index}
        >
            <span className='ccd-table-header-ellipsis'>
                <span
                    className='ccd-table-header-ellipsis-text'
                    draggable={column.id !== 'empty'}
                    onDragStart={onDragStart}
                    onDrag={onDrag}
                    onDragEnd={onDragEnd}
                    onDragEnter={onDragEnter}
                    data-index={index}
                    data-column-id={column.id}
                >
                    {column.render('Header')}
                </span>
                <div className='ccd-table-header-ellipsis-sorting-icon'>
                    {column.ccdSorted && (
                        <CcdSortingMenu
                            useTableOffset
                            column={column}
                            onSorted={onSortedMemo}
                            direction={addSortingMenu(column, headerGroup.headers)}
                            initialSortDirection={initialSortDirection}
                        />
                    )}
                </div>
            </span>
            <ColumnResizerDnd {...column.getResizerProps()} id={'columnResizer' + column.Header} />
        </div>
    );
};

export default CcdTableDragAndDropHeaderColumn;

CcdTableDragAndDropHeaderColumn.propTypes = {
    column: object,
    headerProps: func,
    onDragOver: func,
    onDrop: func,
    onDragStart: func,
    onDrag: func,
    onDragEnd: func,
    onDragEnter: func,
    onSortedMemo: func,
    addSortingMenu: func,
    headerGroup: object,
    index: number,
    initialSortDirection: string,
};
