import React from 'react';

import { CcdTileGrid } from 'components/_legacy/ccd-tile-grid';
import { ProjectTileThumbnail } from 'components/templates/ProjectTiles/ProjectTileThumbnail';
import { isScanStatusInfected } from 'shared/utility';
import { IProjectOpen, Project } from 'types/project';
import { SelectedProjectData } from 'types/selection';

import ProjectTileFooter from '../ProjectTileFooter';
import {
    DefaultThumbnail,
    InfectedIcon,
    InfectedThumbnail,
    Paragraph,
    Thumbnail,
    Tile,
} from './ProjectTiles.styles';

export interface ProjectTilesInterface extends React.PropsWithChildren {
    data: Array<Project>;
    loadingComponent?: React.ReactNode;
    onSelected?: (project: Array<SelectedProjectData>) => void;
    onOpen: (project: IProjectOpen) => void;
    currentSelection?: Project;
}

export function ProjectTiles({
    loadingComponent,
    data,
    onSelected = () => {},
    onOpen,
    currentSelection,
}: ProjectTilesInterface) {
    const getThumbnail = (record: Project) => {
        if (record.thumbnailUri) {
            return <Thumbnail draggable={false} src={record.thumbnailUri} alt={record.name} />;
        }
        if (isScanStatusInfected(record.scanInfo)) {
            return (
                <InfectedThumbnail>
                    <InfectedIcon />
                    <Paragraph>Security scan detected a threat in this cover image.</Paragraph>
                    <Paragraph>Make sure you upload a virus free file.</Paragraph>
                </InfectedThumbnail>
            );
        }

        return <DefaultThumbnail selected={currentSelection?.projectId === record.projectId} />;
    };

    const tileFactory = (record: Project) => {
        return (
            <Tile key={record.projectId}>
                <ProjectTileThumbnail project={record}>{getThumbnail(record)}</ProjectTileThumbnail>
                <ProjectTileFooter
                    onNameClick={() => {
                        onOpen({
                            projectId: record.projectId,
                            latestRevision: record.latestRevision,
                        });
                    }}
                    name={record.name}
                    modificationDate={new Date(record.lastModifiedDate)}
                    createdBy={record.lastModifiedBy}
                    collaborationSpaceId={record.collaborationSpaceId}
                    isSharedWithMe={record.isSharedWithMe}
                    isSharedByMe={record.isSharedByMe}
                    status={record.status}
                />
            </Tile>
        );
    };

    return (
        <CcdTileGrid
            componentAfter={loadingComponent}
            data={data as any}
            tileFactory={tileFactory}
            settings={{ multiselection: false }}
            onSelected={onSelected}
            onDoubleClick={(tile) => onOpen(tile.original)}
        />
    );
}

export default ProjectTiles;
