import styled from '@emotion/styled';

import Box from 'components/atoms/Box';

export const Center = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
});

export const AlignCenter = styled(Box)({
    display: 'flex',
    alignItems: 'center',
});
