import { useContext } from 'react';

import { TopMarginContext } from 'providers/topMargin/TopMarginProvider';

export const useCurrentTopMargin = () => {
    const { topMargin } = useContext(TopMarginContext);

    return {
        topMargin,
    };
};
