import React, { useEffect, useState } from 'react';

import AuroraDataPrivacy from 'components/organisms/DataPrivacy';
import { useGetAuroraOptOutQuery } from 'services/queries/analyticsSettings.query';
import useFeatureFlagsQuery from 'services/queries/featuresFlags.query';

const ProductExcellenceProgramDialog = () => {
    const { data: featureFlags } = useFeatureFlagsQuery();
    const { data } = useGetAuroraOptOutQuery();

    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (data && data.pepOptOut === null) {
            setOpen(true);
        }
    }, [data]);

    if (featureFlags?.Analytics_SiemensAuroraAnalyticsEnabled) {
        return <AuroraDataPrivacy open={open} setOpen={setOpen} pepOnly />;
    } else {
        return null;
    }
};

export default ProductExcellenceProgramDialog;
