import fetcher from 'providers/api/fetcher';
import { GetProvisioningStatusResponse } from 'types/auth';

export const getCollaborationSpaces = async (): Promise<string> => {
    const { data } = await fetcher.get(`/frontend/collaboration-spaces`);
    return data[0].collaborationSpaceId;
};

export const getProvisioningStatus = async (): Promise<GetProvisioningStatusResponse> => {
    const { data } = await fetcher.get(`/frontend/provisioning/progress`);
    return data;
};
