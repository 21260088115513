import { useEffect, useState } from 'react';

import { ProgressIndicator, ProgressLoop, ProgressRoot } from './ProgressBar.styles';

export const PROGRESS_BAR_EVENT = 'progressEvent';

export interface ProgressParams {
    variant?: 'loop' | 'progress';
    progress?: number;
}

export interface ProgressEvent {
    eventName: 'start' | 'end';
    eventArgs: ProgressParams;
}

const ProgressBar = () => {
    const [visible, setVisible] = useState(false);
    const [progress, setProgress] = useState(0);
    const [variant, setVariant] = useState<'progress' | 'loop'>('loop');

    const setProgressBar = (values: CustomEvent<ProgressEvent>) => {
        values.detail.eventArgs?.variant
            ? setVariant(values.detail.eventArgs.variant)
            : setVariant('loop');
        values.detail.eventArgs?.progress
            ? setProgress(values.detail.eventArgs.progress)
            : setProgress(0);
        values.detail?.eventName === 'start' && setVisible(true);
        values.detail?.eventName === 'end' && setVisible(false);
    };

    useEffect(() => {
        window.addEventListener(PROGRESS_BAR_EVENT, setProgressBar as EventListener);

        return () => {
            window.removeEventListener(PROGRESS_BAR_EVENT, setProgressBar as EventListener);
        };
    }, []);

    return visible ? (
        <ProgressRoot value={progress}>
            {variant === 'progress' && <ProgressIndicator progress={progress} />}
            {variant === 'loop' && <ProgressLoop />}
        </ProgressRoot>
    ) : null;
};

export default ProgressBar;
