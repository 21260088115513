import React, { useCallback } from 'react';

import { CcdAccordion } from 'components/_legacy/ccd-accordion';
import Box from 'components/atoms/Box';
import { ProcessedRevision } from 'components/templates/ProjectHistory/ProjectHistoryView/ProjectHistoryView';

import ProjectHistoryPanel from '../ProjectHistoryPanel';

export type AccordionType = 'single' | 'first' | 'last';
export type Direction = 'left' | 'right';
export default function ProjectHistoryViewAccordion({
    title,
    revisions,
    accordionType,
    initialDirection,
}: {
    title: string;
    revisions: ProcessedRevision[];
    accordionType?: AccordionType;
    initialDirection: number;
}) {
    const direction: (index: number) => Direction = useCallback(
        (index) => {
            return (initialDirection + index) % 2 === 0 ? 'left' : 'right';
        },
        [initialDirection]
    );

    const createItems: Function = useCallback(
        (revisions: ProcessedRevision[]) => {
            return revisions.map((item, index) => (
                <ProjectHistoryPanel
                    accordionType={accordionType}
                    isLast={index === 0}
                    isFirst={index === revisions.length - 1}
                    direction={direction(index)}
                    revision={item}
                    key={'history_panel' + item.versionNumber + index}
                />
            ));
        },
        [initialDirection]
    );

    return (
        <Box css={{ width: '100%' }} data-testid={'project-history-accordion'}>
            <CcdAccordion key={'accordion' + title} title={title} open>
                {createItems(revisions)}
            </CcdAccordion>
        </Box>
    );
}
