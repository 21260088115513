import { CcdEmptyState } from 'components/_legacy/ccd-empty-state';
import EmptyIcon from 'images/History24.svg?react';

export default function ProjectHistoryViewEmptyState() {
    return (
        <CcdEmptyState
            message='No project history to show yet'
            subMessage='Please make your first Check-in in your desktop application'
            icon={EmptyIcon}
            id='HistoryViewEmptyState'
        />
    );
}
