import cx from 'classnames';
import debounce from 'lodash/debounce';
import isNil from 'lodash/isNil';
import { arrayOf, bool, node, oneOfType, string } from 'prop-types';
import { useEffect, useRef, useState } from 'react';

import Box from 'components/atoms/Box';

import './ccdOverlay.css';

const DEBOUNCE_DELAY = 10;

const getElementDimensions = (el) => {
    if (isNil(el)) {
        throw new Error('There is no root element.');
    }

    const { width, height } = el.getBoundingClientRect();

    return { width, height };
};

const CcdOverlay = ({ children, isBlocked, className }) => {
    const wrapperRef = useRef(null);
    const [overlayScreenStyle, setOverlayScreenStyle] = useState({
        width: 0,
        height: 0,
    });
    const desiredClassName = cx(`overlay-wrapper`, className);

    useEffect(() => {
        const debouncedOnResize = debounce(() => {
            setOverlayScreenStyle(getElementDimensions(wrapperRef.current));
        }, DEBOUNCE_DELAY);

        window.addEventListener('resize', debouncedOnResize);

        return () => window.removeEventListener('resize', debouncedOnResize);
    }, []);

    useEffect(() => {
        isBlocked === true && setOverlayScreenStyle(getElementDimensions(wrapperRef.current));
    }, [isBlocked]);

    return (
        <Box className={desiredClassName} ref={wrapperRef} css={{ height: '100%' }}>
            {children}
            {isBlocked && <div className='overlay-wrapper__screen' style={overlayScreenStyle} />}
        </Box>
    );
};

CcdOverlay.propTypes = {
    children: oneOfType([arrayOf(node), node]),
    isBlocked: bool,
    className: string,
};

CcdOverlay.defaultProps = {
    isBlocked: false,
    className: '',
};

export default CcdOverlay;
