export default function useRescaleImage() {
    function rescaleImage(src) {
        const MAX_WIDTH = 300;

        return new Promise((resolve, reject) => {
            const img = document.createElement('img');
            img.onload = () => {
                const canvas = document.createElement('canvas');

                let width = img.width;
                let height = img.height;

                if (width > MAX_WIDTH) {
                    const ratio = MAX_WIDTH / width;
                    height = height * ratio;
                    width = width * ratio;
                }

                canvas.width = width;
                canvas.height = height;

                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0, width, height);
                resolve(canvas.toDataURL('image/png'));
            };
            img.onerror = () => {
                reject(new Error('Incorrect file'));
            };
            img.src = src;
        });
    }

    return {
        rescaleImage,
    };
}
