import { PropsWithChildren, createContext } from 'react';

import useXccService from 'hooks/useXccService';

export const XccInfrastructureProviderContext = createContext<any>({});

export function XccInfrastructureProvider({ children }: PropsWithChildren): JSX.Element {
    const xccService = useXccService();
    return (
        <XccInfrastructureProviderContext.Provider value={xccService}>
            {children}
        </XccInfrastructureProviderContext.Provider>
    );
}

export default XccInfrastructureProvider;
