import { useCallback, useState } from 'react';

import Drawer from './components/Drawer';
import NotificationIcon from './components/NotificationIcon';

export const useEventNotifications = () => {
    const [visibility, setVisibility] = useState(false);
    const switchVisibility = () => setVisibility((prev: boolean) => !prev);

    const closeSideNav = useCallback(() => setVisibility(false), []);

    return {
        notificationsIcon: <NotificationIcon />,
        notificationsComponent: visibility && <Drawer closeSideNav={closeSideNav} />,
        switchVisibility,
    };
};

export default useEventNotifications;
