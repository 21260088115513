import fetcher from 'providers/api/fetcher';
import {
    CreateProjectRequest,
    DeleteProjectRequest,
    DeleteProjectResponse,
    DeleteRevisionRequest,
    DeleteRevisionResponse,
    GetProjectRequest,
    GetProjectResponse,
    GetProjectRevisionRequest,
    GetProjectRevisionsRequest,
    GetProjectsRequest,
    GetProjectsResponse,
    GetRecentProjectsRequest,
    GetRecentProjectsResponse,
    LockProjectRequest,
    LockProjectResponse,
    Project,
    ProjectRevision,
    ProjectRevisionResponse,
    RestoreRevisionRequest,
    RestoreRevisionResponse,
    UnLockProjectRequest,
    UnLockProjectResponse,
    UpdateProjectRequest,
    UpdateProjectResponse,
} from 'types/project';

export const getProject = async ({
    collaborationSpaceId,
    projectId,
}: GetProjectRequest): Promise<GetProjectResponse> => {
    const { data } = await fetcher.get(`/frontend/project/${collaborationSpaceId}/${projectId}`);
    return data;
};

export type SortOrder = 'ASC' | 'DESC';

export const getProjects = async ({
    collaborationSpaceId,
    ownerShipFilter,
    pageSize,
    startFrom,
    sortColumn,
    sortOrder,
}: GetProjectsRequest & { sortOrder?: SortOrder }): Promise<GetProjectsResponse> => {
    const { data } = await fetcher.get(`/frontend/query-projects`, {
        params: {
            collaborationSpaceId,
            ownerShipFilter,
            pageSize,
            startFrom,
            sortColumn,
            sortOrder,
        },
    });
    return data;
};

export const getRecentProjects = async ({
    collaborationSpaceId,
}: GetRecentProjectsRequest): Promise<GetRecentProjectsResponse> => {
    const { data } = await fetcher.get(`/frontend/recent-projects`, {
        params: { collaborationSpaceId },
    });
    return data;
};

export const deleteProject = async ({
    collaborationSpaceId,
    projectId,
}: DeleteProjectRequest): Promise<DeleteProjectResponse> => {
    const { status } = await fetcher.delete(
        `/frontend/project/${collaborationSpaceId}/${projectId}`
    );
    return { status };
};

export const createProject = async ({
    collaborationSpaceId,
    bodyData,
}: CreateProjectRequest): Promise<Project> => {
    const { data } = await fetcher.post(`/frontend/project/${collaborationSpaceId}`, bodyData);
    return data;
};

export const updateProject = async ({
    collaborationSpaceId,
    projectId,
    bodyData,
}: UpdateProjectRequest): Promise<UpdateProjectResponse> => {
    const { status } = await fetcher.post(
        `/frontend/project/${collaborationSpaceId}/${projectId}`,
        bodyData
    );
    return { status };
};

export const lockProject = async ({
    collaborationSpaceId,
    projectId,
}: LockProjectRequest): Promise<LockProjectResponse> => {
    const { status } = await fetcher.post(
        `/frontend/project/lock/${collaborationSpaceId}/${projectId}`
    );
    return { status };
};

export const unlockProject = async ({
    collaborationSpaceId,
    projectId,
    force = false,
}: UnLockProjectRequest): Promise<UnLockProjectResponse> => {
    const { status } = await fetcher.post(
        `/frontend/project/unlock/${collaborationSpaceId}/${projectId}`,
        {},
        {
            params: {
                force,
            },
        }
    );
    return { status };
};

export const deleteRevision = async ({
    collaborationSpaceId,
    projectId,
    revisionId,
}: DeleteRevisionRequest): Promise<DeleteRevisionResponse> => {
    const { status } = await fetcher.delete(
        `/frontend/project/design/${collaborationSpaceId}/${projectId}/${revisionId}`
    );
    return { status };
};

export const restoreRevision = async ({
    collaborationSpaceId,
    projectId,
    revisionId,
}: RestoreRevisionRequest): Promise<RestoreRevisionResponse> => {
    const { data } = await fetcher.post(
        `/frontend/project/design/${collaborationSpaceId}/${projectId}/restore/${revisionId}`
    );
    return data;
};

export const getDesign = async ({
    collaborationSpaceId,
    projectId,
    revisionId,
}: GetProjectRevisionRequest): Promise<ProjectRevisionResponse> => {
    const { data } = await fetcher.get(
        `/frontend/project/design/${collaborationSpaceId}/${projectId}`,
        {
            params: {
                version: revisionId,
            },
        }
    );
    return data;
};
