import { useCallback } from 'react';

import { ProcessedRevision } from 'components/templates/ProjectHistory/ProjectHistoryView/ProjectHistoryView';

export default function useRevisionChangeHook(revision: ProcessedRevision) {
    const changeCurrentRevision = useCallback(() => {
        const event = new CustomEvent('setCurrentVersion', {
            detail: {
                eventArgs: {
                    current: revision.versionNumber,
                    comment: revision.description,
                },
            },
        });
        document.dispatchEvent(event);
    }, [revision]);

    return {
        changeCurrentRevision,
    };
}
