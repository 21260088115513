import React, { useState } from 'react';

import Collapse from 'components/atoms/Collapse';
import {
    Description,
    DescriptionContainer,
    EmptyDescription,
    ToggleButton,
} from 'components/molecules/TileProjectDescription/TileProjectDescription.styles';

const MAX_VISIBLE_DESCRIPTION_CHARS = 90;

interface TileProjectContentProps {
    description?: string;
    onToggle?: () => void;
}

export const TILE_PROJECT_DESCRIPTION_TOGGLE = 'tile-project-description__toggle';

export function TileProjectDescription({ description, onToggle }: TileProjectContentProps) {
    const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false);

    const toggleDescription = () => {
        setIsDescriptionExpanded((prev) => !prev);
        onToggle && onToggle();
    };

    const getDescription = () => {
        if (isDescriptionExpanded) {
            return description;
        }
        return `${description?.substring(0, MAX_VISIBLE_DESCRIPTION_CHARS).trim()}...`;
    };

    if (!description) {
        return (
            <DescriptionContainer>
                <EmptyDescription>No comment was added for this version.</EmptyDescription>
            </DescriptionContainer>
        );
    }

    return (
        <DescriptionContainer>
            {description.length > MAX_VISIBLE_DESCRIPTION_CHARS ? (
                <>
                    <ToggleButton
                        data-testid={TILE_PROJECT_DESCRIPTION_TOGGLE}
                        onClick={toggleDescription}
                    >
                        <Collapse isOpened={isDescriptionExpanded} />
                    </ToggleButton>
                    <Description>{getDescription()}</Description>
                </>
            ) : (
                <Description>{description}</Description>
            )}
        </DescriptionContainer>
    );
}
