import { Table } from 'components/_legacy/ccd-table/Table.styles';

import '../../FileManagementCommon.css';

export default function TableComponent({
    data,
    columns,
    rowHandler,
    doubleClickHandler,
    sortingHandler,
    tableSettings,
    paginationMemo,
    rowRenderer,
    selectedFiles,
}) {
    const forcedSelection = {
        enabled: true,
        elements: selectedFiles,
        comparisonFunc: (selectedElement, item) => {
            return selectedElement.id === item.original.id;
        },
    };

    return (
        <Table
            columns={columns}
            data={data}
            onSelected={rowHandler}
            onDoubleClick={(row) => doubleClickHandler(row.original)}
            onSorted={sortingHandler}
            tableSettings={tableSettings}
            pagination={paginationMemo}
            forcedSelection={forcedSelection}
            className='ccd-file-management-display'
            rowRenderer={rowRenderer}
        />
    );
}
