import { array, func } from 'prop-types';
import React, { useCallback } from 'react';

import { DialogButton } from 'components/atoms/Button';
import Dialog from 'components/molecules/Dialog';

export function FilesAlreadyExistsModal({ filesNames, onClose }) {
    function getMultipleFilesMessage() {
        return (
            <>
                <div className='ccd-fm__modal-text-bolded'>
                    Multiple files already exist in this folder.
                </div>
                <div>Please correct filenames to avoid conflicts.</div>
            </>
        );
    }

    function getSingleFileMessage() {
        return (
            <>
                <div>
                    <span className='ccd-fm__modal-text-bolded'>{`${filesNames[0].name}`}</span>
                    &nbsp;
                    <span>already exists in this folder.</span>
                </div>
                <div>Please correct filename accordingly to avoid conflicts.</div>
            </>
        );
    }

    const getMessage = useCallback(() => {
        if (filesNames.length === 1) {
            return getSingleFileMessage();
        } else {
            return getMultipleFilesMessage();
        }
    }, [filesNames]);

    const FileManagementMovePrefix = 'file-management-move';

    return (
        <Dialog
            title={'File already exists'}
            buttons={
                <DialogButton
                    color='primary'
                    variant={'contained'}
                    onClick={onClose}
                    id={`${FileManagementMovePrefix}-rename-button`}
                >
                    OK
                </DialogButton>
            }
            id={FileManagementMovePrefix}
            open
            setOpen={onClose}
        >
            {getMessage()}
        </Dialog>
    );
}

FilesAlreadyExistsModal.propTypes = {
    filesNames: array,
    onClose: func,
};
