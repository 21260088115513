import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';

import fetcher from 'providers/api/fetcher';

import { INotification } from '../../INotification';
import { LATEST_NOTIFICATION } from '../queries/notification.query';

function getLatestNotificationId(data: INotification[]) {
    return data
        .map((item) => {
            return item.NotificationId;
        })
        .sort()[data.length - 1];
}

const useNotificationApi = () => {
    const queryClient = useQueryClient();
    const [notificationId, setNotificationId] = useState<string | null>(null);

    return async (): Promise<INotification[]> => {
        const previousData = queryClient.getQueryData<INotification[]>([LATEST_NOTIFICATION]);

        const { data } = await fetcher({
            url: `/api/notification/inapp/latest`,
            params: { ...(notificationId && { 'newer-than': notificationId }) },
        });

        data.length && setNotificationId(getLatestNotificationId(data));
        if (notificationId !== null) {
            return previousData?.concat(data) ?? data;
        } else {
            return data;
        }
    };
};

export default useNotificationApi;
