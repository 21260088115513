import { useQuery } from '@tanstack/react-query';

import { getFeatureFlags } from 'services/api/featuresFlags.service';

const useFeatureFlagsQuery = (enabled = true) => {
    return useQuery({
        queryKey: ['featuresFlags'],
        queryFn: getFeatureFlags,
        refetchInterval: 3600000,
        refetchOnWindowFocus: false,
        enabled,
    });
};

export default useFeatureFlagsQuery;
