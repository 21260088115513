import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';

import {
    ccdCloseToast,
    ccdErrorToast,
    ccdLoadingCompletedWithError,
    ccdLoadingCompletedWithSuccess,
    ccdLoadingToast,
} from 'components/_legacy/ccd-toast';
import useCurrentFolderContext from 'components/templates/FileManagement/components/CurrentFolderContextProvider/useCurrentFolderContext';
import { useFileManagementContext } from 'components/templates/FileManagement/components/FileManagementContext';
import { FILES_QUERY } from 'components/templates/FileManagement/components/parts/Table/service/files.query';

import { unexpectedError } from '../../../../consts';
import { EE, FILE_UPLOADED } from '../../../../eventEmitter';
import { useGlobalUiBlockersCounterContext } from '../../../../hooks';
import { useCreateDocument } from '../../../../services/useCreateDocument';
import { isSuccessfulPromiseFilter, isUnsuccessfulPromiseFilter } from '../../../../utils';
import { getMessageStrategy, useMoreFlexibleReplace } from '../../../parts/FileUpload';
import { prepareFileNameForRename } from '../NameGenerator';

const handleUnexpectedError = (notificationId, err) => {
    console.error(err);
    ccdLoadingCompletedWithError(notificationId, unexpectedError);
};

const useMultipleFilesAlreadyExist = ({ isDragAndDropUpload }) => {
    const {
        state: { getRoot, backendUrl, collaborationSpaceId, projectId, currentFolder },
    } = useFileManagementContext();

    const queryClient = useQueryClient();

    const { increaseBlockerCounter, decreaseBlockerCounter } = useGlobalUiBlockersCounterContext();
    const root = useMemo(() => getRoot(), []);
    const { onReplace } = useMoreFlexibleReplace();
    const { data: filesInCurrentFolder } = useCurrentFolderContext();
    const { createDocument } = useCreateDocument();

    const handleApiResult = (notificationId, resultPromises) => {
        const resultsWithSuccess = resultPromises.filter(isSuccessfulPromiseFilter);

        const resultsWithErrors = resultPromises.filter(isUnsuccessfulPromiseFilter);

        const resultPromisesWithAlreadyExistErrors = resultsWithErrors.filter(
            (e) => e?.reason?.status === 409
        );

        const areOnlySuccessfulResults =
            resultsWithSuccess.length > 0 && resultsWithErrors.length === 0;
        const areSuccessfulAndErrorResults =
            resultsWithSuccess.length > 0 && resultsWithErrors.length > 0;

        if (areOnlySuccessfulResults) {
            ccdLoadingCompletedWithSuccess(
                notificationId,
                getMessageStrategy(resultPromises).successMessage(resultPromises)
            );
        } else if (areSuccessfulAndErrorResults) {
            ccdLoadingCompletedWithError(
                notificationId,
                getMessageStrategy(resultPromises).partiallyFailedMessage()
            );
        } else {
            ccdCloseToast(notificationId);
            ccdErrorToast(getMessageStrategy(resultsWithErrors).failedMessage());
        }
        if (resultPromisesWithAlreadyExistErrors.length > 0) {
            ccdErrorToast(
                getMessageStrategy(resultPromisesWithAlreadyExistErrors).alreadyExist(
                    resultPromisesWithAlreadyExistErrors
                )
            );
        }
        resultsWithSuccess.map((promise) =>
            EE.emit(FILE_UPLOADED, {
                fileName: promise.value.fileName,
                typeName: promise.value.typeName,
                isDragAndDropUpload: isDragAndDropUpload,
            })
        );
    };

    const replaceHandler = useCallback(
        async (fileNames, onClose) => {
            increaseBlockerCounter({ isLight: true });

            const notificationId = ccdLoadingToast(getMessageStrategy(fileNames).loadingMessage());
            const replacePromises = fileNames.map((f) => onReplace(f.originalFile, f.id));

            onClose();

            const promiseResults = await Promise.allSettled(replacePromises);

            handleApiResult(notificationId, promiseResults);

            decreaseBlockerCounter({ isLight: true });
            await queryClient.invalidateQueries({
                queryKey: [FILES_QUERY, collaborationSpaceId, projectId],
            });
        },
        [
            onReplace,
            handleUnexpectedError,
            collaborationSpaceId,
            projectId,
            backendUrl,
            currentFolder,
            isDragAndDropUpload,
        ]
    );

    const onRename = async (file) => {
        let createResponse;
        const fileObj = new File([file.originalFile], file.name, {
            type: file.originalFile.type,
        });

        try {
            createResponse = await createDocument({
                fileObj,
                collaborationSpaceId,
                projectId,
                backendUrl,
                currentFolder,
            });

            if (!createResponse.ok) {
                createResponse.name = file.name;
                throw createResponse;
            }
            const response = await createResponse.json();
            return {
                fileName: file.name,
                typeName: response.typeName,
            };
        } catch (ex) {
            console.error(ex);
            throw ex;
        }
    };

    const renameHandler = useCallback(
        async (fileNames, onClose) => {
            increaseBlockerCounter({ isLight: true });
            const notificationId = ccdLoadingToast(getMessageStrategy(fileNames).loadingMessage());
            const desiredFiles = prepareFileNameForRename(fileNames, filesInCurrentFolder);
            const promiseBucket = desiredFiles.map(onRename);

            onClose();

            const resultPromises = await Promise.allSettled(promiseBucket);

            handleApiResult(notificationId, resultPromises);

            decreaseBlockerCounter({ isLight: true });

            decreaseBlockerCounter({ isLight: true });
            await queryClient.invalidateQueries({
                queryKey: [FILES_QUERY, collaborationSpaceId, projectId],
            });
        },
        [
            increaseBlockerCounter,
            prepareFileNameForRename,
            filesInCurrentFolder,
            decreaseBlockerCounter,
            ccdLoadingCompletedWithSuccess,
            ccdLoadingCompletedWithError,
            isDragAndDropUpload,
        ]
    );
    return { replaceHandler, renameHandler, root };
};

export default useMultipleFilesAlreadyExist;
