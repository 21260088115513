import { useMutation, useQueryClient } from '@tanstack/react-query';

import { ccdErrorToast, ccdInfoToast } from 'components/_legacy/ccd-toast';
import { useProgressBar } from 'components/atoms/ProgressBar';
import { shareProjectApi } from 'components/templates/ProjectUsers/services/api/shareProject.api';
import { GET_PROJECT_USERS } from 'components/templates/ProjectUsers/services/query/projectUsers.query';
import { useUserDataContext } from 'providers/currentUser/UserDataProvider';
import { triggerAnalyticsClickEvent } from 'services/SiemensAnalyticsService/SiemensAnalyticsModule';
import analyticsConsts from 'services/SiemensAnalyticsService/analyticsConsts';

export interface IShareProject {
    projectId: string;
    messages: { success: string; fail: string };
    analyticsAction: string;
}

export const shareProject = ({ projectId, messages, analyticsAction }: IShareProject) => {
    const { setProgressBar } = useProgressBar();
    const queryClient = useQueryClient();
    const { collaborationSpaceId } = useUserDataContext();
    return useMutation(shareProjectApi, {
        onMutate: () => {
            setProgressBar(true);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [GET_PROJECT_USERS, collaborationSpaceId, projectId],
            });
            ccdInfoToast({
                messages: [messages.success],
            });
            triggerAnalyticsClickEvent(
                analyticsAction,
                analyticsConsts.Categories.projectUserManagement,
                analyticsConsts.Sources.projectUsers
            );
        },
        onError: (error, variables) => {
            console.error(error, variables);
            ccdErrorToast({
                title: messages.fail,
                messages: ['Try again or contact support'],
            });
        },
        onSettled: () => {
            setProgressBar(false);
        },
    });
};
