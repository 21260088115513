import React from 'react';

import CcdRevisionTile, {
    OnCLose as DeleteNotification,
} from 'components/_legacy/ccd-revision-tile';
import { TileColor } from 'components/atoms/TileContainer';
import useRelativeTime from 'hooks/useRelativeTime';

import { EventType, INotification } from '../../INotification';

interface ITileFactory {
    item: INotification;
    markAsRead: () => void;
    deleteNotification: DeleteNotification;
    openProject: () => void;
}

export function TileFactory({ item, markAsRead, deleteNotification, openProject }: ITileFactory) {
    const { relativeTime } = useRelativeTime();

    function checkEventDescription(eventDate: string) {
        return `${relativeTime({ date: new Date(eventDate) })}`;
    }

    if (item.EventType === EventType.CheckIn) {
        return (
            <CcdRevisionTile
                projectTitle={'Design Check In update!'}
                creator={item.SenderUserName}
                description={item.Body.CheckInComment}
                eventDescription={checkEventDescription(item.NotificationDate)}
                onProjectTitle={{
                    handler: openProject,
                    tip: 'Open project',
                }}
                title={item.Body.ProjectName}
                revisionNumber={item.Body.ProjectRevision}
                onClose={deleteNotification}
                color={TileColor.GREEN}
                read={item.IsRead}
                markAsRead={markAsRead}
                thumbnailUrl={item.ThumbnailUrl}
            />
        );
    } else if (item.EventType === EventType.CheckOut) {
        return (
            <CcdRevisionTile
                projectTitle={'Design Check Out update!'}
                creator={item.SenderUserName}
                eventDescription={checkEventDescription(item.NotificationDate)}
                onProjectTitle={{
                    handler: openProject,
                    tip: 'Open project',
                }}
                title={item.Body.ProjectName}
                revisionNumber={item.Body.ProjectRevision}
                onClose={deleteNotification}
                color={TileColor.YELLOW}
                read={item.IsRead}
                markAsRead={markAsRead}
                disableDescription
                thumbnailUrl={item.ThumbnailUrl}
            />
        );
    } else if (item.EventType === EventType.DocumentMarkedAsClean) {
        return (
            <CcdRevisionTile
                projectTitle={`File was marked as safe!`}
                creator={item.SenderUserName}
                description={`${item.SenderUserName} has marked ${item.Body.DocumentName} as safe`}
                eventDescription={checkEventDescription(item.NotificationDate)}
                onProjectTitle={{
                    handler: openProject,
                    tip: 'Open project',
                }}
                title={item.Body.ProjectName}
                onClose={deleteNotification}
                color={TileColor.GREEN}
                read={item.IsRead}
                markAsRead={markAsRead}
                thumbnailUrl={item.ThumbnailUrl}
            />
        );
    } else if (item.EventType === EventType.VersionMarkedAsClean) {
        return (
            <CcdRevisionTile
                projectTitle={`Project version was marked as safe!`}
                creator={item.SenderUserName}
                description={`${item.SenderUserName} has marked this project version ${item.Body.VersionNumber} as safe`}
                eventDescription={checkEventDescription(item.NotificationDate)}
                onProjectTitle={{
                    handler: openProject,
                    tip: 'Open project',
                }}
                title={item.Body.ProjectName}
                revisionNumber={item.Body.VersionNumber}
                onClose={deleteNotification}
                color={TileColor.GREEN}
                read={item.IsRead}
                markAsRead={markAsRead}
                thumbnailUrl={item.ThumbnailUrl}
            />
        );
    } else throw Error('Unknown event type');
}
