import Box from 'components/atoms/Box';
import { Spinner } from 'components/atoms/ProgressSpiner/ProgressSpinner';
import { LoadingContainer } from 'components/organisms/ProjectVersion/components/Footer/LoadingContainer';

export const LoadingRevisions = ({ searchEnabled = true }: { searchEnabled?: boolean }) => {
    return (
        <LoadingContainer id={'loading'}>
            <Box css={{ display: 'flex', alignItems: 'center' }}>
                <Spinner css={{ height: '16px', width: '16px', marginRight: '8px' }} />
                <Box as={'span'} css={{ fontWeight: '600' }}>
                    Loading 25 more versions...
                </Box>
            </Box>
            {searchEnabled && (
                <Box as={'span'} css={{ fontWeight: '600', color: '#8c8c8c' }}>
                    Search results may be incomplete until all versions are loaded.
                </Box>
            )}
        </LoadingContainer>
    );
};
