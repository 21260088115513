import { useCallback } from 'react';

import { ccdLoadingCompletedWithSuccess, ccdLoadingToast } from 'components/_legacy/ccd-toast';
import { useCcdFetch } from 'components/_legacy/hooks/useCcdFetch';
import { useFileManagementContext } from 'components/templates/FileManagement/components/FileManagementContext';

import { EE, FILE_UPLOADED } from '../../../../eventEmitter';
import { useGlobalUiBlockersCounterContext } from '../../../../hooks';
import { singleFileUploadStrategy } from '../DragNDropUpload';

export default function useReplace(
    file,
    conflictFileId,
    dispatchDataLoaded,
    handleUnexpectedError,
    isDragAndDropUpload
) {
    const {
        state: { backendUrl, collaborationSpaceId, projectId },
    } = useFileManagementContext();
    const { increaseBlockerCounter, decreaseBlockerCounter } = useGlobalUiBlockersCounterContext();
    const { ccdFetch } = useCcdFetch();

    const onReplace = useCallback(async () => {
        increaseBlockerCounter({ isLight: true });
        const notificationId = ccdLoadingToast(singleFileUploadStrategy.loadingMessage());
        try {
            const replaceResponse = await replaceFile(file, conflictFileId);
            if (replaceResponse.ok) {
                ccdLoadingCompletedWithSuccess(
                    notificationId,
                    singleFileUploadStrategy.successMessage([file])
                );
                await dispatchDataLoaded();
                const body = await replaceResponse.json();
                EE.emit(FILE_UPLOADED, {
                    fileName: file.name,
                    typeName: body.typeName,
                    isDragAndDropUpload: isDragAndDropUpload,
                });
            } else {
                handleUnexpectedError(notificationId, replaceResponse);
            }
        } catch (err) {
            console.error(err);
            handleUnexpectedError(notificationId, err);
        } finally {
            decreaseBlockerCounter({ isLight: true });
        }
    }, [conflictFileId, file]);

    async function replaceFile(fileObj, fileId) {
        const url = new URL(
            `/frontend/file-management/${collaborationSpaceId}/${projectId}/document/${fileId}`,
            backendUrl
        );
        const data = new FormData();
        data.append('file', fileObj);
        return ccdFetch(url.toString(), { method: 'PATCH', body: data });
    }

    return { onReplace };
}
