import styled from '@emotion/styled';

import Box from 'components/atoms/Box';

const MenuWrapper = styled(Box)(({ theme }) => ({
    backgroundColor: theme.colors.accordion.header,
    color: theme.colors.text,
    marginBottom: theme.spacing(4),
    padding: theme.spacing(1),
    borderRadius: '2px',
    display: 'flex',

    'div::-webkit-scrollbar': { display: 'none' },

    button: {
        marginRight: theme.spacing(1),
    },
}));

export default MenuWrapper;
