import React from 'react';

import Box from 'components/atoms/Box';

import './ErrorModalOverlay.scss';

export default function ErrorModalOverlay({ title, message, messages, onClose }) {
    const renderMultipleMessages = (messageArray) => {
        return messageArray.map((msg, index) => (
            <p className='error-modal-message-line' key={index}>
                {msg}
            </p>
        ));
    };
    return (
        <Box
            css={({ colors }) => ({
                background: colors.error.background,
                border: `1px solid ${colors.error.border}`,
                borderLeft: `4px solid ${colors.error.text}`,
            })}
            className='error-modal'
        >
            <div className='error-modal-content'>
                <div className='error-modal-header-container'>
                    {title && (
                        <Box
                            as={'h2'}
                            css={({ colors }) => ({ color: colors.error.text })}
                            className='error-modal-header'
                        >
                            {title}
                        </Box>
                    )}
                </div>
                <div>
                    {message && <p className='error-modal-message-line'>{message}</p>}
                    {messages && renderMultipleMessages(messages)}
                </div>
            </div>
            <button className='error-modal-close' type='button' onClick={onClose} />
        </Box>
    );
}
