import { DragNDropOverlay } from '../../../UI';
import { FilesAlreadyExistsModalForFileUpload, useFilesAlreadyExistsModal } from '../../../parts';
import { NoAccessUploadModal, useNoAccessUploadModal } from './NoUploadAccessModal';
import useUpload from './useUpload';

export default function DragNDropUpload({ children }) {
    const {
        isModalVisible: isFilesAlreadyExistsModalVisible,
        namesConflicts,
        hideModal: hideFilesAlreadyExistsModal,
        showModal: showFilesAlreadyExistsModal,
    } = useFilesAlreadyExistsModal();
    const {
        isModalVisible: isNoAccessModalVisible,
        hideModal: hideNoAccessModal,
        showModal: showNoAccessModal,
    } = useNoAccessUploadModal();

    const { onDragUpload, hasAccess } = useUpload(
        showFilesAlreadyExistsModal,
        showNoAccessModal,
        true
    );

    return (
        <>
            <DragNDropOverlay
                children={children}
                onDrop={onDragUpload}
                hasAccess={hasAccess}
                id='upload-file-drag-n-drop'
            />
            {isFilesAlreadyExistsModalVisible && (
                <FilesAlreadyExistsModalForFileUpload
                    filesNames={namesConflicts}
                    onClose={hideFilesAlreadyExistsModal}
                    isDragAndDropUpload
                />
            )}
            {isNoAccessModalVisible && <NoAccessUploadModal onClose={hideNoAccessModal} />}
        </>
    );
}
