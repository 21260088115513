import styled from '@emotion/styled';

const Delay = styled('div')(() => ({
    animation: 'delay 1s normal step-end',
    '@keyframes delay': {
        '0%': {
            opacity: '0',
        },
        '100%': {
            opacity: '1',
        },
    },
}));

export default Delay;
