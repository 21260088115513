import React from 'react';

import { CloseButton } from '../CloseButton/CloseButton';
import { Container, Header } from './SideNavHeader.style';

export interface Props extends React.PropsWithChildren {
    onClose: () => void;
    header: string;
}

const SideNavHeader = ({ onClose, header }: Props) => {
    return (
        <Container>
            <Header>{header}</Header>
            <CloseButton onClose={onClose} />
        </Container>
    );
};

export default SideNavHeader;
