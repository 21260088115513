import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

import { useToast } from 'components/_legacy/ccd-toast';
import HorizontalMenuDivider from 'components/atoms/HorizontalMenuDivider';
import { useProgressBar } from 'components/atoms/ProgressBar';
import Switch from 'components/atoms/Switch';
import ThemesComboBox from 'components/templates/Theme/ThemesComboBox';
import { updateNotificationsSettings } from 'services/api/notificationSettings.service';
import useFeatureFlagsQuery from 'services/queries/featuresFlags.query';
import { useGetNotificationsSettings } from 'services/queries/notificationSettings.query';

import {
    AlertsArea,
    AlertsLabel,
    OptionLabel,
    OptionWrapper,
    SwitchDiv,
} from './SettingsPanel.styles';
import { useDebounce } from './hooks/useDebounce';

export interface Props {}

const SettingsPanel = ({}: Props) => {
    const [emailCheckIn, setEmailCheckIn] = useState(false);
    const [emailCheckOut, setEmailCheckOut] = useState(false);

    const { data, isLoading } = useGetNotificationsSettings();
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const { setProgressBar } = useProgressBar();
    const { data: featureFlagsData } = useFeatureFlagsQuery();

    useEffect(() => {
        if (data && !isLoading) {
            setEmailCheckIn(data.EmailCheckIn);
            setEmailCheckOut(data.EmailCheckOut);
        }
    }, [data, isLoading]);

    useEffect(() => () => setProgressBar(false), []);

    const { mutate } = useMutation({
        mutationFn: updateNotificationsSettings,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['getNotificationsSettings'] });
            showToast({
                type: 'info',
                messages: ['Notification settings updated'],
            });
        },
        onError: () => {
            showToast({
                type: 'error',
                messages: ['Cannot update notification settings'],
            });
        },
        onSettled: () => {
            setProgressBar(false);
        },
    });

    const debouncedMutation = useDebounce(() => {
        setProgressBar(true);
        mutate({ EmailCheckIn: emailCheckIn, EmailCheckOut: emailCheckOut });
    });

    const onCheckInChange = (state: boolean) => {
        setEmailCheckIn(state);
        debouncedMutation();
    };

    const onCheckOutChange = (state: boolean) => {
        setEmailCheckOut(state);
        debouncedMutation();
    };

    return (
        <>
            <h4>Settings</h4>
            <AlertsArea>
                <AlertsLabel>Alerts</AlertsLabel>
                <OptionWrapper>
                    <SwitchDiv>
                        <Switch
                            data-testid='email-checkin-switch'
                            checked={emailCheckIn}
                            onCheckedChange={onCheckInChange}
                        />
                    </SwitchDiv>
                    <OptionLabel>Checkin email alerts</OptionLabel>
                </OptionWrapper>
                <OptionWrapper>
                    <SwitchDiv>
                        <Switch
                            data-testid='email-checkout-switch'
                            checked={emailCheckOut}
                            onCheckedChange={onCheckOutChange}
                        />
                    </SwitchDiv>
                    <OptionLabel>Checkout email alerts</OptionLabel>
                </OptionWrapper>
            </AlertsArea>
            <HorizontalMenuDivider width={250} />
            {featureFlagsData && featureFlagsData.Frontend_Theme && (
                <>
                    <h4>Theme</h4>
                    <ThemesComboBox />
                    <HorizontalMenuDivider width={250} />
                </>
            )}
        </>
    );
};

export default SettingsPanel;
