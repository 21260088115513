import toLower from 'lodash/toLower';
import trim from 'lodash/trim';

import { getFileNameWithNoExtension } from '../../../helpers';

const filterFunction = (desiredFileName) => (item) => {
    return toLower(desiredFileName) === toLower(item.name);
};

export const prepareFileNameForRename = (fileNames, filesInCurrentFolder) => {
    return fileNames.reduce((acc, singleFile) => {
        let desiredFileName;
        let i = 0;
        const [fileNameWithNoExtension, fileType] = getFileNameWithNoExtension(singleFile.name);

        do {
            // prepare a new file name
            i += 1;
            desiredFileName = trim(`${fileNameWithNoExtension}-${i}${fileType}`);
            // check in new file name is already in a folder
            // check acc as well
            // don't skip folder names
        } while (
            filesInCurrentFolder.filter(filterFunction(desiredFileName)).length > 0 ||
            acc.filter(filterFunction(desiredFileName)).length > 0
        );

        singleFile.name = desiredFileName;
        acc.push(singleFile);

        return acc;
    }, []);
};
