import styled from '@emotion/styled';

import Box, { BoxProps } from 'components/atoms/Box';
import SiemensLogoSvg from 'images/miscSiemensLogo24.svg?react';
import { customColors } from 'theme';

const MainWrapper = styled(Box)({
    backgroundColor: customColors['old-blue-dark'],
    width: '100vw',
    height: '100vh',
    margin: 0,
    padding: 0,
});

const ContentWrapper = styled(Box)({
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    top: '50%',
    left: '50%',
});

const Header = styled(Box)(({ theme }) => ({
    padding: theme.spacing(0, 8, 4, 8),
}));

const SiemensLogo = styled(SiemensLogoSvg)({
    fill: 'white',
    width: 100,
    float: 'right',
    height: 36,
});

const Logo = styled('span')({
    color: 'white',
    fontSize: '22px',
    fontWeight: 700,
    height: 60,
});

const Content = styled(Box)(({ theme }) => ({
    backgroundColor: 'white',
    borderRadius: 12,
    padding: theme.spacing(8),
    width: 440,
    minHeight: 400,
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: customColors['old-blue-dark'],
    fontStyle: 'normal',
    '& h1': {
        fontWeight: 400,
        fontSize: 36,
        lineHeight: '48px',
    },
    '& h3': {
        fontWeight: 600,
        fontSize: 16,
        lineHeight: '21px',
    },
    '& p': {
        fontWeight: 400,
        fontSize: 16,
        lineHeight: '21px',
    },
    '& a': {
        textDecoration: 'none',
        color: 'inherit',
        fontWeight: 600,
    },
}));

export interface Props extends BoxProps {
    width?: number;
    height?: number;
}

function StaticPage({ children, width, height }: Props) {
    return (
        <MainWrapper>
            <ContentWrapper data-testid='static-page-wrapper'>
                <Header>
                    <Logo>Connect</Logo>
                    <SiemensLogo />
                </Header>
                <Content css={{ width, height }}>
                    <Box>{children}</Box>
                </Content>
            </ContentWrapper>
        </MainWrapper>
    );
}

export default StaticPage;
