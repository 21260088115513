import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

import { useProgressBar } from 'components/atoms/ProgressBar';
import { ScanTooltip } from 'components/organisms/ProjectVersion/components/TooltipContent/SecurityThreatTooltipContent';
import UnlockSuspiciousContentDialog from 'components/organisms/UnlockSuspiciousContentDialog/UnlockSuspiciousContentDialog';
import { GET_PROJECT_DESIGN } from 'components/templates/Design/api/query/design.query';
import fetcher from 'providers/api/fetcher';
import { GET_PROJECT_REVISIONS } from 'services/queries/project.query';
import { Pagination, ProjectRevision, ProjectRevisionPagination, ScanStatus } from 'types/project';

export const UnlockSuspiciousVersionDialog = () => {
    const [isOpen, setOpen] = useState<boolean>(false);
    const [data, setData] = useState<ScanTooltip>();

    const queryClient = useQueryClient();
    const { setProgressBar } = useProgressBar();

    const [collaborationSpaceId, projectId] = decodeURIComponent(data?.projectId ?? '').split('/');

    const { mutate } = useMutation(
        () => {
            setProgressBar(true);
            return fetcher.post(
                `/api/v3/sync/frontend/${data?.projectId}/mark-clean/${data?.versionNumber}`
            );
        },
        {
            onMutate: async () => {
                await queryClient.cancelQueries({ queryKey: [GET_PROJECT_REVISIONS] });

                // Snapshot the previous value
                const previousTodos = queryClient.getQueryData<{
                    pages: ProjectRevisionPagination[];
                }>([GET_PROJECT_REVISIONS, collaborationSpaceId, projectId]);

                const pageWithRevision = previousTodos?.pages.find((page) =>
                    page.data.find((revision) => revision.versionNumber === data?.versionNumber)
                );

                if (pageWithRevision) {
                    const modifiedPageData = pageWithRevision?.data.map((revision) => {
                        if (revision.versionNumber === data?.versionNumber) {
                            return {
                                ...revision,
                                scanInfo: { scanStatus: ScanStatus.CLEAN, scanMessage: null },
                            };
                        } else {
                            return revision;
                        }
                    });

                    queryClient.setQueryData<{
                        pages?: {
                            data: ProjectRevision[] | undefined;
                            pagination?: Pagination;
                        }[];
                    }>([GET_PROJECT_REVISIONS, collaborationSpaceId, projectId], (old) => {
                        return {
                            ...old,
                            pages: old?.pages?.map((page) => {
                                if (
                                    page?.pagination?.current ===
                                    pageWithRevision?.pagination.current
                                ) {
                                    return { ...pageWithRevision, data: modifiedPageData };
                                } else {
                                    return page;
                                }
                            }),
                        };
                    });
                }

                return { previousTodos };
            },
            onError: (_err, _new, context) => {
                queryClient.setQueryData(
                    [GET_PROJECT_REVISIONS, collaborationSpaceId, projectId],
                    context?.previousTodos
                );
            },
            onSettled: () => {
                queryClient.invalidateQueries({
                    queryKey: [GET_PROJECT_REVISIONS],
                });
                queryClient.invalidateQueries({
                    queryKey: [GET_PROJECT_DESIGN],
                });
            },
        }
    );

    useEffect(() => {
        window.addEventListener('openUnlockSuspiciousVersionDialog', (event) => {
            setOpen(true);
            setData((event as CustomEvent).detail);
        });
        return () => {
            window.removeEventListener('openUnlockSuspiciousVersionDialog', () => {
                setOpen(true);
            });
        };
    }, []);

    if (!isOpen) {
        return null;
    }

    return (
        <UnlockSuspiciousContentDialog
            title={'Unlock suspicious version?'}
            onClose={() => {
                setOpen(false);
            }}
            onConfirm={() => {
                setOpen(false);
                mutate();
            }}
        />
    );
};
