import { ccdErrorToast, ccdInfoToast } from './';

export interface IShowToast {
    type: 'info' | 'error';
    title?: string;
    messages: Array<string>;
    onClose?: <T = Record<string, unknown>>(props: T) => void;
    autoCloseTime?: number;
    autoClose?: boolean;
}

export const useToast = () => {
    const showToast = ({
        type,
        title = '',
        messages,
        onClose = () => {},
        autoCloseTime = 3000,
        autoClose = true,
    }: IShowToast) => {
        if (type === 'info') {
            return ccdInfoToast({
                title,
                messages,
                onClose,
                autoCloseTime,
                autoClose,
            });
        } else if (type === 'error') {
            return ccdErrorToast({
                title,
                messages,
                onClose,
                autoCloseTime,
                autoClose,
            });
        }
    };

    return {
        showToast,
    };
};
