import styled from '@emotion/styled';

import { customColors } from 'theme';

export interface ErrorProps {
    error?: boolean;
}

export const TextArea = styled('textarea')(({ error }: ErrorProps) => ({ theme }) => ({
    resize: 'none',
    height: 340,

    padding: theme.spacing(2),
    marginTop: theme.spacing(1),
    borderRadius: '2px',
    width: '100%',
    outline: 'solid 1px white',
    border: error ? `1px solid ${customColors['red']}` : `1px solid ${customColors['grey-1']}`,
    '&:hover': {
        outlineColor: error ? customColors.red : customColors['grey-1'],
    },

    '&:focus': {
        caretColor: customColors['grey-1'],
        outlineColor: customColors['blue-13'],
        borderColor: customColors['blue-13'],
        ...(error && {
            borderColor: customColors['red'],
            outlineColor: customColors['red'],
        }),
    },
}));

export const InputField = styled('input')(({ error }: ErrorProps) => ({ theme }) => ({
    padding: theme.spacing(2),
    marginTop: theme.spacing(1),
    borderRadius: '2px',
    width: '100%',
    outline: 'solid 1px white',
    border: error ? `1px solid ${customColors['red']}` : `1px solid ${customColors['grey-1']}`,
    '&:hover': {
        outlineColor: error ? customColors.red : customColors['grey-1'],
    },

    '&:focus': {
        caretColor: customColors['grey-1'],
        outlineColor: customColors['blue-13'],
        borderColor: customColors['blue-13'],
        ...(error && {
            borderColor: customColors['red'],
            outlineColor: customColors['red'],
        }),
    },
}));
