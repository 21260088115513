import { useCallback } from 'react';

/*
 *  Wrapper around SiemensAnalytics allows to send event collected by this library
 *  @returns
 *  sendClickEvent( action, category, label, value) - notify click event eg.
 *                sendClickEvent('View', 'Project Actions', 'Schematic 2D')
 *
 *  sendPageVisitedEvent( page ) - notify about visited page eg.
 *                sendPageVisitedEvent('/BomViewSettings')
 *
 *  logEvent( event ) - wrapper on raw event sending
 *                where event is an object contains
 *                    @param {string} eventName - name of the event, shouldn't contain any special chars.
 *                    @param {Object[]} eventProperties - OPTIONAL - should be simple key value struct where key will be passed as event property key and value as its value.
 *
 *  logCommand( command ) - wrapper on raw command sending
 *                where command is an object contains
 *                     @param {string} commandName - name of the event.
 *                     @param {Object[]} commandProperties - OPTIONAL - should be simple key value struct where key will be passed as event property key and value as its value.
 *
 *  logError( error ) - wrapper on sending raw error sending
 *                where error is an object contains
 *                @param {string} errorCode - error code, which will be identifier of error type.
 *                @param {string} errorMessage - error message or any additional info about error.
 *                @param {Object} stackTrace - OPTIONAL - object which contains info about stack trace, should contain described below fields.
 *                @param {string} stackTrace.location - points location of error occurrence.
 *                @param {string} stackTrace.library - points name of the library where error occurred.
 *                @param {string} stackTrace.metadata - any extra information about error stack trace.
 *                @param {Object[]} errorProperties - OPTIONAL - should be simple key value struct where key will be passed as event property key and value as its value.
 */

export default function useSiemensAnalytics() {
    const sendPageVisitedAnalyticsEvent = (pageName) => {
        document.dispatchEvent(new CustomEvent('pageVisited', { detail: pageName }));
    };

    const sendAnalyticsClickEvent = (action, category, label, value) => {
        document.dispatchEvent(
            new CustomEvent('clickEvent', {
                detail: { action, category, label, value },
            })
        );
    };

    const logAnalyticsEvent = (event) => {
        document.dispatchEvent(
            new CustomEvent('logEvent', {
                detail: event,
            })
        );
    };

    const logCommandEvent = (event) => {
        document.dispatchEvent(
            new CustomEvent('logCommand', {
                detail: event,
            })
        );
    };

    const logErrorEvent = (event) => {
        document.dispatchEvent(
            new CustomEvent('logError', {
                detail: event,
            })
        );
    };

    const sendPageVisitedEvent = useCallback(sendPageVisitedAnalyticsEvent, []);
    const sendClickEvent = useCallback(sendAnalyticsClickEvent, []);
    const logEvent = useCallback(logAnalyticsEvent, []);
    const logCommand = useCallback(logCommandEvent, []);
    const logError = useCallback(logErrorEvent, []);

    return {
        sendClickEvent,
        sendPageVisitedEvent,
        logEvent,
        logCommand,
        logError,
    };
}
