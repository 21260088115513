import { ProcessedRevision } from 'components/templates/ProjectHistory/ProjectHistoryView/ProjectHistoryView';
import {
    AccordionType,
    Direction,
} from 'components/templates/ProjectHistory/ProjectHistoryViewAccordion/ProjectHistoryViewAccordion';

import ProjectInfoPanelContext from '../ProjectHistoryPanelContext';
import useProjectHistoryLineLeftPanel from './ProjectHistoryPanelLeftSide';
import useProjectHistoryLineRightPanel from './ProjectHistoryPanelRightSide';

import './ProjectHistoryPanel.scss';

export default function ProjectHistoryPanel({
    accordionType,
    isFirst,
    isLast,
    direction,
    revision,
}: {
    accordionType?: AccordionType;
    isFirst: boolean;
    isLast: boolean;
    direction: Direction;
    revision: ProcessedRevision;
}) {
    const leftSidePanel = useProjectHistoryLineLeftPanel({
        accordionType,
        revision,
    });

    const rightSidePanel = useProjectHistoryLineRightPanel({
        accordionType,
        revision,
    });

    return (
        <ProjectInfoPanelContext.Provider
            value={{
                isFirst: isFirst,
                isLast: isLast,
            }}
        >
            <div className='project-history-panel'>
                {direction === 'left' ? leftSidePanel() : rightSidePanel()}
            </div>
        </ProjectInfoPanelContext.Provider>
    );
}
