export enum CollaborationSpacePermissions {
    CollaborationSpaceProjectCreate = 'CollaborationSpaceProjectCreate',
    CollaborationSpaceProjectDelete = 'CollaborationSpaceProjectDelete',
    CollaborationSpaceProjectEdit = 'CollaborationSpaceProjectEdit',
    SubscriptionManagerView = 'SubscriptionManagerView',
}

export enum ProjectPermissions {
    ProjectDeleteRevision = 'ProjectDeleteRevision',
    ProjectRestoreRevision = 'ProjectRestoreRevision',
    ProjectLockUnlock = 'ProjectLockUnlock',
    ProjectForceUnlock = 'ProjectForceUnlock',
    ProjectShare = 'ProjectShare',
    ProjectUnShare = 'ProjectUnShare',
    FileManagementTransferFiles = 'FileManagementTransferFiles',
    FileManagementCreateFolder = 'FileManagementCreateFolder',
    FileManagementDeleteFolder = 'FileManagementDeleteFolder',
    FileManagementRenameFolder = 'FileManagementRenameFolder',
    FileManagementCreateDocument = 'FileManagementCreateDocument',
    FileManagementDeleteDocument = 'FileManagementDeleteDocument',
    FileManagementGetDocument = 'FileManagementGetDocument',
    FileManagementRenameDocument = 'FileManagementRenameDocument',
    MarkAsClean = 'MarkAsClean',
}
