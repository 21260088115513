import { FC, useEffect, useMemo, useState } from 'react';

import { CcdTable, CcdTableColumn } from 'components/_legacy/ccd-table';
import Box from 'components/atoms/Box';
import { useProgressBar } from 'components/atoms/ProgressBar';
import VerticalMenu, { TButtons } from 'components/molecules/VerticalMenu';
import ErrorState from 'components/templates/ProjectUsers/components/ErrorState';
import UserSideNav, {
    AddUser,
    EUserOperation,
    ModifyUser,
} from 'components/templates/ProjectUsers/components/UserSideNav';
import { projectUsersQuery } from 'components/templates/ProjectUsers/services/query/projectUsers.query';
import { IUser, TRoles } from 'components/templates/ProjectUsers/services/types';
import RemoveUserIcon from 'images/Remove16.svg?react';
import AddNewIcon from 'images/cmdAdd16.svg?react';
import ModifyUserIcon from 'images/cmdAssignUsers16.svg?react';
import { useUserDataContext } from 'providers/currentUser/UserDataProvider';
import { useProjectPermission } from 'providers/permissions';
import { ProjectPermissions } from 'types/permissions';

import { useUserRemove } from './hooks/useUserRemove';

interface IProjectUsers {
    projectId: string;
}

enum ERole {
    Admin = 'Admin',
    Editor = 'Editor',
    Viewer = 'Viewer',
}

export interface IUserTable {
    id: string;
    name: string;
    role: ERole;
    status: string;
    email: string;
}

export interface SelectedUserData {
    id: number;
    original: IUserTable;
}

const ProjectUsers: FC<IProjectUsers> = ({ projectId }) => {
    const [open, setOpen] = useState(false);
    const [operationType, setOperationType] = useState<AddUser | ModifyUser>();
    const [selectedUser, setSelectedUser] = useState({} as IUserTable);
    const { data, fetchNextPage, hasNextPage, isError } = projectUsersQuery({
        projectId,
    });
    const { collaborationSpaceId, user: currentUser } = useUserDataContext();

    const { setProgressBar } = useProgressBar();
    const { openRemoveUserDialog, removeUserDialog } = useUserRemove(
        collaborationSpaceId,
        projectId,
        selectedUser
    );
    const { hasProjectPermission } = useProjectPermission(collaborationSpaceId, projectId!);
    useEffect(() => {
        if (!data) {
            setProgressBar(true);
        } else {
            setProgressBar(false);
        }
    }, [data]);

    const columnsDescription: Array<CcdTableColumn> = [
        {
            Header: 'Name',
            accessor: 'name',
            width: '200',
            minWidth: 100,
        },
        {
            Header: 'Role',
            accessor: 'role',
            width: 100,
            minWidth: 80,
        },
        {
            Header: 'Email',
            accessor: 'email',
            width: 300,
            minWidth: 100,
        },
        {
            Header: 'Status',
            accessor: 'status',
            width: 100,
            minWidth: 130,
        },
    ];

    const onSelected = (selectedUsers: Array<SelectedUserData>) => {
        const currentlySelectedUser = selectedUsers?.[0]?.original ?? {};
        setSelectedUser(currentlySelectedUser);
    };

    const tableDataPagination = {
        hasMore: hasNextPage,
        update: fetchNextPage,
    };

    const getRole = (item: TRoles[]): ERole => {
        if (item.find((element) => element === TRoles.ProjectAdmin)) {
            return ERole.Admin;
        } else if (item.find((element) => element === TRoles.ProjectEditor)) {
            return ERole.Editor;
        } else {
            return ERole.Viewer;
        }
    };

    function isNameEmpty({ firstName, lastName }: { firstName?: string; lastName?: string }) {
        return !(firstName && lastName);
    }

    const users: IUserTable[] = useMemo(
        () =>
            data?.pages
                .map((x) => x.content)
                .flat()
                .map((item: IUser) => ({
                    id: item.userId,
                    name: isNameEmpty({ firstName: item.firstName, lastName: item.lastName })
                        ? 'Pending sign-in'
                        : `${item.firstName} ${item.lastName}`,
                    role: getRole(item.roles),
                    email: item.email,
                    status: item.status ? 'Active' : 'Inactive',
                })) ?? [],
        [data]
    );

    useEffect(() => {
        setSelectedUser((previous) =>
            previous?.id
                ? (users?.find((x) => x.id === previous.id) ?? ({} as IUserTable))
                : ({} as IUserTable)
        );
    }, [users]);

    if (!data && isError) {
        return (
            <Box
                css={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                }}
            >
                <ErrorState />;
            </Box>
        );
    }

    const buttons: TButtons = [
        {
            id: '1',
            variant: 'text',
            color: 'gray',
            children: 'Add New User to Project',
            leftIcon: AddNewIcon,
            onClick: () => {
                setOperationType({ type: EUserOperation.AddUser });
                setOpen((prev) => !prev);
            },
            visible: hasProjectPermission(ProjectPermissions.ProjectShare),
        },
        {
            id: '2',
            variant: 'text',
            color: 'gray',
            children: 'Remove User from Project',
            leftIcon: RemoveUserIcon,
            onClick: openRemoveUserDialog,
            visible:
                hasProjectPermission(ProjectPermissions.ProjectUnShare) &&
                Object.keys(selectedUser).length > 0 &&
                selectedUser.email !== currentUser?.email,
        },
        {
            id: '3',
            variant: 'text',
            color: 'gray',
            children: 'Change User Role',
            leftIcon: ModifyUserIcon,
            onClick: () => {
                setOperationType({
                    type: EUserOperation.ModifyUser,
                    user: {
                        email: selectedUser.email,
                        fullName: selectedUser.name,
                        id: selectedUser.id,
                        label: `${selectedUser.name} (${selectedUser.email})`,
                    },
                });
                setOpen((prev) => !prev);
            },
            visible:
                hasProjectPermission(ProjectPermissions.ProjectShare) &&
                Object.keys(selectedUser).length > 0 &&
                selectedUser.email !== currentUser?.email,
        },
    ];

    return (
        <Box
            css={{
                display: 'flex',
                flexDirection: 'column',
                maxHeight: '100%',
            }}
        >
            <VerticalMenu buttons={buttons} />
            {!hasProjectPermission(ProjectPermissions.ProjectShare) && (
                <Box css={{ height: '12px' }} />
            )}
            <CcdTable
                css={{ flexGrow: 1 }}
                columns={columnsDescription as any}
                data={users}
                onSelected={onSelected}
                hiddenColumns={undefined}
                pagination={tableDataPagination}
                onSorted={undefined}
                onOrderChange={undefined}
                onColumnResize={undefined}
                settingsMenu={undefined}
                tableSettings={{ suppressOutsideClick: true }}
                rowRenderer={undefined}
                selectedItem={selectedUser}
            />
            {operationType && (
                <UserSideNav
                    open={open}
                    setOpen={setOpen}
                    projectId={projectId}
                    userOperationType={operationType}
                />
            )}
            {removeUserDialog}
        </Box>
    );
};

export default ProjectUsers;
