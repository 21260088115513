import React from 'react';

import Box from 'components/atoms/Box';

export interface ScanTooltip {
    versionNumber: string;
    projectId: string;
    hasPermissionToMarkAsSafe: boolean;
}

const HavePermission = ({ onClick }: { onClick: () => void }) => (
    <div>
        <span>You can only remove this version or </span>
        <Box
            as={'span'}
            css={{ color: '#005F87', fontWeight: 600, cursor: 'pointer' }}
            onClick={onClick}
        >
            confirm it is safe
        </Box>
        <span> at your own risk.</span>
    </div>
);

const HaveNotPermission = () => (
    <div>
        <div>Only an admin can manually confirm it is safe at their own risk.</div>
    </div>
);

const SecurityThreatTooltipContent = (data: string) => {
    const values: ScanTooltip = JSON.parse(data);
    const onClick = () => {
        window.dispatchEvent(
            new CustomEvent('openUnlockSuspiciousVersionDialog', { detail: values })
        );
    };

    if (!data) {
        return null;
    }

    return (
        <div>
            <div>
                <b>We detected a security threat in Version {values.versionNumber}</b>
            </div>
            <div>
                <div>Opening and other actions are suspended.</div>
                {values.hasPermissionToMarkAsSafe ? (
                    <HavePermission onClick={onClick} />
                ) : (
                    <HaveNotPermission />
                )}
            </div>
        </div>
    );
};

export default SecurityThreatTooltipContent;
