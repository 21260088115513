import { Box } from '@mui/material';
import React from 'react';

import Delay from 'components/atoms/Delay';
import ProgressSpinner from 'components/atoms/ProgressSpiner';

import useNotification from '../../services/queries/notification.query';

const LoadingState: React.FC = () => {
    const { isLoading, data } = useNotification();
    if (isLoading && !data) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                }}
            >
                <Delay>
                    <ProgressSpinner containerVersion={false} />
                </Delay>
            </Box>
        );
    }
    return null;
};

export default LoadingState;
