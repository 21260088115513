import styled from '@emotion/styled';

import { CcdTable } from 'components/_legacy/ccd-table/index';

export const Table = styled(CcdTable)(({ theme }) => ({
    '& a': {
        cursor: 'pointer',
        overflow: 'hidden',
        textOverflow: 'ellipsis',

        '&:hover': {
            color: `${theme.colors.header} !important`,
        },
    },

    '& .ccd-table-row': {
        height: 'fit-content',
        borderBottomColor: theme.colors.table.border,
    },

    fontSize: 12,
}));
