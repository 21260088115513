import { ScanInfo } from 'types/project';

export interface ISelectedFile {
    id: string;
    isFolder: boolean;
    name: string;
    sizeInKB: number;
    creationDate: string;
    createdBy: string;
    lastModifiedDate: string;
    lastModifiedBy: string;
    typeName: string;
    thumbnailUrl: string;
    contentScanInfo?: ScanInfo;
    thumbnailScanInfo?: ScanInfo;
}

export interface Inputs {
    projectName?: string;
    backendUrl?: string;
    collaborationSpaceId?: string;
    projectId?: string;
    getRoot?: () => Document;
}

export interface InitializerArg {
    dataLoadToggle?: boolean;
    currentFolder?: CurrentFolder;
    selectedFiles?: ISelectedFile[];
    isTableViewDisplayed?: boolean;
}

export type FileManagementContextProps = InitializerArg & Inputs;

export interface CurrentFolder {
    id: string;
    name: string;
    typeName: string;
    folderLevel: number;
}

export const initializerArg: FileManagementContextProps = {
    currentFolder: {
        id: '',
        name: '',
        typeName: '',
        folderLevel: 0,
    },
    projectName: undefined,
    projectId: undefined,
    collaborationSpaceId: undefined,
    backendUrl: undefined,
    dataLoadToggle: false,
    getRoot: undefined,
    selectedFiles: [],
    isTableViewDisplayed: false,
};
