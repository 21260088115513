import React, { PropsWithChildren } from 'react';

import Tooltip from 'components/atoms/Tooltip';
import {
    ChipContainer,
    ThumbnailWrapper,
    ToolVersionChip,
    VersionChip,
} from 'components/templates/ProjectTiles/ProjectTiles.styles';
import { Project } from 'types/project';

interface ProjectTileThumbnail extends PropsWithChildren {
    project: Project;
}
export function ProjectTileThumbnail({ project, children }: ProjectTileThumbnail) {
    return (
        <ThumbnailWrapper>
            <ChipContainer>
                {project.latestRevision && (
                    <VersionChip
                        label={'Version ' + project.latestRevision}
                        size='small'
                        variant='outlined'
                    />
                )}
                {project.tool && (
                    <Tooltip title={'Project uploaded to Connect from'} arrow placement='left'>
                        <ToolVersionChip label={project.tool} size={'small'} variant={'outlined'} />
                    </Tooltip>
                )}
            </ChipContainer>
            {children}
        </ThumbnailWrapper>
    );
}
