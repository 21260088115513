import { SvgIcon } from '@mui/material';
import React from 'react';

import {
    CcdEmptyStateContainer,
    CcdEmptyStateSubtitle,
    CcdEmptyStateTitle,
} from './CcdSmallEmptyState.styles';

interface ICcdSideNavEmptyStateProps {
    icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    message: string;
    subMessage?: string;
    id?: string;
}

export function CcdSmallEmptyState({ icon, message, subMessage, id }: ICcdSideNavEmptyStateProps) {
    return (
        <CcdEmptyStateContainer data-testid={id}>
            <SvgIcon
                component={icon}
                inheritViewBox
                sx={{
                    width: 56,
                    height: 56,
                }}
            />
            <CcdEmptyStateTitle>{message}</CcdEmptyStateTitle>
            <CcdEmptyStateSubtitle>{subMessage}</CcdEmptyStateSubtitle>
        </CcdEmptyStateContainer>
    );
}

export default CcdSmallEmptyState;
