import React, { PropsWithChildren, ReactElement } from 'react';

import {
    NotificationHeaderLeftGroup,
    NotificationHeaderMarkAsRead,
    NotificationHeaderTitle,
    NotificationHeaderWrapper,
} from './NotificationHeader.styles';

export interface CcdNotificationHeaderProps extends PropsWithChildren {
    markAsReadCallback?: () => void;
}

export const MARK_AS_READ_ID = 'notification-header__mark-all-as-read';
export const TITLE = 'notification-header__title';

export function NotificationHeader({
    children,
    markAsReadCallback,
}: CcdNotificationHeaderProps): ReactElement {
    return (
        <NotificationHeaderWrapper data-testid='notification-header__wrapper'>
            <NotificationHeaderLeftGroup>
                <NotificationHeaderTitle data-testid={TITLE}>{children}</NotificationHeaderTitle>
            </NotificationHeaderLeftGroup>
            {markAsReadCallback && (
                <NotificationHeaderMarkAsRead
                    data-testid={MARK_AS_READ_ID}
                    onClick={(event) => {
                        event.stopPropagation();
                        markAsReadCallback();
                    }}
                >
                    Mark all as read
                </NotificationHeaderMarkAsRead>
            )}
        </NotificationHeaderWrapper>
    );
}
