import { arrayOf, node, oneOfType } from 'prop-types';
import React, { useCallback, useMemo, useReducer } from 'react';

import GlobalUiBlockersCounterContext from '../../contexts/GlobalUiBlockersCounterContext';

// actions
const INCREASE_REGULAR_COUNTER = 'INCREASE_REGULAR_COUNTER';
const DECREASE_REGULAR_COUNTER = 'DECREASE_REGULAR_COUNTER';
const INCREASE_LIGHT_COUNTER = 'INCREASE_LIGHT_COUNTER';
const DECREASE_LIGHT_COUNTER = 'DECREASE_LIGHT_COUNTER';

// initial state
const initialState = {
    regularCounter: 0,
    lightCounter: 0,
};

// reducer
const globalUiBlockersCounterReducer = (state, action) => {
    const { type } = action;

    switch (type) {
        case INCREASE_REGULAR_COUNTER:
            return { ...state, regularCounter: state.regularCounter + 1 };
        case DECREASE_REGULAR_COUNTER:
            return { ...state, regularCounter: state.regularCounter - 1 };
        case INCREASE_LIGHT_COUNTER:
            return { ...state, lightCounter: state.lightCounter + 1 };
        case DECREASE_LIGHT_COUNTER:
            return { ...state, lightCounter: state.lightCounter - 1 };
        default:
            return state;
    }
};

const GlobalUiBlockersCounterContextProvider = ({ children }) => {
    const [counterState, dispatch] = useReducer(globalUiBlockersCounterReducer, initialState);
    const isBlocked = useMemo(
        () => counterState.regularCounter > 0 || counterState.lightCounter > 0,
        [counterState]
    );
    const isLightlyBlocked = useMemo(() => counterState.lightCounter > 0, [counterState]);
    const isRegularlyBlocked = useMemo(() => counterState.regularCounter > 0, [counterState]);

    const increaseBlockerCounter = useCallback(
        ({ isLight = false } = {}) =>
            dispatch({
                type: isLight === true ? INCREASE_LIGHT_COUNTER : INCREASE_REGULAR_COUNTER,
            }),
        [dispatch]
    );

    const decreaseBlockerCounter = useCallback(
        ({ isLight = false } = {}) =>
            dispatch({
                type: isLight === true ? DECREASE_LIGHT_COUNTER : DECREASE_REGULAR_COUNTER,
            }),
        [dispatch]
    );

    const value = useMemo(
        () => ({
            isBlocked,
            isLightlyBlocked,
            isRegularlyBlocked,
            increaseBlockerCounter,
            decreaseBlockerCounter,
            counterState,
        }),
        [
            isBlocked,
            isLightlyBlocked,
            isRegularlyBlocked,
            increaseBlockerCounter,
            decreaseBlockerCounter,
            counterState,
        ]
    );

    return (
        <GlobalUiBlockersCounterContext.Provider value={value}>
            {children}
        </GlobalUiBlockersCounterContext.Provider>
    );
};

GlobalUiBlockersCounterContextProvider.propTypes = {
    children: oneOfType([arrayOf(node), node]),
};

export default GlobalUiBlockersCounterContextProvider;
