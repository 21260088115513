import styled from '@emotion/styled';
import { useCallback, useEffect, useState } from 'react';

import Box from 'components/atoms/Box';
import Button, { FormButton } from 'components/atoms/Button';
import Checkbox from 'components/atoms/Checkbox';
import { useProgressBar } from 'components/atoms/ProgressBar';

import './BomConfigureSettingsView.css';

function BomConfigureSettingsView({ settings, onConfirm }) {
    const DUMMY_COLUMN_NAME = '';
    const {
        columns,
        hiddenColumns,
        bindingEnabled,
        bindingColumn,
        columnsOrder,
        defaultColumns,
        displayConfiguration,
    } = settings;
    const [bindingMapping, setBindingMapping] = useState(bindingColumn);
    const [columnState, setColumnState] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [mappingPresent, setMappingPresent] = useState(bindingEnabled);

    const { setProgressBar } = useProgressBar();

    const initialState = useCallback((currentVisibleColumnsOrder, currentHiddenColumns) => {
        if (displayConfiguration === null) {
            const visible = {};
            const hidden = {};
            for (const column of columns) {
                if (column.Header !== DUMMY_COLUMN_NAME) {
                    if (defaultColumns.includes(column.Header.toLowerCase())) {
                        visible[column.Header] = defaultColumns.includes(
                            column.Header.toLowerCase()
                        );
                        continue;
                    }
                    hidden[column.Header] = false;
                }
            }
            return { ...visible, ...hidden };
        } else {
            const state = {};
            for (const column of currentVisibleColumnsOrder) {
                if (!currentHiddenColumns.includes(column) && column !== DUMMY_COLUMN_NAME) {
                    state[column] = !currentHiddenColumns.includes(column);
                }
            }
            for (const column of currentHiddenColumns) {
                state[column] = false;
            }
            return state;
        }
    }, []);

    useEffect(() => {
        const columnsList = columns.map((x) => x.Header);
        const hidden = hiddenColumns.filter((x) => columnsList.includes(x));
        setColumnState(initialState(columnsOrder, hidden));
    }, [columns, columnsOrder, hiddenColumns]);

    useEffect(() => {
        const isSelected = Object.values(columnState).some((data) => data === false);
        if (isSelected) {
            setSelectAll(false);
        } else {
            setSelectAll(true);
        }
    }, [columnState]);

    const onInternalConfirm = useCallback(() => {
        setProgressBar(true);
        const result = {
            currentlyVisibleColumns: columnsOrder,
            hiddenColumns: Object.keys(columnState).filter((x) => columnState[x] !== true),
            bindingColumn: bindingMapping,
            bindingEnabled: mappingPresent,
        };
        onConfirm(result);
    }, [columnState, bindingMapping, columnsOrder, mappingPresent]);

    const setStateForAllColumns = useCallback(
        (state) => {
            const next = { ...columnState };
            for (const column of Object.keys(next)) {
                next[column] = state;
            }
            setColumnState(next);
        },
        [columnState]
    );

    const onSelectAll = useCallback(() => {
        const state = !selectAll;
        setSelectAll(state);
        setStateForAllColumns(state);
    }, [selectAll, setStateForAllColumns]);

    const toggleHiddenColumns = useCallback((column) => {
        setColumnState((prev) => {
            const next = { ...prev };
            next[column] = !next[column];
            return next;
        });
    }, []);

    const StyledLabel = styled('span')(({ theme }) => ({
        paddingLeft: 8,
        fontSize: 12,
        color: theme.colors.text,
    }));

    return (
        <Box
            css={{
                height: '100%',
                padding: '12px',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Box
                css={{
                    overflowY: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                    marginBottom: '16px',
                }}
            >
                <Box
                    css={({ colors }) => ({
                        color: colors.headerText,
                        backgroundColor: colors.header,
                        fontWeight: 600,
                        height: '32px',
                        padding: '8px',
                        marginBottom: '12px',
                    })}
                >
                    PROPERTIES
                </Box>
                <Button
                    variant='text'
                    css={{
                        cursor: 'pointer',
                        '& span': {
                            justifyContent: 'flex-start',
                        },
                    }}
                    onClick={onSelectAll}
                >
                    {selectAll ? 'Deselect all' : 'Select all'}
                </Button>
                {columnState &&
                    Object.keys(columnState).map((x) => (
                        <Checkbox
                            key={x}
                            children={<StyledLabel>{x}</StyledLabel>}
                            checked={columnState[x] === true}
                            defaultChecked={columnState[x] === true}
                            onCheckedChange={() => toggleHiddenColumns(x)}
                        />
                    ))}

                <hr className='vertical-line' />
                <Checkbox
                    children={<StyledLabel>Enable Findchips search option</StyledLabel>}
                    checked={mappingPresent}
                    defaultChecked={mappingPresent}
                    onCheckedChange={() => setMappingPresent(!mappingPresent)}
                />
                {mappingPresent && (
                    <label className='custom-select'>
                        <Box
                            as={'select'}
                            id='findchips-column-select'
                            css={({ colors }) => ({
                                backgroundColor: colors.background,
                                color: colors.text,
                                border: `1px solid ${colors.textArea.border}`,
                            })}
                            value={bindingMapping}
                            onChange={(event) => setBindingMapping(event.target.value)}
                        >
                            {bindingMapping === '' && (
                                <Box
                                    as={'option'}
                                    cdd={{ '&:hover': { backgroundColor: 'red' } }}
                                    value=''
                                    disabled
                                    hidden
                                >
                                    Select Column With Manufacturer Part Number
                                </Box>
                            )}
                            {columnState &&
                                Object.keys(columnState).map((x) => {
                                    return (
                                        <option key={x} value={x}>
                                            {x}
                                        </option>
                                    );
                                })}
                        </Box>
                    </label>
                )}
            </Box>

            <FormButton variant={'contained'} onClick={onInternalConfirm} id={'Done'}>
                Done
            </FormButton>
        </Box>
    );
}

export default BomConfigureSettingsView;
