import { Theme } from '@emotion/react';
import { Interpolation } from '@emotion/serialize/dist/emotion-serialize.cjs';
import React from 'react';
import { Slide, ToastContainer } from 'react-toastify';

import './ToastifyStyleOverrides.scss';

export interface Props {
    containerId?: string;
    additionalCss?: Interpolation<Theme>;
}

const CcdToastContainer: React.FC<Props> = ({ containerId, additionalCss }: Props) => {
    return (
        <ToastContainer
            css={additionalCss}
            position='top-right'
            hideProgressBar
            closeOnClick={false}
            draggable={false}
            transition={Slide}
            autoClose={false}
            containerId={containerId}
        />
    );
};

export default CcdToastContainer;
