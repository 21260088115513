import { useMutation } from '@tanstack/react-query';

import deleteNotificationApi from '../api/deleteNotificiation.api';
import useOptimisticUpdate from './optimisticUpdate';

const useDeleteNotificationQuery = () => {
    const { onMutate, onError } = useOptimisticUpdate();

    return useMutation({
        mutationFn: deleteNotificationApi,
        onMutate: async (notificationIdToMark) =>
            onMutate((cache) => {
                const index = cache.findIndex(
                    (item) => item.NotificationId === notificationIdToMark
                );
                return [...cache.slice(0, index), ...cache.slice(index + 1)];
            }),
        onError: onError,
    });
};

export default useDeleteNotificationQuery;
