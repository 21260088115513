export enum TRoles {
    ProjectAdmin = 'ProjectAdmin',
    ProjectEditor = 'ProjectAuthor',
    ProjectViewer = 'ProjectViewer',
}

export interface IUser {
    userId: string;
    email: string;
    firstName: string;
    lastName: string;
    roles: TRoles[];
    status: boolean;
    collaborationSpaceId: string;
    projectId: string;
}
