import {
    NAME_INPUT_ID,
    NAME_IS_REQUIRED,
} from 'components/templates/FileManagement/components/parts/FileForm/consts';
import {
    reservedNamesValidator,
    specialCharactersValidator,
    trailingDot,
    whitespacesValidator,
} from 'shared/validation';
import useNameAlreadyExistsValidator from 'shared/validation/useNameAlreadyExistsValidator';

export function useGetFileInputs() {
    const { nameAlreadyExists } = useNameAlreadyExistsValidator();
    return {
        getFileInputs: (defaultValue, getFullFileName) => [
            {
                label: 'File name',
                id: NAME_INPUT_ID,
                inputMode: 'text',
                defaultValue: defaultValue,
                isRequired: true,
                validationConditions: {
                    validate: {
                        required: (value) => !!getFullFileName(value) || NAME_IS_REQUIRED,
                        maxLength: (value) =>
                            getFullFileName(value).length <= 256 ||
                            'Name can have max 256 characters',
                        noSpecialChar: (value) => {
                            return specialCharactersValidator(getFullFileName(value));
                        },
                        noReservedChar: (value) => {
                            return reservedNamesValidator(getFullFileName(value));
                        },
                        noTrailingDot: (value) => {
                            return trailingDot(getFullFileName(value));
                        },
                        noWhitespaces: (value) => {
                            return whitespacesValidator(
                                getFullFileName(value),
                                'File name cannot contain only whitespaces'
                            );
                        },
                        nameAlreadyExists: nameAlreadyExists,
                    },
                },
            },
        ],
    };
}
