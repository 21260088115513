import React from 'react';

import { useFileManagementContext } from 'components/templates/FileManagement/components/FileManagementContext';

import FileManagementBreadcrumb from './parts/Breadcrumb';

export default function MainTableBreadcrumb() {
    const {
        state: { currentFolder },
        dispatch: dispatchFileManagement,
    } = useFileManagementContext();

    return (
        <FileManagementBreadcrumb
            dispatchFileManagement={dispatchFileManagement}
            currentFolder={currentFolder}
        />
    );
}
