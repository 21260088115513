import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';

interface Props {
    isOpened: boolean;
}

export const DATA_TESTID = 'collapse';

const Icon = styled('div')<{ isOpened: boolean }>(({ isOpened }) => ({
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    border: 'none',
    ...(isOpened && { transform: 'rotate(180deg)' }),
}));

export default function Collapse({ isOpened }: Props) {
    const theme = useTheme();

    return (
        <Icon isOpened={isOpened} data-testid={DATA_TESTID}>
            <svg width='9' height='4' viewBox='0 0 9 4' fill='none'>
                <path d='M4.90476 0L0.809524 4L9 4L4.90476 0Z' fill={theme.colors.text} />
            </svg>
        </Icon>
    );
}
