import styled from '@emotion/styled';

export const ActionTileHeaderRoot = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
}));

export const ActionTileHeaderThumbnail = styled('div')(({ theme }) => ({
    marginRight: theme.spacing(2),
    float: 'left',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    flexShrink: 0,
    height: '40px',
}));

export const ActionTileHeaderText = styled('div')`
    font-size: 14px;
    font-weight: 700;
    overflow: hidden;
    overflow-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: 'vertical';
`;
