import { useEffect, useState } from 'react';

import { ProjectRevision } from 'types/project';

export default function useSearchRevision(revisions: ProjectRevision[] | undefined) {
    const [currentRevisions, setCurrentRevisions] = useState(revisions);
    const [currentCriteria, setCurrentCriteria] = useState<string[]>([]);
    const [criteriaPresent, setCriteriaPresent] = useState(false);

    useEffect(() => {
        if (criteriaPresent) {
            setCurrentRevisions(calculateRevisions());
        }
    }, [revisions, currentCriteria]);

    function calculateRevisions() {
        const checkCriteriaForObject = (item: ProjectRevision) => {
            const searchable = {
                description: item.description,
                createdBy: item.lastModified.by.name,
                revisionId: item.versionNumber,
                creationDate: item.lastModified.date,
            };
            return currentCriteria.every((crit: string) =>
                Object.values(searchable).some((field) => {
                    return field.toUpperCase().includes(crit.toUpperCase());
                })
            );
        };
        return revisions?.filter((x) => checkCriteriaForObject(x)) || [];
    }

    function onChange(criteriaStr: string) {
        if (criteriaStr.length === 0) {
            onClear();
        } else {
            setCriteriaPresent(true);
            const criteria = criteriaStr.split(' ').map((x) => x.trim());
            setCurrentCriteria(criteria);
        }
    }

    function onClear() {
        setCriteriaPresent(false);
        setCurrentCriteria([]);
        setCurrentRevisions(revisions);
    }

    return {
        criteriaPresent,
        currentRevisions,
        currentCriteria,
        onChange,
        onClear,
    };
}
