import fetchBuilder from 'fetch-retry';
import { useCallback, useState } from 'react';

/**
 * useHttp hook gives functionality of sending/receive request with retry policy
 *
 *  @returns
 *  isLoading - indicates that
 *  const [isLoading,  error, sendRequest] = useHttp();
 *
 *  isSending - indicated that operation is in progress
 *  error - indicated that error occur
 *  sendRequest - function that realize request sending
 *    sendRequest({requestConfig, retryOptions, onSuccess, onError})
 *        requestConfig = {
 *        url: url
 *        method: 'GET', 'POST', 'PUT', 'DELETE', etc. ( 'GET' is default ),
 *        headers: - request header - default  {
 *                   'Content-Type': 'application/json'
 *               },
 *        body: json with request body,
 *        credentials: credentials options - default value 'include',
 *        retries: retries options - default is
 *           {
 *              retries: 5,
 *              retryDelay: function (attempt, error, response) {
 *                               return Math.random() * (Math.pow(2, attempt) * 1000);
 *                              }
 *          }
 *
 *         processData => (data) => {} - optional processig of response data. It may be used when eg. blob is required instead of json
 *         onSuccess - (response) => {} - handler to function called when operation finish with success.
 *                      It is called with response json, if present, or without value if operation finish without any response.
 *         onError - (error) => {} - handler to function called when error occurs. If it is an error coming from communication process
 *                    argument error contains { code : Http error code, message : error message }. In case of error coming from exception not related to
 *                    communication error contains { code : undefined, message : exception message}
 *
 *  Usage:
 *  const [isLoading, error, sendRequest] = useHttp();
 *
 *  const onSuccess = (data) => { consume request response }
 *  const onError = (data) => { consume error response }
 *  const body = { request body }
 *  const requestConfig = {url : url, method: "PUT", body: body};
 *  const processData = (data) => data.json();
 *  sendRequest({requestConfig, onSuccess, onError})
 */

export default function useHttp() {
    const [isSending, setIsSending] = useState(false);
    const [error, setError] = useState(false);

    async function send({ requestConfig, retryOptions, processData, onSuccess, onError }) {
        const retryFetch = await fetchBuilder(fetch);
        const defaultRetryOptions = {
            retries: 5,
            retryDelay: (attempt) => Math.random() * (Math.pow(2, attempt) * 1000),
        };
        setIsSending(true);
        setError(false);

        const options = {
            ...requestConfig,
            ...(retryOptions ?? defaultRetryOptions),
        };
        return retryFetch(requestConfig.url, {
            method: options.method ?? 'GET',
            headers: prepareHeaders(options),
            body: options.body instanceof FormData ? options.body : JSON.stringify(options.body),
            credentials: options.credentials ?? 'include',
            retries: options.retries,
            retryDelay: options.retryDelay,
        })
            .then((res) => {
                if (!res.ok) {
                    const error = new Error(res.statusText);
                    error.code = res.status;
                    throw error;
                }
                if (res.status === 200) {
                    const isJson = res.headers?.get('Content-Type')?.includes('application/json');
                    if (isJson) {
                        return res.json();
                    } else {
                        return processData ? processData(res) : res;
                    }
                }
            })
            .then((responseData) => {
                if (!onSuccess) {
                    return;
                }
                if (responseData == null) {
                    onSuccess();
                } else {
                    onSuccess(responseData);
                }
            })
            .catch((error) => {
                console.error(error);
                setError(true);
                if (onError) {
                    onError({
                        code: error.code ?? undefined,
                        message: error.message,
                    });
                }
            })
            .finally(setIsSending(false));
    }

    const sendRequest = useCallback(send, []);
    return {
        isSending,
        error,
        sendRequest,
    };
}

function prepareHeaders(options) {
    const headers = {
        ...(options.headers ?? {
            'Content-Type': 'application/json',
        }),
    };
    if (options.body instanceof FormData) {
        delete headers['Content-Type'];
    }
    return headers;
}
