import { DialogButton } from 'components/atoms/Button';
import Paragraph from 'components/atoms/Paragraph/Paragraph';
import Dialog from 'components/molecules/Dialog';
import { useResetIpl } from 'services/queries/ipl.query';

interface IResetAnalysisDialog {
    collaborationSpaceId: string;
    projectId: string | undefined;
    revisionId: string;
    variant: string | undefined;
    open: boolean;
    setOpen: (open: boolean) => void;
    onConfirm: () => void;
    onClose?: () => void;
}

export default function ResetAnalysisDialog({
    collaborationSpaceId,
    projectId,
    revisionId,
    variant,
    open,
    setOpen,
    onConfirm,
    onClose,
}: IResetAnalysisDialog) {
    const { mutate: resetIpl } = useResetIpl();

    return (
        <Dialog
            title='Reset analysis?'
            open={open}
            setOpen={setOpen}
            onCloseButton={onClose}
            onInteractOutside={onClose}
            buttons={
                <>
                    <DialogButton
                        id={'buttonCancel'}
                        color={'primary'}
                        variant={'outlined'}
                        onClick={onClose}
                        data-testid={'confirmationDialogCancelButton'}
                    >
                        Cancel
                    </DialogButton>
                    <DialogButton
                        id={'buttonOK'}
                        color={'primary'}
                        variant={'contained'}
                        onClick={() => {
                            resetIpl({
                                collaborationSpaceId,
                                projectId,
                                revisionId,
                                variant,
                            });
                            onConfirm();
                        }}
                        data-testid={'confirmationDialogConfirmButton'}
                    >
                        Understood, reset entire analysis
                    </DialogButton>
                </>
            }
            contentCssOverride={{
                maxWidth: 382,
                minWidth: 380,
            }}
        >
            <Paragraph style={{ margin: 0 }}>
                Reseting analysis will delete your results and all previously used settings. It’s
                advised if most of the parts resulted in an error.
            </Paragraph>
            <Paragraph style={{ marginBottom: 0, marginTop: '8px' }}>
                Otherwise, you can try the refresh option in the Summary.
            </Paragraph>
        </Dialog>
    );
}
