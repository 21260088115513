import SideNav from 'components/molecules/SideNav';
import DrawerContent from 'components/templates/NotificationPanel/components/DrawerContent';

interface INotificationSideNav {
    closeSideNav: () => void;
}

const Drawer = ({ closeSideNav }: INotificationSideNav) => {
    return (
        <SideNav
            title={'Alerts'}
            open={true}
            setOpen={closeSideNav}
            style={{ width: '374px', fontSize: '1rem' }}
        >
            <DrawerContent />
        </SideNav>
    );
};

export default Drawer;
