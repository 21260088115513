import styled from '@emotion/styled';

import Box from 'components/atoms/Box';

export const LoadingContainer = styled(Box)(({ theme }) => ({
    height: '16px',
    fontSize: '10px',
    display: 'flex',
    alignItems: 'center',
    borderTop: `1px solid ${theme.colors.background}`,
    margin: '8px',
    justifyContent: 'space-between',
}));
