import { UseInfiniteQueryResult, useInfiniteQuery } from '@tanstack/react-query';

import { getProjectUsersApi } from 'components/templates/ProjectUsers/services/api/projectUsers.api';
import { IUser } from 'components/templates/ProjectUsers/services/types';
import { useUserDataContext } from 'providers/currentUser/UserDataProvider';

interface IUsersQuery {
    projectId: string | undefined;
    pageSize?: number;
    startFrom?: number;
}

type GetUsersResponse = {
    totalSize: number;
    content: IUser[];
};

export const GET_PROJECT_USERS = 'getProjectUsers';

export const projectUsersQuery = ({
    projectId,
    pageSize = 40,
    startFrom = 0,
}: IUsersQuery): UseInfiniteQueryResult<GetUsersResponse> => {
    const { collaborationSpaceId } = useUserDataContext();
    return useInfiniteQuery<any>(
        [GET_PROJECT_USERS, collaborationSpaceId, projectId, { pageSize, startFrom }],
        async ({ pageParam = 0 }) => {
            return getProjectUsersApi({
                collaborationSpaceId,
                projectId: projectId!,
                pageSize,
                startFrom: pageParam,
            });
        },
        {
            enabled: !!(collaborationSpaceId && projectId),
            getNextPageParam: (lastPage, pages) => {
                if (pages.length * pageSize >= lastPage.totalSize) return;
                return pages.length * pageSize;
            },
        }
    );
};
