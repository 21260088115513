export type INotification = ICheckIn | ICheckOut | DocumentMarkedAsClean | VersionMarkedAsClean;

interface ICheckIn extends INotificationCommon {
    EventType: EventType.CheckIn;
    Body: CheckInBody;
}

interface ICheckOut extends INotificationCommon {
    EventType: EventType.CheckOut;
    Body: CheckOutBody;
}

interface DocumentMarkedAsClean extends INotificationCommon {
    EventType: EventType.DocumentMarkedAsClean;
    Body: DocumentMarkedAsCleanBody;
}

interface VersionMarkedAsClean extends INotificationCommon {
    EventType: EventType.VersionMarkedAsClean;
    Body: VersionMarkedAsCleanBody;
}

interface INotificationCommon {
    UserIdPerTenant: string;
    NotificationId: string;
    NotificationDate: string;
    IsRead: boolean;
    ReceiverUserId: string;
    ReceiverUserUrn: string;
    SenderUserId: string;
    SenderUserUrn: string;
    CreatedAt: string;
    UpdatedAt: string;
    ThumbnailUrl: string;
    SenderUserName: string;
}

interface CollaborationSpaceBody {
    CollaborationSpaceId: string;
    CollaborationSpaceName: string;
}

interface ProjectBody {
    ProjectId: string;
    ProjectName: string;
    ProjectRevision: number;
}

export interface CheckInBody extends CollaborationSpaceBody, ProjectBody {
    CheckInComment: string;
}

export interface CheckOutBody extends CollaborationSpaceBody, ProjectBody {}

export interface DocumentMarkedAsCleanBody extends CollaborationSpaceBody {
    ProjectId: string;
    ProjectName: string;
    DocumentName: string;
}

export interface VersionMarkedAsCleanBody extends CollaborationSpaceBody {
    ProjectId: string;
    ProjectName: string;
    VersionNumber: number;
}

export enum EventType {
    CheckIn = 'CheckIn',
    CheckOut = 'CheckOut',
    DocumentMarkedAsClean = 'DocumentMarkedAsClean',
    VersionMarkedAsClean = 'VersionMarkedAsClean',
}
