import { useCallback, useEffect, useState } from 'react';

import { CLOSE_PROGRAMMABLE_MODALS, EE } from '../../../../eventEmitter';

const useProgrammableVisibility = ({ eventName }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [eventData, setEventData] = useState(null);

    const onOpen = useCallback(
        (data) => {
            setIsOpen(true);
            setEventData(data);
        },
        [setIsOpen, setEventData]
    );

    const onClose = useCallback(() => {
        setIsOpen(false);
        setEventData(null);
    }, [setIsOpen, setEventData]);

    useEffect(() => {
        EE.addListener(eventName, onOpen);
        EE.addListener(CLOSE_PROGRAMMABLE_MODALS, onClose);

        return () => {
            EE.removeListener(eventName, onOpen);
            EE.removeListener(CLOSE_PROGRAMMABLE_MODALS, onClose);
        };
    }, [onOpen, onClose]);

    return { isOpen, eventData, onClose };
};

export default useProgrammableVisibility;
