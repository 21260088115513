import * as React from "react";
const SvgType2D48 = (props) => /* @__PURE__ */ React.createElement("svg", { id: "Layer_1", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", x: "0px", y: "0px", viewBox: "0 0 48 48", style: {
  enableBackground: "new 0 0 48 48"
}, xmlSpace: "preserve", ...props }, /* @__PURE__ */ React.createElement("style", { type: "text/css" }, "\n	.st0{fill:url(#SVGID_1_);}\n	.st1{fill:#464646;}\n	.st2{fill:url(#SVGID_00000037683719095235826870000007778859244476735652_);}\n	.st3{fill:url(#SVGID_00000108274934061756587850000001916858217786023348_);}\n	.st4{fill:#FFFFFF;}\n"), /* @__PURE__ */ React.createElement("g", { id: "XCC-Viewer-with-Notes-v4" }, /* @__PURE__ */ React.createElement("g", { id: "Group-3", transform: "translate(-330.000000, -344.000000)" }, /* @__PURE__ */ React.createElement("g", { transform: "translate(80.000000, 284.000000)" }, /* @__PURE__ */ React.createElement("g", { id: "type3D48", transform: "translate(251.000000, 61.000000)" }, /* @__PURE__ */ React.createElement("g", { id: "polygon13" }, /* @__PURE__ */ React.createElement("linearGradient", { id: "SVGID_1_", gradientUnits: "userSpaceOnUse", x1: -259.1046, y1: 427.0693, x2: -257.848, y2: 425.4286, gradientTransform: "matrix(18.383 0 0 -24 4777.2231 10256.0674)" }, /* @__PURE__ */ React.createElement("stop", { offset: 0, style: {
  stopColor: "#FFFFFF"
} }), /* @__PURE__ */ React.createElement("stop", { offset: 0.232, style: {
  stopColor: "#FAFAFA"
} }), /* @__PURE__ */ React.createElement("stop", { offset: 0.496, style: {
  stopColor: "#EDEDED"
} }), /* @__PURE__ */ React.createElement("stop", { offset: 0.775, style: {
  stopColor: "#D6D6D6"
} }), /* @__PURE__ */ React.createElement("stop", { offset: 1, style: {
  stopColor: "#BEBEBE"
} })), /* @__PURE__ */ React.createElement("polygon", { className: "st0", points: "10.5,45.4 10.5,0.6 32,0.6 44.4,12.9 44.4,45.4  " }), /* @__PURE__ */ React.createElement("path", { className: "st1", d: "M31.6,1.6l11.8,11.8v31.1H11.5V1.6H31.6 M32.5-0.4H9.5v46.9h35.9V12.5L32.5-0.4L32.5-0.4z" })), /* @__PURE__ */ React.createElement("g", { id: "polygon26" }, /* @__PURE__ */ React.createElement("linearGradient", { id: "SVGID_00000052814070255753648310000016006331907644408255_", gradientUnits: "userSpaceOnUse", x1: -231.2729, y1: 383.3836, x2: -229.7146, y2: 381.8253, gradientTransform: "matrix(6.6383 0 0 -6.6383 1565.0532 2549.5847)" }, /* @__PURE__ */ React.createElement("stop", { offset: 0, style: {
  stopColor: "#FFFFFF"
} }), /* @__PURE__ */ React.createElement("stop", { offset: 0.221, style: {
  stopColor: "#F8F8F8"
} }), /* @__PURE__ */ React.createElement("stop", { offset: 0.541, style: {
  stopColor: "#E5E5E5"
} }), /* @__PURE__ */ React.createElement("stop", { offset: 0.92, style: {
  stopColor: "#C6C6C6"
} }), /* @__PURE__ */ React.createElement("stop", { offset: 1, style: {
  stopColor: "#BEBEBE"
} })), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "url(#SVGID_00000052814070255753648310000016006331907644408255_)"
}, points: "32.4,12.3 32.4,2 42.7,12.3  " }), /* @__PURE__ */ React.createElement("path", { className: "st1", d: "M33.4,4.4l6.9,6.9h-6.9V4.4 M31.4-0.4v13.8h13.8L31.4-0.4L31.4-0.4z" })), /* @__PURE__ */ React.createElement("g", { id: "rect45" }, /* @__PURE__ */ React.createElement("linearGradient", { id: "SVGID_00000168803639692693321980000007127639973084433296_", gradientUnits: "userSpaceOnUse", x1: -255.6434, y1: 392.4703, x2: -253.3779, y2: 391.2203, gradientTransform: "matrix(14.8085 0 0 -8.1702 3791.4363 3231.1003)" }, /* @__PURE__ */ React.createElement("stop", { offset: 0, style: {
  stopColor: "#73B4C8"
} }), /* @__PURE__ */ React.createElement("stop", { offset: 0.175, style: {
  stopColor: "#6AAEC3"
} }), /* @__PURE__ */ React.createElement("stop", { offset: 0.457, style: {
  stopColor: "#529EB5"
} }), /* @__PURE__ */ React.createElement("stop", { offset: 0.809, style: {
  stopColor: "#2C839F"
} }), /* @__PURE__ */ React.createElement("stop", { offset: 1, style: {
  stopColor: "#147391"
} })), /* @__PURE__ */ React.createElement("rect", { x: 0.6, y: 18, style: {
  fill: "url(#SVGID_00000168803639692693321980000007127639973084433296_)"
}, width: 43.8, height: 23.3 }), /* @__PURE__ */ React.createElement("path", { className: "st1", d: "M43.4,19v21.3H1.6V19H43.4 M45.4,17H-0.4v25.3h45.8V17L45.4,17z" })), /* @__PURE__ */ React.createElement("g", { id: "text870-9", transform: "translate(7.768340, 2.822716)" }, /* @__PURE__ */ React.createElement("path", { id: "path849", className: "st4", d: "M27.9,26.8c0,1.3-0.3,2.5-0.8,3.6c-0.5,1-1.2,1.8-2.1,2.5c-0.6,0.4-1.3,0.7-2.1,0.9 c-0.9,0.2-1.8,0.3-2.7,0.3h-4.5V19.6h4.7c0.9,0,1.8,0.1,2.7,0.3c0.7,0.2,1.3,0.4,1.9,0.8c0.9,0.6,1.6,1.5,2.1,2.5 C27.6,24.3,27.9,25.6,27.9,26.8L27.9,26.8z M24.4,26.8c0-0.8-0.1-1.7-0.5-2.4c-0.5-1-1.4-1.8-2.5-2c-0.5-0.1-1.1-0.1-1.6-0.1 h-0.8v9.1h0.8c0.6,0,1.2,0,1.7-0.1c0.4-0.1,0.7-0.2,1.1-0.4c0.6-0.4,1-0.9,1.3-1.6C24.3,28.5,24.5,27.6,24.4,26.8L24.4,26.8z" })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { className: "st4", d: "M14.3,34.2L14.3,34.2l5.9,0.1v2.7h-9.6v-2.5l4.2-4c0.8-0.8,1.4-1.5,1.8-2c0.3-0.5,0.5-1.1,0.5-1.7 c0-1.3-0.7-2-2.2-2c-1.3,0-2.5,0.5-3.6,1.5v-2.8c1.3-0.8,2.7-1.2,4.3-1.2c1.5,0,2.7,0.4,3.5,1.1c0.8,0.8,1.3,1.8,1.3,3 c0,1.7-1,3.5-3.1,5.3L14.3,34.2z" })))))));
export default SvgType2D48;
