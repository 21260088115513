import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Align } from 'components/_legacy/ccd-popup-menu';
import { CcdTableColumn } from 'components/_legacy/ccd-table';
import ascSortIcon from 'images/miscSortedAscending10.svg';
import descSortIcon from 'images/miscSortedDescending10.svg';
import noSortIcon from 'images/miscUnSorted10.svg';

import { CcdPopupMenu } from '../../../ccd-popup-menu';

type Order = 'ascending' | 'descending' | 'no-sort';
interface CcdSortingMenu {
    column: CcdTableColumn;
    onSorted?: (column: CcdTableColumn, direction: Order) => void;
    direction?: Align;
    initialSortDirection?: Order;
    useTableOffset?: boolean;
}

const ascending = 'ascending';
const descending = 'descending';
const noSort = 'no-sort';
const CcdSortingMenu = ({
    column,
    onSorted,
    direction,
    initialSortDirection,
    useTableOffset,
}: CcdSortingMenu) => {
    const sortedColumnChangedEventName = 'sortedColumnChanged';

    const [sortDirection, setSortDirection] = useState(initialSortDirection || noSort);
    const resetSortOrder = (event: Event) => {
        const customEvent = event as CustomEvent<{ currentlySortedColumn: CcdTableColumn }>;
        if (
            !customEvent.detail.currentlySortedColumn.tableId ||
            (customEvent.detail.currentlySortedColumn.id !== column.id &&
                customEvent.detail.currentlySortedColumn.tableId === column.tableId)
        ) {
            setSortDirection(noSort);
        }
    };

    useEffect(() => {
        document.addEventListener(sortedColumnChangedEventName, resetSortOrder);
        return () => document.removeEventListener(sortedColumnChangedEventName, resetSortOrder);
    }, [column, setSortDirection]);

    const getSortIcon = useMemo(() => {
        switch (sortDirection) {
            case ascending:
                return ascSortIcon;
            case descending:
                return descSortIcon;
            default:
                return noSortIcon;
        }
    }, [sortDirection]);

    const changeSorting = useCallback((column: CcdTableColumn, direction: Order) => {
        const changeSortedColumnEvent = new CustomEvent(sortedColumnChangedEventName, {
            detail: { currentlySortedColumn: column },
        });
        document.dispatchEvent(changeSortedColumnEvent);
        setSortDirection(direction);
        onSorted && onSorted(column, direction);
    }, []);

    const sortItems = useMemo(
        () => [
            {
                name: 'Sort Ascending',
                icon: ascSortIcon,
                callback: () => changeSorting(column, ascending),
            },
            {
                name: 'Sort Descending',
                icon: descSortIcon,
                callback: () => changeSorting(column, descending),
            },
            {
                name: 'No Sorting',
                icon: noSortIcon,
                callback: () => changeSorting(column, noSort),
            },
        ],
        []
    );

    return (
        <CcdPopupMenu
            menuButton={getSortIcon}
            menuItems={sortItems}
            direction={direction}
            id={column.Header as string}
            useTableOffset={useTableOffset}
            closeOthers
        />
    );
};

export default CcdSortingMenu;
