import React from 'react';
import { OptionProps } from 'react-select';

import Box from 'components/atoms/Box';

import { IUserItem } from './IUserItem';

const Option = (props: OptionProps<IUserItem>) => {
    const {
        data,
        innerProps: { ref, ...restInnerProps },
        getStyles,
    } = props;

    let firstLine: string;
    let secondLine: string;
    if (data.fullName.includes('null')) {
        firstLine = data.email;
        secondLine = '';
    } else {
        firstLine = data.fullName;
        secondLine = data.email;
    }

    return (
        <Box {...restInnerProps} ref={ref} css={getStyles('option', props)}>
            <div>
                <div>{firstLine}</div>
                <div style={{ fontSize: '10px' }}>{secondLine}</div>
            </div>
        </Box>
    );
};

export default Option;
