import styled from '@emotion/styled';
import { useState } from 'react';

import { Center } from 'components/atoms/Flex/Flex';
import { Input, InputWrapper } from 'components/atoms/Input/Input';
import Close from 'images/close.svg?react';
import Search from 'images/search.svg?react';

const IconContainer = styled(Center)(({ theme }) => ({
    padding: '0 4px',
    backgroundColor: theme.colors.textArea.background,
    '*': {
        fill: theme.colors.textArea.text,
    },
}));

export default function CcdSearchInput({ idPrefix, placeholder, onChangeCallback, className }) {
    const [currentText, setCurrentText] = useState('');

    const onClickHandler = (event) => {
        event.stopPropagation();
        event.preventDefault();
    };

    const onInputChange = (event) => {
        event.stopPropagation();
        event.preventDefault();
        setCurrentText(event.target.value);
        onChangeCallback(event.target.value);
    };

    const onClear = (event) => {
        setCurrentText('');
        onChangeCallback('');
        event.preventDefault();
        event.stopPropagation();
    };

    const renderIcon = () => {
        if (currentText) {
            return (
                <IconContainer>
                    <Close
                        onClick={onClear}
                        aria-hidden='true'
                        id={idPrefix + '-clear'}
                        css={{
                            display: 'flex',
                            cursor: 'pointer',
                        }}
                    />
                </IconContainer>
            );
        } else {
            return (
                <IconContainer>
                    <Search
                        onClick={onClickHandler}
                        aria-hidden='true'
                        css={{
                            display: 'flex',
                        }}
                    />
                </IconContainer>
            );
        }
    };
    return (
        <div className={className}>
            <InputWrapper tabIndex='-1'>
                <Input
                    css={{
                        width: '95%',
                        paddingLeft: '4px',
                        borderColor: 'transparent',
                        outline: 'none',
                        fontSize: '12px',
                        '&:focus': {
                            boxShadow: 'none',
                        },
                        '&::placeholder': {
                            fontStyle: 'italic',
                        },
                    }}
                    id={idPrefix + '-input'}
                    value={currentText}
                    type='text'
                    placeholder={placeholder}
                    onInput={onInputChange}
                    onClick={onClickHandler}
                />
                {renderIcon()}
            </InputWrapper>
        </div>
    );
}
