import React, { useContext } from 'react';

import projectInfoPanelContext from '../../ProjectHistoryPanelContext';
import useProjectHistoryLineElementsHook from './useProjectHistoryLineElementsHook';
import useProjectHistoryLinePrototypesHook from './useProjectHistoryLinePrototypes';
import useProjectLineHistoryDrawStrategyHook from './useProjectLineHistoryDrawStrategy';

export default function useProjectHistoryLineRegularAccordion(direction, width, height) {
    const placement = useContext(projectInfoPanelContext);

    const {
        lastItem,
        firstItem,
        drawBottomLine,
        drawBottomShortenLine,
        drawUpperLine,
        drawUpperShortenLine,
    } = useProjectHistoryLinePrototypesHook(width, height);

    const { baseItemElement } = useProjectHistoryLineElementsHook(direction, width, height);

    const firstElement = (
        <React.Fragment key='useProjectHistoryLineRegularAccordion_firstElement'>
            {drawUpperLine}
            {drawBottomShortenLine}
            {firstItem}
        </React.Fragment>
    );

    const lastElement = (
        <React.Fragment key='useProjectHistoryLineRegularAccordion_lastElement'>
            {drawUpperShortenLine}
            {drawBottomLine}
            {lastItem}
        </React.Fragment>
    );

    const regularElement = (
        <React.Fragment key='useProjectHistoryLineRegularAccordion_regularElement'>
            {drawUpperLine}
            {drawBottomLine}
        </React.Fragment>
    );

    const { draw } = useProjectLineHistoryDrawStrategyHook(
        firstElement,
        lastElement,
        regularElement,
        baseItemElement
    );

    return {
        drawRegularAccordion: () => draw(placement.isFirst, placement.isLast),
    };
}
