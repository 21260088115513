import fetcher from 'providers/api/fetcher';

import { IUser } from '../types';

interface IUsersApi {
    collaborationSpaceId: string;
    projectId: string;
    startFrom?: number;
    pageSize?: number;
}

interface IUsers {
    content: IUser[];
    totalSize: number;
}

export const getProjectUsersApi = async ({
    collaborationSpaceId,
    projectId,
    startFrom,
    pageSize,
}: IUsersApi): Promise<IUsers> => {
    const { data } = await fetcher({
        url: `user-management/project`,
        params: {
            collaborationSpaceId,
            projectId,
            startFrom,
            pageSize,
        },
    });
    return data;
};
