import { PropsWithChildren } from 'react';

import {
    AddToUserDataLayer,
    InitGtm,
    useGoogleTagManager,
} from 'hooks/analytics/useGoogleTagManager';
import { createGenericContext } from 'shared/createGenericContext';

const [useGoogleTagManagerContext, GoogleTagManagerContextProvider] = createGenericContext<{
    addToUserDataLayer: AddToUserDataLayer;
    isInitialized: boolean;
    initGtm: InitGtm;
}>();

const GoogleTagManagerProvider = ({ children }: PropsWithChildren) => {
    const { initGtm, addToUserDataLayer, isInitialized } = useGoogleTagManager();

    return (
        <GoogleTagManagerContextProvider value={{ addToUserDataLayer, isInitialized, initGtm }}>
            {children}
        </GoogleTagManagerContextProvider>
    );
};

export { useGoogleTagManagerContext, GoogleTagManagerProvider };
