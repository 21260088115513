export const uploadFileMaxLengthInBytes = 1073741824;

export const DATA_LOADING_MAX_REQUEST_ATTEMPTS = 20;

export const DATA_LOADING_TIME_DELAY_IN_MILLIS = 500;

export const RENAME_FILE_BUTTON = 'renameFileButton';
export const CREATE_FOLDER_BUTTON = 'createFolderButton';
export const UPLOAD_FILE_BUTTON = 'uploadFileButton';
export const DOWNLOAD_FILE_BUTTON = 'downloadFileButton';
export const DELETE_FILE_BUTTON = 'deleteFileButton';
export const MOVE_FILE_BUTTON = 'moveFileButton';
export const CREATE_FOLDER_BUTTON_ID = 'create-folder';
export const SWITCH_TILE_VIEW = 'switch-tile-view';
export const SWITCH_TABLE_VIEW = 'switch-table-view';
export const IMAGE_VIEWER_BUTTON = 'show-image-viewer';

export const unexpectedError = {
    title: 'Unexpected error',
    messages: [
        'Oops! Something went wrong.',
        'Please try again.',
        'If you have seen this message multiple times, please contact our support.',
    ],
};

export const CONTACT_SUPPORT_MESSAGE =
    'If you have seen this message multiple times, please contact our support.';

export const THERE_ARE_NO_FILES_HERE_YET = 'There are no files here yet';
