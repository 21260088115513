import * as React from "react";
const SvgTypeTopicSchemaRevsion48 = (props) => /* @__PURE__ */ React.createElement("svg", { id: "Layer_1", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", x: "0px", y: "0px", viewBox: "2 0 50 48", style: {
  enableBackground: "new 2 0 50 48"
}, xmlSpace: "preserve", ...props }, /* @__PURE__ */ React.createElement("style", { type: "text/css" }, "\n	.st0{fill:url(#SVGID_1_);}\n	.st1{fill:#464646;}\n	.st2{fill:url(#SVGID_00000103249579764015671550000011681144783458611596_);}\n	.st3{fill:none;stroke:#6E6E6E;}\n"), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("linearGradient", { id: "SVGID_1_", gradientUnits: "userSpaceOnUse", x1: 4.8517, y1: 801.0382, x2: 44.3519, y2: 840.5385, gradientTransform: "matrix(1 0 0 1 0 -793.89)" }, /* @__PURE__ */ React.createElement("stop", { offset: 0, style: {
  stopColor: "#FFFFFF"
} }), /* @__PURE__ */ React.createElement("stop", { offset: 0.2323, style: {
  stopColor: "#FAFAFA"
} }), /* @__PURE__ */ React.createElement("stop", { offset: 0.4964, style: {
  stopColor: "#EDEDED"
} }), /* @__PURE__ */ React.createElement("stop", { offset: 0.775, style: {
  stopColor: "#D6D6D6"
} }), /* @__PURE__ */ React.createElement("stop", { offset: 1, style: {
  stopColor: "#BEBEBE"
} })), /* @__PURE__ */ React.createElement("polygon", { className: "st0", points: "10.5,46.5 10.5,1.5 32.9,1.5 44.5,13.1 44.5,46.5  " }), /* @__PURE__ */ React.createElement("path", { className: "st1", d: "M32.5,2.5l11,11v32h-32v-43H32.5 M33.3,0.5H9.5v47h36V12.7L33.3,0.5L33.3,0.5z" })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("linearGradient", { id: "SVGID_00000131348888463698247160000000631384664380974764_", gradientUnits: "userSpaceOnUse", x1: 31.4148, y1: 799.1382, x2: 40.7517, y2: 808.4751, gradientTransform: "matrix(1 0 0 1 0 -793.89)" }, /* @__PURE__ */ React.createElement("stop", { offset: 0, style: {
  stopColor: "#FFFFFF"
} }), /* @__PURE__ */ React.createElement("stop", { offset: 0.2214, style: {
  stopColor: "#F8F8F8"
} }), /* @__PURE__ */ React.createElement("stop", { offset: 0.5415, style: {
  stopColor: "#E5E5E5"
} }), /* @__PURE__ */ React.createElement("stop", { offset: 0.92, style: {
  stopColor: "#C6C6C6"
} }), /* @__PURE__ */ React.createElement("stop", { offset: 1, style: {
  stopColor: "#BEBEBE"
} })), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "url(#SVGID_00000131348888463698247160000000631384664380974764_)"
}, points: "33.7,12.3 33.7,2.9 43.1,12.3 " }), /* @__PURE__ */ React.createElement("path", { className: "st1", d: "M34.7,5.3l5.9,5.9h-5.9V5.3 M32.7,0.5v12.8h12.8L32.7,0.5L32.7,0.5z" })), /* @__PURE__ */ React.createElement("line", { className: "st3", x1: 25.7, y1: 24, x2: 29.8, y2: 24 }), /* @__PURE__ */ React.createElement("line", { className: "st3", x1: 20.9, y1: 15.1, x2: 20.9, y2: 19.2 }), /* @__PURE__ */ React.createElement("polyline", { className: "st3", points: "20.9,28.8 20.9,37.7 29.8,37.7 " }), /* @__PURE__ */ React.createElement("rect", { x: 16.8, y: 6.2, className: "st3", width: 8.2, height: 8.2 }), /* @__PURE__ */ React.createElement("line", { className: "st3", x1: 18.9, y1: 8.9, x2: 23, y2: 8.9 }), /* @__PURE__ */ React.createElement("line", { className: "st3", x1: 18.9, y1: 11.7, x2: 23, y2: 11.7 }), /* @__PURE__ */ React.createElement("rect", { x: 16.8, y: 19.9, className: "st3", width: 8.2, height: 8.2 }), /* @__PURE__ */ React.createElement("line", { className: "st3", x1: 18.9, y1: 22.6, x2: 23, y2: 22.6 }), /* @__PURE__ */ React.createElement("line", { className: "st3", x1: 18.9, y1: 25.4, x2: 23, y2: 25.4 }), /* @__PURE__ */ React.createElement("rect", { x: 30.5, y: 19.9, className: "st3", width: 8.2, height: 8.2 }), /* @__PURE__ */ React.createElement("line", { className: "st3", x1: 32.6, y1: 22.6, x2: 36.7, y2: 22.6 }), /* @__PURE__ */ React.createElement("line", { className: "st3", x1: 32.6, y1: 25.4, x2: 36.7, y2: 25.4 }), /* @__PURE__ */ React.createElement("rect", { x: 30.5, y: 33.6, className: "st3", width: 8.2, height: 8.2 }), /* @__PURE__ */ React.createElement("line", { className: "st3", x1: 32.6, y1: 36.3, x2: 36.7, y2: 36.3 }), /* @__PURE__ */ React.createElement("line", { className: "st3", x1: 32.6, y1: 39.1, x2: 36.7, y2: 39.1 }));
export default SvgTypeTopicSchemaRevsion48;
