import { Chip, styled } from '@mui/material';

const RevisionChip = styled(Chip)(({ theme }) => ({
    width: '64px',
    height: '16px',
    backgroundColor: theme.colors.background,
    fontSize: '10px',
    borderColor: theme.colors.text,
    color: theme.colors.text,
    '& > .MuiChip-label': {
        padding: theme.spacing(0, 1),
    },
}));

RevisionChip.defaultProps = { variant: 'outlined' };

export default RevisionChip;
