import { RefObject, useCallback, useEffect, useState } from 'react';

/**
 * useDetectOutsideClick hook gives possibility to react when click event was performed outside watched DOM element
 *
 *  @example
 *  const [setIsDetectionActive] = useDetectOutsideClick(elementRef, outsideClickCallback, initialState, isSuppressed);
 *   @returns setIsDetectionActive - function to enable/disable outside click detection.
 *  Useful when there is a need to temporarily disable outside click detection (e.g. when performing actions on server)
 *  @param elementRef - a ref to the element which need to be watched for outside clicks
 *  @param outsideClickCallback - a function called when outside click is detected
 *  @param initialState - a boolean which sets the initial state of detection. True - detection enabled, False - disabled
 *  @param isSuppressed - a boolean which disables detection completely (calling setIsDetectionActive(true) won't have any effect)
 */
export default function useDetectOutsideClick(
    elementRef: RefObject<HTMLElement>,
    outsideClickCallback: () => void,
    initialState: boolean,
    isSuppressed?: boolean
) {
    const [isDetectionActive, setIsDetectionActive] = useState(initialState);

    const pageClickEvent = useCallback(
        (e: Event) => {
            if (elementRef.current !== null && !e.composedPath().includes(elementRef.current)) {
                if (outsideClickCallback) {
                    outsideClickCallback();
                }
            }
        },
        [outsideClickCallback]
    );

    useEffect(() => {
        if (isSuppressed) {
            return;
        }

        if (isDetectionActive) {
            window.addEventListener('click', pageClickEvent);

            return () => {
                window.removeEventListener('click', pageClickEvent);
            };
        } else {
            window.removeEventListener('click', pageClickEvent);
        }
    }, [isDetectionActive, elementRef, outsideClickCallback, isSuppressed]);

    return [setIsDetectionActive];
}
