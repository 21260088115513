import { CcdTileUploadFile } from 'components/_legacy/ccd-tile-upload-file';

import CcdInputUpload from '../../../../FileUpload/DragNDropUpload/CcdInputUpload';

export default function CcdFileUploadStaticFile() {
    return (
        <CcdInputUpload>
            {(onInputUpload) => (
                <CcdTileUploadFile message='Drag & Drop files here, or' onUpload={onInputUpload} />
            )}
        </CcdInputUpload>
    );
}
