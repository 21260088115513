import { useCollaborationSpacePermissionQuery } from 'providers/permissions/permission.query';
import { CollaborationSpacePermissions } from 'types/permissions';

export default function useCollaborationSpacePermission(collaborationSpaceId: string) {
    const { data, isLoading } = useCollaborationSpacePermissionQuery(collaborationSpaceId);
    const hasCollaborationSpacePermission = (
        operationId: CollaborationSpacePermissions
    ): boolean => {
        return data ? data[operationId] : false;
    };

    return {
        hasCollaborationSpacePermission,
        permissionsIsLoading: isLoading,
    };
}
