class SelectionGroup {
    constructor(initial) {
        this.initialPosition = initial;
        this.rangePosition = initial;
    }

    isBetween = (element, min, max) => {
        return element >= min && element <= max;
    };

    clear = (current) => {
        const min = Math.min(this.rangePosition, this.initialPosition);
        const max = Math.max(this.rangePosition, this.initialPosition);

        return current.filter((x) => !this.isBetween(parseInt(x.id), min, max));
    };

    appendRange = (current, rows, row) => {
        const min = Math.min(parseInt(row.id), this.initialPosition);
        const max = Math.max(parseInt(row.id), this.initialPosition);

        const previousSelection = this.clear(current);
        this.rangePosition = parseInt(row.id);

        return [
            ...previousSelection,
            ...rows.filter((x) => this.isBetween(parseInt(x.id), min, max)),
        ];
    };
}

class SelectionProvider {
    constructor() {
        this.lastSelection = -1;
        this.currentSelectedGroup = undefined;
    }

    isSelected = (current, row) =>
        current.find((selectedRow) => selectedRow.id === row.id) !== undefined;

    storeCurrentRow = (row) => {
        this.lastSelection = parseInt(row.id);
    };

    clearSelectionGroup = () => (this.currentSelectedGroup = undefined);
    clearLastSelection = () => (this.lastSelection = -1);

    rangeSelection = (current, rows, row) => {
        if (this.lastSelection === -1) {
            this.storeCurrentRow(row);
        }
        if (this.currentSelectedGroup === undefined) {
            this.currentSelectedGroup = new SelectionGroup(this.lastSelection);
        }
        return this.currentSelectedGroup.appendRange(current, rows, row);
    };

    regularSelection = (row) => {
        this.clearSelectionGroup();
        this.storeCurrentRow(row);
        return [row];
    };

    appendSelection = (current, row) => {
        this.storeCurrentRow(row);
        this.clearSelectionGroup();
        if (this.isSelected(current, row)) {
            return [...current.filter((x) => x.id !== row.id)];
        } else {
            const newArray = [...current];
            newArray.push(row);
            return newArray;
        }
    };

    toggleSelection = (current, row) => {
        this.storeCurrentRow(row);
        this.clearSelectionGroup();
        if (this.isSelected(current, row)) {
            return [];
        } else {
            return [row];
        }
    };

    selectAll = (rows) => {
        this.clearSelectionGroup();
        return rows;
    };

    clearSelection = () => {
        this.clearSelectionGroup();
        this.clearLastSelection();
    };
}

export default function createSelectionProvider() {
    return new SelectionProvider();
}
