import { useCallback, useState } from 'react';

export default function useMoveForm() {
    const [error, setError] = useState();
    const [buttonDisabled, setButtonDisabled] = useState(false);

    const handleCloseError = useCallback(() => {
        setError(null);
        enableButton();
    }, []);

    const enableButton = useCallback(() => {
        setButtonDisabled(false);
    }, []);

    const disableButton = useCallback(() => {
        setButtonDisabled(true);
    }, []);

    return {
        error,
        setError,
        buttonDisabled,
        handleCloseError,
        disableButton,
        enableButton,
    };
}
