import styled from '@emotion/styled';
import React from 'react';
import { useNavigate } from 'react-router';

import { Accordion, AccordionBody, AccordionHeader } from 'components/atoms/Accordion';
import Collapse from 'components/atoms/Collapse';
import { MARK_AS_READ_ID, NotificationHeader } from 'components/atoms/NotificationHeader';
import useMarkAllAsRead from 'components/templates/NotificationPanel/services/queries/markAllAsRead.query';
import useRelativeTime from 'hooks/useRelativeTime';
import { Path } from 'types/paths.enum';

import { EventType, INotification } from '../../INotification';
import { TileFactory } from '../Tiles';

const Box = styled('div')({
    [`& :not(:first-of-type) [data-testid=${MARK_AS_READ_ID}]`]: {
        display: 'none',
    },
});

interface IAccordions {
    markAsRead: (item: string) => void;
    deleteNotification: (item: string) => void;
    data?: INotification[];
}

export default function Accordions({ data, markAsRead, deleteNotification }: IAccordions) {
    const { mutate: markAllAsRead } = useMarkAllAsRead();
    const { relativeTime } = useRelativeTime();
    const navigator = useNavigate();

    const sorted =
        data?.sort((a, b) => {
            return new Date(b.NotificationDate).getTime() - new Date(a.NotificationDate).getTime();
        }) ?? [];

    const groupedNotifications: { [k: string]: INotification[] } = {};
    sorted.forEach((item) => {
        const date = new Date(item.NotificationDate);
        date.setHours(0, 0, 0, 0);

        const dateStr = date.toString();

        if (dateStr in groupedNotifications) {
            groupedNotifications[dateStr].push(item);
        } else {
            groupedNotifications[dateStr] = [item];
        }
    });

    function tileRenderer(item: INotification) {
        return (
            <TileFactory
                key={item.NotificationId}
                item={item}
                markAsRead={() => item.IsRead || markAsRead(item.NotificationId)}
                deleteNotification={(event) => {
                    event.stopPropagation();
                    deleteNotification(item.NotificationId);
                }}
                openProject={() => {
                    if (
                        item.EventType === EventType.CheckIn ||
                        item.EventType === EventType.CheckOut
                    ) {
                        navigator(
                            `${Path.PROJECTS}/${item.Body.ProjectId}/${item.Body.ProjectRevision}`
                        );
                    } else if (isDocumentMarkedAsClean(item)) {
                        const filePath = '/0/2';
                        navigator(`${Path.PROJECTS}/${item.Body.ProjectId}${filePath}`);
                    } else {
                        navigator(`${Path.PROJECTS}/${item.Body.ProjectId}`);
                    }
                }}
            />
        );
    }

    function isDocumentMarkedAsClean(item: INotification) {
        return item.EventType === EventType.DocumentMarkedAsClean;
    }

    function getRelativeTime(notification: INotification) {
        const time = relativeTime({
            date: notification.NotificationDate,
            options: {
                dateFormat: { year: 'numeric', month: 'numeric', day: 'numeric' },
                supportedUnits: ['day', 'week', 'month', 'quarter', 'year'],
                upperCaseFirstLetter: true,
            },
        });
        return time === 'Today' ? 'Earlier Today' : time;
    }
    if (data && data.length !== 0) {
        return (
            <Box>
                {Object.entries(groupedNotifications).map(([day, notifications]) => (
                    <Accordion key={day}>
                        <AccordionHeader expandIcon={<Collapse isOpened={false} />}>
                            <NotificationHeader
                                markAsReadCallback={() => {
                                    markAllAsRead({ notificationId: data?.[0].NotificationId });
                                }}
                            >
                                {getRelativeTime(notifications[0])}
                            </NotificationHeader>
                        </AccordionHeader>
                        <AccordionBody>{notifications.map(tileRenderer)}</AccordionBody>
                    </Accordion>
                ))}
            </Box>
        );
    }
    return null;
}
