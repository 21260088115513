import Box from 'components/atoms/Box';
import ComboBox, { ISelectList } from 'components/atoms/ComboBox';
import Label from 'components/atoms/Label';

export type BomColumn = { label: string; value: string };
interface Props {
    defaultMpn?: string;
    bomColumns: ISelectList[];
    onChange: (value: unknown) => void;
}

export const MpnComboBox = ({ defaultMpn, bomColumns, onChange }: Props) => {
    return (
        <Box
            css={{
                width: '100%',
                fontSize: '12px',
            }}
        >
            <Label required={true} style={{ paddingBottom: '8px' }}>
                Manufacturer Part Number
            </Label>
            <ComboBox
                selectList={bomColumns}
                onChange={onChange}
                placeholder={'Select column with MPN'}
                defaultValue={bomColumns.find((column) => column.value === defaultMpn)}
                id={'mpns'}
                css={({ colors }) => ({
                    backgroundColor: colors.background,
                })}
                overrideStyles={{
                    control: {
                        padding: '6px 8px',
                        ':focus-within': {
                            padding: '6px 8px',
                        },
                    },
                    menu: {
                        position: 'fixed',
                        width: 'calc(100% - 32px)',
                    },
                }}
            />
        </Box>
    );
};

export default MpnComboBox;
