export default function useProjectHistoryLinePrototypesHook(width, height) {
    const smallCircleRadius = 8;
    const bigCircleRadius = 12;
    const lineShorter = 3 * smallCircleRadius;
    const color = '#005F87';

    const lineStyle = {
        strokeWidth: 4,
        stroke: color,
    };

    const circleStyle = {
        strokeWidth: 4,
        fill: color,
    };

    const itemAnchor = (
        <circle cx={width / 2} cy={height / 2} r={bigCircleRadius} style={circleStyle} />
    );

    const leftLine = (
        <line x1={width / 2} y1={height / 2} x2={0} y2={height / 2} style={lineStyle} />
    );

    const rightLine = (
        <line x1={width / 2} y1={height / 2} x2={width} y2={height / 2} style={lineStyle} />
    );

    const lastItem = (
        <circle cx={width / 2} cy={lineShorter} r={smallCircleRadius} style={circleStyle} />
    );

    const firstItem = (
        <circle
            cx={width / 2}
            cy={height - lineShorter}
            r={smallCircleRadius}
            style={circleStyle}
        />
    );

    const drawLastElementLine = (
        <line x1={width / 2} y1={height / 2} x2={width / 2} y2={height} style={lineStyle} />
    );

    const drawUpperLine = (
        <line x1={width / 2} y1={0} x2={width / 2} y2={height / 2} style={lineStyle} />
    );

    const drawBottomLine = (
        <line x1={width / 2} y1={height} x2={width / 2} y2={height / 2} style={lineStyle} />
    );

    const drawBottomShortenLine = (
        <line
            x1={width / 2}
            y1={height - lineShorter}
            x2={width / 2}
            y2={height / 2}
            style={lineStyle}
        />
    );

    const drawUpperShortenLine = (
        <line x1={width / 2} y1={lineShorter} x2={width / 2} y2={height / 2} style={lineStyle} />
    );

    const drawFirstElementLine = (
        <line x1={width / 2} y1={0} x2={width / 2} y2={height / 2} style={lineStyle} />
    );

    return {
        leftLine,
        rightLine,
        lastItem,
        firstItem,
        itemAnchor,
        drawFirstElementLine,
        drawLastElementLine,
        drawBottomLine,
        drawUpperLine,
        drawBottomShortenLine,
        drawUpperShortenLine,
    };
}
