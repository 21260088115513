import { useEffect, useState } from 'react';

import { useFileManagementContext } from 'components/templates/FileManagement/components/FileManagementContext';

import { EE, OPEN_IMAGE_VIEWER } from '../../../eventEmitter';

export default function useOpenImageViewer() {
    const { dispatch } = useFileManagementContext();
    const [openImageViewer, setOpenImageViewer] = useState(false);

    useEffect(() => {
        if (openImageViewer) {
            EE.emit(OPEN_IMAGE_VIEWER);
            setOpenImageViewer(false);
        }
    }, [openImageViewer]);

    function onImageClickHandler(item) {
        setOpenImageViewer(true);
        dispatch({
            selectedFiles: [item],
        });
    }

    return { onImageClickHandler };
}
