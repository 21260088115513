import { useCallback, useRef, useState } from 'react';

import useDetectOutsideClick from 'components/_legacy/hooks/useDetectOutsideClick';
import SortAZIcon from 'images/SortAZ16.svg?react';
import SortAscendingIcon from 'images/SortAscendIng16.svg?react';
import SortDescendingIcon from 'images/SortDescendIng16.svg?react';

const SORT_TILES_BUTTON = 'sortTilesButton';

export enum SortType {
    LATEST = 'Latest',
    OLDEST = 'Oldest',
    ALPHABETICAL = 'Alphabetical',
}

export interface SortFields {
    sortColumn: 'name' | 'lastModifiedDate';
    sortOrder: 'ascending' | 'descending';
    sortType: SortType;
}

export type OnGridSort = (sort: SortFields) => void;

const icons = {
    [SortType.OLDEST]: SortAscendingIcon,
    [SortType.LATEST]: SortDescendingIcon,
    [SortType.ALPHABETICAL]: SortAZIcon,
};

export default function useSortTiles(
    onSort: OnGridSort | undefined,
    visible: boolean,
    sortDirection: SortType = SortType.LATEST
) {
    const target = useRef(null);
    const [isActive, setActive] = useState(false);
    const [sortIcon, setSortIcon] = useState<SortType>(sortDirection);

    const outsideClickCallback = useCallback(() => {
        setActive(false);
    }, [setActive]);

    useDetectOutsideClick(target, outsideClickCallback, true);
    const onSortAscending = useCallback(() => {
        setSortIcon(SortType.OLDEST);
        onSort?.({
            sortColumn: 'lastModifiedDate',
            sortOrder: 'ascending',
            sortType: SortType.OLDEST,
        });
    }, []);

    const onSortDescending = useCallback(() => {
        setSortIcon(SortType.LATEST);
        onSort?.({
            sortColumn: 'lastModifiedDate',
            sortOrder: 'descending',
            sortType: SortType.LATEST,
        });
    }, []);

    const onSortAZ = useCallback(() => {
        setSortIcon(SortType.ALPHABETICAL);
        onSort?.({
            sortColumn: 'name',
            sortOrder: 'ascending',
            sortType: SortType.ALPHABETICAL,
        });
    }, []);

    const menuItems = [
        {
            name: SortType.LATEST,
            icon: SortDescendingIcon,
            callback: onSortDescending,
        },
        { name: SortType.OLDEST, icon: SortAscendingIcon, callback: onSortAscending },
        { name: SortType.ALPHABETICAL, icon: SortAZIcon, callback: onSortAZ },
    ];

    return {
        sortTiles: {
            id: SORT_TILES_BUTTON,
            children: 'Change Sort',
            onClick: () => {
                setActive((prev) => !prev);
            },
            leftIcon: icons[sortIcon],
            popupMenu: {
                menuItems: menuItems,
                isActive: isActive,
                direction: 'right',
                enableSelection: true,
                defaultSelection: sortDirection,
            },
            variant: 'text',
            color: 'gray',
            visible: onSort && visible,
            ref: target,
        },
    };
}
