import styled from '@emotion/styled';

import { CommonStyles } from 'components/atoms/TextArea/TextArea';

export const Input = styled('input')(({ theme }) => ({
    backgroundColor: theme.colors.textArea.background,
    color: theme.colors.textArea.text,
}));

export const InputWrapper = styled('div')(({ theme }) => ({
    width: '496px',
    height: '32px',
    display: 'flex',
    ...CommonStyles(theme),
}));
