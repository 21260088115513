import { useMemo } from 'react';

import { IPagination } from 'components/templates/FileManagement/components/parts/Table/useTable';

const usePagination = () => {
    const paginationMemo: IPagination = useMemo(
        () => ({
            update: () => {
                // This is intentional
            },
            hasMore: false,
        }),
        []
    );

    return { paginationMemo };
};

export default usePagination;
