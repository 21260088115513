import React, { PropsWithChildren, createContext } from 'react';

import { VerticalMenuViewType } from 'components/molecules/VerticalMenu/VerticalMenu';
import { useFileManagementContext } from 'components/templates/FileManagement/components/FileManagementContext';
import { ISelectedFile } from 'components/templates/FileManagement/contexts/FileManagementContext';
import { OnGridSort, SortType } from 'hooks/useSortTiles';

import useTable, { FileFilterHandler, ISettings, OnFilesTableSort } from '../parts/Table/useTable';

export interface ICurrentFolderContext {
    data: ISelectedFile[] | undefined;
    filteredData: ISelectedFile[] | undefined;
    hasError: boolean;
    isLoading: boolean;
    sortingHandler: OnFilesTableSort;
    gridSortingHandler: OnGridSort;
    filteringHandler: FileFilterHandler;
    onViewChange: (viewType: VerticalMenuViewType) => void;
    sortType: SortType;
    settings: ISettings;
}

export const CurrentFolderContext = createContext<ICurrentFolderContext | undefined>(undefined);

const CurrentFolderContextProvider = ({ children }: PropsWithChildren) => {
    const {
        state: { currentFolder },
        dispatch: dispatchFileManagement,
    } = useFileManagementContext();

    const value = useTable(currentFolder, dispatchFileManagement);

    return <CurrentFolderContext.Provider value={value}>{children}</CurrentFolderContext.Provider>;
};

export default CurrentFolderContextProvider;
