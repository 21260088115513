import React from 'react';

import ComboBox, { ComboBoxGroupRoot, ISelectList } from 'components/atoms/ComboBox';
import { ThemesName, useThemeContext } from 'providers/theme/ThemeProvider';

interface Theme extends ISelectList {
    value: ThemesName;
}
const ThemesComboBox = () => {
    const { selectTheme, currentTheme } = useThemeContext();

    const roles: Theme[] = [
        { label: 'Light', value: ThemesName.Light },
        {
            label: 'Calidum Dark Grey',
            value: ThemesName.CalidumDarkGrey,
        },
        {
            label: 'Calidum Light Grey',
            value: ThemesName.CalidumLightGrey,
        },
    ];

    return (
        <ComboBoxGroupRoot>
            <ComboBox
                options={roles}
                onChange={(value) => {
                    selectTheme((value as Theme).value);
                }}
                defaultValue={roles.find((item) => item.value === currentTheme)}
                placement={'top'}
                invertedColors
            />
        </ComboBoxGroupRoot>
    );
};

export default ThemesComboBox;
