import useOpenHTMLArchiveViewer from 'FileManagement/components/parts/Table/useOpenHTMLArchiveViewer';
import { useCallback } from 'react';

import { isImage, isPdf, isWebPageHTML } from '../../../utils';
import useOpenFolder from './useOpenFolder';
import useOpenImageViewer from './useOpenImageViewer';
import useOpenPdfViewer from './useOpenPdfViewer';

export default function useListItem(currentFolder, dispatchFileManagement) {
    const { onFolderClickHandler } = useOpenFolder(currentFolder, dispatchFileManagement);
    const { onImageClickHandler } = useOpenImageViewer();
    const { onPdfClickHandler } = useOpenPdfViewer();
    const { onHTMLArchiveClickHandler } = useOpenHTMLArchiveViewer();

    const getFilesDetails = (selectionArray) => {
        return selectionArray.map((object) => {
            return object.original;
        });
    };

    const selectionHandler = useCallback(
        (row) => {
            dispatchFileManagement({
                selectedFiles: getFilesDetails(row),
            });
        },
        [dispatchFileManagement]
    );

    // TODO: D-90672
    const onFileClickHandler = useCallback(
        (item) => {
            if (item.isFolder) {
                onFolderClickHandler(item);
            } else if (isImage(item.name)) {
                onImageClickHandler(item);
            } else if (isPdf(item.name)) {
                onPdfClickHandler(item);
            } else if (isWebPageHTML(item.typeName)) {
                onHTMLArchiveClickHandler(item);
            }
        },
        [currentFolder]
    );

    return { selectionHandler, onFileClickHandler };
}
