import styled from '@emotion/styled';

import CannotUploadImageSvg from 'images/cmdUpload24-red.svg?react';
import UploadImageSvg from 'images/cmdUpload24.svg?react';
import InProgressSvg from 'images/cmdUploadDrag24.svg?react';
import InfectedIconSvg from 'images/indicatorError16.svg?react';
import { customColors } from 'theme';

import { isDropInProgress, isDropNotPossible } from './utils/stateUtils';

export const DragDrop = styled('div')(({ theme }) => ({
    width: '100%',
    height: 200,
    boxSizing: 'border-box',
    position: 'relative',
    marginTop: theme.spacing(2),
}));

export interface StateParams {
    hasAccess: boolean;
    mode: number;
    counter: number;
    infected: boolean;
}

const StateParams = ({ hasAccess, mode, counter }: StateParams) => {
    if (!hasAccess) {
        return {
            backgroundColor: 'rgba(#fff7cd, 0.8)',
            border: `dashed 1px ${customColors['yellow-11']}`,
        };
    } else if (isDropInProgress(mode, counter)) {
        return {
            backgroundColor: 'aliceblue',
            border: `dashed 1px ${customColors['blue-18']}`,
        };
    } else if (isDropNotPossible(mode)) {
        return {
            backgroundColor: 'rgba(220, 0, 0, 0.15)',
            border: `dashed 1px ${customColors['red']}`,
        };
    }
    return {
        borderRadius: 3,
        border: 'dashed 1px #6e6e6e',
    };
};

export const DragDropZone = styled('div')((props: StateParams) => ({ theme }) => ({
    boxSizing: 'border-box',
    width: '100%',
    height: '100%',
    padding: theme.spacing(6),
    display: 'block',
    alignItems: 'center',
    justifyContent: 'center',
    ...StateParams(props),
}));

export const DragContent = styled('div')({
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
});

const getColor = ({
    hasAccess,
    mode,
    counter,
    infected,
}: StateParams): keyof typeof customColors => {
    if (!(hasAccess || infected)) return 'yellow-11';
    else if (isDropNotPossible(mode) || infected) return 'red';
    else if (isDropInProgress(mode, counter)) return 'blue-13';
    return 'black-0';
};

export const Message = styled('div')((props: StateParams) => ({ theme }) => ({
    fontSize: 16,
    lineHeight: '19px',
    fontStyle: 'italic',
    textAlign: 'center',
    flexGrow: 1,
    marginTop: theme.spacing(3),
    color: customColors[getColor(props)],
}));

export const CurrentImage = styled('img')({
    width: 176,
    height: 88,
    objectFit: 'contain',
});

export const ReplaceRemoveArea = styled('div')({
    display: 'flex',
});

const CommonImageStyle = {
    width: 80,
    height: 50,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundClip: 'padding-box',
    backgroundSize: '50%',
};

export const UploadImage = styled(UploadImageSvg)({
    ...CommonImageStyle,
});

export const CannotUploadImage = styled(CannotUploadImageSvg)({
    fill: 'red',
    ...CommonImageStyle,
});

export const InProgressImage = styled(InProgressSvg)({
    ...CommonImageStyle,
});

export const InfectedIcon = styled(InfectedIconSvg)(() => ({
    width: '24px',
    height: '24px',
}));
