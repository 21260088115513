import { useTheme } from '@emotion/react';
import { Box, Drawer } from '@mui/material';

export interface Props extends React.PropsWithChildren {
    open: boolean;
    closeDrawer: () => void;
}

const LeftDrawer = ({ children, open, closeDrawer }: Props): JSX.Element => {
    const theme = useTheme();
    return (
        <Drawer
            anchor='left'
            open={open}
            onClose={closeDrawer}
            sx={{ marginLeft: '64px', zIndex: 1500 }}
        >
            <Box
                sx={{
                    marginLeft: '64px',
                    width: 300,
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    backgroundColor: theme.colors.drawerBackground,
                    color: 'white',
                }}
            >
                <Box />
                <Box sx={{ margin: 2 }}>{children}</Box>
            </Box>
        </Drawer>
    );
};

export default LeftDrawer;
