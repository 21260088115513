import { PROGRESS_BAR_EVENT, ProgressEvent, ProgressParams } from './ProgressBar';

export default function useProgressBar() {
    const setProgressBar = (visible: boolean, params: ProgressParams = {}) => {
        document.dispatchEvent(
            new CustomEvent<ProgressEvent>(PROGRESS_BAR_EVENT, {
                bubbles: true,
                detail: {
                    eventName: visible ? 'start' : 'end',
                    eventArgs: params,
                },
            })
        );
    };

    return {
        setProgressBar,
    };
}
