import { createContext } from 'react';

const initializerArgs = {
    isAddFolderCommandAccessible: false,
    isUploadFileCommandAccessible: false,
    isRenameFileCommandAccessible: false,
    isRenameFolderCommandAccessible: false,
    isDisplayPdfCommandAccessible: false,
    isDisplayImageCommandAccessible: false,
    isDeleteFilesCommandAccessible: false,
    isMoveFilesCommandAccessible: false,
    isDownloadCommandAccessible: false,
    isDownloadCurrentFolderCommandAccessible: false,
    isDisplayHTMLCommandAccessible: false,
    isMarkAsCleanCommandAccessible: false,
};

const CommandVisibilityContext = createContext(initializerArgs);

export default CommandVisibilityContext;
