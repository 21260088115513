import { useState } from 'react';

import { SideNavComponents } from 'components/molecules/SideNav';
import { INotification } from 'components/templates/NotificationPanel/INotification';
import Accordions from 'components/templates/NotificationPanel/components/Accordion';
import { NotificationSection } from 'components/templates/NotificationPanel/components/DrawerContent/DrawerContent.styles';
import EmptyState from 'components/templates/NotificationPanel/components/EmptyState';
import ErrorState from 'components/templates/NotificationPanel/components/ErrorState';
import LoadingState from 'components/templates/NotificationPanel/components/LoadingState';
import Menu from 'components/templates/NotificationPanel/components/Menu';
import useDeleteNotificationQuery from 'components/templates/NotificationPanel/services/queries/deleteNotification.query';
import useMarkAsRead from 'components/templates/NotificationPanel/services/queries/markAsRead.query';
import useNotification from 'components/templates/NotificationPanel/services/queries/notification.query';

export type FilterFunction = (data: INotification[]) => INotification[];

const DrawerContent = () => {
    const [filterFunction, setFilterFunction] = useState<FilterFunction>();

    const { mutate: markAsRead } = useMarkAsRead();
    const { mutate: deleteNotification } = useDeleteNotificationQuery();
    const { data } = useNotification(filterFunction);

    return (
        <SideNavComponents>
            <Menu setFilterFn={setFilterFunction} />
            <NotificationSection>
                <Accordions
                    data={data}
                    markAsRead={markAsRead}
                    deleteNotification={deleteNotification}
                />
                <LoadingState />
                <EmptyState />
                <ErrorState />
            </NotificationSection>
        </SideNavComponents>
    );
};

export default DrawerContent;
