import { useFileManagementContext } from 'components/templates/FileManagement/components/FileManagementContext';

import { useCommandBarVisibilityContext } from '../../../../hooks';

export default function useCommandBar() {
    const {
        state: { isTableViewDisplayed },
    } = useFileManagementContext();
    const {
        isAddFolderCommandAccessible,
        isUploadFileCommandAccessible,
        isRenameFileCommandAccessible,
        isRenameFolderCommandAccessible,
        isDisplayPdfCommandAccessible,
        isDisplayImageCommandAccessible,
        isDeleteFilesCommandAccessible,
        isMoveFilesCommandAccessible,
        isDownloadCommandAccessible,
        isDownloadCurrentFolderCommandAccessible,
        isMarkAsCleanCommandAccessible,
    } = useCommandBarVisibilityContext();

    const currentFolderGroup = [
        isAddFolderCommandAccessible,
        isDownloadCurrentFolderCommandAccessible,
    ];
    const selectionGroup = [
        isDownloadCommandAccessible,
        isRenameFileCommandAccessible,
        isRenameFolderCommandAccessible,
        isDisplayPdfCommandAccessible,
        isDisplayImageCommandAccessible,
        isDeleteFilesCommandAccessible,
        isMoveFilesCommandAccessible,
        isMarkAsCleanCommandAccessible,
    ];

    const isCurrentFolderGroupVisible = currentFolderGroup.some(
        (isCommandAccessible) => isCommandAccessible
    );

    const isSelectionGroupVisible = selectionGroup.some(
        (isCommandAccessible) => isCommandAccessible
    );

    const isSeparatorVisible = isCurrentFolderGroupVisible && isSelectionGroupVisible;

    const isSideNavFileUploadVisible = isUploadFileCommandAccessible && isTableViewDisplayed;

    const isTileGroupVisible = !isTableViewDisplayed;

    return {
        isSeparatorVisible,
        isSideNavFileUploadVisible,
        isTileGroupVisible,
    };
}
