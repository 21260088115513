import { Theme } from '@emotion/react';
import { Interpolation } from '@emotion/serialize';
import * as RadixDialog from '@radix-ui/react-dialog';
import { ReactNode, useEffect } from 'react';

import Box from 'components/atoms/Box';
import CloseSvgIcon from 'components/atoms/CloseSvgIcon';

import {
    DialogButtons,
    DialogChildren,
    DialogClose,
    DialogContent,
    DialogHeader,
    DialogOverlay,
    DialogTitle,
} from './Dialog.styles';

export interface DialogProps extends RadixDialog.DialogProps {
    open: boolean;
    onCloseButton?: RadixDialog.DialogCloseProps['onClick'];
    onInteractOutside?: RadixDialog.DialogContentProps['onInteractOutside'];
    title?: ReactNode;
    buttons?: ReactNode;
    setOpen?: (open: boolean) => void;
    overlayColor?: string;
    hideOverlay?: boolean;
    contentCssOverride?: Interpolation<Theme>;
    closeButton?: boolean;
}

function Dialog({
    children,
    open,
    title,
    buttons,
    setOpen,
    overlayColor,
    onCloseButton,
    onInteractOutside,
    contentCssOverride,
    closeButton = true,
    hideOverlay = false,
}: DialogProps) {
    useEffect(() => {
        if (open) {
            document.body.classList.add('bannerFix');
        }
        return () => {
            document.body.classList.remove('bannerFix');
        };
    }, [open]);

    return (
        <RadixDialog.Root open={open} onOpenChange={setOpen}>
            <RadixDialog.Portal>
                {hideOverlay !== true ? (
                    <DialogOverlay css={{ backgroundColor: overlayColor }} />
                ) : null}
                <DialogContent
                    data-testid={'DialogContent'}
                    css={contentCssOverride}
                    onInteractOutside={onInteractOutside}
                >
                    {title ? (
                        <DialogHeader>
                            <DialogTitle data-testid={'DialogTitle'}>{title}</DialogTitle>
                            {closeButton ? (
                                <DialogClose onClick={onCloseButton} asChild id={'dialogClose'}>
                                    <Box>
                                        <CloseSvgIcon />
                                    </Box>
                                </DialogClose>
                            ) : null}
                        </DialogHeader>
                    ) : null}
                    {children ? (
                        <DialogChildren data-testid={'DialogChildren'} css={{ overflow: 'auto' }}>
                            {children}
                        </DialogChildren>
                    ) : null}
                    {buttons ? <DialogButtons>{buttons}</DialogButtons> : null}
                </DialogContent>
            </RadixDialog.Portal>
        </RadixDialog.Root>
    );
}

export default Dialog;
