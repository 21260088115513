import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { useProgressBar } from 'components/atoms/ProgressBar';
import useDataMapperHook from 'components/organisms/ProjectVersion/hooks/dataMapperHook';
import { getProjectVersion } from 'services/api/synchronization/project.service';
import { ProjectRevision } from 'types/project';

import { IProjectInfo } from '../../Design';

export const GET_PROJECT_DESIGN = 'getProjectDesign';

export default function designQuery({ projectInfo }: { projectInfo: IProjectInfo }) {
    const { mapData } = useDataMapperHook();
    const { setProgressBar } = useProgressBar();

    const collaborationSpaceId = projectInfo.collaborationSpaceId;
    const projectId = projectInfo.projectId;
    const revisionId = projectInfo.revisionId === '0' ? undefined : projectInfo.revisionId;

    return useQuery({
        queryKey: [GET_PROJECT_DESIGN, collaborationSpaceId, projectId, revisionId],
        queryFn: () => {
            if (!projectId) throw new Error('Missing projectId parameter');
            return getProjectVersion({ collaborationSpaceId, projectId, revisionId });
        },
        retry: (counter: number, error: AxiosError) => {
            if (error?.response?.status === 404) {
                return false;
            }
            return counter <= 2;
        },
        select: (data: ProjectRevision) => {
            return mapData([data])[0];
        },
        enabled: !!(collaborationSpaceId && projectId),
        onSettled: () => {
            setProgressBar(false);
        },
    });
}
