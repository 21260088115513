import styled from '@emotion/styled';

export const HorizontalMenuDivider = styled('div')<{ width: number }>(({ width, theme }) => ({
    display: 'inline-flex',
    height: 1,
    width: width,
    backgroundColor: theme.colors.panelButtonText,
}));

export default HorizontalMenuDivider;
