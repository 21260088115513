import isNil from 'lodash/isNil';

import { useFileManagementContext } from 'components/templates/FileManagement/components/FileManagementContext';
import { useProjectPermission } from 'providers/permissions';
import { isScanStatusClean, isScanStatusInfected } from 'shared/utility';
import { ProjectPermissions } from 'types/permissions';

import CommandVisibilityContext from '../../contexts/CommandVisibilityContext';
import { isImage, isPdf, isWebPageHTML } from '../../utils';

function isSingleFolderSelected(selectedFiles) {
    return !isNil(selectedFiles) && selectedFiles.length === 1 && selectedFiles[0].isFolder;
}

function isSingleDocumentSelected(selectedFiles) {
    return !isNil(selectedFiles) && selectedFiles.length === 1 && !selectedFiles[0].isFolder;
}

function areMultipleFilesSelected(selectedFiles) {
    return !isNil(selectedFiles) && selectedFiles.length > 0;
}

function areMultipleDocumentsSelected(selectedFiles) {
    return areMultipleFilesSelected(selectedFiles);
}

export default function CommandVisibilityContextProvider({ children }) {
    const {
        state: { selectedFiles, projectId, collaborationSpaceId },
    } = useFileManagementContext();

    const { hasProjectPermission } = useProjectPermission(collaborationSpaceId, projectId);

    const value = {
        isAddFolderCommandAccessible: hasProjectPermission(
            ProjectPermissions.FileManagementCreateFolder
        ),
        isUploadFileCommandAccessible: hasProjectPermission(
            ProjectPermissions.FileManagementCreateDocument
        ),
        isRenameFileCommandAccessible:
            isSingleDocumentSelected(selectedFiles) &&
            hasProjectPermission(ProjectPermissions.FileManagementRenameDocument),
        isRenameFolderCommandAccessible:
            isSingleFolderSelected(selectedFiles) &&
            hasProjectPermission(ProjectPermissions.FileManagementRenameFolder),
        isDisplayPdfCommandAccessible:
            isSingleDocumentSelected(selectedFiles) &&
            isPdf(selectedFiles[0].name) &&
            hasProjectPermission(ProjectPermissions.FileManagementGetDocument),
        isDisplayPdfCommandActive:
            isSingleDocumentSelected(selectedFiles) &&
            isScanStatusClean(selectedFiles[0].contentScanInfo),
        isDisplayImageCommandAccessible:
            isSingleDocumentSelected(selectedFiles) &&
            isImage(selectedFiles[0].name) &&
            isScanStatusClean(selectedFiles[0].contentScanInfo) &&
            hasProjectPermission(ProjectPermissions.FileManagementGetDocument),
        isDeleteFilesCommandAccessible:
            areMultipleFilesSelected(selectedFiles) &&
            hasProjectPermission(ProjectPermissions.FileManagementDeleteDocument) &&
            hasProjectPermission(ProjectPermissions.FileManagementDeleteFolder),
        isMoveFilesCommandAccessible:
            areMultipleDocumentsSelected(selectedFiles) &&
            hasProjectPermission(ProjectPermissions.FileManagementTransferFiles),
        isDownloadCommandAccessible:
            areMultipleFilesSelected(selectedFiles) &&
            hasProjectPermission(ProjectPermissions.FileManagementGetDocument),
        isDownloadCurrentFolderCommandAccessible: hasProjectPermission(
            ProjectPermissions.FileManagementGetDocument
        ),
        isDisplayHTMLCommandAccessible:
            isSingleDocumentSelected(selectedFiles) &&
            isWebPageHTML(selectedFiles[0].typeName) &&
            isScanStatusClean(selectedFiles[0].contentScanInfo) &&
            hasProjectPermission(ProjectPermissions.FileManagementGetDocument),
        isMarkAsCleanCommandAccessible:
            isSingleDocumentSelected(selectedFiles) &&
            isScanStatusInfected(selectedFiles[0].contentScanInfo) &&
            hasProjectPermission(ProjectPermissions.MarkAsClean),
    };
    return (
        <CommandVisibilityContext.Provider value={value}>
            {children}
        </CommandVisibilityContext.Provider>
    );
}
