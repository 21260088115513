import { useState } from 'react';

export default function useTooltipHandler() {
    const [descriptionTooltip, setDescriptionTooltip] = useState(true);
    const [buttonTooltip, setButtonTooltip] = useState(true);

    function disableDescriptionTooltip() {
        setDescriptionTooltip(false);
        setTimeout(() => setDescriptionTooltip(true));
    }

    function disableButtonTooltip() {
        setButtonTooltip(false);
        setTimeout(() => setButtonTooltip(true));
    }

    return {
        descriptionTooltip,
        buttonTooltip,
        disableDescriptionTooltip,
        disableButtonTooltip,
    };
}
