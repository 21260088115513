import { useTheme } from '@emotion/react';
import cx from 'classnames';
import debounce from 'lodash/debounce';
import propTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import {
    useBlockLayout,
    useColumnOrder,
    useFlexLayout,
    useResizeColumns,
    useRowSelect,
    useTable,
} from 'react-table';

import useDetectOutsideClick from 'components/_legacy/hooks/useDetectOutsideClick';
import useMultiSelection from 'components/_legacy/selection/useMultiSelection';
import Box from 'components/atoms/Box';

import { CcdSortingMenu, CcdTableDragAndDropHeader } from './';

import './CcdTable.scss';

const { array, arrayOf, bool, func, oneOfType, shape, string } = propTypes;

const fallbackColumnWidth = 150;
const columnResizeTimeout = 1000;

function CcdTable({
    columns,
    data,
    hiddenColumns,
    pagination,
    onSelected,
    onDoubleClick,
    onSorted,
    onHover,
    onOrderChange,
    settingsMenu,
    tableSettings,
    className,
    onColumnResize,
    forcedSelection,
    rowRenderer,
    selectedItem,
    style = {},
}) {
    const columnsMemo = useMemo(() => columns, [columns]);
    const dataMemo = useMemo(() => data, [data]);
    const getTableLayout = useCallback(
        () => (tableSettings?.layout === 'flex' ? useFlexLayout : useBlockLayout),
        [tableSettings]
    );

    const suppressOutsideClick = React.useMemo(() => {
        return tableSettings?.suppressOutsideClick === true;
    }, [tableSettings]);

    const layoutPlugin = getTableLayout();

    const tableRef = useRef();
    const { colors } = useTheme();

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        visibleColumns,
        prepareRow,
        setColumnOrder,
        setHiddenColumns,
        state,
    } = useTable(
        {
            columns: columnsMemo,
            data: dataMemo,
            initialState: { hiddenColumns },
        },
        useColumnOrder,
        useRowSelect,
        useResizeColumns,
        layoutPlugin
    );

    const onResizeColumnHandler = useCallback(
        debounce((widths, columns) => {
            const result = columns.reduce((res, col) => {
                res[col.Header] = Object.keys(widths).find((x) => x === col.accessor)
                    ? widths[col.accessor]
                    : (col.width ?? col.minWidth ?? fallbackColumnWidth);
                return res;
            }, {});
            onColumnResize(result);
        }, columnResizeTimeout),
        []
    );

    useEffect(() => {
        if (
            onColumnResize &&
            state?.columnResizing?.columnWidths &&
            Object.keys(state.columnResizing.columnWidths).length > 0
        ) {
            onResizeColumnHandler(state.columnResizing.columnWidths, columns);
        }
    }, [state.columnResizing, columns]);

    useEffect(() => {
        if (tableSettings && tableSettings.initialColumnOrder) {
            setColumnOrder(tableSettings?.initialColumnOrder);
        }
    }, [tableSettings]);

    const currentColOrder = useMemo(() => visibleColumns.map((o) => o.id), [visibleColumns]);

    const headerProps = (props) => [
        props,
        {
            style: {
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                display: 'flex',
            },
        },
    ];

    useEffect(() => {
        hiddenColumns !== undefined && setHiddenColumns(hiddenColumns);
    }, [hiddenColumns, setHiddenColumns]);

    const baseTableRowClass = useMemo(() => {
        const condition = tableSettings !== undefined && !tableSettings.wrapText;
        return cx('tr ccd-table-row', {
            'ccd-table-cell-ellipsis': condition,
            'ccd-table-row-wrap': !condition,
        });
    }, [tableSettings]);

    const columnClassNameHandler = useMemo(
        () =>
            tableSettings?.wrapText === true
                ? 'td ccd-table-cell ccd-table-cell-wrap'
                : 'td ccd-table-cell ccd-table-cell-ellipsis',

        [tableSettings]
    );

    const {
        handleSelection,
        clearSelection,
        selectedRecords: selectedRows,
        onKeyDown,
    } = useMultiSelection(tableSettings, rows, onSelected, forcedSelection);
    const onSortedMemo = useMemo(() => onSorted, [onSorted]);

    useEffect(() => {
        if (
            (!selectedItem || Object.keys(selectedItem).length === 0) &&
            selectedRows &&
            Object.keys(selectedRows).length > 0
        ) {
            clearSelection();
        }
    }, [selectedItem]);

    useDetectOutsideClick(tableRef, clearSelection, true, suppressOutsideClick);

    function getCells(row) {
        return (
            <>
                {row.cells.map((cell) => {
                    return (
                        <div
                            key={`${cell.column.Header}-${cell.row.id}`}
                            className={columnClassNameHandler}
                            {...cell.getCellProps()}
                        >
                            {cell.render('Cell')}
                        </div>
                    );
                })}
            </>
        );
    }

    const renderRows = useCallback(() => {
        return (
            <>
                {rows.map((row) => {
                    prepareRow(row);
                    const isSelected = !!selectedRows.find((x) => x.id === row.id);

                    const rowArgs = {
                        key: row.id,
                        id: 'row'.concat(row.id),
                        ...row.getRowProps(),
                        onClick: (event) => {
                            if (onDoubleClick && event.detail === 2) {
                                onDoubleClick(row, event);
                            } else {
                                handleSelection(row, event);
                            }
                        },
                        onMouseMoveCapture: () => {
                            onHover?.(row);
                        },
                        className: baseTableRowClass,
                        css: {
                            ':hover': {
                                backgroundColor: colors.table.row.hover,
                            },
                            ...(isSelected && {
                                backgroundColor: colors.table.row.select,
                            }),
                        },
                    };

                    if (rowRenderer) {
                        return rowRenderer({
                            rowArgs: { ...rowArgs },
                            row: row,
                            children: getCells(row),
                        });
                    } else {
                        return (
                            <Box
                                key={rowArgs.id}
                                {...rowArgs}
                                css={(props) => {
                                    return {
                                        ...rowArgs.css,
                                        borderBottomColor: props.colors.table.border,
                                    };
                                }}
                            >
                                {getCells(row)}
                            </Box>
                        );
                    }
                })}
            </>
        );
    }, [
        rows,
        onSelected,
        onDoubleClick,
        onHover,
        prepareRow,
        selectedRows,
        getTableBodyProps,
        columnClassNameHandler,
    ]);

    const addSortingMenu = useCallback((column, cols) => {
        const min = Math.min(cols.findIndex((item) => item.isVisible));
        const index = cols.findIndex((item) => item.Header === column.Header);
        if (index === min) {
            return 'right';
        } else if (index === column.length - 1) {
            return 'left';
        } else {
            return 'center';
        }
    }, []);

    function standardTableHeader() {
        return (
            <div className='ccd-table-header-with-settings'>
                {headerGroups.map((headerGroup, headerGroupIndex) => {
                    const headerGroupKey = `tr headerGroupId-${headerGroupIndex}`;

                    return (
                        <div
                            key={headerGroupKey}
                            id={headerGroupKey}
                            {...headerGroup.getHeaderGroupProps()}
                            className='ccd-table-header-with-settings__tr'
                        >
                            {headerGroup.headers
                                .filter((column) => column.isVisible)
                                .map((column) => {
                                    const desiredHeaderColumnProps =
                                        column.getHeaderProps(headerProps);
                                    // because it spoils elements visibility, i.e. popup
                                    delete desiredHeaderColumnProps.style.position;
                                    // spoils table header in Apollo
                                    delete desiredHeaderColumnProps.style.alignItems;

                                    return (
                                        <div key={column.id} {...desiredHeaderColumnProps}>
                                            <span className='ccd-table-header-ellipsis'>
                                                <span className='ccd-table-header-ellipsis-text'>
                                                    {column.render('Header')}
                                                </span>
                                                <div className='ccd-table-header-ellipsis-sorting-icon'>
                                                    {column.ccdSorted && (
                                                        <CcdSortingMenu
                                                            column={column}
                                                            isTableOffset
                                                            onSorted={onSortedMemo}
                                                            direction={addSortingMenu(
                                                                column,
                                                                headerGroup.headers
                                                            )}
                                                            initialSortDirection={
                                                                tableSettings?.initialSortSettings &&
                                                                column.id ===
                                                                    tableSettings
                                                                        .initialSortSettings
                                                                        .sortedColumnName
                                                                    ? tableSettings
                                                                          .initialSortSettings
                                                                          .sortDirection
                                                                    : ''
                                                            }
                                                        />
                                                    )}
                                                </div>
                                            </span>
                                            <Box
                                                {...column.getResizerProps()}
                                                className='ccd-table-column-resizer'
                                                id={'columnResizer' + column.Header}
                                                css={(props) => ({
                                                    borderRightColor: props.colors.table.border,
                                                })}
                                            />
                                        </div>
                                    );
                                })}
                        </div>
                    );
                })}
            </div>
        );
    }

    const getTableHeader = () =>
        tableSettings?.supportsDragNDrop === true ? (
            <CcdTableDragAndDropHeader
                currentColOrder={currentColOrder}
                headerGroups={headerGroups}
                headerProps={headerProps}
                onSortedMemo={onSortedMemo}
                addSortingMenu={addSortingMenu}
                visibleColumns={visibleColumns}
                setColumnOrder={setColumnOrder}
                initialSortSettings={tableSettings?.initialSortSettings}
                onOrderChange={onOrderChange}
            />
        ) : (
            standardTableHeader()
        );

    return (
        <Box
            className={cx('ccd-table-container', 'nonselectable', className)}
            css={({ colors }) => ({
                borderColor: colors.table.border,
                boxShadow: colors.boxShadow,
            })}
            tabIndex={0}
            onKeyDown={onKeyDown}
            ref={tableRef}
            style={style}
            {...getTableProps()}
        >
            {settingsMenu && (
                <Box
                    as={'span'}
                    className='ccd-table-settings-header'
                    css={(props) => ({
                        backgroundColor: colors.table.header.background,
                        ':hover': { backgroundColor: props.colors.tileHover },
                    })}
                >
                    {settingsMenu}
                </Box>
            )}
            <Box
                className='ccd-table-header'
                css={({ colors }) => ({
                    backgroundColor: colors.table.header.background,
                })}
            >
                {getTableHeader()}
            </Box>
            <div className='ccd-table-body' {...getTableBodyProps()}>
                {renderRows()}
                {pagination?.hasMore ? pagination?.component : null}
            </div>
        </Box>
    );
}

export default CcdTable;

CcdTable.propTypes = {
    columns: arrayOf(shape({ Header: string, accessor: string })),
    hiddenColumns: array,
    pagination: shape({}),
    onSelected: func,
    onDoubleClick: func,
    onOrderChange: func,
    onHover: func,
    tableSettings: shape({
        wrapText: bool,
        layout: string,
        supportsDragNDrop: bool,
        suppressOutsideClick: bool,
        initialSortSettings: shape({
            sortedColumnName: string,
            sortDirection: string,
        }),
        initialColumnOrder: oneOfType([string, array]),
    }),
    className: string,
    forcedSelection: shape({
        enabled: bool,
        elements: array,
        comparisonFunc: func,
    }),
    selectedItem: shape({}),
};

CcdTable.defaultProps = {
    columns: [],
    hiddenColumns: [],
};
