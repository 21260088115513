import { useContext } from 'react';

import { useXccCompareContext } from 'hooks/XccCompareContextProvider';
import { XccInfrastructureProviderContext } from 'providers/xccInfrastructure/XccInfrastructureProvider';

const XccGuidanceMessage = () => {
    const xccContext = useContext(XccInfrastructureProviderContext);
    const xccCompareContext = useXccCompareContext();

    return (
        <>
            {xccContext.processingGuidanceMessageVisible ||
            xccContext.processedGuidanceMessageVisible
                ? xccContext.xccGuidanceMassages
                : null}
            {xccCompareContext.processingGuidanceMessageVisible ||
            xccCompareContext.processedGuidanceMessageVisible
                ? xccCompareContext.xccGuidanceMassages
                : null}
        </>
    );
};
export default XccGuidanceMessage;
