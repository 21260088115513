import { CcdEmptyState } from 'components/_legacy/ccd-empty-state';
import Box from 'components/atoms/Box';
import ProgressSpinner from 'components/atoms/ProgressSpiner';
import { VerticalMenuViewType } from 'components/molecules/VerticalMenu/VerticalMenu';
import useCurrentFolderContext from 'components/templates/FileManagement/components/CurrentFolderContextProvider/useCurrentFolderContext';
import { CcdInputUpload } from 'components/templates/FileManagement/components/parts/FileUpload/DragNDropUpload';
import NoSearchResultsFile from 'images/Empty-Search-Results-State-Icon.svg?react';
import ErrorStateFile from 'images/Error-State-icon.svg?react';
import { LoadingStateLabel } from 'types/loadingStateLabel';

import { useCommandBarVisibilityContext } from '../../../hooks';
import FileEmptyState from './FileEmptyState/FileEmptyState';
import TableDisplay from './Table/TableDisplay';
import TileDisplay from './Tile/TileDisplay';

export default function FileDisplay() {
    const { isUploadFileCommandAccessible } = useCommandBarVisibilityContext();

    const { sortingHandler, data, filteredData, hasError, isLoading, settings } =
        useCurrentFolderContext();

    const renderTable = () => {
        return (
            <TableDisplay
                data={filteredData}
                sortingHandler={sortingHandler}
                initialSortSettings={{
                    sortedColumnName: settings.table?.sortColumn,
                    sortDirection: settings.table?.sortOrder,
                }}
            />
        );
    };
    const renderTileGrid = () => {
        return <TileDisplay data={filteredData} />;
    };
    const renderDisplay = () => {
        return settings.current === VerticalMenuViewType.TABLE ? renderTable() : renderTileGrid();
    };

    const renderErrorMessage = () => {
        return (
            <CcdEmptyState
                message='Failed to load files'
                subMessage='Try to refresh the page'
                icon={ErrorStateFile}
            />
        );
    };

    const renderNoFilesMessage = () => {
        if (!isUploadFileCommandAccessible) {
            return <FileEmptyState hasAccess={isUploadFileCommandAccessible} />;
        } else {
            return (
                <CcdInputUpload>
                    {(onInputUpload: any) => (
                        <FileEmptyState
                            onInputUpload={onInputUpload}
                            hasAccess={isUploadFileCommandAccessible}
                        />
                    )}
                </CcdInputUpload>
            );
        }
    };

    const renderEmptySearchResultMessage = () => {
        return (
            <CcdEmptyState
                message='No search results found. Try searching for something else...'
                icon={NoSearchResultsFile}
            />
        );
    };

    const errorOccurred = !data && hasError;
    const emptyResultReturned = data && !data.length && !hasError;
    const emptyFilteredResultReturned =
        filteredData && !filteredData.length && !emptyResultReturned;
    const nonEmptyResultReturned = data && filteredData?.length !== 0 && !hasError;

    return (
        <Box
            css={{
                height: '100%',
            }}
            id='mainTable'
        >
            {errorOccurred && renderErrorMessage()}
            {emptyResultReturned && renderNoFilesMessage()}
            {emptyFilteredResultReturned && renderEmptySearchResultMessage()}
            {nonEmptyResultReturned && renderDisplay()}
            {isLoading && !data && <ProgressSpinner label={LoadingStateLabel.FILES} />}
        </Box>
    );
}
