import React from 'react';
import { type Id as ToastIdType, toast } from 'react-toastify';

import CcdToastContent from './ccdToastContent';

export interface ToastCommonParams {
    toastId?: ToastIdType; // Allows to manually set toast id. Useful in snapshot testing. If not set - random toast id will be generated
    onClose?: <T = Record<string, unknown>>(props: T) => void; // Callback called when toast is closed
    autoCloseTime?: number; // default 5000 milliseconds). Time in milliseconds after which toast will automatically be closed.
    autoClose?: boolean; // If set to true toast will close automatically after a period of time configured in {@link autoCloseTime}
}

export interface ToastDisplayParams extends ToastCommonParams {
    title?: string; // Text which should be displayed as toast title
    messages?: Array<string>; // Array of texts which should be displayed as toast content. Each record represents single line of toast content.
    closeButton?: boolean; // If set to true close button will be displayed
}

export interface ToastEditParams extends ToastDisplayParams {
    newToastId: ToastIdType; // Allows to manually set toast id in case toast was previously closed. Useful in snapshot testing. If not set - random toast id will be generated
}

/**
 * Displays appropriately styled error popup (toast)
 */
function ccdErrorToast({
    title,
    messages,
    onClose,
    toastId,
    autoCloseTime,
    autoClose = true,
}: Omit<ToastDisplayParams, 'closeButton'>): ToastIdType {
    return toast.error(
        getContent('error', title, messages),
        getCommonOptions({
            autoClose,
            autoCloseTime,
            onClose,
            toastId,
        })
    );
}

/**
 * Displays appropriately styled info popup (toast)
 */
function ccdInfoToast({
    title,
    messages,
    onClose,
    toastId,
    autoCloseTime,
    autoClose = true,
}: Omit<ToastDisplayParams, 'closeButton'>): ToastIdType {
    return toast.info(
        getContent('info', title, messages),
        getCommonOptions({
            autoClose,
            autoCloseTime,
            onClose,
            toastId,
        })
    );
}

/**
 * Displays appropriately styled loading popup (toast).
 * This toast need to be used along with {@link ccdLoadingCompletedWithError} or {@link ccdLoadingCompletedWithSuccess}
 */
function ccdLoadingToast({
    title,
    messages,
    toastId,
    closeButton = false,
}: ToastDisplayParams): ToastIdType {
    return toast.loading(getContent('info', title, messages), {
        closeButton: closeButton,
        ...getCommonOptions({ toastId }),
    });
}

/**
 * Updates already existing popup (toast) to display appropriately styled error popup
 */
function ccdLoadingCompletedWithError(
    toastId: ToastIdType,
    { title, messages, autoClose = true, autoCloseTime, onClose, newToastId }: ToastEditParams
): ToastIdType | void {
    return toast.isActive(toastId)
        ? toast.update(toastId, {
              render: getContent('error', title, messages),
              type: toast.TYPE.ERROR,
              isLoading: false,
              closeButton: true,
              ...getCommonOptions({
                  autoClose,
                  autoCloseTime,
                  onClose,
              }),
          })
        : ccdErrorToast({
              title,
              messages,
              autoClose,
              autoCloseTime,
              onClose,
              toastId: newToastId,
          });
}

/**
 * Updates already existing popup (toast) to display apriopriately styled success popup
 */
function ccdLoadingCompletedWithSuccess(
    toastId: ToastIdType,
    { title, messages, autoClose = true, autoCloseTime, onClose, newToastId }: ToastEditParams
) {
    return toast.isActive(toastId)
        ? toast.update(toastId, {
              render: getContent('info', title, messages),
              type: toast.TYPE.INFO,
              isLoading: false,
              closeButton: true,
              ...getCommonOptions({
                  autoClose,
                  autoCloseTime,
                  onClose,
              }),
          })
        : ccdInfoToast({
              title,
              messages,
              autoClose,
              autoCloseTime,
              onClose,
              toastId: newToastId,
          });
}

/**
 * Closes toast or toasts
 */
function ccdCloseToast(toastId?: ToastIdType) {
    if (toastId !== undefined) {
        toast.dismiss(toastId);
    } else {
        toast.dismiss();
    }
}

const defaultCloseTime = 5000;

function getAutoCloseTime(autoCloseTime?: number) {
    return autoCloseTime || defaultCloseTime;
}

function getContent(type: string, title?: string, messages?: Array<string>) {
    return <CcdToastContent type={type} title={title} messages={messages} />;
}

function getCommonOptions({
    autoClose,
    autoCloseTime,
    onClose,
    toastId,
}: Partial<ToastCommonParams>) {
    return {
        autoClose: autoClose && getAutoCloseTime(autoCloseTime),
        onClose: onClose,
        toastId: toastId,
    };
}

function ccdWarningToast({
    title,
    messages,
    onClose,
    toastId,
    autoCloseTime,
    autoClose = true,
}: Omit<ToastDisplayParams, 'closeButton'>): ToastIdType {
    return toast.warn(
        getContent('warning', title, messages),
        getCommonOptions({
            autoClose,
            autoCloseTime,
            onClose,
            toastId,
        })
    );
}

export {
    ccdErrorToast,
    ccdInfoToast,
    ccdLoadingToast,
    ccdLoadingCompletedWithError,
    ccdLoadingCompletedWithSuccess,
    ccdCloseToast,
    ccdWarningToast,
};
