import { useQuery } from '@tanstack/react-query';
import { useFileManagementContext } from 'FileManagement/components/FileManagementContext';
import { CONTACT_SUPPORT_MESSAGE } from 'FileManagement/consts';
import { EE, OPEN_HTML_ARCHIVE_VIEWER } from 'FileManagement/eventEmitter';
import {
    useCommandBarVisibilityContext,
    useGlobalUiBlockersCounterContext,
} from 'FileManagement/hooks';
import { decodeFileId } from 'FileManagement/utils';
import React, { useEffect, useState } from 'react';

import { ccdErrorToast } from 'components/_legacy/ccd-toast';
import Box from 'components/atoms/Box';
import { useCurrentTopMargin } from 'hooks/useCurrentTopMargin';
import CmdShow16 from 'images/cmdShow16.svg?react';
import fetcher from 'providers/api/fetcher';

import { DesCollabWebpageViewer } from './DesCollabWebpageViewer';

export const errorDuringGettingAccId = {
    title: 'Cannot display HTML archive',
    messages: [
        'There was a problem opening HTML archive.',
        'Please refresh website.',
        CONTACT_SUPPORT_MESSAGE,
    ],
};
export const htmlViewerEvents = {
    close: 'des-collab-webpage-viewer.close',
};

export default function useWebpageViewer() {
    const {
        state: { selectedFiles },
    } = useFileManagementContext();
    const { isDisplayHTMLCommandAccessible } = useCommandBarVisibilityContext();
    const [accId, setAccId] = useState(undefined);
    const [isViewerVisible, setViewerVisible] = useState(false);

    const { topMargin } = useCurrentTopMargin();

    const { increaseBlockerCounter, decreaseBlockerCounter } = useGlobalUiBlockersCounterContext();

    const { refetch } = useQuery({
        queryKey: ['ACC'],
        queryFn: async () => {
            increaseBlockerCounter();
            const { data } = await fetcher.get('/frontend/acc/WebPageViewer');
            return data;
        },
        enabled: false,
        onError: () => {
            decreaseBlockerCounter();
            ccdErrorToast(errorDuringGettingAccId);
        },
        onSuccess: (response) => {
            setAccId(response.id);
            setViewerVisible(true);
        },
    });

    const onOpen = () => {
        if (isDisplayHTMLCommandAccessible) {
            refetch();
        }
    };

    useEffect(() => {
        const hideViewer = () => {
            setViewerVisible(false);
        };
        window.addEventListener(htmlViewerEvents.close, hideViewer);
        EE.addListener(OPEN_HTML_ARCHIVE_VIEWER, onOpen);
        return () => {
            window.removeEventListener(htmlViewerEvents.close, hideViewer);
            EE.removeListener(OPEN_HTML_ARCHIVE_VIEWER, onOpen);
        };
    }, [isDisplayHTMLCommandAccessible]);

    const htmlArchiveUrn = isDisplayHTMLCommandAccessible
        ? decodeFileId(selectedFiles[0].id).Urn
        : undefined;

    return {
        htmlViewer: isViewerVisible && (
            <Box
                key='displayHTMLViewer'
                css={{
                    backgroundColor: 'red',
                    borderRadius: '24px 24px 0 0',
                    position: 'fixed',
                    top: `calc(54px + ${topMargin})`,
                    left: 64,
                    right: 8,
                    height: `calc(100% - 53px - ${topMargin})`,
                    zIndex: 11,
                    background: 'white',
                    '@media (max-width: 460px)': {
                        left: 0,
                        right: 0,
                        top: 77,
                        height: 'calc(100% - 77px)',
                    },
                }}
            >
                <DesCollabWebpageViewer accId={accId} revisionUrn={htmlArchiveUrn} />
            </Box>
        ),
        displayHTML: {
            id: 'displayHTML',
            children: 'Open Archive',
            onClick: refetch,
            leftIcon: CmdShow16,
            variant: 'text',
            color: 'gray',
            visible: isDisplayHTMLCommandAccessible,
        },
    };
}
