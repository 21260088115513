import { useCallback, useState } from 'react';

import { ccdInfoToast } from 'components/_legacy/ccd-toast';
import { useCcdFetch } from 'components/_legacy/hooks/useCcdFetch';
import { useFileManagementContext } from 'components/templates/FileManagement/components/FileManagementContext';

import { unexpectedError } from '../../../consts';
import { EE, FOLDER_CREATED } from '../../../eventEmitter';
import { useGlobalUiBlockersCounterContext, useTableRefresh } from '../../../hooks';
import { useWaitForFolderInTheLoop } from '../../../services/useWaitForFolderInTheLoop';
import { nullIfEmpty } from '../../../utils';
import { NAME_INPUT_ID, useGetFolderInputs } from '../../parts/FileForm';
import { successfulNotification } from './consts';

export default function useAddFolder(formHandlers, onClose) {
    const [isLoading, setLoading] = useState(false);
    const { increaseBlockerCounter, decreaseBlockerCounter } = useGlobalUiBlockersCounterContext();

    const {
        state: { backendUrl, collaborationSpaceId, projectId, currentFolder },
    } = useFileManagementContext();

    const { dispatchDataLoaded } = useTableRefresh();
    const { waitForFolderInTheLoop } = useWaitForFolderInTheLoop();
    const { ccdFetch } = useCcdFetch();

    const { getFolderInputs } = useGetFolderInputs();

    const onSubmit = useCallback(
        async (params, event) => {
            const inputValue = params[NAME_INPUT_ID].trim();
            event.preventDefault();
            formHandlers.preSubmitCleanup();
            increaseBlockerCounter();
            const url = new URL(
                `/frontend/file-management/${collaborationSpaceId}/${projectId}/folder`,
                backendUrl
            );

            setLoading(true);

            try {
                const response = await ccdFetch(url.toString(), {
                    method: 'POST',
                    body: JSON.stringify({
                        parentFolderId: nullIfEmpty(currentFolder.id),
                        folderName: inputValue,
                    }),
                    cache: 'no-cache',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                if (response.ok) {
                    await waitForFolder(response);
                    await dispatchDataLoaded();
                    ccdInfoToast(successfulNotification(inputValue));
                    onClose();
                    EE.emit(FOLDER_CREATED, {
                        folderLevel: currentFolder.folderLevel,
                    });
                } else {
                    handleServerError(response);
                }
            } catch (err) {
                console.error(err);
                formHandlers.setError(unexpectedError);
            }
            setLoading(false);
            decreaseBlockerCounter();
        },
        [formHandlers, currentFolder]
    );

    async function waitForFolder(response) {
        const folderCreationResult = await response.json();
        const folderCheckingResponse = await waitForFolderInTheLoop({
            collaborationSpaceId,
            projectId,
            backendUrl,
            folderId: folderCreationResult.id,
        });

        if (!folderCheckingResponse.ok) {
            handleServerError(folderCheckingResponse);
        }
    }

    function handleServerError(response) {
        if (response.status === 409) {
            // status returned if file or folder name already exists
            return;
        }
        console.error(response);
        formHandlers.setError(unexpectedError);
    }

    return {
        inputs: getFolderInputs(),
        onSubmit,
        isLoading,
    };
}
