const singleFileDownloadStrategy = {
    fileNotFound: {
        title: 'Cannot download file',
        messages: ['File does not exists. It was probably deleted.', 'Please refresh website.'],
    },
    genericError: {
        title: 'Cannot download file',
        messages: [
            'Oops! Something went wrong during file download. Please try again.',
            'If you have seen this message multiple times, please contact our support.',
        ],
        autoCloseTime: 10000,
    },
};

const multipleFilesDownloadStrategy = {
    fileNotFound: {
        title: 'Cannot download files',
        messages: ['Files do not exist. They were probably deleted.', 'Please refresh website.'],
    },
    genericError: {
        title: 'Cannot download files',
        messages: [
            'Oops! Something went wrong during files download. Please try again.',
            'If you have seen this message multiple times, please contact our support.',
        ],
        autoCloseTime: 10000,
    },
};

export default function getMessageStrategy(selectedFiles) {
    return selectedFiles.length === 1 ? singleFileDownloadStrategy : multipleFilesDownloadStrategy;
}
