import { bool, func } from 'prop-types';
import { useEffect } from 'react';

import { useForm } from '../../../UI';
import { FileFormComponent, NAME_INPUT_ID } from '../../../parts/FileForm';
import EmptyFileModal from './EmptyFileModal';
import useRenameFileForm from './useRenameFileForm';

export default function RenameFileForm({ isVisible, onClose }) {
    const {
        error,
        buttonDisabled,
        setError,
        handleCloseError,
        preSubmitCleanup,
        activateForm,
        focus,
    } = useForm(NAME_INPUT_ID);

    const {
        onSubmit,
        inputs,
        isModalVisible,
        onModalClose,
        onRenameConfirmed,
        isLoading,
        formRef,
    } = useRenameFileForm(
        {
            preSubmitCleanup,
            setError,
            activateForm,
        },
        onClose
    );

    useEffect(() => {
        isVisible && activateForm();
    }, [isVisible]);

    return (
        <>
            <FileFormComponent
                onSubmit={onSubmit}
                onClose={onClose}
                headerTitle='Rename file'
                isVisible={isVisible}
                error={error}
                buttonDisabled={buttonDisabled}
                handleCloseError={handleCloseError}
                focus={focus}
                inputs={inputs}
                isLoading={isLoading}
                formRef={formRef}
            />
            {isModalVisible && (
                <EmptyFileModal onRenameConfirmed={onRenameConfirmed} onCancel={onModalClose} />
            )}
        </>
    );
}

RenameFileForm.propTypes = {
    isVisible: bool,
    onClose: func,
};
