import { Header, Paragraph } from 'components/organisms/DataPrivacy/DataPrivacy.styles';
import appConfig from 'constants/appConfig';

export interface Props {
    firstTimeDisplay: boolean;
}

export const ProductExcellenceProgram = ({ firstTimeDisplay }: Props) => {
    return (
        <>
            <Header>Product Excellence Program</Header>
            <Paragraph>
                Siemens Digital Industries Software collects information about the use of our
                products by its users. This information helps us to improve our product features and
                functionality to better meet our customers' needs.
            </Paragraph>
            <Paragraph>
                All data collected under the Product Excellence Program will be aggregated and
                pseudonymized. No intellectual property information is collected or sent. Product
                performance is not affected.
            </Paragraph>
            <Paragraph>
                The details regarding the types of data and the third parties engaged are described
                in the{' '}
                <a href={appConfig.PEP_TRUST_CENTER} target={'_blank'}>
                    Siemens Trust Center
                </a>
                .
            </Paragraph>
            {firstTimeDisplay ? (
                <>
                    <Paragraph>
                        Please click on "Agree" to consent to Siemens Industry Software data
                        collection under the Product Excellence Program in order to support us to
                        improve our offerings.
                    </Paragraph>
                    <Paragraph>
                        You can also stop any data processing under the Product Excellence Program
                        by withdrawing your consent in the Data Privacy menu which can be found in
                        the software settings.
                    </Paragraph>
                </>
            ) : (
                <Paragraph>
                    If you wish to control the participation (or withdraw any given consent) with
                    regard to the data collection under the Product Excellence Program, you can do
                    so with this option.
                </Paragraph>
            )}
        </>
    );
};
