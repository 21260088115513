import { CONTACT_SUPPORT_MESSAGE } from '../../../consts';

const somethingWentWrong = 'Oops! Something went wrong during file move. Please try again.';

const singleFileMoveStrategy = {
    loadingMessage: () => {
        return ['Moving file. Please wait...'];
    },
    successMessage: (selectedFiles) => {
        return [`${selectedFiles[0].name} successfully moved.`];
    },
    moveFailedMessage: () => {
        return [somethingWentWrong, CONTACT_SUPPORT_MESSAGE];
    },
};

const multipleFilesMoveStrategy = {
    loadingMessage: () => {
        return ['Moving files. Please wait...'];
    },
    successMessage: (selectedFiles) => {
        return [`${selectedFiles.length} files successfully moved.`];
    },
    moveFailedMessage: () => {
        return [somethingWentWrong, CONTACT_SUPPORT_MESSAGE];
    },
    movePartiallyFailedMessage: () => {
        return ["Couldn't move all the files. Please try again.", CONTACT_SUPPORT_MESSAGE];
    },
};

export default function getMessageStrategy(selectedFiles) {
    return selectedFiles.length === 1 ? singleFileMoveStrategy : multipleFilesMoveStrategy;
}
