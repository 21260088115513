import styled from '@emotion/styled';

import Box from 'components/atoms/Box';

export const FileName = styled(Box)<{ isOpenable: boolean }>(({ isOpenable, theme }) => ({
    fontSize: 12,
    fontWeight: 600,
    width: 'calc(100% - 8px)',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    ...(isOpenable && {
        cursor: 'pointer',
        '&:hover': {
            color: theme.colors.actionTextHover,
        },
    }),
}));

export const FileSpan = styled('span')(() => ({
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: 'flex',
    height: '20px',
}));

export const FooterInfectedArea = styled(Box)(({ theme }) => ({
    margin: theme.spacing(0, 0, 0, 0),
    display: 'flex',
    justifyContent: 'space-between',
    height: '13px',
}));

export const InfectedText = styled('p')(({ theme }) => ({
    margin: theme.spacing(0, 0, 0, 0),
    fontWeight: 600,
    color: theme.colors.error.text,
    fontSize: '10px',
}));
