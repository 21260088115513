import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

import { ccdErrorToast, ccdInfoToast } from 'components/_legacy/ccd-toast';
import useHttp from 'components/_legacy/hooks/useHttp';
import { useProgressBar } from 'components/atoms/ProgressBar';
import appConfig from 'constants/appConfig';
import fetcher from 'providers/api/fetcher';
import { useBomSettingsQuery } from 'services/queries/bom.query';

import { BOMTABLE_ENDPOINT } from './consts';

export default function useBomSettings(collaborationSpaceId, projectId) {
    const [textWrap, setTextWrap] = useState(false);
    const [hiddenColumns, setHiddenColumns] = useState([]);
    const [bindingColumn, setBindingColumn] = useState('');
    const [bindingEnabled, setBindingEnabled] = useState(false);
    const [initialSortSettings, setInitialSortSettings] = useState();
    const [initialColumnOrder, setInitialColumnOrder] = useState([]);
    const [initialColumnsWidth, setInitialColumnsWidth] = useState([]);
    const [findchipsDisplayConfiguration, setFindchipsDisplayConfiguration] = useState(null);
    const [isReady, setIsReady] = useState(false);
    const [isFindchipsSet, setIsFindchipsSet] = useState(false);
    const [isProjectEmpty, setIsProjectEmpty] = useState(false);
    const { setProgressBar } = useProgressBar();
    const { sendRequest } = useHttp();
    const queryClient = useQueryClient();

    const readSortSettings = (sortConfiguration) =>
        sortConfiguration?.enabled &&
        setInitialSortSettings({
            sortedColumnName: sortConfiguration.columnName,
            sortDirection: sortConfiguration.direction.toLowerCase(),
        });

    const readOrderSettings = (orderConfiguration) =>
        orderConfiguration && setInitialColumnOrder(orderConfiguration.columnOrder);

    const readDisplaySettings = (displayConfiguration) => {
        if (!displayConfiguration) {
            return;
        }
        if (displayConfiguration.wrapText) {
            setTextWrap(displayConfiguration.wrapText);
        }
        if (displayConfiguration.hiddenColumns) {
            setHiddenColumns(displayConfiguration.hiddenColumns);
        }
        if (displayConfiguration.findchips) {
            setFindchipsDisplayConfiguration(displayConfiguration.findchips);
        }
    };

    const setFindchips = (findchips) => {
        setBindingEnabled(findchips.enabled);
        setBindingColumn(findchips.columnName);
        setIsFindchipsSet(true);
    };

    const setDefaultFindchips = (defaultFindChipsColumn) => {
        if (defaultFindChipsColumn) {
            setBindingEnabled(true);
            setBindingColumn(defaultFindChipsColumn);
        } else {
            setBindingEnabled(false);
            setBindingColumn('');
        }
        setIsFindchipsSet(true);
    };

    const readWidthSettings = (widthConfiguration) => {
        setInitialColumnsWidth(widthConfiguration.columnWidths);
    };

    const onWrapText = () => {
        setTextWrap(!textWrap);
    };

    const onSortColumnSettingsChange = (sortSettings) => {
        const isSortDifferentThanDefault = sortSettings.direction !== 'no-sort';
        const body = {
            enabled: isSortDifferentThanDefault,
            direction: isSortDifferentThanDefault ? sortSettings.direction : null,
            columnName: isSortDifferentThanDefault ? sortSettings.column.Header : null,
        };

        const url = new URL(
            `${BOMTABLE_ENDPOINT}${collaborationSpaceId}/${projectId}/sort`,
            appConfig.baseURL
        );

        const requestConfig = { url: url, method: 'PUT', body: body };
        sendRequest({ requestConfig });
        queryClient.invalidateQueries(['getBomSettings']);
    };

    const { mutate: mutateColumnsOrder } = useMutation({
        mutationFn: async (orderSettings) => {
            return fetcher.put(`${BOMTABLE_ENDPOINT}${collaborationSpaceId}/${projectId}/order`, {
                columnOrder: orderSettings,
            });
        },
        onSettled: () => {
            queryClient.invalidateQueries(['getBomSettings']);
        },
    });

    const onOrderColumnSettingsChange = async (orderSettings) => mutateColumnsOrder(orderSettings);
    const onColumnsWidthSettingsChange = (widths) => {
        const body = {
            columnWidths: widths,
        };
        setInitialColumnsWidth(widths);

        const url = new URL(
            `${BOMTABLE_ENDPOINT}${collaborationSpaceId}/${projectId}/column-widths`,
            appConfig.baseURL
        );

        const onError = () => {
            console.error('Unable to store column widths');
        };

        const requestConfig = { url: url, method: 'PUT', body: body };
        sendRequest({ requestConfig, onError });
        queryClient.invalidateQueries(['getBomSettings']);
    };

    const onSettingsChange = (settings) => {
        const body = {
            wrapText: textWrap,
            hiddenColumns: settings.hiddenColumns,
            findchips: {
                enabled: settings.bindingEnabled,
                columnName: settings.bindingColumn,
            },
        };

        const url = new URL(
            `${BOMTABLE_ENDPOINT}${collaborationSpaceId}/${projectId}/display`,
            appConfig.baseURL
        );

        const onSuccess = () => {
            setHiddenColumns(settings.hiddenColumns);
            setBindingEnabled(settings.bindingEnabled);
            setBindingColumn(settings.bindingColumn);
            setFindchipsDisplayConfiguration({
                enabled: settings.bindingEnabled,
                columnName: settings.bindingColumn,
            });

            const successNotification = {
                title: 'Success!',
                messages: ['BOM configuration saved successfully!'],
            };
            queryClient.invalidateQueries(['getBomSettings']);
            ccdInfoToast(successNotification);
            setProgressBar(false);
        };

        const onError = () => {
            const errorNotification = {
                title: 'BOM Configuration Failed',
                messages: ['BOM configuration failed. Try again.'],
            };

            ccdErrorToast(errorNotification);
            setProgressBar(false);
        };
        const requestConfig = { url: url, method: 'PUT', body: body };
        sendRequest({ requestConfig, onSuccess, onError });
    };

    const {
        data: bomSettingsData,
        error: bomSettingsDataError,
        isLoading,
    } = useBomSettingsQuery({
        collaborationSpaceId,
        projectId,
    });

    const isBomSettingsPresent = (bomSettingsData) =>
        Object.keys(bomSettingsData).reduce((acc, val) => {
            return acc || !!bomSettingsData[val];
        }, false);

    useEffect(() => {
        if (isLoading === false) {
            if (isBomSettingsPresent(bomSettingsData)) {
                if (bomSettingsData.orderConfiguration) {
                    readOrderSettings(bomSettingsData.orderConfiguration);
                }
                if (bomSettingsData.displayConfiguration) {
                    readDisplaySettings(bomSettingsData.displayConfiguration);
                }
                if (bomSettingsData?.sortConfiguration) {
                    readSortSettings(bomSettingsData.sortConfiguration);
                }
                if (bomSettingsData.columnWidthsConfiguration) {
                    readWidthSettings(bomSettingsData.columnWidthsConfiguration);
                }
            } else {
                setIsProjectEmpty(true);
            }
            setIsReady(true);
        }
    }, [bomSettingsData, isLoading]);

    useEffect(() => {
        if (bomSettingsDataError === true) {
            const errorNotification = {
                title: 'BOM Configuration Failed',
                messages: [
                    `Your BOM configuration couldn't be read. Default configuration loaded.`,
                    'Try again later and refresh the page',
                ],
            };
            ccdErrorToast(errorNotification);
            setIsReady(false);
        }
    }, [bomSettingsDataError]);

    return {
        onWrapText,
        textWrap,
        hiddenColumns,
        bindingColumn,
        bindingEnabled,
        onSettingsChange,
        onSortColumnSettingsChange,
        onOrderColumnSettingsChange,
        onColumnsWidthSettingsChange,
        initialSortSettings,
        initialColumnOrder,
        setInitialColumnOrder,
        initialColumnsWidth,
        setDefaultFindchips,
        setFindchips,
        findchipsDisplayConfiguration,
        isReady,
        isFindchipsSet,
        isProjectEmpty,
        displayConfiguration: bomSettingsData?.displayConfiguration,
        columnsModified: bomSettingsData?.displayConfiguration?.columnsModified,
    };
}
