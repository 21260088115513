const reservedNamesRegex = /^(?!(CON|COM[1-9]|PRN|AUX|NUL|LPT[1-9])(\..*)?$)/gi;
const findAllSpecialCharInstancesRegex = /([<>:/\\|?*"])/g;
const trailingDotRegex = /\.$/;

function reservedNamesValidator(value: string) {
    const trimmedValue = value.trim();

    return (
        reservedNamesRegex.test(trimmedValue) ||
        'The following name is not allowed: ' + trimmedValue
    );
}

function specialCharactersValidator(value: string) {
    const singleCharMessage = 'The following character is not allowed: ';
    const manyCharMessage = 'The following characters are not allowed: ';
    const distinctInvalidCharacters = [...new Set(value.match(findAllSpecialCharInstancesRegex))];
    const message = (distinctedCharacters: string[]) => {
        const messagePrefix =
            distinctedCharacters.length === 1 ? singleCharMessage : manyCharMessage;
        return messagePrefix + distinctedCharacters.join(' ');
    };

    return distinctInvalidCharacters.length === 0 || message(distinctInvalidCharacters);
}

function trailingDot(value: string) {
    const trimmedValue = value.trim();
    const message = 'Trailing period is not allowed';

    const tested = trailingDotRegex.test(trimmedValue);
    return tested ? message : !tested;
}

function whitespacesValidator(value: string, customValidationMessage?: string) {
    const message = customValidationMessage ?? 'Whitespace-only text is not allowed';

    const trimmedValue = value.trim();
    return !trimmedValue && value ? message : true;
}

export {
    reservedNamesValidator,
    specialCharactersValidator,
    trailingDot,
    whitespacesValidator,
    reservedNamesRegex,
};
