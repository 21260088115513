import { useCallback, useState } from 'react';

export default function useForm(id) {
    const [error, setError] = useState();
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [focus, setFocus] = useState();

    const activateForm = useCallback(() => {
        setFocus(id);
        setButtonDisabled(false);
    }, [id]);

    const handleCloseError = useCallback(() => {
        setError(null);
        activateForm();
    }, [activateForm]);

    const preSubmitCleanup = useCallback(() => {
        setButtonDisabled(true);
        setFocus(undefined);
    }, []);

    const afterSubmit = useCallback(() => {
        setButtonDisabled(false);
    }, []);

    return {
        error,
        setError,
        buttonDisabled,
        focus,
        handleCloseError,
        preSubmitCleanup,
        afterSubmit,
        activateForm,
    };
}
