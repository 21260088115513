import styled from '@emotion/styled';

export const AlertsArea = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    padding: theme.spacing(0, 0, 0, 2),
}));

export const AlertsLabel = styled('div')({
    fontSize: 14,
    fontWeight: '400',
});

export const OptionWrapper = styled('div')(({ theme }) => ({
    width: '100%',
    display: 'flex',
    padding: theme.spacing(4, 0, 0, 0),
}));

export const OptionLabel = styled('div')({
    fontSize: 12,
    fontWeight: '400',
    display: 'flex',
});

export const SwitchDiv = styled('div')({
    flex: '0 0 15%',
    display: 'flex',
    justifyContent: 'flex-start',
});
