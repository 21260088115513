import fetcher from 'providers/api/fetcher';
import { IplRequestParams, IplResponseBody, ResetIplResponse } from 'types/ipl';

export const getIplTableWithSession = async ({
    collaborationSpaceId,
    projectId,
    revisionId,
    variant,
}: IplRequestParams): Promise<IplResponseBody> => {
    const { status, data } = await fetcher.post(
        `/api/ipl/${collaborationSpaceId}/${projectId}/${revisionId}`,
        { variant }
    );
    return { status, data };
};

export const resetIpl = async ({
    collaborationSpaceId,
    projectId,
    revisionId,
    variant,
}: IplRequestParams): Promise<ResetIplResponse> => {
    const { status } = await fetcher.post(
        `/api/ipl/${collaborationSpaceId}/${projectId}/${revisionId}/reset`,
        { variant }
    );
    return { status };
};
