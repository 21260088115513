import { func } from 'prop-types';

import ConfirmationModal from 'components/organisms/ProjectVersion/components/ConfirmationModal';

interface IEmptyFileModal {
    onRenameConfirmed: () => void;
    onCancel: () => void;
}

export default function EmptyFileModal({ onRenameConfirmed, onCancel }: IEmptyFileModal) {
    return (
        <ConfirmationModal
            title={'Name missing'}
            onConfirm={onRenameConfirmed}
            confirmLabel={'Rename'}
            onClose={onCancel}
        >
            Are you sure you want no file name?
        </ConfirmationModal>
    );
}

EmptyFileModal.propTypes = {
    onRenameConfirmed: func,
    onCancel: func,
};
