export function successfulNotification(name) {
    return {
        title: 'Rename successful!',
        messages: [`${name} successfully renamed.`],
    };
}

export function alreadyExistsError(name) {
    return {
        title: 'Rename unsuccessful!',
        messages: [`${name} already exists.`, 'Please try different name.'],
    };
}
