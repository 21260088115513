import Box from 'components/atoms/Box';
import ComboBox, { ISelectList } from 'components/atoms/ComboBox';

export type Variants = { label: string; name?: string; value: string };
interface Props {
    variants: ISelectList[];
    onChange: (value: unknown) => void;
}

export const VariantComboBox = ({ variants, onChange }: Props) => {
    return (
        <Box
            css={{
                marginLeft: '12px',
                width: '200px',
                fontSize: '12px',
            }}
        >
            <Box css={{ fontWeight: '600' }}>Project Variant</Box>
            <ComboBox
                selectList={variants}
                onChange={onChange}
                placeholder={'Select variant'}
                defaultValue={variants[0]}
                id={'Variants'}
                css={({ colors }) => ({
                    backgroundColor: colors.background,
                })}
                overrideStyles={{
                    control: {
                        padding: '6px 8px',
                        ':focus-within': {
                            padding: '6px 8px',
                        },
                    },
                }}
            />
        </Box>
    );
};

export default VariantComboBox;
