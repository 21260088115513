import CmdEdit16 from 'images/cmdEdit16.svg?react';

import { useCommandBarVisibilityContext } from '../../../../hooks';
import { useSideNavVisibility } from '../../../parts/FileForm';
import { RenameFileForm } from '../../../parts/Rename';

import '../../../FileManagement.scss';

export default function useRenameFolder() {
    const { visibility, closeSideNav, switchSideNavVisibility } = useSideNavVisibility();
    const { isRenameFileCommandAccessible } = useCommandBarVisibilityContext();

    return {
        renameFileForm: (
            <RenameFileForm key='rename-file-form' isVisible={visibility} onClose={closeSideNav} />
        ),
        renameFile: {
            id: 'rename-file',
            children: 'Rename',
            onClick: switchSideNavVisibility,
            leftIcon: CmdEdit16,
            variant: 'text',
            color: 'gray',
            visible: isRenameFileCommandAccessible,
        },
    };
}
