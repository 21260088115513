import React, { FunctionComponent, ReactNode, SVGProps } from 'react';

import Box from 'components/atoms/Box';

import './ccdEmptyState.scss';

interface IProps {
    message: string;
    icon: FunctionComponent<SVGProps<SVGSVGElement>>;
    subMessage?: string;
    subMessageRenderer?: () => ReactNode;
    id?: string;
}

export const CcdEmptyState = ({
    message,
    icon: Icon,
    subMessage,
    subMessageRenderer,
    id,
}: IProps) => {
    return (
        <Box
            css={({ colors }) => ({ color: colors.tile.textLight })}
            className='ccd-empty-state ccd-empty-state-content'
            id={id}
        >
            <Icon
                css={({ colors }) => ({ fill: colors.tile.textLight })}
                className='ccd-empty-state-icon'
            />
            <div className='ccd-empty-state__message'>{message}</div>
            <div className={'ccd-empty-state__sub-message'}>
                {subMessageRenderer?.() ?? subMessage}
            </div>
        </Box>
    );
};

export default CcdEmptyState;
