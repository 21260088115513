import { useState } from 'react';

import useRescaleImage from './useRescaleImage';

function useShowFile() {
    const [image, setImage] = useState(null);
    const { rescaleImage } = useRescaleImage();
    const [loadingError, setLoadingError] = useState(false);
    function getImage(file) {
        let fileToLoad;

        const reader = new FileReader();
        reader.onload = async (e) => {
            if (e.target.result.includes('data:image')) {
                try {
                    const converted = await rescaleImage(e.target.result);
                    setImage(converted);
                    setLoadingError(false);
                } catch {
                    setImage('');
                    setLoadingError(true);
                }
            } else {
                setImage('');
                setLoadingError(true);
            }
        };

        setLoadingError(false);
        if (!file) {
            setImage('');
        } else if (file instanceof File) {
            fileToLoad = file;
            reader.readAsDataURL(fileToLoad);
        } else if (typeof file === 'string') {
            setImage(file);
        } else {
            fileToLoad = file[0].getAsFile();
            reader.readAsDataURL(fileToLoad);
        }
    }

    return {
        getImage,
        image,
        loadingError,
    };
}

export default useShowFile;
