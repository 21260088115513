import { useCallback, useState } from 'react';

export function useFilesAlreadyExistsModal() {
    const [isModalVisible, setModalVisibility] = useState(false);
    const [namesConflicts, setNamesConflicts] = useState();

    const hideModal = useCallback(() => {
        setModalVisibility(false);
    }, []);

    const showModal = useCallback((message) => {
        setNamesConflicts(message);
        setModalVisibility(true);
    }, []);

    return { isModalVisible, namesConflicts, hideModal, showModal };
}
