import { Theme } from '@emotion/react';
import styled from '@emotion/styled';

export const CommonStyles = (theme: Theme) => ({
    background: `${theme.colors.textArea.background} 0% 0% no-repeat padding-box`,
    border: `1px solid ${theme.colors.textArea.border}`,
    borderRadius: '2px',
    opacity: '1',
    color: theme.colors.text,

    ':focus-within': {
        border: `1px solid ${theme.colors.textArea.focus.border}`,
        transition: `${theme.colors.textArea.focus.transition} 0.3s ease-in-out`,
        outline: `1px solid ${theme.colors.textArea.focus.outline}`,
    },

    '&:hover:not(:focus-within) ': {
        border: `1px solid ${theme.colors.textArea.hover.border}`,
        outline: `1px solid ${theme.colors.textArea.hover.outline}`,
    },
});

export const TextArea = styled('textarea')(({ theme }) => ({
    width: 300,
    height: 52,
    overflow: 'hidden',
    ...CommonStyles(theme),
}));

export const ProjectTextBoxWidth = '600px';

export default TextArea;
