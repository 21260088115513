import trim from 'lodash/trim';

import useHttp from 'components/_legacy/hooks/useHttp';
import { useFileManagementContext } from 'components/templates/FileManagement/components/FileManagementContext';

export default function useFileFromDestinationFolder() {
    const {
        state: { collaborationSpaceId, projectId, backendUrl },
    } = useFileManagementContext();
    const { sendRequest } = useHttp();

    async function getFilesFromDestinationDirectory(folderId) {
        let fileList = [];
        const url = new URL(
            `/frontend/file-management/${collaborationSpaceId}/${projectId}/files`,
            backendUrl
        );
        const urlSearchParams = new URLSearchParams();
        folderId && urlSearchParams.set('folderId', folderId.toString());
        url.search = urlSearchParams.toString();
        const requestConfig = {
            url: url,
            method: 'GET',
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
        };

        const onSuccess = (response) => {
            fileList = response.files;
        };

        await sendRequest({
            requestConfig,
            onSuccess,
        });

        return fileList;
    }

    async function getInvalidFiles(files, destinationFolderId) {
        const alreadyExistingFileCount = [];
        const fileList = await getFilesFromDestinationDirectory(destinationFolderId);

        // check names
        fileList.forEach((file) => {
            files.forEach((selected) => {
                if (trim(selected.name) === file.name) {
                    alreadyExistingFileCount.push({
                        ...file,
                        originalFile:
                            // determine if file starts with white space
                            // and if it starts, trim it.
                            selected.name.at(0) === ' '
                                ? new File([selected], trim(selected.name), {
                                      type: selected.type,
                                  })
                                : selected,
                    });
                }
            });
        });

        return alreadyExistingFileCount;
    }

    return { getInvalidFiles };
}
