export const restoreRevisionSuccess = {
    title: 'Version restored',
    messages: ['Version successfully restored!'],
};

export const restoreRevisionFailed = (error) => {
    const messageDescription =
        error === 409 ? 'Project is locked. Cannot restore version.' : 'Restoring version failed.';
    return {
        title: 'Restoring failed',
        messages: [messageDescription],
    };
};

export const deleteRevisionSuccess = {
    title: 'Version deleted',
    messages: ['Version successfully deleted!'],
};

export const deleteRevisionFailed = (errorCode) => {
    if (errorCode === 409) {
        return {
            title: 'Delete failed',
            messages: ['Version is locked by another user.'],
        };
    }
    return {
        title: 'Delete failed',
        messages: ['Version was not deleted.'],
    };
};
