export default function useProjectLineHistoryDrawStrategyHook(
    firstElement,
    lastElement,
    regularElement,
    baseItemElement
) {
    const draw = (isFirst, isLast) => {
        const items = [];

        if (!(isFirst || isLast)) {
            items.push(regularElement);
        } else if (isFirst && !isLast) {
            items.push(firstElement);
        } else if (!isFirst && isLast) {
            items.push(lastElement);
        }
        items.push(baseItemElement);

        return items;
    };

    return {
        draw,
    };
}
