export enum Path {
    HOME = '/',
    LOGIN = '/login',
    LOGOUT = '/logout',
    LOGGED_OUT = '/logged-out',
    LOGGED_OUT_HTML = '/logged-out.html',
    THIRD_PARTY = '/terms-of-use',
    LOGIN_ERROR_HTML = '/error/login-error.html',
    PROVISIONING = '/provisioning-in-progress',
    PROVISIONING_HTML = '/provisioning-in-progress.html',
    ERROR = '/error',
    ERROR_HTML = '/error/error.html',
    ERROR_403 = '/error_403',
    ERROR_404 = '/error_404',
    ERROR_SUB = '/error_sub',
    ERROR_UNSUBSCRIBED_USER = '/error/unsubscribed-user',
    ERROR_UNSUBSCRIBED_USER_HTML = '/error/unsubscribed-user.html',
    RECENT = '/recent',
    PROJECTS = '/projects',
    PARAMS_PROJECT_ID = ':projectId',
    PARAMS_PROJECT_ID_REVISION_ID = ':projectId/:revisionId',
    PARAMS_PROJECT_ID_REVISION_ID_MISSING_ASSET = ':projectId/:revisionId/missing_asset',
    PARAMS_PROJECT_ID_REVISION_ID_TAB = ':projectId/:revisionId/:tab',
    PARAMS_XCC_PROJECT_ID_REV_ID_DESIGN_TYPE_MODE = '/xcc/:projectId/:revisionId/:designType/:mode',
    PARAMS_XCC_COMPARE_PROJECT_ID_REV_ID_DESIGN_TYPE_MODE = '/xcc/compare/:projectId/:firstRevisionId/:secondRevisionId/:designType',
    PARAMS_ACC_PROJECT_ID_REV_ID_REV_URM = '/acc/:projectId/:revisionId/:revisionUrn',
    PARAMS_PDF_PROJECT_ID_REV_ID_REV_URM = '/pdf/:projectId/:revisionId/:revisionUrn/:pdfType',
    SM = '/sm',
    XCC = '/xcc',
    XCC_COMPARE = '/xcc/compare',
    ACC = '/acc',
    EMPTY_ROUTE = '',
    ANY_ROUTE = '/*',
    MISSING_ASSET = 'missing_asset',
}
