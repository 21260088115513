import { isEmpty } from 'lodash';

import useCurrentFolderContext from 'components/templates/FileManagement/components/CurrentFolderContextProvider/useCurrentFolderContext';
import CmdFolderDownload16 from 'images/cmdFolderDownload16.svg?react';

import { useCommandBarVisibilityContext, useDownload } from '../../../../hooks';

export default function useDownloadCurrentFolder() {
    const { isDownloadCurrentFolderCommandAccessible } = useCommandBarVisibilityContext();
    const { anchorRef, downloadCurrentFolder } = useDownload();

    const { data } = useCurrentFolderContext();

    return {
        downloadCurrentFolderAnchor: (
            <a
                hidden
                key='downloadCurrentFolder-input'
                id='downloadCurrentFolder-input'
                ref={anchorRef}
                download
            />
        ),
        downloadCurrentFolder: {
            id: 'downloadCurrentFolder',
            children: isEmpty(data) ? 'No Files to Download' : 'Download Current Folder',
            onClick: downloadCurrentFolder,
            leftIcon: CmdFolderDownload16,
            disabled: isEmpty(data),
            variant: 'text',
            color: 'gray',
            visible: isDownloadCurrentFolderCommandAccessible,
        },
    };
}
