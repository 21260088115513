import Cookies from 'js-cookie';

export const baseURL =
    Cookies.get('REGIONAL_BACKEND_URL') || (import.meta.env.VITE_BACKEND_URL as string);
export const env = import.meta.env.VITE_NODE_ENV || 'dev';
export const PRIVACY_POLICY = import.meta.env.VITE_CSP_SIEMENS_PRIVACY_POLICY;
export const SECURITY = import.meta.env.VITE_CSP_SIEMENS_SECURITY;
export const TRUST_CENTER = import.meta.env.VITE_CSP_SIEMENS_TRUST_CENTER;
export const PEP_TRUST_CENTER = import.meta.env.VITE_PEP_SIEMENS_TRUST_CENTER;
export const SIEMENS_ANALYTICS_USE_PRODUCTION = import.meta.env
    .VITE_SIEMENS_ANALYTICS_USE_PRODUCTION;
export const WALK_ME_ENV_TYPE = import.meta.env.VITE_WALK_ME_ENV_TYPE;
export const INTERCOM_APP_ID = import.meta.env.VITE_INTERCOM_APP_ID as string;
export const SUBSCRIPTION_MANAGER = import.meta.env.VITE_CSP_SUBSCRIPTION_MANAGER_URL;
export const DOCUMENTATION_URL = import.meta.env.VITE_CSP_DOCUMENTATION_URL;
export const GTM_ID = import.meta.env.VITE_GTM_ID;
export const GTM_AUTH = import.meta.env.VITE_GTM_AUTH;
export const GTM_PREVIEW = import.meta.env.VITE_GTM_PREVIEW;
export const IS_CLOUD_ENVIRONMENT = import.meta.env.VITE_IS_CLOUD_ENVIRONMENT;
export const ANALYTICS_PRODUCT_KEY = import.meta.env.VITE_ANALYTICS_PRODUCT_KEY as string;
export const TERMS_OF_USE = import.meta.env.VITE_CSP_TERMS_OF_USE;
export const EDIF_PROTOCOL = import.meta.env.VITE_EDIF_PROTOCOL;
export const CELUS_URL = import.meta.env.VITE_CELUS_URL;
export const XCC_TERMS_OF_USE = import.meta.env.VITE_XCC_TERMS_OF_USE;
export const CELUS_TERMS_OF_USE = import.meta.env.VITE_CELUS_TERMS_OF_USE;
export const CELUS_PRIVACY_POLICY = import.meta.env.VITE_CELUS_PRIVACY_POLICY;

export function getLocalstorageCleanupInterval() {
    const localstorageCleanupIntervalRaw = import.meta.env.VITE_LOCALSTORAGE_CLEAR_INTERVAL;
    return !isNaN(Number(localstorageCleanupIntervalRaw))
        ? Number(localstorageCleanupIntervalRaw)
        : 90;
}

export default {
    baseURL,
    env,
    PRIVACY_POLICY,
    SECURITY,
    TRUST_CENTER,
    PEP_TRUST_CENTER,
    SIEMENS_ANALYTICS_USE_PRODUCTION,
    WALK_ME_ENV_TYPE,
    INTERCOM_APP_ID,
    SUBSCRIPTION_MANAGER,
    DOCUMENTATION_URL,
    GTM_ID,
    GTM_AUTH,
    GTM_PREVIEW,
    IS_CLOUD_ENVIRONMENT,
    ANALYTICS_PRODUCT_KEY,
    TERMS_OF_USE,
    EDIF_PROTOCOL,
    CELUS_URL,
    XCC_TERMS_OF_USE,
    CELUS_TERMS_OF_USE,
    CELUS_PRIVACY_POLICY,
};
