import isEmpty from 'lodash/isEmpty';
import trim from 'lodash/trim';
import { useCallback } from 'react';

import {
    ccdErrorToast,
    ccdLoadingCompletedWithError,
    ccdLoadingCompletedWithSuccess,
    ccdLoadingToast,
} from 'components/_legacy/ccd-toast';
import useHttp from 'components/_legacy/hooks/useHttp';
import { useFileManagementContext } from 'components/templates/FileManagement/components/FileManagementContext';

import { uploadFileMaxLengthInBytes } from '../../../../consts';
import { CLOSE_SIDE_NAV, EE, FILE_UPLOADED } from '../../../../eventEmitter';
import {
    useCommandBarVisibilityContext,
    useFileFromDestinationFolder,
    useGlobalUiBlockersCounterContext,
    useTableRefresh,
} from '../../../../hooks';
import {
    getMessageStrategy,
    getMessageStrategyByNumberOfElements,
} from './uploadMessageStrategies';

export default function useUpload(
    showFilesAlreadyExistsModal,
    showNoAccessModal,
    isDragAndDropUpload
) {
    const {
        state: { backendUrl, collaborationSpaceId, projectId, currentFolder },
    } = useFileManagementContext();
    const { isUploadFileCommandAccessible } = useCommandBarVisibilityContext();
    const { increaseBlockerCounter, decreaseBlockerCounter } = useGlobalUiBlockersCounterContext();
    const { dispatchDataLoaded } = useTableRefresh();
    const { sendRequest } = useHttp();
    const { getInvalidFiles } = useFileFromDestinationFolder();
    let messageStrategy;

    async function useCreateDocumentAndUpload(file) {
        return new Promise((resolve, reject) => {
            const url = new URL(
                `/frontend/file-management/${collaborationSpaceId}/${projectId}/document`,
                backendUrl
            );
            const data = new FormData();
            data.append('file', file);
            if (!isEmpty(currentFolder?.id)) {
                data.append('parentFolderId', currentFolder?.id);
            }
            const requestConfig = {
                url: url,
                method: 'POST',
                credentials: 'include',
                body: data,
            };
            const onError = (error) => {
                error.fileName = file.name;

                console.error(error);
                reject(error);
            };

            const onSuccess = (response) => {
                resolve({
                    fileName: file.name,
                    typeName: response.typeName,
                });
            };

            sendRequest({
                requestConfig,
                onSuccess,
                onError,
            });
        });
    }

    async function uploadFileResponseHandler(files) {
        const promises = [];
        for (const file of files) {
            promises.push(useCreateDocumentAndUpload(file));
        }
        const promiseResults = await Promise.allSettled(promises);
        return promiseResults.reduce(successfulPromisesReduceFunc, []);
    }

    function successfulPromisesReduceFunc(filtered, promise) {
        if (promise.status === 'fulfilled') {
            filtered.push(promise.value);
        }
        return filtered;
    }

    function convertToFiles(dataTransferItems) {
        const file = [];
        for (const item of dataTransferItems) {
            file.push(item.getAsFile());
        }
        return file;
    }

    async function successHandler(notificationId, files) {
        ccdLoadingCompletedWithSuccess(notificationId, messageStrategy.successMessage(files));
        await dispatchDataLoaded();
    }

    function failHandler(notificationId) {
        ccdLoadingCompletedWithError(notificationId, messageStrategy.failedMessage());
    }

    async function partiallyFailHandler(notificationId) {
        ccdLoadingCompletedWithError(notificationId, messageStrategy.partiallyFailedMessage());
        await dispatchDataLoaded();
    }

    async function uploadValidFiles(files, notificationId) {
        const uploadedFiles = await uploadFileResponseHandler(files);

        if (uploadedFiles.length === 0) {
            await failHandler(notificationId);
        } else if (uploadedFiles.length === files.length) {
            await successHandler(notificationId, files);
        } else {
            await partiallyFailHandler(notificationId);
        }
        return uploadedFiles;
    }

    async function onDragUpload(dataTransferItems) {
        if (isDirectory(dataTransferItems)) {
            return;
        }
        const files = convertToFiles(dataTransferItems);
        await onUpload(files);
    }

    async function onInputUpload(itemsObj) {
        const itemList = Array.from(itemsObj);
        await onUpload(itemList);
    }

    const onUpload = useCallback(
        async (files) => {
            if (!isUploadFileCommandAccessible) {
                showNoAccessModal();
                return;
            }

            if (!validate(files)) {
                return;
            }

            increaseBlockerCounter({ isLight: true });

            const filesAlreadyExists = await getInvalidFiles(files, currentFolder?.id);

            const namesOfFilesAlreadyExist = filesAlreadyExists.map((f) => f.name);

            const notExistingFiles = files.filter(
                (f) => !namesOfFilesAlreadyExist.includes(trim(f.name))
            );

            if (filesAlreadyExists.length > 0) {
                EE.emit(CLOSE_SIDE_NAV);
                showFilesAlreadyExistsModal(filesAlreadyExists);
            }

            if (notExistingFiles.length > 0) {
                messageStrategy = getMessageStrategy(notExistingFiles);
                const notificationId = ccdLoadingToast(messageStrategy.loadingMessage());
                const uploadedFiles = await uploadValidFiles(notExistingFiles, notificationId);
                uploadedFiles.map((file) =>
                    EE.emit(FILE_UPLOADED, {
                        fileName: file.fileName,
                        typeName: file.typeName,
                        isDragAndDropUpload: isDragAndDropUpload,
                    })
                );
            }
            decreaseBlockerCounter({ isLight: true });
        },
        [
            currentFolder,
            dispatchDataLoaded,
            isUploadFileCommandAccessible,
            showNoAccessModal,
            increaseBlockerCounter,
            decreaseBlockerCounter,
            showFilesAlreadyExistsModal,
        ]
    );

    function validate(files) {
        if (!files) {
            return false;
        }

        const invalidCount = files.filter((f) => f.size > uploadFileMaxLengthInBytes).length;

        if (invalidCount >= 1) {
            ccdErrorToast(getMessageStrategyByNumberOfElements(invalidCount).tooLargeFileError());

            return false;
        }

        return true;
    }

    function isDirectory(items) {
        // webkitGetAsEntry is an experimental function
        // in the future some changes will be applied
        if (items.length !== 0 && !items[0].webkitGetAsEntry) {
            console.error(
                'webkitGetAsEntry method was removed or renamed. Check javascript documentation for updates.'
            );
            return false;
        }
        for (const item of items) {
            if (item.webkitGetAsEntry().isDirectory) {
                ccdErrorToast({
                    title: "Can't upload a folder",
                    messages: ["We don't support folder upload yet"],
                });
                return true;
            }
        }
        return false;
    }

    return {
        onInputUpload,
        onDragUpload,
        hasAccess: isUploadFileCommandAccessible,
    };
}
