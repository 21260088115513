import { Theme, createTheme } from '@mui/material/styles';

export const customColors = {
    blue: '#0f789b',
    'blue-1': '#003750',
    'blue-2': '#003E5C',
    'blue-3': '#004669',
    'blue-7': '#005f87',
    'blue-9': '#0F789B',
    'blue-10': '#cde6eb',
    'blue-13': '#3296b9',
    'blue-17': '#50BED7',
    'blue-18': '#66c8de',
    'blue-22': '#B9EDFA',
    'blue-24': '#e3faff',
    'old-blue-dark': '#006487',
    'apollo-hover-blue': '#daecf0',
    'sea-blue': '#66c8de',
    'deep-sea-blue': '#005f87',
    orange: '#e3700f',
    'orange-1': '#dc6914',
    'red-1': '#130100',
    'red-2': '#260300',
    'red-3': '#390400',
    'red-4': '#4D0600',
    'red-5': '#600700',
    'red-6': '#730900',
    'red-7': '#870B00',
    'red-8': '#91140A',
    'red-9': '#9B1E14',
    'red-10': '#A5271E',
    red: '#dc0000',
    'red-11': '#AF3128',
    'red-12': '#B93A32',
    'red-13': '#C3443C',
    'red-14': '#CD4D46',
    'red-15': '#D75750',
    'red-16': '#DB6761',
    'red-17': '#DF7873',
    'red-18': '#e38984',
    'red-19': '#E79A96',
    'red-20': '#EBABA7',
    'red-21': '#EFBBB9',
    'red-22': '#F3CCCA',
    'red-23': '#F7DDDC',
    'red-24': '#fbeeed',
    'green-1': '#0a9b00',
    white: '#fff',
    'apollo-gray-1': '#464646',
    'grey-1': '#8c8c8c',
    'grey-2': '#aaaaaa',
    '-grey-9': '#d8d8d8',
    'yellow-1': '#7D2D1E',
    'yellow-2': '#8E3420',
    'yellow-3': '#A03C23',
    'yellow-4': '#AA4325',
    'yellow-5': '#B44B28',
    'yellow-6': '#BE5223',
    'yellow-7': '#C85A1E',
    'yellow-8': '#D26119',
    'yellow-9': '#DC6914',
    'yellow-10': '#E3700F',
    'yellow-11': '#EB780A',
    'yellow-12': '#F0820F',
    'yellow-13': '#F58C14',
    'yellow-14': '#F7980F',
    'yellow-15': '#FAA50A',
    'yellow-16': '#FCAF05',
    'yellow-17': '#FFB900',
    'yellow-18': '#FFC328',
    'yellow-19': '#FFCD50',
    'yellow-20': '#FFD764',
    'yellow-21': '#FFE178',
    'yellow-22': '#FFE889',
    'yellow-23': '#FFF09B',
    'yellow-24': '#FFF7CD',
    'yellow-25': '#F6D9C4',
    'yellow-26': '#FBF0E7',
    'black-0': '#000000',
    'pl-black-4': '#1e1e1e',
    'pl-black-10': '#5a5a5a',
    'pl-black-12': '#6e6e6e',
    'pl-black-16': '#969696',
    'pl-black-23': '#dcdcdc',
    'pl-black-25': '#f0f0f0',
    focus: '#3296b9',
    'current-selection-dropdown': '#cde6eb',
    'accordion-title-background': '#f2f2f2',
    'accordion-title-line': '#dfdfdf',
    petrol: '#009999',
    'spinner-foreground': '#E6E6E6',
    'checked-checkbox': '#197FA2',
    'history-acordion': '#f2f2f2',
    'siemens-stone-dark': '#3c464b',
    'siemens-stone-light': '#BFD7E1',
    'siemens-natural-sky-blue': '#d9e7ed',
    'siemens-blue-21': '#AAE6F5',
};

const theme: Theme = createTheme({
    shape: {
        borderRadius: 2,
    },
    typography: {
        button: {
            textTransform: 'none',
        },
        fontFamily: `'Segoe UI', 'Open Sans', 'Helvetica Neue', Verdana, Arial, 'YU Gothic UI', 'MS PGothic', 'Hiragino Sans', 'Microsoft YaHei', 微软雅黑, STXihei, 华文细黑, sans-serif;`,
    },
    palette: {
        secondary: {
            main: customColors['apollo-gray-1'],
            dark: customColors['siemens-stone-dark'],
            light: customColors['-grey-9'],
            contrastText: customColors.white,
        },
        primary: {
            main: customColors['old-blue-dark'],
            dark: customColors['blue-7'],
            light: customColors['apollo-hover-blue'],
            contrastText: customColors.white,
        },
    },
    spacing: 4,
});

export default theme;
