import { DialogButton } from 'components/atoms/Button';
import Dialog from 'components/molecules/Dialog';

export function NoAccessUploadModal({ onClose }) {
    const NoUploadAccessPrefix = 'no-upload-access';
    return (
        <Dialog
            title={'No permissions'}
            buttons={
                <DialogButton
                    color='primary'
                    variant={'contained'}
                    onClick={onClose}
                    id={`${NoUploadAccessPrefix}-ok-button`}
                >
                    OK
                </DialogButton>
            }
            id={NoUploadAccessPrefix}
            open
            setOpen={onClose}
        >
            <div className='ccd-fm__modal-text-bolded'>
                Your viewer role doesn't allow to upload files.
            </div>
            <div>Please contact your project admin to grant you Editor Role.</div>
        </Dialog>
    );
}
