import { PropsWithChildren, createContext, useState } from 'react';

export type FullScreenState = {
    fullScreen: boolean;
    setFullScreen: (fullScreen: boolean) => void;
};

export const FullScreenContext = createContext<FullScreenState>({
    fullScreen: false,
    setFullScreen: () => {},
});

export const FullScreenProvider = ({ children }: PropsWithChildren) => {
    const setFullScreen = (fullScreen: boolean) => {
        setState((state: FullScreenState) => ({ ...state, fullScreen: fullScreen }));
    };

    const initState: FullScreenState = {
        fullScreen: false,
        setFullScreen: setFullScreen,
    };

    const [state, setState] = useState(initState);

    return <FullScreenContext.Provider value={state}>{children}</FullScreenContext.Provider>;
};
