import React, { useContext } from 'react';

import projectInfoPanelContext from '../../ProjectHistoryPanelContext';
import useProjectHistoryLineElementsHook from './useProjectHistoryLineElementsHook';
import useProjectHistoryLinePrototypesHook from './useProjectHistoryLinePrototypes';
import useProjectLineHistoryDrawStrategyHook from './useProjectLineHistoryDrawStrategy';

export default function useProjectHistoryLineFirstAccordion(direction, width, height) {
    const placement = useContext(projectInfoPanelContext);

    const { lastItem, drawBottomLine, drawUpperLine, drawUpperShortenLine } =
        useProjectHistoryLinePrototypesHook(width, height);

    const { baseItemElement } = useProjectHistoryLineElementsHook(direction, width, height);

    const firstElement = (
        <React.Fragment key='useProjectHistoryLineFirstAccordion_firstElement'>
            {drawUpperLine}
        </React.Fragment>
    );

    const lastElement = (
        <React.Fragment key='useProjectHistoryLineFirstAccordion_lastElement'>
            {drawUpperShortenLine}
            {drawBottomLine}
            {lastItem}
        </React.Fragment>
    );

    const regularElement = (
        <React.Fragment key='useProjectHistoryLineFirstAccordion_regularElement'>
            {drawUpperLine}
            {drawBottomLine}
        </React.Fragment>
    );

    const { draw } = useProjectLineHistoryDrawStrategyHook(
        firstElement,
        lastElement,
        regularElement,
        baseItemElement
    );

    return {
        drawFistAccordion: () => draw(placement.isFirst, placement.isLast),
    };
}
