import { useContext } from 'react';

import { CurrentFolderContext } from 'components/templates/FileManagement/components/CurrentFolderContextProvider/CurrentFolderContextProvider';

function useCurrentFolderContext() {
    const context = useContext(CurrentFolderContext);

    if (context === undefined) {
        throw new Error(
            'useCurrentFolderContext must be used within a CurrentFolderContextProvider'
        );
    }

    return context;
}

export default useCurrentFolderContext;
