export enum LoadingStateLabel {
    LOADING = 'Loading. Please wait...',
    DESIGN = 'Loading design. Please wait...',
    LOCKING = 'Locking. Please wait...',
    UNLOCKING = 'Unlocking. Please wait...',
    FORCE_UNLOCKING = 'Force unlocking. Please wait...',
    DELETING = 'Deleting. Please wait...',
    BOM = 'Loading BOM. Please wait...',
    PROCESSING_DATA = 'Processing data, please wait...',
    PROJECTS = 'Loading projects. Please wait...',
    USERS = 'Loading users. Please wait...',
    USER = 'Loading user data. Please wait...',
    HISTORY = 'Project history is loading. Please wait...',
    PROJECT_CREATING = 'Creating project. Please wait...',
    FILES = 'Loading files. Please wait...',
    IPL = 'Loading BOM Intelligence. Please wait...',
    RESETTING = 'Resetting. Please wait...',
}
