import fetcher from 'providers/api/fetcher';

export async function getPermissions(
    objectType: PermissionType,
    objectId: string
): Promise<GetPermissions> {
    const { data } = await fetcher.get('/permissions/bulk-permissions', {
        params: { objectType: objectType, objectId: objectId },
    });
    return data;
}

export enum PermissionType {
    CollaborationSpace = 'collaborationSpace',
    Project = 'project',
}

export type GetPermissions = {
    [permission: string]: boolean;
};
