import React from 'react';

export default function DateCellRenderer(field: string, data: any) {
    const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
    };
    // @ts-ignore
    const converted = Intl.DateTimeFormat('default', options).format(
        new Date(data.cell.row.original[field])
    );

    return <span>{converted}</span>;
}
