import prettyBytes from 'pretty-bytes';

import { ToastDisplayParams } from 'components/_legacy/ccd-toast/ccdToast';
import { ISelectedFile } from 'components/templates/FileManagement/contexts/FileManagementContext';

import { CONTACT_SUPPORT_MESSAGE, uploadFileMaxLengthInBytes } from '../../../../consts';

export const UPLOAD_FAILED = 'Upload failed';
export const UPLOAD_SUCCESSFUL = 'Upload successful';

type Rejected = { status: 'rejected'; reason: { name: string } };

export const singleFileUploadStrategy = {
    loadingMessage: (): ToastDisplayParams => {
        return {
            messages: ['Uploading file. Please wait...'],
            closeButton: true,
            toastId: 'UploadingFileToast',
        };
    },
    successMessage: (selectedFiles: ISelectedFile[]): ToastDisplayParams => {
        return {
            title: UPLOAD_SUCCESSFUL,
            messages: [`${selectedFiles[0].name} successfully uploaded.`],
            toastId: 'FileUploadSuccessfulToast',
        };
    },
    failedMessage: () => {
        return {
            title: UPLOAD_FAILED,
            messages: [
                'Oops! Something went wrong during file upload. Please try again.',
                CONTACT_SUPPORT_MESSAGE,
            ],
            autoCloseTime: 10000,
        };
    },
    tooLargeFileError: () => {
        return {
            title: "Can't upload file",
            messages: [
                'File is too big.',
                `Please choose a file with size less than ${prettyBytes(
                    uploadFileMaxLengthInBytes,
                    { maximumFractionDigits: 0 }
                )}.`,
            ],
        };
    },
    alreadyExist: (response: Rejected[]) => {
        return {
            title: UPLOAD_FAILED,
            messages: [`${response[0].reason.name} already exists.`],
        };
    },
};

const multipleFilesUploadStrategy = {
    loadingMessage: () => {
        return {
            title: null,
            messages: ['Uploading files. Please wait...'],
            closeButton: true,
        };
    },
    successMessage: (selectedFiles: ISelectedFile[]) => {
        return {
            title: UPLOAD_SUCCESSFUL,
            messages: [`${selectedFiles.length} files successfully uploaded.`],
        };
    },
    failedMessage: () => {
        return {
            title: UPLOAD_FAILED,
            messages: [
                'Oops! Something went wrong during files upload. Please try again.',
                CONTACT_SUPPORT_MESSAGE,
            ],
            autoCloseTime: 10000,
        };
    },
    partiallyFailedMessage: () => {
        return {
            title: UPLOAD_FAILED,
            messages: ["Couldn't upload all files. Please try again.", CONTACT_SUPPORT_MESSAGE],
            autoCloseTime: 10000,
        };
    },
    tooLargeFileError: () => {
        return {
            title: "Can't upload files",
            messages: [
                'Some files are too big.',
                `Please choose files with size less than ${prettyBytes(uploadFileMaxLengthInBytes, {
                    maximumFractionDigits: 0,
                })} each.`,
            ],
        };
    },
    alreadyExist: (selectedFiles: ISelectedFile[]) => {
        return {
            title: UPLOAD_FAILED,
            messages: [`${selectedFiles.length} files already exist.`],
        };
    },
};

export function getMessageStrategyByNumberOfElements(numberOfElements: number) {
    if (numberOfElements === 1) {
        return singleFileUploadStrategy;
    } else {
        return multipleFilesUploadStrategy;
    }
}

export function getMessageStrategy(selectedFiles: ISelectedFile[]) {
    return getMessageStrategyByNumberOfElements(selectedFiles.length);
}
