import React, { useCallback, useEffect, useRef, useState } from 'react';

import useProjectHistoryLineFirstAccordion from './hooks/useProjectHistoryLineFirstAccordion';
import useProjectHistoryLineLastAccordion from './hooks/useProjectHistoryLineLastAccordion';
import useProjectHistoryLineRegularAccordion from './hooks/useProjectHistoryLineRegularAccordion';

export default function ProjectHistoryLine({ accordionType, direction, containerHeight }) {
    const ref = useRef(null);
    const [height, setHeight] = useState(containerHeight);
    const [width, setWidth] = useState(0);

    const { drawFistAccordion } = useProjectHistoryLineFirstAccordion(direction, width, height);
    const { drawLastAccordion } = useProjectHistoryLineLastAccordion(direction, width, height);
    const { drawRegularAccordion } = useProjectHistoryLineRegularAccordion(
        direction,
        width,
        height
    );

    const drawStrategy = useCallback(
        (type) => {
            if (type === 'first') {
                return drawFistAccordion();
            } else if (type === 'last') {
                return drawLastAccordion();
            }
            return drawRegularAccordion();
        },
        [direction, width, height]
    );

    useEffect(() => {
        setHeight(ref.current.parentElement.offsetHeight ?? containerHeight);
        setWidth(88);
    }, [containerHeight, ref?.current?.parentElement?.offsetHeight]);

    return (
        <div ref={ref} className='projectHistoryLine'>
            <svg width={width} height='100%' xmlns='http://www.w3.org/2000/svg'>
                {drawStrategy(accordionType)}
            </svg>
        </div>
    );
}
