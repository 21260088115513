import { useQuery } from '@tanstack/react-query';

import useFeatureFlagsQuery from 'services/queries/featuresFlags.query';
import { getLcc } from 'services/queries/lcc.query';

export const useGetLcc = () => {
    const { data: featureFlags } = useFeatureFlagsQuery();

    return useQuery<string>({
        queryKey: ['getLcc'],
        queryFn: getLcc,
        enabled: featureFlags?.SubscriptionManagerFeature === true,
    });
};
