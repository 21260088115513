import styled from '@emotion/styled';

export const CheckboxRow = styled('div')(({ theme }) => ({
    display: 'flex',
    padding: theme.spacing(1, 0, 0, 0),
    color: theme.colors.text,
}));

export const CheckBoxLabel = styled('span')(({ theme }) => ({
    marginLeft: theme.spacing(2),
    fontWeight: 'bolder',
    color: theme.colors.text,
}));
