import isEqual from 'lodash/isEqual';
import { useCallback, useEffect, useMemo, useState } from 'react';

import createSelectionProvider from 'components/_legacy/selection/selectionProvider';

function areEqualById(selectedElement, row) {
    return row.original.id === selectedElement.id;
}

const useMultiSelection = (settings, records, onSelected, forcedSelection) => {
    const Selection = useMemo(() => createSelectionProvider(), []);
    const [selectedRecords, setSelectedRecords] = useState([]);

    const isMultiselectionAvailable = useMemo(() => settings?.multiselection === true, [settings]);

    const clearSelection = useCallback(() => {
        Selection.clearSelection();
        setSelectedRecords([]);
    }, []);

    const handleSelection = useCallback(
        (record, event) => {
            const currentSelection = selectedRecords;

            const processSelection = (processedRecord, evt) => {
                if (isMultiselectionAvailable) {
                    if (evt.ctrlKey) {
                        return Selection.appendSelection(currentSelection, processedRecord);
                    } else if (evt.shiftKey) {
                        return Selection.rangeSelection(currentSelection, records, processedRecord);
                    } else {
                        return Selection.regularSelection(processedRecord);
                    }
                } else {
                    if (evt.ctrlKey) {
                        return Selection.toggleSelection(currentSelection, processedRecord);
                    } else {
                        return Selection.regularSelection(processedRecord);
                    }
                }
            };
            setSelectedRecords(processSelection(record, event));
        },
        [records, selectedRecords, isMultiselectionAvailable]
    );

    const onKeyDown = useCallback(
        (event) => {
            if (
                isMultiselectionAvailable &&
                event.ctrlKey &&
                (event.keyCode === 65 || event.keyCode === 97)
            ) {
                setSelectedRecords(Selection.selectAll(records));
                event.preventDefault();
                event.stopPropagation();
            }
        },
        [records, isMultiselectionAvailable]
    );

    useEffect(() => {
        onSelected?.(selectedRecords);
    }, [selectedRecords]);

    useEffect(() => {
        if (forcedSelection?.enabled && forcedSelection.elements) {
            const comparisonFunc = forcedSelection.comparisonFunc ?? areEqualById;
            const selected = records.filter((record) =>
                forcedSelection.elements.find((selectedElement) =>
                    comparisonFunc(selectedElement, record)
                )
            );
            if (
                !isEqual(
                    selected.map((record) => record.id),
                    selectedRecords.map((record) => record.id)
                )
            ) {
                setSelectedRecords(selected);
            }
        }
    }, [forcedSelection]);

    return {
        clearSelection,
        selectedRecords,
        handleSelection,
        onKeyDown,
    };
};

export default useMultiSelection;
