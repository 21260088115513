import { Box } from '@mui/material';
import React, { useState } from 'react';

import Collapse from 'components/atoms/Collapse';

import {
    Description,
    Expandable,
    ToggleButtonArea,
    WrappedDescription,
} from './ExpandableTextArea.styles';

export interface ExpandableTextAreaInterface extends React.PropsWithChildren {
    description: string;
}

export const ExpandableTextArea = ({ description }: ExpandableTextAreaInterface) => {
    const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false);

    const toggleDescription = () => {
        setIsDescriptionExpanded((prev) => !prev);
    };

    const shouldTrimDescription = (description?: string) => {
        return description?.includes('\n');
    };

    return (
        <Expandable>
            {shouldTrimDescription(description) && (
                <ToggleButtonArea onClick={toggleDescription}>
                    <Collapse isOpened={isDescriptionExpanded} />
                </ToggleButtonArea>
            )}

            {isDescriptionExpanded ? (
                <Box>{description}</Box>
            ) : shouldTrimDescription(description) ? (
                <WrappedDescription>{description}</WrappedDescription>
            ) : (
                <Description>{description}</Description>
            )}
        </Expandable>
    );
};
