import { useCallback } from 'react';

export default function useVisualizeSearchHook() {
    const visualizeChunk = useCallback((text: string, criteria: string[], index = 1) => {
        const ranges = Array(text.length).fill(0);
        let wasPresent = false;
        for (const item of criteria) {
            const begin = text.toUpperCase().indexOf(item.toUpperCase());
            if (begin !== -1) {
                wasPresent = true;
                const end = begin + item.length;
                ranges.fill(1, begin, end);
            }
        }

        const shouldBeBolded = (x: number) => ranges[x];

        if (!wasPresent) {
            return <span key={index}>{text}</span>;
        } else {
            return (
                <span key={index}>
                    {[...Array(text.length).keys()].map((x, index) => {
                        const letter = text[x];
                        return (
                            <span key={index}>{shouldBeBolded(x) ? <b>{letter}</b> : letter}</span>
                        );
                    })}
                </span>
            );
        }
    }, []);

    const visualizeSearch = useCallback((text: string, criteria: string[] = []) => {
        const chunks = text.split(' ');
        return chunks.map((chunk, index) => visualizeChunk(chunk + ' ', criteria, index));
    }, []);

    const visualizeVersionSearch = useCallback((text: string, criteria: string[] = []) => {
        return visualizeChunk(text, criteria);
    }, []);

    return {
        visualizeSearch,
        visualizeVersionSearch,
    };
}
