import { bool, func } from 'prop-types';
import { PropsWithChildren, useEffect } from 'react';

import { FileFormComponent, NAME_INPUT_ID } from '../';
import { useForm } from '../../UI';
import useAddFolderForm from './useAddFolderForm';

export interface Props extends PropsWithChildren {
    isVisible: boolean;
    onClose: () => void;
}

export default function AddFolderForm({ isVisible, onClose }: Props) {
    const {
        error,
        focus,
        buttonDisabled,
        setError,
        handleCloseError,
        preSubmitCleanup,
        activateForm,
    } = useForm(NAME_INPUT_ID);

    const { onSubmit, inputs, isLoading } = useAddFolderForm(
        {
            handleCloseError,
            preSubmitCleanup,
            setError,
        },
        onClose
    );

    useEffect(() => {
        isVisible && activateForm();
    }, [isVisible]);

    return (
        <FileFormComponent
            onSubmit={onSubmit}
            onClose={onClose}
            headerTitle='Create new folder'
            isVisible={isVisible}
            error={error}
            buttonDisabled={buttonDisabled}
            handleCloseError={handleCloseError}
            focus={focus}
            inputs={inputs}
            isLoading={isLoading}
        />
    );
}

AddFolderForm.propTypes = {
    isVisible: bool,
    onClose: func,
};
