import React from 'react';
import { StateManagerProps } from 'react-select/dist/declarations/src/useStateManager';

import ComboBox, { ComboBoxGroupRoot, ISelectList } from 'components/atoms/ComboBox';
import Label from 'components/atoms/Label';
import { TRoles } from 'components/templates/ProjectUsers/services/types';

interface IComboBox extends StateManagerProps {
    isError?: boolean;
    onChange: (choice: any) => void;
}

export interface Role extends ISelectList {
    value: TRoles;
}

const RoleComboBox = (props: IComboBox) => {
    const { isDisabled, isError, onChange, value } = props;

    const roles: Role[] = [
        { label: 'Admin', value: TRoles.ProjectAdmin },
        { label: 'Editor', value: TRoles.ProjectEditor },
        { label: 'Viewer', value: TRoles.ProjectViewer },
    ];

    const showError: boolean = !!(isError && !value);

    return (
        <ComboBoxGroupRoot>
            <Label
                required
                style={{ ...(showError && { color: 'red', '> span': { color: 'red' } }) }}
            >
                {showError ? 'Role (select role)' : 'Role'}
            </Label>
            <ComboBox
                selectList={roles}
                isError={showError}
                onChange={(value) => {
                    onChange(value);
                }}
                placeholder={'Select role'}
                isDisabled={isDisabled}
                value={value}
                id={'RoleComboBox'}
            />
        </ComboBoxGroupRoot>
    );
};

export default RoleComboBox;
