import { ReactElement, useState } from 'react';

import { createGenericContext } from 'shared/createGenericContext';

import {
    FileManagementContextProps,
    Inputs,
    initializerArg,
} from '../../contexts/FileManagementContext';

export type Value = {
    state: FileManagementContextProps;
    dispatch: (arg: FileManagementContextProps) => void;
};

const [useFileManagementContext, FileManagementContextProvider] = createGenericContext<Value>();

function FileManagementProvider({ values, children }: { values: Inputs; children: ReactElement }) {
    const [state, updateState] = useState<FileManagementContextProps>({
        ...initializerArg,
        ...values,
    });

    const dispatch = (arg: FileManagementContextProps) =>
        updateState((prevState) => ({
            ...prevState,
            ...arg,
        }));
    const value: Value = { state, dispatch };

    return <FileManagementContextProvider value={value}>{children}</FileManagementContextProvider>;
}

export { useFileManagementContext, FileManagementProvider };
