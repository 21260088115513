import { useCallback, useEffect, useReducer } from 'react';

import { CcdBreadcrumb } from 'components/_legacy/ccd-breadcrumb';
import { useFileManagementContext } from 'components/templates/FileManagement/components/FileManagementContext';

export default function FileManagementBreadcrumb({ currentFolder, dispatchFileManagement }) {
    const {
        state: { projectName },
    } = useFileManagementContext();

    useEffect(() => {
        dispatch({ type: 'nameChanged' });
    }, [projectName]);

    const extendedOnClick = useCallback((currentDirectory) => {
        dispatch({ type: 'popup' });
        dispatchFileManagement({
            currentFolder: currentDirectory,
            selectedFiles: [],
        });
    }, []);

    const initialState = {
        breadCrumbArray: [],
    };

    function tableHandler(stateUpdate) {
        return {
            ...stateUpdate,
            breadCrumbArray: [
                ...stateUpdate.breadCrumbArray,
                {
                    key: currentFolder.id,
                    name: currentFolder.id === '' ? projectName : currentFolder.name,
                    id: currentFolder.id,
                    typeName: currentFolder.typeName,
                    folderLevel: stateUpdate.breadCrumbArray.length,
                    suffix: currentFolder.id === '' ? ' Files' : undefined,
                },
            ],
        };
    }

    function popupHandler(stateUpdate) {
        const selectedFolderIndex = stateUpdate.breadCrumbArray.findIndex((element) => {
            return element.id === currentFolder.id;
        });
        return {
            ...stateUpdate,
            breadCrumbArray:
                stateUpdate.breadCrumbArray.length <= 1 ||
                selectedFolderIndex === stateUpdate.breadCrumbArray.length
                    ? stateUpdate.breadCrumbArray
                    : stateUpdate.breadCrumbArray.slice(0, selectedFolderIndex + 1),
        };
    }

    function nameChangedHandler(stateUpdate) {
        const currentArray = [...stateUpdate.breadCrumbArray];
        const firstBreadcrumb = currentArray.find((b) => b.id === '');
        if (firstBreadcrumb) {
            firstBreadcrumb.name = projectName;
        }
        return {
            ...stateUpdate,
            breadCrumbArray: currentArray,
        };
    }

    function cleanupHandler(stateUpdate) {
        return {
            ...stateUpdate,
            breadCrumbArray: [],
        };
    }

    function reducer(stateUpdate, action) {
        switch (action.type) {
            case 'table':
                return tableHandler(stateUpdate);
            case 'popup':
                return popupHandler(stateUpdate);
            case 'nameChanged':
                return nameChangedHandler(stateUpdate);
            case 'cleanup':
                return cleanupHandler(stateUpdate);
            default:
                throw new Error();
        }
    }

    const [state, dispatch] = useReducer(reducer, initialState);

    const isFolderExists = useCallback(
        (currentFolderId) => !!state.breadCrumbArray.find((obj) => obj.key === currentFolderId),
        [state.breadCrumbArray]
    );

    useEffect(() => {
        const folderExists = isFolderExists(currentFolder.id);
        if (currentFolder.id === '' && !folderExists) {
            return;
        }
        if (!folderExists) {
            dispatch({ type: 'table' });
        }
    }, [currentFolder.id]);

    useEffect(() => {
        dispatch({ type: 'table' });
        return () => {
            dispatch({ type: 'cleanup' });
        };
    }, []);

    return <CcdBreadcrumb breadcrumbArray={state.breadCrumbArray} onClick={extendedOnClick} />;
}
