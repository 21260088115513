import { useCallback } from 'react';

export default function useOpenFolder(currentFolder, dispatchFileManagement) {
    const onFolderClickHandler = useCallback(
        (item) => {
            dispatchFileManagement({
                currentFolder: {
                    id: item.id,
                    name: item.name,
                    typeName: item.typeName,
                    folderLevel: currentFolder.folderLevel + 1,
                },
                selectedFiles: [],
            });
        },
        [currentFolder]
    );

    return { onFolderClickHandler };
}
