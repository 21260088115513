import styled from '@emotion/styled';
import * as RadioGroup from '@radix-ui/react-radio-group';

export const RadioGroupRoot = styled(RadioGroup.Root)({
    display: 'flex',
    flexDirection: 'row',
    gap: 10,
});

export const RadioGroupItem = styled(RadioGroup.Item)(({ theme }) => ({
    all: 'unset',
    backgroundColor: theme.colors.radioButton.backgroundColor,
    width: 18,
    height: 18,
    borderRadius: '100%',
    marginRight: theme.spacing(2),
    boxShadow: `0px 0px 0px 1px ${theme.colors.radioButton.boxShadow}`,
    '&[data-state="checked"]': {
        boxShadow: `0 0px 0px 1px ${theme.colors.radioButton.selectedBoxShadow}`,
    },
    '&:hover': {
        boxShadow: `0px 0px 0px 2px ${theme.colors.radioButton.hoverBoxShadow}`,
        cursor: 'pointer',
    },
    '&:focus': { boxShadow: `0 0 0 2px ${theme.colors.radioButton.focusBoxShadow}` },
}));

export const RadioGroupIndicator = styled(RadioGroup.Indicator)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    position: 'relative',
    '&::after': {
        content: '""',
        display: 'block',
        width: 10,
        height: 10,
        borderRadius: '50%',
        backgroundColor: theme.colors.radioButton.indicatorBackground,
    },
}));

export const RadioTextLabel = styled('label')(
    ({ theme }) =>
        ({ isError = false }: { isError?: boolean }) => ({
            marginRight: '24px',
            fontWeight: 600,
            ...(isError && { color: theme!.colors.error.text, fontWeight: '600' }),
        })
);
