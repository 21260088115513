import { useContext } from 'react';

import CcdRevisionTile from 'components/_legacy/ccd-revision-tile';
import { useProjectHistoryLinePanel } from 'components/templates/ProjectHistory/ProjectHistoryPanel/useProjectHistoryLinePanel';
import { ProcessedRevision } from 'components/templates/ProjectHistory/ProjectHistoryView/ProjectHistoryView';
import { AccordionType } from 'components/templates/ProjectHistory/ProjectHistoryViewAccordion/ProjectHistoryViewAccordion';

import ProjectHistoryItemVersionPanel from '../ProjectHistoryItemVersionPanel';
import ProjectHistoryLine from '../ProjectHistoryLine/ProjectHistoryLine';
import ProjectInfoContext from '../ProjectInfoContext';

import './ProjectHistoryPanel.scss';

export default function useProjectHistoryLineRightPanel({
    accordionType,
    revision,
}: {
    accordionType?: AccordionType;
    revision: ProcessedRevision;
}) {
    const projectInfoContext = useContext(ProjectInfoContext);
    const { onResize, onProjectTitle, height } = useProjectHistoryLinePanel({ revision });

    return () => (
        <>
            <div
                key={'id0' + revision.versionNumber + 'top'}
                className='project-history-panel-elements project-history-panel-revision-element project-history-panel__left__spacing'
            >
                <ProjectHistoryItemVersionPanel
                    key={'id0' + revision.versionNumber}
                    revision={revision}
                />
            </div>
            <ProjectHistoryLine
                key={'id1-right' + revision.versionNumber}
                direction='right'
                accordionType={accordionType}
                containerHeight={height}
            />
            <div
                key={'id2' + revision.versionNumber + 'top'}
                className='project-history-panel-elements'
            >
                <CcdRevisionTile
                    key={'id2' + revision.versionNumber}
                    title={revision.eventName}
                    description={revision.description}
                    projectTitle={projectInfoContext.projectName}
                    thumbnailUrl={projectInfoContext.thumbnail}
                    eventDescription={revision.eventDescription}
                    creator={revision.lastModified.by.name}
                    onResize={onResize}
                    onProjectTitle={
                        projectInfoContext.currentRevision !== +revision.versionNumber
                            ? onProjectTitle
                            : undefined
                    }
                />
            </div>
        </>
    );
}
