import Box from 'components/atoms/Box';
import { AlignCenter } from 'components/atoms/Flex/Flex';
import { LoadingContainer } from 'components/organisms/ProjectVersion/components/Footer/LoadingContainer';
import { ProjectVersionButton } from 'components/organisms/ProjectVersion/components/Footer/ProjectVersionButton';
import SuccessIcon from 'images/indicatorInformationSuccess16.svg?react';

export const RevisionsLoaded = ({ onClick }: { onClick: () => void }) => {
    return (
        <LoadingContainer
            id={'loaded'}
            css={{
                fontWeight: '600',
                fontSize: '10px',
            }}
        >
            <AlignCenter>
                <SuccessIcon css={{ height: '12px', width: '12px', marginRight: '4px' }} />
                <Box as={'span'} css={{ height: '13px' }}>
                    All versions loaded
                </Box>
                <ProjectVersionButton variant={'text'} onClick={onClick}>
                    Close
                </ProjectVersionButton>
            </AlignCenter>
        </LoadingContainer>
    );
};
