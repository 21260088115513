import styled from '@emotion/styled';

export const Header = styled('h1')(({ theme }) => ({
    fontSize: '14px',
    lineHeight: '19px',
    fontWeight: 600,
    color: theme.colors.text,
    marginTop: '0px',
}));

export const Paragraph = styled('p')(({ theme }) => ({
    marginTop: theme.spacing(1),
    '& a': {
        display: 'inline',
        color: theme.colors.actionText,
        fontWeight: 'bolder',
        textDecoration: 'none',
        '&:hover': { color: theme.colors.actionTextHover, cursor: 'pointer' },
    },
    color: theme.colors.text,
}));

export const RadioButtonsRow = styled('div')(({ theme }) => ({
    justifyContent: 'center',
    display: 'flex',
    padding: theme.spacing(4, 0, 4, 0),
    color: theme.colors.text,
}));

export const ErrorArea = styled('span')(({ theme }) => ({
    color: theme.colors.error.text,
    fontSize: '14px',
    fontWeight: '600',
    display: 'flex',
    alignItems: 'center',
    marginRight: '12px',
}));
