import Button from 'components/atoms/Button';
import Paragraph from 'components/atoms/Paragraph/Paragraph';
import BomErrorState, {
    Container,
    ErrorMessage,
    ErrorStateIcon,
    ErrorTitle,
    MessageContainer,
} from 'components/templates/iplView/ErrorState/BomErrorState';
import ErrorLabel from 'images/error_generic.svg?react';
import { IplError } from 'types/ipl';

import ErrorState from './ErrorState';

const getErrorMessage = (e: IplError) => {
    if (e.response?.data.Errors?.DetailedMessage[0]) {
        return e.response?.data.Errors?.DetailedMessage[0];
    } else if (e.response?.data.Title) {
        return e.response?.data.Title;
    }

    return 'BOM Intelligence service error';
};

export function showErrorState(error: IplError, buttonClick: () => void) {
    return (
        <ErrorState>
            <ErrorLabel />
            <h1>{error?.response?.data.Title}</h1>
            <Paragraph>
                Please try again. If the problem persists, please contact our support.
            </Paragraph>
            <Button onClick={buttonClick}>Retry</Button>
        </ErrorState>
    );
}

export function actionOnNoMpnColumn(buttonClick: () => void): null {
    buttonClick();
    return null;
}

export function showNoMpnState(buttonClick: () => void) {
    return (
        <ErrorState>
            <ErrorLabel />
            <h1>We couldn't detect any Manufacturer Part Number in this BOM.</h1>
            <Paragraph>
                Please use the Reset button, make sure the file contains MPN data and when the
                configuration window shows up, select proper MPN column in your BOM.
            </Paragraph>
            <Button onClick={buttonClick}>Reset</Button>
        </ErrorState>
    );
}

export function showMpnNotFoundState(buttonClick: () => void) {
    return (
        <ErrorState>
            <ErrorLabel />
            <h1>Manufacturer part number not found in current BOM</h1>
            <Paragraph>
                Please use the reset button and when the configuration window shows up, select
                proper MPN column in your BOM.
            </Paragraph>
            <Button onClick={buttonClick}>Reset</Button>
        </ErrorState>
    );
}

export function showConflictState(error: IplError, buttonClick: () => void) {
    return (
        <ErrorState>
            <ErrorLabel />
            <h1>{getErrorMessage(error)}</h1>
            <Paragraph>
                Please try to use reset button. If the problem persists, please contact our support.
            </Paragraph>
            <Button onClick={buttonClick}>Reset</Button>
        </ErrorState>
    );
}

export function showTooLargeData(buttonClick: () => void) {
    return (
        <BomErrorState>
            <Container>
                <ErrorStateIcon />
                <MessageContainer>
                    <ErrorTitle>BOM Intelligence error</ErrorTitle>
                    <ErrorMessage>
                        The BOM you are trying to upload exceeds the maximum file limit.
                    </ErrorMessage>
                    <ErrorMessage>Please contact our support</ErrorMessage>
                </MessageContainer>
                <Button color='primary' variant='contained' onClick={buttonClick}>
                    Start again
                </Button>
            </Container>
        </BomErrorState>
    );
}
