import styled from '@emotion/styled';
import { ListItem, Drawer as MuiDrawer } from '@mui/material';

import Box from 'components/atoms/Box';

export const Root = styled(Box)({
    position: 'absolute',
    width: '100vw',
    margin: 0,
    display: 'flex',
    overflowX: 'hidden',
});

export const Container = styled(Box)(({ theme }) => ({
    backgroundColor: theme.colors.background,
    marginLeft: theme.spacing(16),
    padding: theme.spacing(0, 6, 6),
    height: '100%',
    overflow: 'visible',
    overflowX: 'hidden',
}));

export const Drawer = styled(MuiDrawer)(({ theme }) => {
    return {
        flexShrink: 0,
        '& .MuiDrawer-paper': {
            width: theme.spacing(16),
            boxSizing: 'border-box',
            backgroundColor: theme.colors.header,
            borderRight: 'none',
        },
    };
});

export const AppBar = styled(Box)(({ theme }) => ({
    paddingLeft: theme.spacing(13),
    background: theme.colors.header,
    width: '100%',
}));

export const MenuListItem = styled(ListItem)(({ theme }) => ({
    '&:hover': {
        backgroundColor: theme.colors.header,
    },
    '&.Mui-selected': {
        backgroundColor: theme.colors.header,
    },
    height: '45px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

export const MenuButton = styled(Box)(({ theme }) => ({
    cursor: 'pointer',
    textAlign: 'center',
    fontSize: 10,
    '&': {
        color: theme.palette.common.white,
    },
    '& svg': {
        width: 16,
        height: 16,
    },
}));

export const MenuAvatarButton = styled(Box)(({ theme }) => ({
    borderRadius: '50%',
    margin: theme.spacing(2, 4, 8, 4),
    backgroundColor: theme.palette.common.white,
    width: 32,
    height: 32,
    cursor: 'pointer',
    '&:hover': {
        outline: `2px solid ${theme.palette.primary.light}`,
    },
    '& svg': {
        margin: theme.spacing(2),
        fill: theme.palette.primary.dark,
        width: 16,
        height: 16,
    },
}));
