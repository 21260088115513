import { useState } from 'react';

import { showSecurityToast } from 'components/molecules/SecurityToast/SecurityToast';
import useRevisionChangeHook from 'components/templates/ProjectHistory/ProjectHistoryPanel/hooks/RevisionChangeHook';
import { ProcessedRevision } from 'components/templates/ProjectHistory/ProjectHistoryView/ProjectHistoryView';

export const useProjectHistoryLinePanel = ({ revision }: { revision: ProcessedRevision }) => {
    const { changeCurrentRevision } = useRevisionChangeHook(revision);
    const [height, setHeight] = useState(0);

    const onProjectTitle = {
        handler: () => {
            showSecurityToast(revision);
            changeCurrentRevision();
        },
        tip: 'Switch currently in use version to this one',
    };

    const onResize = (height: number) => setHeight(height);

    return {
        onProjectTitle,
        onResize,
        height,
    };
};
