import { useEffect, useState } from 'react';

const useRevisionsActionStatus = () => {
    const [revisionsActionInProgress, setRevisionsActionInProgress] = useState(false);

    const restoringRevisionState = () => {
        setRevisionsActionInProgress(true);
    };

    const restoredRevisionState = () => {
        setRevisionsActionInProgress(false);
    };

    useEffect(() => {
        document.addEventListener('restoringRevision', restoringRevisionState);
        document.addEventListener('revisionRestored', restoredRevisionState);
        document.addEventListener('deletingRevision', restoringRevisionState);
        document.addEventListener('revisionDeleted', restoredRevisionState);

        return () => {
            document.removeEventListener('restoringRevision', restoringRevisionState);
            document.removeEventListener('revisionRestored', restoredRevisionState);
            document.removeEventListener('deletingRevision', restoringRevisionState);
            document.removeEventListener('revisionDeleted', restoredRevisionState);
        };
    }, []);

    return {
        revisionsActionInProgress,
    };
};

export default useRevisionsActionStatus;
