import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import * as Progress from '@radix-ui/react-progress';

export interface ProgressIndicatorProps extends Progress.ProgressIndicatorProps {
    progress: number;
}

const loopAnimation = keyframes`
    0% {
        left:0;
        right:100%;
        width:0;
    }
    10% {
        left:0;
        right:90%;
        width:10%;
    }
    90% {
        right:0;
        left:90%;
        width:10%;
    }
    100% {
        left:100%;
        right:0;
        width:0;
    }
`;

export const ProgressRoot = styled(Progress.Root)(({ theme }) => ({
    position: 'absolute',
    width: '100vw',
    top: 0,
    left: 0,
    overflow: 'hidden',
    background: theme.colors.progressIndicator.background,
    height: 4,
    transform: 'translateZ(0)',
    zIndex: 2000,
}));

// @ts-ignore
ProgressRoot.defaultProps = { 'data-testid': 'ProgressBar' };

export const ProgressIndicator = styled(Progress.Indicator)<ProgressIndicatorProps>(
    ({ progress, theme }) => ({
        backgroundImage: `linear-gradient(to right, ${theme.colors.progressIndicator.foreground} 0px, ${theme.colors.progressIndicator.foreground} 0px)`,
        width: '100%',
        height: '100%',
        transition: 'transform 150ms cubic-bezier(0.65, 0, 0.35, 1)',
        transform: `translateX(-${100 - progress}%)`,
    })
);

export const ProgressLoop = styled(Progress.Indicator)(({ theme }) => ({
    position: 'absolute',
    backgroundImage: `linear-gradient(to right, ${theme.colors.progressIndicator.foreground} 0px, ${theme.colors.progressIndicator.foreground} 0px)`,
    top: 0,
    right: '100%',
    height: '100%',
    transition: 'transform 150ms cubic-bezier(0.65, 0, 0.35, 1)',
    animation: `${loopAnimation} 1.5s linear infinite`,
}));
