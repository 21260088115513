import React, { useEffect } from 'react';

import {
    StyledToggleGroup,
    StyledToggleGroupItem,
} from 'components/atoms/SegmentedControl/SegmentedControl.styles';

export type SegmentedControlItem = {
    name: string;
    value: string;
};

export type SegmentedControlProps = {
    items: SegmentedControlItem[];
    defaultSelected: string;
    callback: (value: string) => void;
};

export const SegmentedControl = ({ items, defaultSelected, callback }: SegmentedControlProps) => {
    const [currentValue, setCurrentValue] = React.useState(defaultSelected);
    useEffect(() => {
        callback(currentValue);
    }, [currentValue]);

    return (
        <StyledToggleGroup
            type='single'
            value={currentValue}
            defaultValue={defaultSelected}
            aria-label='View type'
            onValueChange={(value) => {
                if (value) setCurrentValue(value);
            }}
        >
            {items.map((item) => (
                <StyledToggleGroupItem
                    css={{ cursor: 'pointer' }}
                    key={item.value}
                    id={item.value}
                    value={item.value}
                >
                    {item.name}
                </StyledToggleGroupItem>
            ))}
        </StyledToggleGroup>
    );
};
